<template>
  <div class="CommonBgColor">
    <!-- 废弃 -->
    
    <!-- 现在使用 -->
    <header class="color-mode-title">
      <template v-if="colorGRB"> RGB </template>
      <template v-else> CMYK </template>
    </header>
    <div class="color-content-box">
      <template v-if="colorGRB">
        <div class="no-color" @click="clickBusColor" ref="styleColor">
          <img src="../../../../assets/img/color/nocolor.png" alt="" />
        </div>
        <!-- 调色板主体色块 -->
        <div
          class="color-content-header"
          v-for="(item, index) in noNeedMoneyColors"
          :key="index"
        >
          <div
            class="color-content-small color-content-samll-header"
            :style="{
              'background-color': 'rgb(' + item.headerColor.headerColor + ')',
            }"
            @mouseover="
              mouseover(
                index,
                item.headerColor.headerColorId,
                item.headerColor.isMember
              )
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.headerColor.headerColorId,
                item.headerColor.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorOne.smallColorOne + ')',
            }"
            @mouseover="
              mouseover(
                index,
                item.smallColor.smallColorOne.smallColorId,
                item.isMember
              )
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorOne.smallColorId,
                item.smallColor.smallColorOne.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorTwo.smallColorTwo + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorTwo.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorTwo.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorThree.smallColorThree + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorThree.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorThree.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorFour.smallColorFour + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorFour.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorFour.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorFive.smallColorFive + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorFive.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorFive.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
          <div
            class="color-content-small"
            :style="{
              'background-color':
                'rgb(' + item.smallColor.smallColorSix.smallColorSix + ')',
            }"
            @mouseover="
              mouseover(index, item.smallColor.smallColorSix.smallColorId)
            "
            @mouseleave="mouseleave"
            @click="
              clickBusColor(
                index,
                item.smallColor.smallColorSix.smallColorId,
                item.smallColor.smallColorTwo.isMember
              )
            "
            ref="styleColor"
          >
            <div class="color-need-money">
              <a-icon
                v-if="item.headerColor.needMoney != ''"
                :type="item.headerColor.needMoney"
                class="need-lock"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <section class="cmyk-color-box">
          <div
            class="cmyk-color-item"
            v-for="(item, index) in cmykColors"
            :key="index"
          >
            <section
              class="cmyk-show-color"
              v-for="(sitem, sindex) in item.colorsValue"
              :key="sindex"
              :style="{
                'background-color': 'rgb' + sitem.rgbValue,
              }"
              @click="setColor('rgb' + sitem.rgbValue)"
            ></section>
          </div>
        </section>
      </template>

      <!-- 标准颜色 -->
      <div class="color-content-max-titel flex-between" @click.stop="">
        <div class="standard-colors">
          {{ $getStringObj.getString($Strings.Standard_Colors) }}
        </div>
        <section class="color-mode">
          <a-checkbox :checked="colorGRB" @change="onChangeColorMode">
            <span class="color-style"> GRB </span>
          </a-checkbox>
          <a-checkbox :checked="colorCMYK" @change="onSwitchColorMode">
            <span class="color-style"> CMYK </span>
          </a-checkbox>
        </section>
      </div>
      <div
        class="color-content-max-box color"
        v-for="(item, index) in noNeedMoneyColorNorm"
        :key="item.ColorNormColorId"
      >
        <div
          class="color-content-small"
          :style="{ 'background-color': 'rgb(' + item.ColorNormColor + ')' }"
          @mouseover="mouseover(index, item.ColorNormColorId)"
          @mouseleave="mouseleave"
          @click="clickBusColor(index, item.ColorNormColorId, item.isMember)"
          ref="styleColor"
        >
          <div class="color-need-money">
            <a-icon
              v-if="item.needMoney != ''"
              :type="item.needMoney"
              class="need-lock"
            />
          </div>
        </div>
      </div>
      <!-- 更多颜色  -->
      <div class="color-content-max-titel" @click.stop="moreColor">
        <span class="cursor-pointer">
          {{ $getStringObj.getString($Strings.More_Colors) }}
        </span>
        <!--  @mouseover="mouseover(index, item.ColorNormColorId)"
          @mouseleave="mouseleave"
          @click="clickBusColor(index, item.ColorNormColorId, item.isMember)"
              v-if="item.needMoney != ''"
              :type="item.needMoney" -->
        <!-- <div
          class="color-content-small"
          :style="{ 'background-color': 'rgb(255, 152, 152)','position': 'absolute',
          'margin-top': '12px' }"
              >
              </div>
              <div style="width: 100px;
              position: relative;
              top: 14px;
              left: 25px;">
              修改
              <a-slider
                    v-model="opacity"
                    :tooltip-visible="false"
                    :min="0"
                    :max="100"
                    @change="changeSliderVal"
                  />
              </div>
              <div class="slider-val" >{{opacity}}%</div>
              <div class="more-colors-img" @click="moreColor">
                  <img src="../../../../assets/img/mapIcon/res/mipmap-right-menu/more_color.svg"/>
              </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { cmykColors } from "./colorData.js";
import { localStorageKey } from "../../../../assets/js/GlobalVariable.js";

export default {
  name: "CommonBgColor",
  data() {
    return {
      colorGRB: true, //true显示rgb颜色
      colorCMYK: false, //true显示cmyk颜色
      cmykColors,
      Colors: [
        { //1column
          headerColor: {
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "255, 255, 255",
              smallColorId: 1,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "241, 241, 241",
              smallColorId: 2,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "194, 194, 194",
              smallColorId: 3,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "175, 175, 175",
              smallColorId: 4,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "155, 155, 155",
              smallColorId: 5,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "134, 134, 134",
              smallColorId: 6,
              isMember: true,
              needMoney: "",
            },
          },
        },
        { //2column
          headerColor: {
            headerColor: "2, 2, 2",
            headerColorId: 7,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "126, 126, 126",
              smallColorId: 8,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "105, 105, 105",
              smallColorId: 9,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "89, 89, 89",
              smallColorId: 10,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "69, 69, 69",
              smallColorId: 11,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "48, 48, 48",
              smallColorId: 12,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "25, 25, 25",
              smallColorId: 13,
              isMember: true,
              needMoney: "",
            },
          },
        },

        { //3column
          headerColor: {
            headerColor: "94, 132, 255",
            headerColorId: 14,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "234, 237, 255",
              smallColorId: 15,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "210, 217, 255",
              smallColorId: 16,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "182, 194, 255",
              smallColorId: 17,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "146, 167, 255",
              smallColorId: 18,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "82, 116, 225",
              smallColorId: 19,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "67, 95, 188",
              smallColorId: 20,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //4column
          headerColor: {
            headerColor: "92, 157, 255",
            headerColorId: 21,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "234, 240, 255",
              smallColorId: 22,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "210, 223, 255",
              smallColorId: 23,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "181, 204, 255",
              smallColorId: 24,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "146, 182, 255",
              smallColorId: 25,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "80, 138, 225",
              smallColorId: 26,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "65, 114, 188",
              smallColorId: 27,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //5column
          headerColor: {
            headerColor: "204, 126, 176",
            headerColorId: 28,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "246, 236, 242",
              smallColorId: 29,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "236, 216, 228",
              smallColorId: 30,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "226, 192, 212",
              smallColorId: 31,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "215, 163, 195",
              smallColorId: 32,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "179, 110, 155",
              smallColorId: 33,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "149, 91, 128",
              smallColorId: 34,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //6column
          headerColor: {
            headerColor: "249, 65, 57",
            headerColorId: 35,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "255, 149, 149",
              smallColorId: 36,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "255, 82, 82",
              smallColorId: 37,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "255, 60, 0",
              smallColorId: 38,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "227, 44, 44",
              smallColorId: 39,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "181, 39, 39",
              smallColorId: 40,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "147, 39, 39",
              smallColorId: 41,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //7column
          headerColor: {
            headerColor: "107, 116, 183",
            headerColorId: 42,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "235, 236, 243",
              smallColorId: 43,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "212, 214, 230",
              smallColorId: 44,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "186, 188, 216",
              smallColorId: 45,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "153, 158, 200",
              smallColorId: 46,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "93, 101, 161",
              smallColorId: 47,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "77, 83, 134",
              smallColorId: 48,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //8column
          headerColor: {
            headerColor: "248, 174, 26",
            headerColorId: 49,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "254, 242, 231",
              smallColorId: 50,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "252, 227, 204",
              smallColorId: 51,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "251, 211, 171",
              smallColorId: 52,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "249, 194, 126",
              smallColorId: 53,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "218, 153, 21",
              smallColorId: 54,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "182, 127, 16",
              smallColorId: 55,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //9column
          headerColor: {
            headerColor: "75, 173, 208",
            headerColorId: 56,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "233, 241, 247",
              smallColorId: 57,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "208, 227, 238",
              smallColorId: 58,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "177, 211, 228",
              smallColorId: 59,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "139, 193, 218",
              smallColorId: 60,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "65, 152, 183",
              smallColorId: 61,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "53, 126, 152",
              smallColorId: 62,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //10column
          headerColor: {
            headerColor: "0, 175, 84",
            headerColorId: 63,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "231, 242, 233",
              smallColorId: 64,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "203, 227, 209",
              smallColorId: 65,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "170, 212, 179",
              smallColorId: 66,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "124, 195, 142",
              smallColorId: 67,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "0, 154, 73",
              smallColorId: 68,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "0, 128, 59",
              smallColorId: 69,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //11column
          headerColor: {
            headerColor: "243, 133, 144",
            headerColorId: 70,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "253, 237, 238",
              smallColorId: 71,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "250, 217, 219",
              smallColorId: 72,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "248, 194, 198",
              smallColorId: 73,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "245, 167, 174",
              smallColorId: 74,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "214, 116, 126",
              smallColorId: 75,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "179, 96, 104",
              smallColorId: 76,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
        { //12column
          headerColor: {
            headerColor: "77, 199, 188",
            headerColorId: 77,
            isMember: true,
            needMoney: "lock",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "233, 245, 244",
              smallColorId: 78,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "208, 235, 231",
              smallColorId: 79,
              needMoney: "lock",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "178, 224, 218",
              smallColorId: 80,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFour: {
              smallColorFour: "139, 212, 204",
              smallColorId: 81,
              needMoney: "lock",
              isMember: false,
            },
            smallColorFive: {
              smallColorFive: "67, 175, 165",
              smallColorId: 82,
              needMoney: "lock",
              isMember: false,
            },
            smallColorSix: {
              smallColorSix: "54, 146, 137",
              smallColorId: 83,
              needMoney: "lock",
              isMember: false,
            },
          },
        },
      ],
      ColorNorm: [
        {
          ColorNormColor: "192, 0, 0",
          ColorNormColorId: 84,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "255, 0, 0",
          ColorNormColorId: 85,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "249, 102, 40",
          ColorNormColorId: 86,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 192, 0",
          ColorNormColorId: 87,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 255, 0",
          ColorNormColorId: 88,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "146, 208, 80",
          ColorNormColorId: 89,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "47,176,88",
          ColorNormColorId: 90,
          needMoney: "lock",
          isMember: false,
        },
        {
          ColorNormColor: "21,150,144",
          ColorNormColorId: 91,
          needMoney: "lock",
          isMember: false,
        },
        {
          ColorNormColor: "82,196,26",
          ColorNormColorId: 92,
          needMoney: "lock",
          isMember: false,
        },
        {
          ColorNormColor: "40, 136, 204",
          ColorNormColorId: 93,
          needMoney: "lock",
          isMember: false,
        },
        {
          ColorNormColor: "17,37,185",
          ColorNormColorId: 94,
          needMoney: "lock",
          isMember: false,
        },
        {
          ColorNormColor: "112, 48, 160",
          ColorNormColorId: 95,
          needMoney: "lock",
          isMember: false,
        },
      ],

      noNeedMoneyColors: [
        { //第1列
          headerColor: {
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "255, 255, 255",
              smallColorId: 1,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "241, 241, 241",
              smallColorId: 2,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "194, 194, 194",
              smallColorId: 3,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "175, 175, 175",
              smallColorId: 4,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "155, 155, 155",
              smallColorId: 5,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "134, 134, 134",
              smallColorId: 6,
              isMember: true,
              needMoney: "",
            },
          },
        },
        { //第2列
          headerColor: {
            headerColor: "2, 2, 2",
            headerColorId: 7,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "126, 126, 126",
              smallColorId: 8,
              isMember: true,
              needMoney: "",
            },

            smallColorTwo: {
              smallColorTwo: "105, 105, 105",
              smallColorId: 9,
              isMember: true,
              needMoney: "",
            },

            smallColorThree: {
              smallColorThree: "89, 89, 89",
              smallColorId: 10,
              isMember: true,
              needMoney: "",
            },

            smallColorFour: {
              smallColorFour: "69, 69, 69",
              smallColorId: 11,
              isMember: true,
              needMoney: "",
            },

            smallColorFive: {
              smallColorFive: "48, 48, 48",
              smallColorId: 12,
              isMember: true,
              needMoney: "",
            },

            smallColorSix: {
              smallColorSix: "25, 25, 25",
              smallColorId: 13,
              isMember: true,
              needMoney: "",
            },
          },
        },
        { //第6列
          headerColor: {
            headerColor: "249, 65, 57",
            headerColorId: 14,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "255, 243, 243",
              smallColorId: 15,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "255, 215, 215",
              smallColorId: 16,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "255, 149, 149",
              smallColorId: 17,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "255, 82, 82",
              smallColorId: 18,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "255, 60, 0",
              smallColorId: 19,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "227, 44, 44",
              smallColorId: 20,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第8列
          headerColor: {
            headerColor: "255, 111, 0",
            headerColorId: 21,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "254, 242, 231",
              smallColorId: 22,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "252, 227, 204",
              smallColorId: 23,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "253, 216, 52",
              smallColorId: 24,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "255, 192, 9",
              smallColorId: 25,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "255, 159, 1",
              smallColorId: 26,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "255, 111, 0",
              smallColorId: 27,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第10列
          headerColor: {
            headerColor: "0, 175, 84",
            headerColorId: 28,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "231, 242, 233",
              smallColorId: 29,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "203, 227, 209",
              smallColorId: 30,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "170, 212, 179",
              smallColorId: 31,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "124, 195, 142",
              smallColorId: 32,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "0, 154, 73",
              smallColorId: 33,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "0, 128, 59",
              smallColorId: 34,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第12列
          headerColor: {
            headerColor: "77, 199, 188",
            headerColorId: 35,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "233, 245, 244",
              smallColorId: 36,
              isMember: true,
            },
            smallColorTwo: {
              smallColorTwo: "208, 235, 231",
              smallColorId: 37,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "178, 224, 218",
              smallColorId: 38,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "139, 212, 204",
              smallColorId: 39,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "67, 175, 165",
              smallColorId: 40,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "54, 146, 137",
              smallColorId: 41,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第3列
          headerColor: {
            headerColor: "94, 132, 255",
            headerColorId: 42,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "234, 237, 255",
              smallColorId: 43,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "210, 217, 255",
              smallColorId: 44,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "182, 194, 255",
              smallColorId: 45,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "146, 167, 255",
              smallColorId: 46,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "82, 116, 225",
              smallColorId: 47,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "67, 95, 188",
              smallColorId: 48,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第4列
          headerColor: {
            headerColor: "92, 157, 255",
            headerColorId: 49,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "234, 240, 255",
              smallColorId: 50,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "210, 223, 255",
              smallColorId: 51,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "181, 204, 255",
              smallColorId: 52,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "146, 182, 255",
              smallColorId: 53,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "80, 138, 225",
              smallColorId: 54,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "65, 114, 188",
              smallColorId: 55,
              needMoney: "",
              isMember: true,
            },
          },
        },        
        { //第9列
          headerColor: {
            headerColor: "75, 173, 208",
            headerColorId: 56,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "233, 241, 247",
              smallColorId: 57,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "208, 227, 238",
              smallColorId: 58,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "177, 211, 228",
              smallColorId: 59,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "139, 193, 218",
              smallColorId: 60,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "65, 152, 183",
              smallColorId: 61,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "53, 126, 152",
              smallColorId: 62,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第7列
          headerColor: {
            headerColor: "107, 116, 183",
            headerColorId: 63,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "235, 236, 243",
              smallColorId: 64,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "212, 214, 230",
              smallColorId: 65,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "186, 188, 216",
              smallColorId: 66,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "153, 158, 200",
              smallColorId: 67,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "93, 101, 161",
              smallColorId: 68,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "77, 83, 134",
              smallColorId: 69,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第5列
          headerColor: {
            headerColor: "204, 126, 176",
            headerColorId: 70,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "246, 236, 242",
              smallColorId: 71,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "236, 216, 228",
              smallColorId: 72,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "226, 192, 212",
              smallColorId: 73,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "215, 163, 195",
              smallColorId: 74,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "179, 110, 155",
              smallColorId: 75,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "149, 91, 128",
              smallColorId: 76,
              needMoney: "",
              isMember: true,
            },
          },
        },
        { //第11列
          headerColor: {
            headerColor: "243, 133, 144",
            headerColorId: 77,
            isMember: true,
            needMoney: "",
          },
          smallColor: {
            smallColorOne: {
              smallColorOne: "253, 237, 238",
              smallColorId: 78,
              isMember: true,
              needMoney: "",
            },
            smallColorTwo: {
              smallColorTwo: "250, 217, 219",
              smallColorId: 79,
              needMoney: "",
              isMember: true,
            },
            smallColorThree: {
              smallColorThree: "248, 194, 198",
              smallColorId: 80,
              needMoney: "",
              isMember: true,
            },
            smallColorFour: {
              smallColorFour: "245, 167, 174",
              smallColorId: 81,
              needMoney: "",
              isMember: true,
            },
            smallColorFive: {
              smallColorFive: "214, 116, 126",
              smallColorId: 82,
              needMoney: "",
              isMember: true,
            },
            smallColorSix: {
              smallColorSix: "179, 96, 104",
              smallColorId: 83,
              needMoney: "",
              isMember: true,
            },
          },
        },
        
      ],
      noNeedMoneyColorNorm: [
        {
          ColorNormColor: "192, 0, 0",
          ColorNormColorId: 84,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "255, 0, 0",
          ColorNormColorId: 85,
          isMember: true,
          needMoney: "",
        },
        {
          ColorNormColor: "249, 102, 40",
          ColorNormColorId: 86,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 192, 0",
          ColorNormColorId: 87,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "255, 255, 0",
          ColorNormColorId: 88,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "146, 208, 80",
          ColorNormColorId: 89,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "47,176,88",
          ColorNormColorId: 90,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "21,150,144",
          ColorNormColorId: 91,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "82,196,26",
          ColorNormColorId: 92,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "40, 136, 204",
          ColorNormColorId: 93,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "17,37,185",
          ColorNormColorId: 94,
          needMoney: "",
          isMember: true,
        },
        {
          ColorNormColor: "112, 48, 160",
          ColorNormColorId: 95,
          needMoney: "",
          isMember: true,
        },
      ],

      ColorClick: true,

      isMember: false,
      opacity: 100,
    };
  },
  created() {
    //是否会员
    let userData = localStorage.getItem("meMessage");
    if (userData == null || userData == "") {
      return;
    }
    if (userData != null) {
      let userDataObj = JSON.parse(userData);
      this.isMember = userDataObj.isMember;
    }
  },
  mounted() {
    this.isMember = true; //颜色收费作废
    let colorMode = JSON.parse(localStorage.getItem(localStorageKey.ColorMode));
    if (colorMode == false) {
      this.colorGRB = colorMode;
      this.colorCMYK = !colorMode;
    }
  },
  components: {},
  methods: {
    ...mapMutations([
      "backGroundColor",
      "showMoreColor",
      "showChunkColor",
      "backBorderColor",
      "backTextcolor",
      "backBorderMinorStyle",
      "backLineColor",
      "backFillColor",
      "backBaoWeiLineColor",
      "backBaoWeiFillColor",
      "textShadowColor",            
      "backChartFillColor",
      "backSpanTextColor",
      "backSpanTextBgColor",
      "backSpanTextShadowColor",
      "isShowShoppingModel",
    ]),
    mouseover(index, Id) {
      // var busColor = this.$refs.styleColor[(index, Id)].style.backgroundColor;
      // if (this.$store.state.colorAccout == 0) {
      //   this.backGroundColor(busColor);
      // } else if (this.$store.state.colorAccout == 1) {
      //   this.backLineColor(busColor);
      // } else if (this.$store.state.colorAccout == 2) {
      //   this.backTextcolor(busColor);
      // } else if (this.$store.state.colorAccout == 3) {
      //   this.backBorderColor(busColor);
      // } else if (this.$store.state.colorAccout == 4) {
      //   this.backFillColor(busColor);
      // } else if (this.$store.state.colorAccout == 5) {
      //   this.backBorderMinorStyle(busColor);
      // }
    },
    mouseleave() {
      // if (this.ColorClick) {
      //   if (this.$store.state.colorAccout == 0) {
      //     this.backGroundColor("rgb(255, 255, 255)");
      //   } else if (this.$store.state.colorAccout == 1) {
      //     this.backLineColor("rgb(138, 218, 205)");
      //   } else if (this.$store.state.colorAccout == 2) {
      //     this.backTextcolor("rgb(51, 51, 51)");
      //   } else if (this.$store.state.colorAccout == 3) {
      //     this.backBorderColor("rgb(138, 218, 205)");
      //   } else if (this.$store.state.colorAccout == 4) {
      //     this.backFillColor("rgb(255, 255, 255)");
      //   } else if (this.$store.state.colorAccout == 5) {
      //     this.backBorderMinorStyle("rgb(138, 218, 205)");
      //   }
      // }
    },
    moreColor() {
      this.showMoreColor(true);
    },
    onChangeColorMode(checkedValues) {
      // 切换rgb或cmyk颜色
      this.colorGRB = !this.colorGRB;
      this.colorCMYK = !this.colorGRB;
    },
    onSwitchColorMode(checkedValues) {
      // 切换rgb或cmyk颜色
      this.colorCMYK = !this.colorCMYK;
      this.colorGRB = !this.colorCMYK;
    },
    clickBusColor(index, Id, isMember) {
      // //是否会员
      // if (!isMember) {
      //   this.isShowShoppingModel(true);
      //   this.showMoreColor(false);
      //   return;
      // }
      // if (!this.isMember) {
      //   const array = [
      //     14, 21, 28, 35, 42, 49, 56, 63, 70, 77, 19, 20, 26, 27, 33, 34, 40,
      //     47, 48, 54, 55, 61, 62, 68, 69, 75, 76, 82, 83, 91, 92, 93, 94, 95,
      //   ];
      //   for (let index = 0; index < array.length; index++) {
      //     const colorId = array[index];
      //     if (Id == colorId) {
      //       this.isShowShoppingModel(true);
      //       this.showMoreColor(false);
      //       return;
      //     }
      //   }
      // }
      this.ColorClick = false;
      setTimeout(() => {
        this.ColorClick = true;
      }, 100);
      //透明色 busColor = 1
      var busColor = 1
      if (this.$refs.styleColor[(index, Id)] == undefined) {
        busColor = 1; //"rgb(248, 248, 255)"
      } else {
        busColor = this.$refs.styleColor[(index, Id)].style.backgroundColor;
      }
      this.setColor(busColor);
      this.showChunkColor(false);
    },
    setColor(busColor) {
      if (this.$store.state.colorAccout == 0) {
        this.backGroundColor(busColor);
        setTimeout(() => {
          this.backGroundColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 1) {
        this.backLineColor(busColor);
        setTimeout(() => {
          this.backLineColor(null);
        }, 100);
      } else if (
        this.$store.state.colorAccout == 2 ||
        this.$store.state.colorAccout == 8
      ) {
        //设置文字颜色，或者关联线文字颜色，或者包裹线文字颜色。
        this.backTextcolor(busColor);
        setTimeout(() => {
          this.backTextcolor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 3) {
        this.backBorderColor(busColor);
        setTimeout(() => {
          this.backBorderColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 4) {
        this.backFillColor(busColor);
        setTimeout(() => {
          this.backFillColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 5) {
        this.backBorderMinorStyle(busColor);
        setTimeout(() => {
          this.backBorderMinorStyle(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 6) {
        this.backBaoWeiLineColor(busColor);
        setTimeout(() => {
          this.backBaoWeiLineColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 7) {
        this.backBaoWeiFillColor(busColor);
        setTimeout(() => {
          this.backBaoWeiFillColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == 9) {
        this.textShadowColor(busColor);
        setTimeout(() => {
          this.textShadowColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == "CHART_COLOR") {
        this.backChartFillColor(busColor);
        setTimeout(() => {
          this.backChartFillColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_STYLE") {
        this.backSpanTextColor(busColor);
        setTimeout(() => {
          this.backSpanTextColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_BG_COLOR_STYLE") {
        this.backSpanTextBgColor(busColor);
        setTimeout(() => {
          this.backSpanTextBgColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_SHADOW_COLOR_STYLE") {
        this.backSpanTextShadowColor(busColor);
        setTimeout(() => {
          this.backSpanTextShadowColor(null);
        }, 1000);
      }
    },
    changeSliderVal() {
      // console.log("changeSliderVal")
    },
  },
  watch: {
    colorGRB(newVal) {
      localStorage.setItem(localStorageKey.ColorMode, newVal);
    },
  },
};
</script>
<style lang='less' scoped>
.CommonBgColor {
  width: 264px;
  // height: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px;
  position: absolute;
  z-index: 9999;
  background-color: #ffffff;
}

.color-mode-title {
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-weight: 450;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-content-box {
  position: relative;
  margin: 0 5px;
}
.color-content-header {
  width: 20px;
  margin-right: 1px;
  height: 100%;
  float: left;
}
.color-content-small {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 1px;
  cursor: pointer;
}
.color-content-samll-header {
  margin-bottom: 3px;
}
.cmyk-color-box {
  display: flex;
  .cmyk-color-item {
    width: 20px;
    margin-right: 1px;
    display: flex;
    flex-direction: column;
    .cmyk-show-color {
      width: 100%;
      height: 16px;
      cursor: pointer;
    }
  }
}
.color-content-max-titel {
  font-size: 12px;
  line-height: 40px;
  user-select: none;
  padding-left: 5px;
  font-weight: 550;
  color: #000;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  .color-mode {
    display: flex;
    justify-content: space-between;
    .color-style {
      color: #333333;
      font-size: 12px;
      margin-left: -6px;
    }
  }
}
.color-content-max-box {
  position: relative;
}
.color-content-max-box .color-content-small {
  margin-right: 1px;
  float: left;
}
/* .color-content-small:hover {
  border: 1px solid #fc4c51;
} */
.no-color {
  position: absolute;
  top: -5px;
}
.color-need-money {
  width: 20px;
  height: 20px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.need-lock {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 20px;
  height: 20px;
  transition: 0.5s;
}
.need-lock:hover {
  border: 1px solid #fc4c51;
  transition: 0.5s;
}

.slider-val {
  width: 38px;
  height: 21px;
  background: #ffffff;
  border: 1px solid #d4d4d4;
  opacity: 1;
  border-radius: 4px;
  position: relative;
  left: 143px;
  top: -12px;
  line-height: 21px;
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.more-colors-img {
  position: relative;
  height: 20px;
  width: 20px;
  left: 226px;
  top: -44px;
  cursor: pointer;
}
</style>