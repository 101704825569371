
import FrameworkCell from "./FrameworkCell"
import NodeLayoutType from "../datatype/NodeLayoutType";
import LineLayout from "../datatype/LineLayout";
import MindElementShapeType from "../datatype/MindElementShapeType";


class MindMapFramework {
    constructor(selectedIndex = 0) {
        this.currentSelectedIndex = selectedIndex;
        this.MindMapFrameworkList =
            [new FrameworkCell(0, "mind_skeleton_0"), new FrameworkCell(1, "mind_skeleton_1"), new FrameworkCell(2, "mind_skeleton_2"),
            new FrameworkCell(3, "mind_skeleton_3"), new FrameworkCell(4, "mind_skeleton_4"), new FrameworkCell(5, "mind_skeleton_5"),
            new FrameworkCell(6, "mind_skeleton_6"), new FrameworkCell(7, "mind_skeleton_7"), new FrameworkCell(8, "mind_skeleton_8"),

            new FrameworkCell(9, "mind_skeleton_9"), new FrameworkCell(10, "mind_skeleton_10"), new FrameworkCell(11, "mind_skeleton_11"),
            new FrameworkCell(12, "mind_skeleton_12"), new FrameworkCell(13, "mind_skeleton_13"), new FrameworkCell(14, "mind_skeleton_14"),
            new FrameworkCell(15, "mind_skeleton_15"), new FrameworkCell(16, "mind_skeleton_16"), new FrameworkCell(17, "mind_skeleton_17"),

            new FrameworkCell(18, "mind_skeleton_18"), new FrameworkCell(19, "mind_skeleton_20"), new FrameworkCell(20, "mind_skeleton_19"),
            new FrameworkCell(21, "mind_skeleton_21"), new FrameworkCell(22, "mind_skeleton_22"), new FrameworkCell(23, "mind_skeleton_23"),
            new FrameworkCell(24, "mind_skeleton_24"), new FrameworkCell(25, "mind_skeleton_25"), new FrameworkCell(26, "mind_skeleton_26"),

            new FrameworkCell(27, "mind_skeleton_27"), new FrameworkCell(28, "mind_skeleton_28"), new FrameworkCell(29, "mind_skeleton_29"),
            new FrameworkCell(30, "mind_skeleton_30"), new FrameworkCell(31, "mind_skeleton_31"), new FrameworkCell(32, "mind_skeleton_32"),
            new FrameworkCell(33, "mind_skeleton_33")];
        this.mindMapRootLayout = [
            NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
            NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE,
            NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT,

            NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT,
            NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT,
            NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM,

            NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_FISH_RIGHT, NodeLayoutType.LAYOUT_FISH_LEFT,
            NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT, NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT,
            NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT, NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT,

            NodeLayoutType.LAYOUT_VERTICAL_RIGHT, NodeLayoutType.LAYOUT_CIRCLE, NodeLayoutType.LAYOUT_TRIANGLE,
            NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM_HORIZONTAL,
            NodeLayoutType.LAYOUT_FORM_HORIZONTAL];

        this.mindMapSubjectRootLayout = [
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT],
            [NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT],
            [NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT],

            [NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM],
            [NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM],
            [NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM, NodeLayoutType.LAYOUT_BOTTOM],

            [NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT, NodeLayoutType.LAYOUT_LEFT],

            [NodeLayoutType.LAYOUT_TOP_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TOP_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TOP_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT],
            [NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT, NodeLayoutType.LAYOUT_TREE_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM],
            [NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM, NodeLayoutType.LAYOUT_FORM],
            [NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL],

            [NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL, NodeLayoutType.LAYOUT_FORM_HORIZONTAL],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, NodeLayoutType.LAYOUT_BRACKETS_RIGHT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],
            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT],

            [NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT, NodeLayoutType.LAYOUT_RIGHT] ]

        this.mindMapRootLineLayout = [
                                            LineLayout.STRAIGHT_CURVE_LINE, LineLayout.CURVE_LINE_2, LineLayout.CURVE_LINE,
                                            LineLayout.CURVE_LINE, LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE, LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE,
                                            LineLayout.CURVE_LINE, LineLayout.STRAIGHT_CURVE_LINE, LineLayout.CURVE_LINE,

                                            LineLayout.RIGHT_ANGLE_LINE, LineLayout.CURVE_LINE_2, LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE,
                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_LINE, LineLayout.STRAIGHT_CURVE_LINE,

                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.CURVE_LINE_2, LineLayout.CURVE_LINE_2,
                                            LineLayout.CURVE_LINE, LineLayout.CURVE_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,

                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                            LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                            LineLayout.CURVE_LINE_2
        ];

        this.mindMapSubjectLineLayout = [
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.CURVE_LINE_2, LineLayout.CURVE_LINE_2,
                                    LineLayout.CURVE_LINE, LineLayout.RIGHT_ANGLE_LINE, LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE,
                                    LineLayout.CURVE_LINE, LineLayout.CURVE_LINE, LineLayout.CURVE_LINE,

                                    LineLayout.RIGHT_ANGLE_LINE, LineLayout.CURVE_LINE_2, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,

                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.CURVE_LINE_2, LineLayout.CURVE_LINE_2,
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,

                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE,
                                    LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.RIGHT_ANGLE_CORNER_LINE, LineLayout.STRAIGHT_LINE_2,
                                    LineLayout.CURVE_LINE
            ];


        this.rootNodeShapeType = [
            MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner, MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner,
            MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.CornerShadow, MindElementShapeType.Corner,
            MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner, MindElementShapeType.Corner,

            MindElementShapeType.CornerShadow, MindElementShapeType.Corner, MindElementShapeType.Oblique_corner,
            MindElementShapeType.Corner, MindElementShapeType.Two_Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle,

            MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner,  MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Two_Underline, MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle, MindElementShapeType.CornerShadow,

            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Corner,
            MindElementShapeType.Corner];

        this.subjectNodeShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Two_Underline,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Semicircle, MindElementShapeType.Corner, MindElementShapeType.Corner,

            MindElementShapeType.Corner_Right, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Two_Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,

            MindElementShapeType.Corner, MindElementShapeType.Two_Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner,  MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Two_Underline, MindElementShapeType.Corner, MindElementShapeType.CornerShadow,

            MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Circular, MindElementShapeType.Corner,
            MindElementShapeType.Corner];

        this.sonSubjectNodeShapeType = [
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Underline,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Underline,

            MindElementShapeType.Underline, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Underline, MindElementShapeType.Underline,

            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Underline,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Underline, MindElementShapeType.Corner, MindElementShapeType.Corner,

            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Corner, MindElementShapeType.Corner, MindElementShapeType.Corner,
            MindElementShapeType.Underline, MindElementShapeType.Circular, MindElementShapeType.Underline,
            MindElementShapeType.Underline];

        this.sonSubjectNodeFullColor = [
            true, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false,
            false, false, false];
        this.rootNodeBorderWidths = [
            1.5, 1.5, 2.5,
            1.5, 2.0, 1.5,
            1.5, 1.5, 1.5,
            
            2.0, 3.0, 1.5,
            2.0, 3.0, 2.0,
            2.0, 4.0, 2.0,
            
            1.5, 1.0, 1.0,
            1.5, 1.5, 2.0,
            3.0, 1.5, 1.5,
            
            2.0, 1.5, 2.0,
            1.5, 1.5, 1.5,
            1.5, 1.5, 1.5]

        this.rootNodeTextSize =  [32, 32, 32,
            32, 32, 32,
            32, 32, 32,

            32, 32, 32,
            32, 36, 36,
            32, 36, 32,

            32, 24, 24,
            32, 32, 32,
            32, 32, 32,

            32, 32, 32,
            36, 36, 36,
            36, 32, 32]

        this.subjectBorderWidths = [3.0, 3.0, 3.0,
                                    2.0, 5.0, 3.0,
                                    3.0, 3.0, 2.0,
                                    
                                    2.0, 3.0, 3.0,
                                    2.0, 2.0, 0.0,
                                    2.0, 2.0, 2.0,
                                    
                                    2.0, 3.0, 3.0,
                                    2.0, 2.0, 2.0,
                                    2.0, 2.0, 2.0,
                                    
                                    2.0, 2.0, 2.0,
                                    2.0, 2.0, 2.0,
                                    2.0, 2.0, 2.0]
        this.sonSubjectBorderWidths = [0.0, 3.0, 3.0,
                                    0.0, 1.0, 0.0,
                                    0.0, 0.0, 3.0,
                                    
                                    2.0, 3.0, 0.0,
                                    1.0, 2.0, 0.0,
                                    2.0, 1.0, 0.0,
                                    
                                    0.0, 0.0, 2.0,
                                    0.0, 0.0, 0.0,
                                    2.0, 0.0, 0.0,
                                    
                                    0.0, 0.0, 0.0,
                                    0.0, 0.0, 0.0,
                                    0.0, 0.0, 0.0]

        this.lineWidths = [  3.0, 3.0, 3.0,
                            3.0, 1.0, 2.0,
                            3.0, 3.0, 3.0,
                            
                            2.0, 3.0, 3.0,
                            2.0, 2.0, 2.0,
                            2.0, 1.0, 2.0,
                            
                            2.0, 2.0, 2.0,
                            2.0, 2.0, 2.0,
                            2.0, 2.0, 2.0,
                            
                            2.0, 2.0, 2.0,
                            2.0, 2.0, 2.0,
                            2.0, 2.0, 2.0]
        this.rootNodeShowFullColor = [
            true, true, false,
            true, true, true,
            true, true, true,
            
            false, false, true,
            false, false, false,
            false, false, false,
            
            true, true, true,
            true, false, false,
            false, true, true,
            
            true, false, false,
            true, false, true,
            false, true, true];
        this.lineThicken = [
                                true, true, true,
                                true, true, true,
                                true, true, false,
                                
                                true, false, true,
                                true, true, true,
                                true, true, false,
                                
                                true, true, true,
                                false, false, true,
                                true, false, true,
                                
                                true, false, false,
                                false, false, false,
                                false, true, true];
        
        this.rootNodeShowBorderColor = [
                                true, true, true,
                                true, true, true,
                                true, true, true,
                                
                                true, true, true,
                                true, true, false,
                                true, true, true,
                                
                                true, true, true,
                                true, false, true,
                                true, true, true,
                                
                                true, false, false,
                                true, true, true,
                                true, true, true];
        
        this.subjectNodeShowFullColor = [
                                true, false, false,
                                false, false, true,
                                true, true, false,
                                
                                false, false, true,
                                true, false, false,
                                false, false, true,
                                
                                true, false, true,
                                false, true, true,
                                false, false, false,
                                
                                false, true, true,
                                true, false, true,
                                false, true, true,
                                true, true, true,
                                true, true, true];
        
        this.subjectNodeShowBorderColor = [
                                true, true, true,
                                true, true, true,
                                true, true, true,
                                
                                true, true, true,
                                true, true, false,
                                true, true, true,
                                
                                true, true, true,
                                true, true, true,
                                true, true, true,
                                
                                true, true, true,
                                true, true, true,
                                true, true, true];
        
        this.sonSubjectNodeFullColor = [
                                true, false, false,
                                true, false, true,
                                true, true, false,
                                
                                false, false, true,
                                false, false, false,
                                false, false, false,
                                
                                true, true, false,
                                true, true, false,
                                false, false, false,
                                
                                false, false, false,
                                true, false, true,
                                false, false, false];
        this.sonSubjectNodeFullColorAlpha = [
                            0.19, 1.0, 1.0,
                            0.19, 1.0, 0.19,
                            0.19, 0.19, 1.0,
                            
                            1.0, 1.0, 0.19,
                            1.0, 1.0, 1.0,
                            1.0, 1.0, 1.0,
                            
                            0.19, 0.19, 1.0,
                            0.19, 0.19, 1.0,
                            1.0, 1.0, 1.0,
                            
                            1.0, 1.0, 1.0,
                            0.19, 1.0, 0.19,
                            1.0, 1.0, 1.0]

    }

    getCurrentSelectedIndex() {
        return Math.min(Math.max(this.currentSelectedIndex, 0), this.mindMapRootLayout.length - 1);
    }

    getMindMapRootLayout() {
        return this.mindMapRootLayout[this.getCurrentSelectedIndex()];
    }

    getMindMapSubjectLayout() {
        return this.mindMapSubjectRootLayout[this.getCurrentSelectedIndex()];
    }

    getMindMapRootLineLayout() {
        return this.mindMapRootLineLayout[this.getCurrentSelectedIndex()];
    }

    getMindMapSubjectLineLayout() {
        return this.mindMapSubjectLineLayout[this.getCurrentSelectedIndex()];
    }

    getRootNodeShapeType() {
        return this.rootNodeShapeType[this.getCurrentSelectedIndex()];
    }

    getSubjectNodeShapeType() {
        return this.subjectNodeShapeType[this.getCurrentSelectedIndex()];
    }

    getSonSubjectNodeShapeType() {
        return this.sonSubjectNodeShapeType[this.getCurrentSelectedIndex()];
    }

    getSubjectBorderWidths() {
        return this.subjectBorderWidths[this.getCurrentSelectedIndex()];
    }

    getSonSubjectBorderWidths() {
        return this.sonSubjectBorderWidths[this.getCurrentSelectedIndex()];
    }

    isSonSubjectNodeFullColor() {
        return this.sonSubjectNodeFullColor[this.getCurrentSelectedIndex()];
    }

    getLineWidth() {
        return this.lineWidths[this.getCurrentSelectedIndex()];
    }
    
    getSonSubjectNodeFullColorAlpha() {
        return this.sonSubjectNodeFullColorAlpha[this.getCurrentSelectedIndex()];
    }
    
    getSonSubjectNodeFullColorAlpha() {
        return this.sonSubjectNodeFullColorAlpha[this.getCurrentSelectedIndex()];
    }

    isRootNodeShowFullColor() {
        return this.rootNodeShowFullColor[this.getCurrentSelectedIndex()];
    }

    isSubjectNodeShowFullColor() {
        return this.subjectNodeShowFullColor[this.getCurrentSelectedIndex()];
    }

    isRootNodeShowBorderColor() {
        return this.rootNodeShowBorderColor[this.getCurrentSelectedIndex()];
    }

    isSubjectNodeShowBorderColor() {
        return this.subjectNodeShowBorderColor[this.getCurrentSelectedIndex()];
    }

    getRootNodeTextSize() {
        return this.rootNodeTextSize[this.getCurrentSelectedIndex()];
    }

    getLineThicken() {
        return this.lineThicken[this.getCurrentSelectedIndex()];
    }

    getRootBorderWidths() {
        return this.rootNodeBorderWidths[this.getCurrentSelectedIndex()];
    }
}



export default MindMapFramework
