
/**
 * Created by tony on 2019/12/26
 */
import Config from "../../../core/core/calcule/Config"
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation"
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import IdGenerator from "../base/IdGenerator"
import LineOrientation from "../../datatype/LineOrientation"
import LineLayout from "../../datatype/LineLayout"
import MindElementType from "../../datatype/MindElementType"
import NodeLayoutType from "../../datatype/NodeLayoutType"
import LineElementContent from "../../mindelementdata/mindcontent/LineElementContent"
import BaseLayout from "./BaseLayout"
import Point from "../../../viewmodel/core/base/Point"
import EncircleShapeType from "../../datatype/EncircleShapeType"
import RectsMergeCalculation from "./RectsMergeCalculation"
import EncircleTitleLayoutType from "../../datatype/EncircleTitleLayoutType"

class RightLayout extends BaseLayout {

    constructor() {
        super()
        this.NodeVerticalSpacee = Config.NodeVerticalSpacee;
        this.SonNodeHorizontalSpacee = Config.SonNodeHorizontalSpacee;
        this.NodeHorizontalSpacee = Config.NodeHorizontalSpacee;
    }

    initConfig() {
        super.initConfig();
        this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee);
        if (this.rootTreeNode.children.length == 1 || !this.isFromLeftRightLayout) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1);
        } else if (this.rootTreeNode.children.length == 2) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 3);
        } else if (this.rootTreeNode.children.length == 3) {
            this.NodeVerticalSpacee = this.addSubjectSpcaeVertical(Config.NodeVerticalSpacee * 1.5 + Config.NodeVerticalSpacee / 2);
        }
        
        this.SonNodeVerticalSpacee = this.addSonSubjectSpcaeVertical(Config.SonNodeVerticalSpacee)
        if (this.globalLayout == LineLayout.CURVE_LINE_AVERAGE) {
            this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee * 2 + this.getSubjectLineWidth());
        } else if (this.globalLayout == LineLayout.CURVE_LINE_2 ||
            this.globalLayout == LineLayout.CURVE_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_LINE ||
            this.globalLayout == LineLayout.STRAIGHT_LINE_2) {
            this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());
        } else {
            this.NodeHorizontalSpacee = this.addSubjectSpcaeHorizontal(Config.NodeHorizontalSpacee + this.getSubjectLineWidth());
        }
        this.SonNodeHorizontalSpacee = this.addSonSubjectSpcaeHorizontal(Config.SonNodeHorizontalSpacee);
    }

    onLayout(isChange) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.setElementsPoint(isChange);
        this.hideRootFormLine();
        this.setGeneralizationElementsPoint(isChange);
        // this.setEncirclePoint();
        // this.setFloatExplainPoint(isChange);
    }

    setElementsPoint(isChange) {
        if ((!isChange || this.rootTreeNode.value.y == -1 || this.rootTreeNode.value.x == -1) && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            let left = (Config.Mind_Width - (new UiUtil).getScreenWidth()) / 2;
            let top = (Config.Mind_Height - (new UiUtil).getScreenHeight()) / 2;
            this.rootTreeNode.value.y = ((new UiUtil).getScreenHeight() - this.rootTreeNode.value.height) / 2 + top;
            this.rootTreeNode.value.x = 20 + left;
        }
        
        this.initConfig()
        this.setNodeElementsPoint(this.rootTreeNode, isChange);
        this.setNodeElementsLinePoint(this.rootTreeNode, isChange);

    }

    setEncirclePoint() {
        let encircleMindElementDataDict = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDict.length; index++) {
            let mind = this.encircleMindElementDataDict.get(encircleMindElementDataDict[index]);

            let targetIds = new Array();
            if (mind.lineContent == null) {
                continue;
            }
            if (mind.lineContent.targetIds.isEmpty()) {
                targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
            } else {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                continue;
            }
            let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;

            if (targetIds.length == 1) {
                let node = this.getNodeById(targetIds[0]);
                if (node.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let type = mind.lineContent == null ? EncircleShapeType.LAYOUT_TRAPEZOID : mind.lineContent.encircleShapeType;
                let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                let points = new EncircleNodesPointsCalculation().calcule(node, type, padding);
                let encircleNodesPoint = new Array();

                for (let index = 0; index < points.length; index++) {
                    let point = points[index]
                    encircleNodesPoint.push(new Point((point.x) - mind.x, (point.y) - mind.y));
                }
                if (mind.lineContent == null) {
                    let lineContent = new LineElementContent(new Point(), new Point(), node.value.styleColor, node.value.id);
                    lineContent.encircleNodesPoint = encircleNodesPoint;
                    lineContent.strokeColor = node.value.styleColor;
                    mind.lineContent = lineContent;
                } else {
                    mind.lineContent.encircleNodesPoint = encircleNodesPoint;
                }
            } else {
                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                for (let index = 0; index < targetIds.length; index++) {
                    let id = targetIds[index];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation(this.explainMindElementDataDict).calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;

                let rect = new RectsMergeCalculation().calcule(rectList);
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;

                mind.lineContent.encircleNodesPoint = new Array();
            }
            if (mind.lineContent != null && mind.lineContent.isContainText()) {
                if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                    mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                        mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                    mind.lineContent.textContent.width = mind.width;
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                    mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                    mind.lineContent.textContent.y = 0;
                } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                    mind.lineContent.textContent.x = 0;
                    mind.lineContent.textContent.y = 0;
                } else {
                    mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                }
            }
        }
    }

    setNodeElementsPoint(node, isChange) {
        this.setDataPoint(node.value, isChange);
        if (node.children.length == 0 || this.isLayout(node.value)) {
            return;
        }

        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
            let nodeChildrenLength = node.children.length
            for (var index = 0; index < nodeChildrenLength; index++) {
                this.setNodeElementsPoint(node.children[index], isChange);
            }
        } else {
            this.resetNodeLayout(node);
        }
    }

    setNodeElementsLinePoint(node, isChange) {
        this.setElementLine(node, isChange);
        if (node.children.length == 0) {
            return;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.setNodeElementsLinePoint(node.children[index], isChange);
        }
    }

    setDataPoint(data, isChange) {
        let node = this.getNodeById(data.id);
        if (node.isEmpty() || node.value.isHidden || this.isLayout(data)) {
            return;
        }
        this.pushCurrentLayoutNode(node);
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!parentNode.isEmpty() && parentNode.children.isEmpty()) {
            parentNode.children.push(node);
        }
        let siblingsNodeDatas = this.getSiblingsMindElementDataById(data.id);
        if (data.id == this.rootTreeNode.value.id) {
            return;
        } else {
            this.setBorderThicken(node);
            if (this.isFromLeftRightLayout && data.isFreeLayoutNode && 
                this.settingData.branchNodeFreelayout.isValue() && data.type == MindElementType.SUBJECT) {
                if (data.x > 0 && data.y > 0) {
                    return
                }
            }
            let allSiblingsNodesHeight = this.getSiblingsNodesHeight(siblingsNodeDatas);
            let parentNodeY = 0;
            if (((parentNodeData.type == MindElementType.SUBJECT ||
                parentNodeData.type == MindElementType.SON_SUBJECT ||
                parentNodeData.type == MindElementType.CONTENT_GENERALIZATION) &&
                !this.isUnderline(parentNodeData)) ||
                parentNodeData.type == MindElementType.MAIN_SUBJECT) {
                parentNodeY = parentNodeData.y + parentNodeData.height / 2;
            } else {
                parentNodeY = parentNodeData.y + parentNodeData.height;
            }
            let siblingsNodeDatasCount = siblingsNodeDatas.length;
            if (siblingsNodeDatasCount < 1) {
                return;
            }
            let siblingsNodeTopHeight = this.getTreeTopHeight(this.getNodeById(siblingsNodeDatas[0].id));
            if (siblingsNodeDatasCount == 1) {
                if (node.value.type == MindElementType.SUBJECT && this.isFromLeftRightLayout) {
                    data.y = parentNodeY - node.value.height / 2 - this.NodeVerticalSpacee - node.value.height;
                } else if ((node.value.type == MindElementType.SUBJECT ||
                    node.value.type == MindElementType.SON_SUBJECT ||
                    node.value.type == MindElementType.CONTENT_GENERALIZATION) &&
                    !this.isUnderline(node.value)) {
                    data.y = parentNodeY - node.value.height / 2;
                } else {
                    data.y = parentNodeY - node.value.height;
                }
            } else {
                let siblingsNodeBottomHeight = siblingsNodeDatasCount > 1 ? this.getTreeBottomHeight(this.getNodeById(siblingsNodeDatas[siblingsNodeDatasCount - 1].id)) : 0;
                let nodeY = parentNodeY - (allSiblingsNodesHeight - siblingsNodeTopHeight - siblingsNodeBottomHeight) / 2;

                for (let index = 0; index < siblingsNodeDatasCount; index++) {
                    let cell = siblingsNodeDatas[index];
                    if (cell.id == data.id) {
                        if (index == 0) {
                            if ((cell.type == MindElementType.SUBJECT ||
                                cell.type == MindElementType.SON_SUBJECT ||
                                cell.type == MindElementType.MAIN_SUBJECT ||
                                cell.type == MindElementType.CONTENT_GENERALIZATION) && !this.isUnderline(cell)) {
                                nodeY = nodeY - node.value.height / 2;
                            } else {
                                nodeY = nodeY - node.value.height;
                            }
                        } else {
                            let cellTop = this.getTreeTopHeight(this.getNodeById(cell.id));                            
                            if ((cell.type == MindElementType.SUBJECT ||
                                cell.type == MindElementType.SON_SUBJECT ||
                                cell.type == MindElementType.MAIN_SUBJECT ||
                                cell.type == MindElementType.CONTENT_GENERALIZATION) && !this.isUnderline(cell)) {
                                nodeY = nodeY + (cellTop - cell.height / 2);
                            } else {
                                nodeY = nodeY + (cellTop - cell.height);
                            }
                        }
                        break;
                    } else {
                        let cellNode = this.getNodeById(cell.id);
                        if (index == 0) {
                            nodeY = nodeY + this.getTreeBottomHeight(cellNode);
                        } else {
                            nodeY += this.getTreeHeight(this.getNodeById(cell.id));
                        }
                        nodeY += cell.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
                    }
                }
                data.y = nodeY;
            }
            let horizontalSpace = this.getNodeHorizontalSpace(node, parentNode);
            let parentNodeExplain = this.getNodeExplain(parentNode);
            let parentNodeExplainSpace = this.UiUtil.dip2px(4);
            if (parentNodeExplain.isEmpty() || parentNodeExplain.width /2 + parentNodeExplainSpace < parentNodeData.width/2 + horizontalSpace) {
                data.x = parentNodeData.x + parentNodeData.width + horizontalSpace;
            } else {
                data.x = parentNodeData.x + parentNodeData.width/2 + parentNodeExplain.width / 2 + parentNodeExplainSpace;
            }
        }
    }

    setElementLine(node, isChange) {
        let data = node.value;
        if (data.isHidden || this.isRootNode(node) || !this.isIncludeNodeInCurrentLayout(node)) {
            return;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentNodeData = parentNode.value;
        if (!this.isGeneralizatioData(data) && !this.isRootNode(parentNode) && 
            (parentNodeData.isEmpty() || parentNodeData.layout != NodeLayoutType.LAYOUT_RIGHT)) {
            return;
        }

        let isCreateLineData = false;
        let lineData = this.getLineData(node);
        lineData.isFreeTreeNode = false

        lineData.parentNodeId = parentNodeData.id;
        let lineLayout = this.getNodeLineLayout(node, lineData);
        if (lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            this.lineMindElementDataDict.put(lineData.id, lineData);
            this.textElementLineMindElementDataDict.put(node.value.id, lineData);
            isCreateLineData = true;
        }
        lineData.isHidden = false;
        lineData.layout = NodeLayoutType.LAYOUT_RIGHT;
        if (data.type == MindElementType.SUBJECT || 
            data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            var startX = 0;
            var startY = 0;
            var endX = 0;
            var endY = 0;

            if (lineLayout == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_2 ||
                lineLayout == LineLayout.STRAIGHT_LINE ||
                lineLayout == LineLayout.STRAIGHT_LINE_2 ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
                startX = parentNodeData.x + parentNodeData.width - this.getSubjectNodeLineSpace(node);
            } else {
                startX = parentNodeData.x + parentNodeData.width;
            }

            if (lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                let list = this.getNodeOrdreChildByVertical(parentNode)
                let count = list.length;
                let cellSpace = parentNodeData.height / (count + 1);
                cellSpace = Math.min(cellSpace, this.UiUtil.dip2px(15));
                let top = (parentNodeData.height - cellSpace * (count-1))/2;

                for (let index = 0; index < count; index++) {
                    if (list[index].value.id == data.id) {
                        startY = parentNodeData.y + (index) * cellSpace + top;
                        break;
                    }
                }
            } else {
                startY = parentNodeData.y + parentNodeData.height / 2;
            }
            endX = data.x;
            if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR) {
                endX = data.x - 5;
            } else {
                endX = data.x;
            }
            if (this.isUnderline(data)) {
                endY = data.y + data.height;
            } else {
                endY = data.y + data.height / 2;
            }

            lineData.y = endY;
            lineData.x = startX;
            
            lineData.width = endX - startX;
            lineData.height = startY - endY;

            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            if (lineData.isFreeLayoutNode && this.isUnderline(data)) {
                lineData.lineContent.endControlPointX = endPoint.x + data.width
                lineData.lineContent.endControlPointY = endPoint.y
            } else {
                lineData.lineContent.endControlPointX = -1
                lineData.lineContent.endControlPointY = -1
            }
            
            if (startPoint.x > endPoint.x) {
                lineData.lineContent.orientation = LineOrientation.LEFT;
            } else {
                lineData.lineContent.orientation = LineOrientation.RIGHT;
            }
            
        } else {
            lineData.type = MindElementType.SON_LINE;

            if (this.isUnderline(parentNodeData)) {
                lineData.y = parentNodeData.y + parentNodeData.height;
                lineData.x = parentNodeData.x + parentNodeData.width;
            } else {
                lineData.y = parentNodeData.y + parentNodeData.height / 2;
                lineData.x = parentNodeData.x + parentNodeData.width;
            }

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            if (this.isUnderline(data)) {
                lineData.height = data.y - lineData.y + data.height;
                lineData.width = data.x - lineData.x;
            } else {
                lineData.height = data.y - lineData.y + data.height / 2;
                lineData.width = data.x - lineData.x;
            }
            if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                lineData.width = lineData.width - 5
            }

            if (lineLayout == LineLayout.STRAIGHT_LINE ||
                lineLayout == LineLayout.STRAIGHT_LINE_2) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 4, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE ||
                lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                lineLayout == LineLayout.STRAIGHT_CURVE_LINE ) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width - lineData.width / 6, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else if (lineLayout == LineLayout.CURVE_LINE_2 ||
                lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
                curveStartPoint = new Point(0, 0);
                curveEndPoint = new Point(lineData.width, lineData.height);
                straightEndPoint = new Point(lineData.width, lineData.height);
            } else {
                curveStartPoint = new Point(0, 0);                
                if (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                    lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                    curveEndPoint = new Point(lineData.width / 2 - 3, lineData.height);
                } else {
                    curveEndPoint = new Point(lineData.width / 2, lineData.height);
                }
                straightEndPoint = new Point(lineData.width, lineData.height);
            }
            
            if (!parentNodeData.isEmpty() && parentNodeData.isStatisticsContent()) {
                if (parentNodeData.statisticsContent.cellsData.length == 0) {
                    curveStartPoint.x = parentNodeData.statisticsContent.radius - parentNodeData.width/2;
                } else {
                    curveStartPoint.x = 0 - (parentNodeData.width -
                                                      parentNodeData.statisticsContent.cellsData[0].x -
                                                parentNodeData.statisticsContent.radius)
                }
            }
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            if (curveStartPoint.x > straightEndPoint.x) {
                lineData.lineContent.orientation = LineOrientation.RIGHT;
            } else {
                lineData.lineContent.orientation = LineOrientation.RIGHT;
            }            
        }
        lineData.lineContent.lineLayout = lineLayout;
        if (isCreateLineData) {
            let lineNodeContent = lineData.lineContent;

            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {                
                if (this.isUnderline(data) && this.isBorderColor(data)) {
                    lineNodeContent.color = data.borderColor;
                } else {
                    lineNodeContent.color = referenceLine.lineContent.color;
                }
                let grandpaNode = this.getNodeById(parentNode.value.parentNodeId);
                if (referenceLine.lineContent.lineWidth == 0 &&
                        !grandpaNode.isEmpty() &&
                        grandpaNode.value.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    lineNodeContent.lineWidth = parentNode.value.borderWidth > 0 ? parentNode.value.borderWidth : 1.5;
                } else {
                    lineNodeContent.lineWidth = referenceLine.lineContent.lineWidth;
                }
                lineNodeContent.dottedLine = referenceLine.lineContent.dottedLine;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                if (this.isUnderline(data)) {
                    lineNodeContent.lineWidth = data.borderWidth;
                    if (this.isBorderColor(data)) {
                        lineNodeContent.color = data.borderColor
                    } else {
                        lineNodeContent.color = node.value.styleColor;
                    }
                } else {
                    lineNodeContent.color = node.value.styleColor;
                    lineNodeContent.lineWidth = this.mindMapStyleColor.getSubjectLineWidth();
                }
            }
            
            if (lineData.type == MindElementType.LINE && !this.settingData.lineColor.isEmpty()) {
                if (!this.originalRootTreeNode.isEmpty() && parentNode.value.id == this.originalRootTreeNode.value.id) {
                    parentNode = this.originalRootTreeNode;
                }
                let nodePoint = 0
                for (let index = 0; index < parentNode.children.length; index++) {
                    if (parentNode.children[index].value.id == data.id) {
                        nodePoint = index
                        break
                    }
                }
                let colorPoint = (parentNode.children.length - 1) % this.settingData.lineColor.length;
                if (nodePoint < parentNode.children.length - 1) {
                    let nextLine = this.getLineData(parentNode.children[nodePoint + 1]);
                    if (nextLine.isEmpty()) {
                        colorPoint = nodePoint % this.settingData.lineColor.length;
                    }
                }  
                        
                lineNodeContent.color = this.settingData.lineColor[Math.abs(colorPoint)]
            }
            lineData.backgroundColor = Colors.transparent;
            lineData.borderColor = Colors.transparent;
            lineData.borderWidth = 0;
        } else {
            let childSize = parentNode.children.length;
            if (lineData.lineContent.lineWidth == 0 && childSize > 2 &&
                parentNode.children[0].value.id != data.id &&
                parentNode.children[childSize - 1].value.id != data.id) {
                let firstLine = this.getLineData(parentNode.children[0]);
                let lastLine = this.getLineData(parentNode.children[childSize - 1]);

                if (!firstLine.isEmpty() && !lastLine.isEmpty() &&
                    firstLine.lineContent.lineWidth > 0 &&
                    lastLine.lineContent.lineWidth > 0) {
                    lineData.lineContent.lineWidth = firstLine.lineContent.lineWidth;
                }
            }
        }
        
        this.setLineThicken(lineData);
    }


    getSubjectNodeLineSpace(node) {
        let margin = this.rootTreeNode.value.width / 10;
        if (this.globalLayout == LineLayout.CURVE_LINE_2) {
            margin = this.isFromLeftRightLayout ? this.rootTreeNode.value.width / 2 : this.rootTreeNode.value.width / 3 * 2;
        }
        let list = this.getNodeOrdreChildByVertical(this.rootTreeNode)
        let baseWidth = this.rootTreeNode.value.width / 2 - margin;
        let totalLineNumber = list.length;
        if (totalLineNumber == 0) {
            return 0;
        }
        let topNumber = 0;
        // let bottomNumber = 0;
        let nodeIndex = -1;
        let parentNodeCenterY = this.rootTreeNode.value.y + this.rootTreeNode.value.height/2;
        for (let index = 0; index < totalLineNumber; index++) {
            let cell = list[index];
            if (cell.value.id == node.value.id) {
                nodeIndex = index;
            }
            if (cell.value.type == MindElementType.SUBJECT && !this.isUnderline(cell.value)) {
                if (cell.value.y + (cell.value.height / 2) < parentNodeCenterY) {
                    topNumber += 1;
                } else {
                    // bottomNumber += 1;
                }
            } else {
                if (cell.value.y + cell.value.height < parentNodeCenterY) {
                    topNumber += 1;
                } else {
                    // bottomNumber += 1;
                }
            }
        }

        if (nodeIndex == -1) {
            return 0;
        }
        let originalTopNumber = topNumber;
        let originalBottomNumber = totalLineNumber - topNumber;

        let bottomNumber = originalBottomNumber;
        if (originalBottomNumber > originalTopNumber) {
            topNumber = bottomNumber;
            if (nodeIndex > originalTopNumber - 1) {
                nodeIndex = nodeIndex + (originalBottomNumber - originalTopNumber);
            }
        } else if (originalBottomNumber < topNumber) {
            bottomNumber = topNumber;
            if (nodeIndex > topNumber - 1) {
                nodeIndex = nodeIndex + topNumber - originalBottomNumber;
            }
        }
        if (nodeIndex < topNumber) {
            if (this.isFromLeftRightLayout && (this.globalLayout == LineLayout.STRAIGHT_LINE || this.globalLayout == LineLayout.STRAIGHT_LINE_2 ||
                this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR || this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE || 
                this.globalLayout == LineLayout.CURVE_LINE)) {
                let cellWidth = baseWidth / 5;
                switch (topNumber) {
                    case 1:
                        cellWidth = baseWidth / 2;
                        break;
                    case 2:
                        cellWidth = baseWidth / 2;
                        break;
                    case 3:
                        cellWidth = baseWidth / 4;
                        break;
                    case 4:
                        cellWidth = baseWidth / 5;
                        break;
                    case 5:
                        cellWidth = baseWidth / 6;
                        break;
                    default:
                        cellWidth = baseWidth / 7;
                        break;
                }
                let index = topNumber - nodeIndex - 1;
                switch (index) {
                    case 0:
                        return topNumber == 1 ? Math.max(this.rootTreeNode.value.width / 5, margin) : margin;
                    case 1:
                        return margin + cellWidth;
                    case 2:
                        return margin + (cellWidth * 2.5);
                    case 3:
                        return margin + cellWidth * 4;
                    case 4:
                        return baseWidth + margin;
                    default:
                        return baseWidth + margin;
                }
            } else {
                let cellWidth = baseWidth / (topNumber + 1);
                return (topNumber - nodeIndex) * cellWidth + margin;
            }
        } else {
            if (this.isFromLeftRightLayout && (this.globalLayout == LineLayout.STRAIGHT_LINE || this.globalLayout == LineLayout.STRAIGHT_LINE_2 ||
                this.globalLayout == LineLayout.CURVE_LINE_CIRCULAR || this.globalLayout == LineLayout.STRAIGHT_CURVE_LINE || this.globalLayout == LineLayout.CURVE_LINE)) {
                let cellWidth = baseWidth / 5;
                switch (bottomNumber) {
                    case 1:
                        cellWidth = baseWidth / 2;
                        break;
                    case 2:
                        cellWidth = baseWidth / 2;
                        break;
                    case 3:
                        cellWidth = baseWidth / 4;
                        break;
                    case 4:
                        cellWidth = baseWidth / 5;
                        break;
                    case 5:
                        cellWidth = baseWidth / 6;
                        break;
                    default:
                        cellWidth = baseWidth / 7;
                        break;
                }
                let index = nodeIndex - topNumber;
                switch (index) {
                    case 0:
                        return bottomNumber == 1 ? Math.max(this.rootTreeNode.value.width / 5, margin) : margin;
                    case 1:
                        return margin + cellWidth;
                    case 2:
                        return margin + cellWidth * 2.5;
                    case 3:
                        return margin + cellWidth * 4;
                    case 4:
                        return baseWidth + margin;
                    default:
                        return baseWidth + margin;
                }
            } else {
                let cellWidth = baseWidth / (bottomNumber + 1);
                return (nodeIndex - topNumber + 1) * cellWidth + margin;
            }
        }
    }


    setGeneralizationElementsPoint(isChange) {

        let list = this.getGeneralizationMindElementDatas();
        
        let listCount = list.length;
        for (let index = 0; index < listCount; index++) {
            let node = list[index];
            if (node.value.isHidden ||
                !this.isIncludeGeneralizationInCurrentLayout(node) ||
                this.isLayout(node.value)) {
                continue
            }
            let mindElementData = node.value;
            let generalizationContent = mindElementData.generalizationContent;
            let top = 1000000;
            let bottom = 0;
            let right = 0;
            let encircleSpace = 0;
            let targetNodes = []
            for (let targetIndex = 0; targetIndex < generalizationContent.targetIds.length; targetIndex++) {
                let targetNode = this.getNodeById(generalizationContent.targetIds[targetIndex]);

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                targetNodes.push(targetNode)
                for (let j = 0; j < index; j++) {
                    let checkNode = list[j];
                    if (checkNode.value.isHidden) {
                        continue
                    }
                    if (checkNode.value.generalizationContent.targetIds.length == 0) {
                        continue
                    }
                    let checkTargetNode = this.getNodeById(checkNode.value.generalizationContent.targetIds[0]);
                    if (!checkTargetNode.isEmpty() && targetNode.isChildNode(checkTargetNode)) {
                        targetNodes.push(checkNode)
                    }
                }
            }
            for (let targetIndex = 0; targetIndex < targetNodes.length; targetIndex++) {
                let targetNode = targetNodes[targetIndex];

                if (targetNode.isEmpty() || targetNode.value.isHidden) {
                    mindElementData.isHidden = true;
                    continue
                }
                let targetLeft = targetNode.getNodeLeft();
                let targetTop = targetNode.getNodeTop();
                let targetBottom = targetNode.getNodeBottom();
                let targetRight = targetNode.getNodeRight();

                if (targetTop < top) {
                    top = targetTop;
                }
                if (targetBottom > bottom) {
                    bottom = targetBottom;
                }
                if (targetRight > right) {
                    right = targetRight;
                }
                let space = this.getEncircleSpaceAndPadding(targetNode.value)
                if (space > 0) {
                    encircleSpace = Math.max(space, encircleSpace)
                }
                
            }
            if (top == 1000000 && right == 0) {
                continue;
            }
            generalizationContent.targetHeight = bottom - top;
            mindElementData.y = top / 2 + bottom / 2 - mindElementData.height / 2;
            let generalizationLineWidth = this.getGeneralizationLineWidth(generalizationContent.targetHeight);
            mindElementData.x = encircleSpace + right + generalizationLineWidth + Config.GeneralizationSpacing * 2;
            this.setGeneralizationElementLine(node, isChange);

            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsPoint(node.children[childrenIndex], isChange);
            }
            for (let childrenIndex = 0; childrenIndex < node.children.length; childrenIndex++) {
                this.setNodeElementsLinePoint(node.children[childrenIndex], isChange);
            }
        }
    }


    setGeneralizationElementLine(node, isChange) {
        let mindElementData = node.value;
        let generalizationContent = mindElementData.generalizationContent;
        let isCreateLineData = false;
        let lineData = this.getGeneralizationLineData(node);
        if (!isChange || lineData.id == IdGenerator.INVALID_ID) {
            lineData.id = IdGenerator.shared.getId();
            isCreateLineData = true;
        }
        let generalizationLineWidth = this.getGeneralizationLineWidth(generalizationContent.targetHeight);
        let generalizationParentNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
        lineData.parentNodeId = mindElementData.id;
        lineData.type = MindElementType.LEFTBRACELINE;
        lineData.y = mindElementData.y + mindElementData.height / 2 - generalizationContent.targetHeight / 2;
        lineData.x = mindElementData.x - generalizationLineWidth - Config.GeneralizationSpacing;
        lineData.width = generalizationLineWidth;
        lineData.height = generalizationContent.targetHeight;

        

        let startPoint = new Point(0, 0);
        let endPoint = new Point(0, lineData.height);
        if (lineData.lineContent == null) {
            lineData.lineContent = new LineElementContent(startPoint, endPoint, mindElementData.borderColor, mindElementData.id);
        } else {
            lineData.lineContent.setStartPoint(startPoint);
            lineData.lineContent.setEndPoint(endPoint);
        }
        lineData.lineContent.orientation = LineOrientation.RIGHT;
        this.generalizationLineMindElementDataDict.put(lineData.id, lineData);

        if (isCreateLineData) {
            lineData.backgroundColor = Colors.clear;
            lineData.borderWidth = 0;
            lineData.borderColor = Colors.clear;
            let referenceLine = this.getReferenceLine(node.value.id);
            if (!referenceLine.isEmpty()) {
                lineData.lineContent.color = referenceLine.lineContent.color;
                lineData.lineContent.lineWidth = referenceLine.lineContent.lineWidth == 0 ? 1.5 : referenceLine.lineContent.lineWidth;
                lineData.isShadow = referenceLine.isShadow;
            } else {
                let generalizationParentNodeLine = this.getLineData(generalizationParentNode);
                if (generalizationParentNodeLine.isEmpty()) {
                    lineData.lineContent.lineWidth = generalizationParentNode.value.borderWidth == 0 ? 1.5 : generalizationParentNode.value.borderWidth;
                    let color = generalizationParentNode.value.borderColor;
                    if (color == Colors.clear) {
                        color = generalizationParentNode.value.backgroundColor;
                    }
                    if (color == Colors.clear) {
                        color = Colors.black60;
                    }
                    lineData.lineContent.color = color;
                } else {
                    lineData.lineContent.lineWidth = generalizationParentNodeLine.lineContent.lineWidth == 0 ? 1.5 : generalizationParentNodeLine.lineContent.lineWidth;
                    lineData.lineContent.color = generalizationParentNodeLine.lineContent.color;
                }
            }
        }
    }

    getTreeHeight(node, isContainGeneralization = true) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataHeightMap.containsKey(node.value.id)) {
            return this.dataHeightMap.get(node.value.id);
        }

        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let hieght = 0;
        if (this.isRootNode(node) || node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
            let topHeight = this.getTreeTopHeight(node, isContainGeneralization);
            let bottomHeight = this.getTreeBottomHeight(node, isContainGeneralization);
            hieght = topHeight + bottomHeight;
        } else {
            let layout = this.getNodeLayout(node);
            hieght = layout.getTreeHeight(node);
            if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                hieght += this.getEncircleBottomHeight(node.value) + this.getEncircleTopHeight(node.value);
            }
            layout.clearDatas();
            let generalizationNode = this.getNodeGeneralization(node)
            if (!generalizationNode.isEmpty()) {
                let generalizationNodeHeight = this.getTreeHeight(generalizationNode) 
                hieght = Math.max(hieght, generalizationNodeHeight)
            }
        }
        this.dataHeightMap.put(node.value.id, hieght);
        return hieght;
    }

    getTreeTopHeight(node, isContainGeneralization = true) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataTopHeightMap.containsKey(node.value.id)) {
            return this.dataTopHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }

        let nodeSelfTop = 0;
        if (((node.value.type == MindElementType.SUBJECT || node.value.type == MindElementType.SON_SUBJECT) && 
            !this.isUnderline(node.value)) || 
            node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            nodeSelfTop = node.value.height / 2;
        } else {
            nodeSelfTop = node.value.height;
        }

        let nodeEncircleTopHeight = this.getEncircleTopHeight(node.value);
        nodeSelfTop += nodeEncircleTopHeight;

        let floatExplainData = this.getNodeExplain(node);
        if (!floatExplainData.isEmpty()) {
            nodeSelfTop += floatExplainData.height + Config.NodeFloatExplainSpace;
            if (!Colors.isClear(floatExplainData.borderColor)) {
                nodeSelfTop += floatExplainData.borderWidth
            }
        }

        if (isContainGeneralization) {
            let generalizationNode = this.getNodeGeneralization(node);
            if (!generalizationNode.isEmpty()) {
                if (node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
                    let targetIds = generalizationNode.value.generalizationContent.targetIds;
                    if (targetIds.length > 1) {
                        let targetTopId = -1
                        let parentNode = this.getNodeById(node.value.parentNodeId)
                        for (let index = 0; index < parentNode.children.length; index++) {
                            const child = parentNode.children[index];
                            if (targetIds.indexOf(child.value.id) > -1 && targetTopId == -1) {
                                targetTopId = child.value.id;
                                break;
                            }
                        }
                        if (targetTopId == node.value.id) {
                            let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                            let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
                            if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                                nodeSelfTop += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
                            }
                        }                     
                    } else {
                        let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                        if (this.isUnderline(node.value)) {
                            generalizationNodeHeight += node.value.height / 2;
                        }
                        nodeSelfTop = Math.max(generalizationNodeHeight / 2 + 20, nodeSelfTop);   
                    }                                 
                } else if (node.value.layout != NodeLayoutType.LAYOUT_FORM &&
                    node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
                    generalizationNode.value.generalizationContent != null &&
                    !generalizationNode.value.generalizationContent.targetIds.isEmpty() &&
                    generalizationNode.value.generalizationContent.targetIds[0] == node.value.id) {
                    let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                    let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
                    if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                        nodeSelfTop += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
                    }
                }
            }
        }

        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataTopHeightMap.put(node.value.id, nodeSelfTop);
            return nodeSelfTop;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_RIGHT &&
            node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let layoutTop = nodeSelfTop;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                layoutTop = nodeSelfTop;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node) - node.value.height + nodeSelfTop;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeTopHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                let layout = this.getNodeLayout(node);
                layoutTop = layout.getTreeHeight(node);
                layout.clearDatas();
            }
            this.dataTopHeightMap.put(node.value.id, layoutTop);
            return layoutTop;
        }
        let childCount = node.children.length;
        let firstTop = this.getTreeTopHeight(node.children[0]) + nodeEncircleTopHeight;
        if (childCount == 1) {
            let topHeight = Math.max(nodeSelfTop, firstTop);
            this.dataTopHeightMap.put(node.value.id, topHeight);
            return topHeight;
        }

        let childTotle = nodeEncircleTopHeight + this.getEncircleBottomHeight(node.value);
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]);
        let height = (childTotle - firstTop - lastBottom) / 2 + firstTop;
        let topHeight = Math.max(height, nodeSelfTop);
        this.dataTopHeightMap.put(node.value.id, topHeight);
        return topHeight;
    }

    getTreeBottomHeight(node, isContainGeneralization = true) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (this.dataBottomHeightMap.containsKey(node.value.id)) {
            return this.dataBottomHeightMap.get(node.value.id);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let nodeSelfBottom = 0;
        if (((node.value.type == MindElementType.SUBJECT ||
            node.value.type == MindElementType.SON_SUBJECT) &&
            !this.isUnderline(node.value)) ||
            node.value.type == MindElementType.MAIN_SUBJECT || node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            nodeSelfBottom = node.value.height / 2 + this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        } else {
            nodeSelfBottom = + this.getNodeExplainHeight(node.value) + Config.NodeExplainTop;
        }

        let nodeEncircleBottomHeight = this.getEncircleBottomHeight(node.value);
        nodeSelfBottom += nodeEncircleBottomHeight;

        if (isContainGeneralization) {
            let generalizationNode = this.getNodeGeneralization(node);

            if (!generalizationNode.isEmpty() && generalizationNode.value.generalizationContent != null) {                
                if (node.value.layout == NodeLayoutType.LAYOUT_RIGHT) {
                    let targetIds = generalizationNode.value.generalizationContent.targetIds;
                    if (targetIds.length > 1) {
                        let targetBottomId = -1
                        let parentNode = this.getNodeById(node.value.parentNodeId)
                        for (let index = 0; index < parentNode.children.length; index++) {
                            const child = parentNode.children[index];
                            if (targetIds.indexOf(child.value.id) > -1) {
                                targetBottomId = child.value.id;
                            }
                        }
                        if (targetBottomId == node.value.id) {
                            let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                            let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
                            if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                                nodeSelfBottom += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
                            }
                            // let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                            // let targetHeight = 0
                            // for (let index = 0; index < targetIds.length; index++) {
                            //     const targetId = targetIds[index];
                            //     let targetNode = this.getNodeById(targetId)
                            //     if (targetNode.isEmpty()) {
                            //         continue;
                            //     }
                            //     targetHeight += this.getTreeHeight(targetNode, false);
                            //     if (index != targetIds.length - 1) {
                            //         let verticalSpace = (targetNode.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee)
                            //         targetHeight += verticalSpace;
                            //     }
                            // }
                            // if (targetHeight < generalizationNodeHeight) {
                                
                            // }
                        }
                    } else {
                        let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                        if (this.isUnderline(node.value)) {
                            generalizationNodeHeight -= node.value.height / 2;
                        }
                        nodeSelfBottom = Math.max(generalizationNodeHeight / 2 + 10, nodeSelfBottom);   
                    }         
                } else if (node.value.layout != NodeLayoutType.LAYOUT_FORM &&
                    node.value.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
                    generalizationNode.value.generalizationContent != null &&
                    !generalizationNode.value.generalizationContent.targetIds.isEmpty() &&
                    generalizationNode.value.generalizationContent.targetIds[generalizationNode.value.generalizationContent.targetIds.length-1]== node.value.id) {
                    let generalizationNodeHeight = this.getTreeHeight(generalizationNode);
                    let generalizationTargetNodeHeight = this.getGeneralizationTargetHeight(generalizationNode);
                    if (generalizationTargetNodeHeight > 0 && generalizationNodeHeight > generalizationTargetNodeHeight) {
                        nodeSelfBottom += (generalizationNodeHeight - generalizationTargetNodeHeight) / 2;
                    }
                }
            }
        }

        if (node.children.isEmpty() || node.children[0].value.isHidden) {
            this.dataBottomHeightMap.put(node.value.id, nodeSelfBottom);
            return nodeSelfBottom;
        }
        if (!this.isRootNode(node) &&
            node.value.layout != NodeLayoutType.LAYOUT_RIGHT &&
            node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            let layoutBottom = nodeSelfBottom;
            if (node.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeHeight(node) - node.value.height + nodeSelfBottom;
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TOP ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                node.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                layoutBottom = nodeSelfBottom;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                let layout = this.getNodeLayout(node);
                layoutBottom = layout.getTreeBottomHeight(node);
                layout.clearDatas();
            } else if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                layoutBottom = 0;
            } else if (node.value.layout == NodeLayoutType.LAYOUT_FORM || node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                let layout = this.getNodeLayout(node);
                if (this.isUnderline(node.value)) {
                    layoutBottom = layout.getTreeHeight(node) - node.value.height;
                } else {
                    layoutBottom = layout.getTreeHeight(node) - node.value.height / 2;
                }
                layoutBottom += this.getEncircleBottomHeight(node.value);
                layout.clearDatas();
            }
            this.dataBottomHeightMap.put(node.value.id, layoutBottom);
            return layoutBottom;
        }

        let childCount = node.children.length;
        let lastBottom = this.getTreeBottomHeight(node.children[childCount - 1]) + nodeEncircleBottomHeight;
        if (childCount == 1) {
            let bottomHeight = Math.max(lastBottom, nodeSelfBottom);
            this.dataBottomHeightMap.put(node.value.id, bottomHeight);
            return bottomHeight;
        }
        let childTotle = this.getEncircleTopHeight(node.value) + nodeEncircleBottomHeight;
        for (let index = 0; index < childCount; index++) {
            let cell = node.children[index];
            let height = this.getTreeHeight(cell);
            childTotle += height;
            if (index < childCount - 1) {
                childTotle += cell.value.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee;
            }
        }
        let firstTop = this.getTreeTopHeight(node.children[0]);
        let height = (childTotle - firstTop - lastBottom) / 2 + lastBottom;

        let bottomHeight = Math.max(height, nodeSelfBottom);
        this.dataBottomHeightMap.put(node.value.id, bottomHeight);
        return bottomHeight;
    }

    getTreeWidth(node) {
        if (node.value.id == IdGenerator.INVALID_ID || node.value.isHidden) {
            return 0;
        }
        if (!this.isRootNode(node) && node.value.layout != NodeLayoutType.LAYOUT_RIGHT) {
            let layout = this.getNodeLayout(node);
            return layout.getTreeWidth(node);
        }
        if (this.isRootNode(node) && node.children.length != this.rootTreeNode.children.length) {
            node = this.getNodeById(node.value.id);
        }
        let width = node.value.width;
        let nodeGeneralizationWidth = this.getNodeGeneralizationWidth(node);
        let nodeGeneralizationLineWidth = this.getNodeGeneralizationLineWidth(node);
        nodeGeneralizationWidth = nodeGeneralizationWidth > 0 ? nodeGeneralizationWidth + nodeGeneralizationLineWidth + Config.GeneralizationSpacing * 2 : 0;
        width = width + nodeGeneralizationWidth;
        let siblingsMaxWidth = 0;
        for (let index = 0; index < node.children.length; index++) {
            let chlidWidth = 0;
            let horizontalSpacee = this.getNodeHorizontalSpace(node.children[index], node);
            if (!node.children[index].children.isEmpty()) {
                chlidWidth += this.getTreeWidth(node.children[index]) + horizontalSpacee;
            } else {
                if (node.children[index].value.isHidden) {
                    continue;
                }
                let nodeidth = node.children[index].value.width;
                let chlidGeneralizationWidth = this.getNodeGeneralizationWidth(node.children[index]);
                let chlidGeneralizationLineWidth = this.getNodeGeneralizationLineWidth(node.children[index]);
                chlidGeneralizationWidth = chlidGeneralizationWidth > 0 ? chlidGeneralizationWidth + chlidGeneralizationLineWidth +  Config.GeneralizationSpacing * 2 : 0;
                nodeidth = nodeidth + chlidGeneralizationWidth;
                chlidWidth += nodeidth + horizontalSpacee;
            }
            if (siblingsMaxWidth < chlidWidth) {
                siblingsMaxWidth = chlidWidth;
            }
        }
        return siblingsMaxWidth + width;
    }

    getSiblingsNodesHeight(nodes) {
        if (nodes.length == 0) {
            return 0;
        }
        let height = this.getTreeHeight(this.getNodeById(nodes[0].id));
        if (nodes.length > 1) {
            for (var index = 1; index < nodes.length; index++) {
                let data = nodes[index];
                if (data.isHidden) {
                    continue
                }
                height += this.getTreeHeight(this.getNodeById(data.id)) + (data.type == MindElementType.SON_SUBJECT ? this.SonNodeVerticalSpacee : this.NodeVerticalSpacee);
            }
        }
        return height;
    }

    getEncircleTopHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();

        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isTopNode(id, targetIds)) {
                        if (mind.lineContent.isContainText()) {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.textContent.height + mind.lineContent.padding;
                        } else {
                            return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                        }
                    } else {
                        return 0
                    }
                }
            }
        }
        return 0;
    }

    getEncircleBottomHeight(data) {
        let encircleMindElementDataDictArr = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
            let key = encircleMindElementDataDictArr[index];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.lineContent == null) {
                return 0;
            }
            let node = this.getNodeById(mind.parentNodeId);
            if (node.isEmpty()) {
                continue;
            }
            let targetIds = new Array();
            if (mind.lineContent != null) {
                targetIds = mind.lineContent.targetIds;
            }
            if (targetIds.isEmpty()) {
                targetIds.push(mind.parentNodeId);
            }
            for (let index = 0; index < targetIds.length; index++) {
                let id = targetIds[index];
                if (id == data.id) {
                    if (this.isBottomNode(id, targetIds)) {
                        return new EncircleNodesPointsCalculation().space + mind.lineContent.padding;
                    } else {
                        return 0;
                    }
                }
            }
        }
        return 0;
    }

    getLayoutType() {
        return NodeLayoutType.LAYOUT_RIGHT;
    }

    getFloatExplainLineOrientation() {
        return LineOrientation.BOTTOM;
    }

}

export default RightLayout
