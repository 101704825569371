import { md5 } from "../../../../common/netWork/base";
import { postMindmapUploadAttachment, postMindmapUploadImage } from "../../../../common/netWork/mind_map_api";
import Config from "../../../../core/core/calcule/Config";
import DoubleBubbleMindDateFormat from "../../../../core/core/dataformat/DoubleBubbleMindDateFormat";
import MindDateFormat from "../../../../core/core/dataformat/MindDateFormat";
import TimeLineMindDateFormat from "../../../../core/core/dataformat/TimeLineMindDateFormat";
import Colors from "../../../../utils/Colors";
import Util from "../../../../utils/Util";
import LineLayout from "../../../datatype/LineLayout";
import MindBGStripesType from "../../../datatype/MindBGStripesType";
import MindDisplayType from "../../../datatype/MindDisplayType";
import MindElementType from "../../../datatype/MindElementType";
import MindType from "../../../datatype/MindType";
import NodeLayoutType from "../../../datatype/NodeLayoutType";
import ResourceType from "../../../datatype/ResourceType";
import MindmapDataRouter from "../../../facade/MindmapDataRouter";
import DoubleBubbleMindNodeUnit from "../../../mindelementdata/DoubleBubbleMindNodeUnit";
import LineMindTypeNode from "../../../mindelementdata/LineMindTypeNode";
import LineMindTypeNodeUnit from "../../../mindelementdata/LineMindTypeNodeUnit";
import TimeMindTypeNodeUnit from "../../../mindelementdata/TimeMindTypeNodeUnit";
import EditMindmapVM from "../../../mindelementdata/vm/EditMindmapVM";
import MindNodesNearbyData from "../../../mindelementdata/vm/MindNodesNearbyData";
import HashMap from "../../base/HashMap";
import MindMapStyleColor from "../../base/MindMapStyleColor";
import SettingData from "../../minddata/SettingData";
import MindResourceData from "./data/MindResourceData";
import LoadLocalXJMind from "./LoadLocalXJMind";
import MindMapZipConstant from "./MindMapZipConstant";

class MindMapSheetData {
    constructor() {
        this.mainMindNodeUnit = new LineMindTypeNodeUnit();
        this.freeNodes = new Array();
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.globalLineLayout = LineLayout.CURVE_LINE_2;
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.mindType = MindType.LINE_MAP;
        this.mindBGColor = Colors.white;
        this.mindMapStyleColor = new MindMapStyleColor(2);
        this.mindDisplayType = MindDisplayType.MindMap;
        this.mindNodeJson = "";
        this.mindGlobalAudio = "";
        this.mindkey = "";
        this.settingData = new SettingData();
        this.dataList = [];
        this.id = "";
        this.rootId = "";
        this.isCurrentMindmap = false;
        this.name = "";
    };    

    loadData(data, mindType) {
        if (data == null || data.length <= 10) {
            return
        }
        this.mindType = mindType;
        this.mindNodeJson = data;
        
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let mind = new MindDateFormat().format(data);
            this.setMindDataFormCache(mind);
        } else if (this.mindType == MindType.TIME_MAP) {
            let mind = new TimeLineMindDateFormat().format(data);
            this.setTimeLineMindDataFormCache(mind);
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let mind = new DoubleBubbleMindDateFormat().format(data);
            this.setDoubleBubbleMindDateFormCache(mind);
        }
        let mindNodesNearbyData = new MindNodesNearbyData()
        mindNodesNearbyData.setData(this.mainMindNodeUnit,
            this.freeNodes,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit);
        this.dataList = mindNodesNearbyData.dataList;
    }

    getMindJson() {
        return this.mindNodeJson;
    }

    getMindResourceList() {
        var resourceList = []
        let nodeCount = this.dataList.length
        for (let index = 0; index < nodeCount; index++) {
            const element = this.dataList[index];
            if (element != null && element.imageContent != null &&
                element.imageContent.key != null && element.imageContent.key.length > 0) {
                resourceList.push(element.imageContent.key)
                if (element.imageContent.videoUrl != null && 
                    element.imageContent.videoUrl.length > 0) {
                    resourceList.push(element.imageContent.videoUrl)
                }
            }
            if (element != null && element.resourceContent != null &&
                element.resourceContent.url != null && element.resourceContent.url.length > 0) {
                    resourceList.push(element.resourceContent.url)
            }
        }
        if (this.mindGlobalAudio != null && this.mindGlobalAudio.length > 0) {
            resourceList.push(this.mindGlobalAudio)
        }
        return resourceList;
    }

    getMindResourceAndIdList() {
        var resourceList = []
        let nodeCount = this.dataList.length
        for (let index = 0; index < nodeCount; index++) {
            const element = this.dataList[index];
            if (element != null && element.imageContent != null &&
                element.imageContent.key != null && element.imageContent.key.length > 0 &&
                element.imageContent.key.indexOf("shanghai.aliyuncs.com/mindmap") == -1) {
                let resourceAndIdData = new MindResourceData()
                resourceAndIdData.url = element.imageContent.key;
                
                resourceAndIdData.mindId = this.id;
                resourceAndIdData.nodeIds.push(element.id);
                resourceList.push(resourceAndIdData)

                if (element.imageContent.videoUrl != null && 
                    element.imageContent.videoUrl.length > 0 &&
                    element.imageContent.videoUrl.indexOf("shanghai.aliyuncs.com/mindmap") == -1) {

                    let resourceAndIdData = new MindResourceData()
                    resourceAndIdData.url = element.imageContent.videoUrl;
                    resourceAndIdData.mindId = this.id;
                    resourceAndIdData.nodeIds.push(element.id);
                    resourceList.push(resourceAndIdData)
                }
            }
            if (element != null && element.resourceContent != null &&
                element.resourceContent.url != null && element.resourceContent.url.length > 0&&
                element.resourceContent.url.indexOf("shanghai.aliyuncs.com/mindmap") == -1) {

                    let resourceAndIdData = new MindResourceData()
                    resourceAndIdData.url = element.resourceContent.url;
                    resourceAndIdData.mindId = this.id;
                    resourceAndIdData.nodeIds.push(element.id);
                    resourceList.push(resourceAndIdData)
            }
        }
        if (this.mindGlobalAudio != null && this.mindGlobalAudio.length > 0) {
            let resourceAndIdData = new MindResourceData()
            resourceAndIdData.url = this.mindGlobalAudio;
            resourceAndIdData.mindId = -1;
            resourceAndIdData.nodeIds.push(-1);
            resourceList.push(resourceAndIdData)
        }
        return resourceList;
    }

    isValid() {
        return this.mindNodeJson != null && this.mindNodeJson.length > 10;
    }

    setMindDataFormCache(data) {
        this.mainMindNodeUnit.selfStaticDataType = true
        this.mindkey = data.mindkey
        if (data.rootNode != null) {
            this.mainMindNodeUnit.globalLayout = data.globalLayout;
            this.mainMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mainMindNodeUnit.setLineColors(data.settingData.lineColor);
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
            this.freeNodes.forEach(item => {
                item.setMindBGColor(this.mindBGColor);
                item.setMindDisplayType(data.mindDisplayType);
            });
        }
        this.mindDisplayType = data.mindDisplayType;
        this.mainMindNodeUnit.mindDisplayType = data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;

        let rootLineCount = data.rootLine.length;
        for (let nber = 0; nber < rootLineCount; nber++) {
            let line = data.rootLine[nber];
            if (line.lineContent != null) {
                let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                let targetLine = this.mainMindNodeUnit.getNodeLine(line.lineContent.targetId);
                if (!node.isEmpty() &&
                        (targetLine.isEmpty() ||
                        line.type == MindElementType.LAYOUT_CUSTOM_LINE && targetLine.type != MindElementType.LAYOUT_CUSTOM_LINE)) {
                    let isAdd = false;
                    if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (node.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            continue;
                        }
                    } else {                   
                        let values = this.mainMindNodeUnit.lineMindElementDataDict.values();
                        let valuesCount = values.length;
                        for (let index = 0; index < valuesCount; index++) {
                            let lineData = values[index];
                            if ((lineData.type == MindElementType.LINE ||
                                lineData.type == MindElementType.SON_LINE) &&
                                lineData.lineContent != null &&
                                lineData.lineContent.targetId == line.lineContent.targetId &&
                                lineData.parentNodeId == line.parentNodeId) {
                                isAdd = true;
                                break;
                            }
                        }
                    }
                    if (isAdd) {
                        continue;
                    }

                    this.mainMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    this.mainMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        }
        data.generalizationLine.forEach(line => {
            this.mainMindNodeUnit.generalizationLineMindElementDataDict.put(line.id, line);
        });
        data.explainData.forEach(cell => {
            this.mainMindNodeUnit.explainMindElementDataDict.put(cell.id, cell);
        });
        data.generalizationNode.forEach(node => {
            this.mainMindNodeUnit.setGeneralizationNode(node);
        });
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
        });
        data.encircle.forEach(line => {
            this.mainMindNodeUnit.encircleMindElementDataDict.put(line.id, line);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            if (unit.rootNode != null) {
                let freeNode = this.createFreeNodeUnit();
                // console.log(unit);
                freeNode.setRootNode(unit.rootNode);
                freeNode.mindDisplayType = this.mindDisplayType;
                unit.rootLine.forEach(line => {
                    freeNode.lineMindElementDataDict.put(line.id, line);
                    freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                });
                unit.generalizationLine.forEach(line => {
                    freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                });
                unit.generalizationNode.forEach(node => {
                    freeNode.setGeneralizationNode(node);
                });
                unit.encircle.forEach(line => {
                    freeNode.encircleMindElementDataDict.put(line.id, line);
                });
                unit.explainData.forEach(cell => {
                    freeNode.explainMindElementDataDict.put(cell.id, cell);
                });
                if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                    freeNode.setElementLayout(data.globalLayout, true);
                } else {
                    freeNode.setElementLayout(unit.globalLayout, true);
                }
                if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                    freeNode.globalLineLayout = data.globalLineLayout;
                } else {
                    freeNode.globalLineLayout = unit.globalLineLayout;
                }
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
    }

    setTimeLineMindDataFormCache(data) {
        this.mindkey = data.mindkey
        this.timeMindTypeNodeUnit.selfStaticDataType = true
        this.timeMindTypeNodeUnit.title = data.title;
        this.timeMindTypeNodeUnit.baseLine = data.baseLine;
        this.mindBGColor = data.mindBGColor;
        this.settingData = data.settingData.copy()
        this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
        this.mindGlobalAudio = data.mindGlobalAudio;
        this.mindBGStripesType = data.mindBGStripesType;
        data.mainMindElement.forEach(node => {
            this.timeMindTypeNodeUnit.mainMindElementDataDict.put(node.id, node);
        });
        this.timeMindTypeNodeUnit.setElementLayout(data.globalLayout);
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            freeNode.setFrameworkModle(unit.frameworkIndex);

            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
        });
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
        });
    }

    setDoubleBubbleMindDateFormCache(data) {
        this.doubleBubbleMindNodeUnit.selfStaticDataType = true
        this.mindkey = data.mindkey
        if (data.rootNode != null) {
            this.doubleBubbleMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
        }

        this.globalLineLayout = data.globalLineLayout;
        this.doubleBubbleMindNodeUnit.globalLineLayout = data.globalLineLayout;
        if (data.rootTreeBrotherNode != null) {
            this.doubleBubbleMindNodeUnit.setRootTreeBrotherNode(data.rootTreeBrotherNode);
        }
        this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas = data.commonGroundMindElementDatas;

        data.rootLine.forEach(line => {
            if (line.lineContent != null) {
                let node = this.doubleBubbleMindNodeUnit.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    this.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        });

        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
            if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                freeNode.globalLineLayout = data.globalLineLayout;
            } else {
                freeNode.globalLineLayout = unit.globalLineLayout;
            }
        });
    }

    createFreeNodeUnit() {
        let mindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, true);
        if (this.mindType == MindType.TIME_MAP) {
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        } else {
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = this.globalLineLayout;
        }

        mindNodeUnit.setMindBGColor(this.mindBGColor);
        this.freeNodes.push(mindNodeUnit);

        return mindNodeUnit;
    }

    uploadMindmapResources(resourcesPath, jsonFullPath) {
        var that = this
        return new Promise(async resolve => {
            let list = that.getMindResourceAndIdList();
            let isChange = false;
            for (let index = 0; index < list.length; index++) {
                const cell = list[index];
                let url = await that.uploadFileByPath(cell.url);
                if (url != null && url.length > 0) {
                    let type = Util.getUrlType(cell.url)
                    if (type.length > 0) {
                        type = "." + type
                    }
                    let newFileName = md5(url) + type
                    let blob = await LoadLocalXJMind.loadResourceData(cell.url);
                    if (blob != null) {
                        let buf = await that.loadFileArrayBuffer(blob, cell.url);
                        if (buf != null) {
                            that.saveLocalContent(resourcesPath, newFileName, buf)
                        }
                    }
                    
                    for (let j = 0; j < cell.nodeIds.length; j++) {
                        const id = cell.nodeIds[j];
                        let node = that.getNodeById(id);
                        
                        if (node.isEmpty()) {
                            if (cell.url == that.mindGlobalAudio) {
                                that.mindGlobalAudio = url;
                            }
                            continue;
                        }
                        let data = node.value;
                        if (data.imageContent != null &&
                            data.imageContent.key != null && 
                            data.imageContent.key.length > 0 &&
                            data.imageContent.key == cell.url) {
                            data.imageContent.key = url
                            isChange = true;
                        }
                        if (data.imageContent != null &&
                            data.imageContent.videoUrl != null && 
                            data.imageContent.videoUrl.length > 0 &&
                            data.imageContent.videoUrl == cell.url) {
                            data.imageContent.videoUrl = url
                            isChange = true;
                        }
                        if (data.resourceContent != null &&
                            data.resourceContent.url != null && 
                            data.resourceContent.url.length > 0 &&
                            data.resourceContent.url == cell.url) {
                            data.resourceContent.url = url
                            isChange = true;
                        }
                        
                        if (that.isCurrentMindmap) {
                            EditMindmapVM.updateMindmapResources(id, cell.url, url)
                        }
                    }
                }
            }
            if (isChange) {
                let json = that.getCurrentMindContent()
                if (json.length > 10) {
                    that.mindNodeJson = json
                    that.saveLocalContent(jsonFullPath, "", json)
                    let success = await that.uploadMindmap()

                    console.log("uploadMindmap-> ", success);
                }
            }
            resolve(true)
        });
    }

    loadFileArrayBuffer(blob, name) {
        let that = this
        return new Promise(async resolve => {
            if (blob == null) {
                resolve(null)
                return
            }
            var fr = new FileReader();
            fr.readAsArrayBuffer(blob);
            fr.addEventListener("loadend",(e) => {
                var buf = e.target.result;
                if (buf != null && buf.byteLength > 10) {
                    try {
                        let data = null
                        if (name.indexOf(".txt") > 0 ||
                            name.indexOf(".doc") > 0 ||
                            name.indexOf(".xls") > 0) {
                            data = new Uint8Array(buf)
                        } else {
                            data = new Int8Array(buf)
                        }
                        resolve(data)
                    } catch (error) {
                        resolve(null)
                    }
                } else {
                    resolve(null)
                }
            },false);
        })
    }

    saveLocalContent(path, name, content) {
        var fs = window.require('fs');
        if (name == null || name.length == 0) {
            fs.writeFile(path, content, function (err) {
                console.log(path, "-write-", err);
            });
        } else {
            fs.writeFile(path + MindMapZipConstant.FileSeparator + name, content, function (err) {
                // console.log(path + MindMapZipConstant.FileSeparator + name, "-write-", err);
            });
        }
    }

    uploadFileByPath(path) {
        var that = this
        return new Promise(async resolve => {
            //转Blob
            let blob = await LoadLocalXJMind.loadResourceData(path);
            if (blob == null) {
                resolve(null)
                return
            }
            if (blob.type.indexOf("image/") > -1) {
                let handler = {
                    "X-Type": "mindmap-inner-image",
                    "X-ID": that.id,
                    };
                if (that.id != null && this.id.length > 0 && that.id != that.rootId) {
                    handler = {
                        "X-Type": "mindmap-inner-image",
                        "X-ID": that.rootId,
                        "Sheet-Id": that.id,
                        };
                }
                postMindmapUploadImage(blob, (res) => {
                        resolve(res.imageUrl)
                    }, (error) => {
                        resolve(null)
                    }, null, handler );
            } else {
                let type = Util.getUrlType(path)
                let resourceName = Util.getNameByUrl(path)
                if (type.length > 1) {
                    resourceName = "." + type
                }
                let handler = {
                    "X-Type": "mindmap-attachment",
                    "X-Name": encodeURIComponent(resourceName),
                    "X-Id": that.rootId,
                };
                if (that.id != null && this.id.length > 0 && that.id != that.rootId) {
                    handler = {
                        "X-Type": "mindmap-attachment",
                        "X-Name": encodeURIComponent(resourceName),
                        "X-Id": that.rootId,
                        "Sheet-Id": that.id,
                        };
                }
                postMindmapUploadAttachment(blob, (res) => {
                    let attachmentUrl = res.attachmentUrl;
                    resolve(attachmentUrl)
                }, (error) => {
                    resolve(null)
                }, null, handler);
            }
        });
    }

    getCurrentMindContent() {
        var content = "";
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            let mindDateFormat = new MindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            mindDateFormat.mindDisplayType = this.mindDisplayType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();

                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            }
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.mainMindNodeUnit.rootTreeNode,
                this.mainMindNodeUnit.lineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationLineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationMindElementDataDict.values(),
                freeUnitNodes,
                this.mainMindNodeUnit.encircleMindElementDataDict.values(),
                this.mainMindNodeUnit.explainMindElementDataDict.values()).setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let mindDateFormat = new DoubleBubbleMindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList).setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.doubleBubbleMindNodeUnit.rootTreeNode,
                this.doubleBubbleMindNodeUnit.lineMindElementDataDict.values(),
                freeUnitNodes,
                this.doubleBubbleMindNodeUnit.rootTreeBrotherNode,
                this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas)
                .setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.TIME_MAP) {
            let format = new TimeLineMindDateFormat();
            format.mindBGColor = this.mindBGColor;
            format.settingData = this.settingData;
            format.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            format.version = Config.MindFormatVersion;
            format.mindkey = this.mindkey;
            format.mindGlobalAudio = this.mindGlobalAudio;
            format.mindBGStripesType = this.mindBGStripesType;
            format.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };

            format = format.setDatas(this.timeMindTypeNodeUnit.title,
                this.timeMindTypeNodeUnit.baseLine,
                this.timeMindTypeNodeUnit.mainMindElementDataDict,
                freeUnitNodes, this.timeMindTypeNodeUnit.globalLayout);
            content = format.toJSONString();
        }
        return content;
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    isMainMap() {
        return (this.rootId == this.id && this.id != "" && this.id != null) || 
                ((this.id == "" || this.id == null) && this.rootId != null && this.rootId.length > 0)
    }

    uploadMindmap() {
        let that = this;
        return new Promise(resolve => {
            if (that.mindNodeJson == null || that.mindNodeJson.length < 20) {
                resolve(false)
                return
            }
            let modifyTime = Math.round(new Date().getTime() / 1000);
            let subtitle = that.getSubtitle();
            let imageCover = "";
            let canvansData = { 
                mindMapId: that.rootId, 
                sheetId: that.id, 
                name: that.name, 
                content: that.mindNodeJson, 
                cover: imageCover, 
                subtitle,
                audio: that.mindGlobalAudio, 
                modifyTime }
            
            MindmapDataRouter.postUploadMindmap(canvansData, imageCover => {
                resolve(true)
            }, error => {
                resolve(false) //TODO 空间不足提醒
            })
        });
    }

    getSubtitle() {
        let subtitle = "";
        if (this.mindType == MindType.LINE_MAP) {
            subtitle = this.mainMindNodeUnit.getSubtitle();

        } else if (this.mindType == MindType.TIME_MAP) {
            subtitle = this.timeMindTypeNodeUnit.getSubtitle();
        } else if (this.mindType == BUBBLE_MAP) {
            subtitle = this.mainMindNodeUnit.getSubtitle();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            subtitle = this.doubleBubbleMindNodeUnit.getSubtitle();
        }
        return subtitle;
    }

    setGlobalLayout(type, isInit = false) {
        this.globalLayout = type;
        this.globalLayout = this.getGlobalLayout();
        this.mainMindNodeUnit.setElementLayout(this.globalLayout, isInit);
        this.timeMindTypeNodeUnit.setElementLayout(this.globalLayout);
        this.doubleBubbleMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_DOUBLE_BUBBLE);
    }

    getGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            //布局
            return NodeLayoutType.LAYOUT_TREE_LEFT;
        } else {
            return this.globalLayout;
        }
    }
}

export default MindMapSheetData