<template>
  <div
    id="complie-left-content-line-menu"
    class="element-icon-menu-list"
    v-show="showRelevanceLineMenu || showEncircleMenu"
    v-clickoutside="outsideCloseIconCompoenntAccout"
    :style="{ left: newMenuX + 'px', top: newMenuY + 'px' }"
  >
    <div class="element-menu-children-list">
      <template v-if="showRelevanceLineMenu">
        <div
          v-for="each in iconMenuData"
          :key="each.id"
          @click="checkMenuLineElement(each)"
        >
          <div class="each-menu">
             <div v-if="each.name == strings.Mind_Edit_Right_Menu_Correlation_Line" 
                :style="{left:secondLevelLeft+'px',top:shapeTop+'px'}"
                class="checkMindShape">
                <ComplieChildMenuCorrelationLine/>
            </div>
            <div v-if="each.name == strings.Mind_Edit_Right_Menu_Correlation_Line_Start"
              :style="{left:secondLevelLeft+'px',top:startTop+'px'}"
              class="checkMindShape">
                <ComplieChildMenuCorrelationLineStartOrEnd :startOrEnd="'start'"/>
            </div>
            <div v-if="each.name == strings.Mind_Edit_Right_Menu_Correlation_Line_End" 
                :style="{left:secondLevelLeft+'px',top:endTop+'px'}"
                class="checkMindShape">
                <ComplieChildMenuCorrelationLineStartOrEnd :startOrEnd="'end'"/>
            </div>
            <img :src="each.iconUrl" class="each-icon" alt="" />
            <div class="each-icon-name">{{ getString(each.name) }}</div>
            <img v-if="each.name == strings.Mind_Edit_Right_Menu_Correlation_Line ||
            each.name == strings.Mind_Edit_Right_Menu_Correlation_Line_Start ||
            each.name == strings.Mind_Edit_Right_Menu_Correlation_Line_End" 
            src="../../../assets/img/canvasMenu/menuTriange.png" class="each-icon-triange" alt="" />
          </div>
        </div>
      </template>
      <template v-else-if="showEncircleMenu">
        <div
          v-for="each in encircle"
          :key="each.id"
          @click="checkMenuLineElement(each)"
        >
          <template v-if ="each.show == true">
            <div class="each-menu">
              <img :src="each.iconUrl" class="each-icon" alt="" />
              <div class="each-icon-name">{{ getString(each.name) }}</div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import ComplieChildMenuCorrelationLine from "../ComplieRightMenuChild/ComplieChildMenuCorrelationLine";
import ComplieChildMenuCorrelationLineStartOrEnd from "../ComplieRightMenuChild/ComplieChildMenuCorrelationLineStartOrEnd";
export default {
  name: "ComplieLeftContentLineMenu",
  props: ["newMenuY", "newMenuX", "showRelevanceLineMenu", "showEncircleMenu","hiddenBottonArray","fullHeight","fullWidth",],
  data() {
    return {
      iconMenuData: [
        {
          id: 1,
          name: strings.Global_Edit,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
          type: "iconMenuData",
          show: true
        },
        {
          id: 2,
          name: strings.Mind_Edit_Right_Menu_Correlation_Line,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenuLine.svg"),
          type: "iconMenuData",
          show: true
        },
        {
          id: 3,
          name: strings.Mind_Edit_Right_Menu_Correlation_Line_Start,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenuStart.svg"),
          type: "iconMenuData",
          show: true
        },
        {
          id: 4,
          name: strings.Mind_Edit_Right_Menu_Correlation_Line_End,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenuEnd.svg"),
          type: "iconMenuData",
          show: true
        },
        {
          id: 11,
          name: strings.Mind_Edit_Node_Menu_Delete_Line,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
          type: "iconMenuData",
          show: true
        },
      ],
      encircle: [
        {
          id: 1,
          name: strings.Global_Edit,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu1.svg"),
          type: "encircle",
          show: true
        },
        {
          id: 2,
          name: strings.Mind_Edit_Node_Menu_Add_Encircle_Background,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu6.svg"),
          type: "encircle",
          show: true
        },
        {
          id: 3,
          name: strings.Mind_Edit_Node_Menu_Delete_Encircle_Background,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu6.svg"),
          type: "encircle",
          show: true
        },
        {
          id: 11,
          name: strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line,
          iconUrl: require("../../../assets/img/canvasMenu/canvasMenu9.svg"),
          type: "encircle",
          show: true
        },
      ],
      strings: strings,
      secondLevelLeft:230,
      shapeTop:0,
      startTop:0,
      endTop:0,
    };
  },
  components: {
    // ComplieChildMenuForm,
    ComplieChildMenuCorrelationLine,
    ComplieChildMenuCorrelationLineStartOrEnd,
  },
  methods: {
    ...mapMutations(["showCropperImg", "showCropperImgUrl"]),
    getString(i) {
      return getString(i);
    },
    outsideCloseIconCompoenntAccout() {
      let obj = {name:'colse',type:'colse'};
      this.$emit("checkMenuLineElement", obj);
    },
    checkMenuLineElement(each) {
      let obj = {};
      obj.name = each.name;
      obj.type = each.type;
      this.$emit("checkMenuLineElement", obj);
    },
  },
  watch: {
    hiddenBottonArray:  {
      handler(newVal) {
        //展示全部按钮
        for (let j = 0; j < this.encircle.length; j++ ){
          this.encircle[j].show = true;
        }
        if (newVal != null && newVal.length != 0) { //隐藏部分按钮
          for (let index = 0; index < newVal.length; index++) { 
            for (let j = 0; j < this.encircle.length; j++ ){
              if (newVal[index] == this.encircle[j].name) {
                this.encircle[j].show = false;
              }
            }
          }
        }

      },
      deep:true
    },
    newMenuX:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollLeft = root.scrollLeft;
        newVal = newVal - scrollLeft
        if(this.fullWidth - newVal > 358){
          this.secondLevelLeft = 230;
        }else{
          this.secondLevelLeft = -128;
        }
      },
      deep:true
    },
    newMenuY:{
      handler(newVal){
        let root = document.getElementById("canvas-father")
        let scrollTop = root.scrollTop;
        newVal = newVal - scrollTop
        if(this.fullHeight - (46 + newVal) >= 193){
          this.shapeTop = 0;
        }else{
          this.shapeTop = this.fullHeight - (46 + newVal + 193);
        }
        if(this.fullHeight - (82 + newVal) >= 284){
          this.startTop = 0;
        }else{
          this.startTop = this.fullHeight - (82 + newVal + 304);
        }
        if(this.fullHeight - (118 + newVal) >= 284){
          this.endTop = 0;
        }else{
          this.endTop = this.fullHeight - (118 + newVal + 304);
        }
      },
      deep:true
    }

  }
};
</script>

<style lang='less' scoped>
/* 元素菜单列表开始 */
.element-icon-menu-list {
  // width: 150px;
  // height: 300px;
  position: absolute;
  left: 200px;
  top: 300px;
  z-index: 999;
  background-color: #fff;
  user-select: "none";
  box-shadow: 0px 3px 10px rgba(80, 80, 80, 0.16);
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  .hiddenImgInput {
    display: none;
  }

  .element-menu-children-list {
    border-bottom: 1px solid #ddd8d8;
    .each-menu {
      display: flex;
      align-items: center;
      width: 160px;
      height: 28px;
      cursor: pointer;
      position: relative;
      .checkMindShape {
        display: none;
        position: absolute;
        left: 230px;
        top: 0;
        background-color: #fff;
      }

      .each-icon {
        width: 17px;
        height: 17px;
        display: block;
        margin-left: 20px;
      }
      .each-icon-name {
        margin-left: 11px;
        font-size: 13px;
        color: #666;
      }
      .each-icon-triange {
        // width: 12px;
        // height: 12px;
        display: block;
        margin-left: 140px;
        margin-top: 3px;
        position: absolute;
      }
      &:hover {
        background-color: #e0e0e0;
      }
      &:hover .checkMindShape {
        display: block;
        z-index: 999;
      }
    }

    &:last-child {
      border-bottom: transparent;
    }
  }
}
/* 元素菜单列表结束 */
</style>