
function getCharSize(char, style = {}){     //用于计算文字的宽高的函数
    if(document.querySelector(".computed-text-width-height")){
        var div = document.querySelector(".computed-text-width-height")
        var span = document.querySelector(".computed-text-width-height-span")
    }else{
        var svg = document.createElement("svg")
        svg.style.position = 'absolute'
        svg.style.left = '0'
        svg.style.top = '0'
        svg.style.zIndex = '-999'

        var g = document.createElement("g")
        var foreignObject = document.createElement("foreignObject")
        var div = document.createElement("div")
        div.className = 'computed-text-width-height'
        var span = document.createElement("div")
        span.className = 'computed-text-width-height-span'
        div.style.display = "block"
        div.style.whiteSpace = "pre-wrap"
        div.style.wordBreak = "break-word"
        div.style.maxWidth = "100px"
        div.style.width = "auto"
        div.style.positon = "absolute"
        div.style.left = "-10000px"
        div.style.zIndex = '10000'
        // div.backgroundColor='lime'
        span.style.opacity = 1
        span.style.maxWidth = '100px'
        span.style.width = "auto"
        div.appendChild(span)
        foreignObject.appendChild(div)
        g.appendChild(foreignObject)
        svg.appendChild(g)
        document.body.appendChild(svg)
    }

  let {
      fontSize = 14,
      fontFamily = "SimSun"
  } = style
  let scale = 1//fontSize / 20
  span.style.fontSize = "14px"
//   span.style.fontFamily = fontFamily
//   span.style.lineHeight = "0"
//   span.style.transform = `scale(${scale})`
  // span.style.display = "inline-block"
  span.innerHTML = char
  let rect = span.getBoundingClientRect()
  let width = rect.width
  // document.body.removeChild(span)
  return {
      width,
      height: rect.height
  }
}

export default getCharSize