<template>
  <div class="CommonMoreBgColor">
    <sketch-picker v-model="moreColors" v-if="this.$store.state.moreColor" />
    <!-- <colorPicker v-model="moreColors" v-if="this.$store.state.moreColor" /> -->
  </div>
  
</template>
<script>
// import { Sketch } from "vue-color";
import { Chrome } from "vue-color";
// import { colorPicker } from "vcolorpicker";
import { mapMutations } from "vuex";
import EditMindmapVM from '../../../../viewmodel/mindelementdata/vm/EditMindmapVM';
export default {
  data() {
    return {
      moreColors: {
        hex: "#fc4c51",
        hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
        hsv: { h: 150, s: 0.66, v: 0.3, a: 1 },
        rgba: { r: 25, g: 77, b: 51, a: 1 },
        a: 1,
      },
      // showMoreColor: false,
      viewModel: null,
      isMember: false,
    };
  },
  components: {
    "sketch-picker": Chrome,//Sketch,
    // "colorPicker":colorPicker,
  },
  created() {
    //是否会员
    let userData = localStorage.getItem("meMessage");
    if (userData == null || userData == "") {
      return;
    }
    if (userData != null) {
      let userDataObj = JSON.parse(userData);
      this.isMember = userDataObj.isMember;
    }
  },
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.isMember = true;//颜色收费作废
  },
  methods: {
    ...mapMutations([
      "backGroundColor",
      "backBorderColor",
      "backTextcolor",
      "backFillColor",
      "backLineColor",
      "backBorderMinorStyle",
      "backBaoWeiLineColor",
      "backBaoWeiFillColor",
      "textShadowColor",    
      "backChartFillColor",
      "isShowShoppingModel",
      "showMoreColor",
      "setBaoWeiTextColor",
      "backSpanTextColor",
      "backSpanTextBgColor",
      "backSpanTextShadowColor",
    ]),
    changeMoreColors(newColor) {
      if (!this.isMember) {
        this.showMoreColor(false);
        this.isShowShoppingModel(true); 
        return;
      }
      if (this.$store.state.colorAccout == 0) {
        this.backGroundColor(newColor.hex);
        setTimeout(() => {
          this.backGroundColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 1) {
        this.backLineColor(newColor.hex);
        setTimeout(() => {
          this.backLineColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 2) {
        this.backTextcolor(newColor.hex);
        setTimeout(() => {
          this.backTextcolor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 3) {
        this.backBorderColor(newColor.hex);
        setTimeout(() => {
          this.backBorderColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 4) {
        this.backFillColor(newColor.hex);
        EditMindmapVM.changeFillColorColorAlpha(newColor.a)
        setTimeout(() => {
          this.backFillColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 5) {
        this.backBorderMinorStyle(newColor.hex);
        setTimeout(() => {
          this.backBorderMinorStyle(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 6) {
        this.backBaoWeiLineColor(newColor.hex);
        setTimeout(() => {
          this.backBaoWeiLineColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 7) {
        this.backBaoWeiFillColor(newColor.hex);
        setTimeout(() => {
          this.backBaoWeiFillColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 8) {
        this.setBaoWeiTextColor(newColor.hex);
        setTimeout(() => {
          this.setBaoWeiTextColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == 9) {
        this.textShadowColor(newColor.hex);
        setTimeout(() => {
          this.textShadowColor(null);
        }, 100);
      } else if (this.$store.state.colorAccout == "CHART_COLOR") {
        this.backChartFillColor(newColor.hex);
        setTimeout(() => {
          this.backChartFillColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_STYLE") {
        this.backSpanTextColor(newColor.hex);
        setTimeout(() => {
          this.backSpanTextColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_BG_COLOR_STYLE") {
        this.backSpanTextBgColor(newColor.hex);
        setTimeout(() => {
          this.backSpanTextBgColor(null);
        }, 1000);
      } else if (this.$store.state.colorAccout == "TEXT_SHADOW_COLOR_STYLE") {
        this.backSpanTextShadowColor(newColor.hex);
        setTimeout(() => {
          this.backSpanTextShadowColor(null);
        }, 1000);
      }
    },
  },
  watch: {
    moreColors(newColor, oldColor) {
      this.changeMoreColors(newColor);
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
  },
};
</script>
<style scoped>
</style>