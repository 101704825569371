
import DoubleBubbleLayout from "../../viewmodel/core/layout/DoubleBubbleLayout"
import BubbleColorMode from "../../viewmodel/core/base/BubbleColorMode"
import IdGenerator from "../../viewmodel/core/base/IdGenerator"
import LineColorMode from "../../viewmodel/core/base/LineColorMode"

import MindMapStyleColor from "../../viewmodel/core/base/MindMapStyleColor"
import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType"
import LineLayout from "../../viewmodel/datatype/LineLayout"
import MindElementShapeType from "../../viewmodel/datatype/MindElementShapeType"
import MindElementType from "../../viewmodel/datatype/MindElementType"
import HashMap from "../../viewmodel/core/base/HashMap"
import LineMindTypeNode from "./LineMindTypeNode"
import MindElementData from "./MindElementData"
import UiUtil from "../../utils/UiUtil"
import zhCn from "../../common/lang/zh-cn"
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation"
import CalculSaveImageRect from "../../core/core/calcule/CalculSaveImageRect"
import TextContent from "./mindcontent/TextContent"
import IconElementContent from "./mindcontent/IconElementContent"
import strings from "../../common/lang/strings"
import CGRect from "../core/base/basedata/Rect"
import MindLayoutSelect from "../core/tools/setting/MindLayoutSelect"
import EditMindmapVM from "./vm/EditMindmapVM"
import Colors from "../../utils/Colors"
import MindSettingManager from "../core/tools/setting/MindSettingManager"
import MindType from "../datatype/MindType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class DoubleBubbleMindNodeUnit {

    constructor(mindType = MindType.LINE_MAP, mindMapStyleSelectedIndex = 0) {
        this.delegate;
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        this.mindMapStyleColor = new MindMapStyleColor(mindMapStyleSelectedIndex);

        this.rootTreeNode; //主体树状数据结构
        this.rootTreeBrotherNode; //主体兄弟节点数据结构
        this.lineMindElementDataDict = new HashMap(); //主体线字典， Int为线ID data为line
        this.mainMindElementDataDict = new HashMap(); //主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.textElementLineMindElementDataDict = new HashMap(); //主体节点对应的线，Int为节点ID，data为line
        this.commonGroundMindElementDatas = new Array();//两个主节点的共同子节点
        this.elementLayout = new DoubleBubbleLayout();
        this.elementLayout.mindLayoutSelect = MindLayoutSelect
        this.lineColorMode = new LineColorMode();
        this.lineColors = [];
        this.mindType = mindType;
        this.selfStaticDataType = false
        this.mindBGColor = -1;
    }

    DoubleBubbleMindNodeUnit(mindType, mindMapStyleSelectedIndex) {
        this.mindType = mindType;
        this.mindMapStyleColor = new MindMapStyleColor(mindMapStyleSelectedIndex);
    }

    setMindMapStyleColor(index) {
        this.mindMapStyleColor = new MindMapStyleColor(index);
    }

    setRootNode(rootTreeNode) {
        this.rootTreeNode = rootTreeNode;
        this.setNodeToDictStructure(this.rootTreeNode);
    }

    setRootTreeBrotherNode(rootTreeBrotherNode) {
        this.rootTreeBrotherNode = rootTreeBrotherNode;
        this.setNodeToDictStructure(this.rootTreeBrotherNode);
    }

    clearSelected() {
    }

    clearAllData() {
        this.rootTreeNode = new LineMindTypeNode();
        this.rootTreeBrotherNode = new LineMindTypeNode();
        this.lineMindElementDataDict.clear();
        this.mainMindElementDataDict.clear();
        this.textElementLineMindElementDataDict.clear();
        this.commonGroundMindElementDatas = new Array();
    }

    getNodeLines(nodeId) {
        var lines = new Array();
        if (this.isEmpty()) {
            return lines;
        }
        if (this.isCommonNode(nodeId)) {
            let keys = this.lineMindElementDataDict.keys()
            for (let i = 0; i < keys.length; i++) {
                var line = this.lineMindElementDataDict.get(keys[i]);
                if (line.isEmpty() || line.lineContent == null) {
                    return
                }

                if (line.lineContent.targetId == nodeId) {
                    lines.push(line);
                }
            }
            return lines;
        }
        if (this.textElementLineMindElementDataDict.containsKey(nodeId)) {
            lines.push(this.textElementLineMindElementDataDict.get(nodeId));
        }
        return lines;
    }

    getNodeLine(nodeId) {
        if (this.isCommonNode(nodeId)) {
            let keys = this.lineMindElementDataDict.keys()
            for (let i = 0; i < keys.length; i++) {
                var line = this.lineMindElementDataDict.get(keys[index]);
                if (line.isEmpty() || line.lineContent == null) {
                    return
                }
                if (line.lineContent.targetId == nodeId) {
                    return line;
                }
            }
        }
        if (this.textElementLineMindElementDataDict.containsKey(nodeId)) {
            return this.textElementLineMindElementDataDict.get(nodeId);
        }
        return new MindElementData().emptyMindNode();
    }

    getLineData(node, parentNodeData) {
        if (this.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        let keys = this.lineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            var data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.LINE &&
                data.lineContent.targetId == node.value.id &&
                data.parentNodeId == parentNodeData.value.id) {
                return data;
            } else if (data.type == MindElementType.SON_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getNodeById(id) {
        if (this.mainMindElementDataDict.containsKey(id)) {
            return this.mainMindElementDataDict.get(id);
        }
        if (this.rootTreeBrotherNode != null && id == this.rootTreeBrotherNode.value.id) {
            return this.rootTreeBrotherNode;
        }
        if (this.isCommonNode(id)) {
            return this.getCommonNode(id);
        }
        if (this.rootTreeNode != null && id == this.rootTreeNode.value.id) {
            return this.rootTreeNode;
        }
        return new LineMindTypeNode();
    }

    getGolbalLineWidth() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return 1.5;
        }
        var line = this.getNodeLine(this.rootTreeNode.children[0].value.id);

        return line.lineContent.lineWidth;
    }

    isLineThicken() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return false;
        }
        var line = this.getNodeLine(this.rootTreeNode.children[0].value.id);

        return line.lineContent.lineThicken;
    }

    getGolbalLineColor() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return Colors.red;
        }
        var line = this.getNodeLine(this.rootTreeNode.children[0].value.id);
        return line.lineContent.color;
    }

    getSubtitle() {
        var subtitle = "";
        if (this.rootTreeNode == null) {
            return subtitle;
        }
        this.rootTreeNode.children.forEach(node => {
            if (subtitle.length > 0) {
                subtitle = subtitle + ", ";
            }
            if (node.value.textContent != null) {
                subtitle = subtitle + node.value.textContent.getContentText();
            }
        });
        return subtitle;
    }

    getTitle() {
        if (this.rootTreeNode == null || (this.rootTreeNode.isEmpty())) {
            return "";
        }
        return this.rootTreeNode.value.textContent.text;
    }

    setvColorMode(mode) {
        this.lineColorMode = mode;
    }

    setNodeToDictStructure(node) {
        this.mainMindElementDataDict.put(node.value.id, node);
        node.value.isEdit = false;
        if (!this.selfStaticDataType) {
            IdGenerator.shared.setId(node.value.id);
        }
        
        node.children.forEach(child => {
            this.setNodeToDictStructure(child);
        });
    }

    addChildMindElement(targetId) {
        var parentNodeId = targetId;

        var parentNode = this.getNodeById(parentNodeId);
        if (parentNode.isEmpty() || parentNode.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return new LineMindTypeNode();
        }

        var parentNodeData = parentNode.value;
        var node;
        var point = parentNode.children.length + 1;
        point = point < 0 ? 0 : point;
        if (this.isCommonNode(targetId) || parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
            var text = this.isCommonNode(targetId) ? new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic)
                : new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Characteristic);
            node = this.createSubjectNode(parentNodeId, parentNodeData.level + 1, point, text);
        } else {
            node = this.createSonSubjectNode(parentNodeId, parentNodeData.level + 1);
        }

        parentNode.addChildrenNode(node);

        if (this.isCommonNode(targetId)) {

            this.setNewCreateCommonNodeAttribute(node.value);
            this.commonGroundMindElementDatas.push(node.value);
        }
        return node;
    }

    setNewCreateCommonNodeAttribute(data) {
        if (this.commonGroundMindElementDatas.isEmpty()) {
            return;
        }
        var commonNode = this.commonGroundMindElementDatas[0];
        data.copyStyle(commonNode);
    }

    addBrotherMindElement(targetId) {
        var targetNodeId = targetId;

        var targetNode = this.getNodeById(targetNodeId);
        if (targetNode.isEmpty() || targetNode.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return new LineMindTypeNode();
        }
        var parentNode = this.getNodeById(targetNode.value.parentNodeId);
        var parentNodeData = parentNode.value;

        if (parentNode.isEmpty()) {
            return this.addChildMindElement(targetId);
        } else {
            var node;
            var point = parentNode.getChildIndex(targetNode);
            point = Math.max(point, 0);
            if (this.isCommonNode(targetId) || parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
                var text = this.isCommonNode(targetId) ? new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic) :
                    new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Characteristic);
                node = this.createSubjectNode(parentNodeData.id, parentNodeData.level + 1, point, text);
            } else {
                node = this.createSonSubjectNode(parentNodeData.id, parentNodeData.level + 1);
            }
            parentNode.addChildrenNode(node, point);
            if (this.isCommonNode(targetId)) {
                this.setNewCreateCommonNodeAttribute(node.value);
                this.commonGroundMindElementDatas.splice(this.getCommonPoint(targetNode.value), 0, node.value);
            }
            return node;
        }
    }

    addYoungerBrotherMindElement(targetId) {
        var targetNodeId = targetId;

        var targetNode = this.getNodeById(targetNodeId);
        if (targetNode.isEmpty() || targetNode.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return new LineMindTypeNode();
        }
        var parentNode = this.getNodeById(targetNode.value.parentNodeId);
        var parentNodeData = parentNode.value;

        if (parentNode.isEmpty()) {
            return this.addChildMindElement(targetId);
        } else {
            var node;
            var point = parentNode.getChildIndex(targetNode) + 1;
            point = point < 0 ? 0 : point;
            if (this.isCommonNode(targetId) || parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
                var text = this.isCommonNode(targetId) ? new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic) :
                    new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Characteristic);
                node = this.createSubjectNode(parentNodeData.id, parentNodeData.level + 1, point, text);
            } else {
                node = this.createSonSubjectNode(parentNodeData.id, parentNodeData.level + 1);
            }
            parentNode.addChildrenNode(node, point);

            if (this.isCommonNode(targetId)) {
                this.setNewCreateCommonNodeAttribute(node.value);
                this.commonGroundMindElementDatas.splice(this.getCommonPoint(targetNode.value) + 1, 0, node.value);
            }
            return node;
        }
    }

    getCommonPoint(data) {
        if (this.commonGroundMindElementDatas.isEmpty()) {
            return -1;
        }

        for (var index = 0; index < this.commonGroundMindElementDatas.length; index++) {
            if (this.commonGroundMindElementDatas[index].id == data.id) {
                return index;
            }
        }
        return -1;
    }

    removeElementNode(id) {
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.id != IdGenerator.INVALID_ID &&
            (node.value.type == MindElementType.MAIN_SUBJECT ||
                node.value.type == MindElementType.SUBJECT ||
                node.value.type == MindElementType.SON_SUBJECT)) {
            if (node.value.parentNodeId != IdGenerator.INVALID_ID) {
                var parentNode = this.getNodeById(node.value.parentNodeId);
                if (parentNode.children.length > 0) {
                    for (var index = 0; index < parentNode.children.length; index++) {
                        if (parentNode.children[index].value.id == node.value.id) {
                            parentNode.children.remove(index);
                            break;
                        }
                    }
                }
            } else {
                this.rootTreeNode = null;
            }
            this.removeElementByNode(node);
        }
        this.removeCommonElementNode(node);
    }

    removeCommonElementNode(node) {
        if (node.isEmpty() || !this.isCommonNode(node.value.id)) {
            return;
        }

        var leftLine = this.getLineData(node, this.rootTreeNode);
        var rightLine = this.getLineData(node, this.rootTreeBrotherNode);
        if (!leftLine.isEmpty()) {
            if (this.textElementLineMindElementDataDict.containsKey(node.value.id)) {
                this.textElementLineMindElementDataDict.remove(node.value.id);
            }
            if (this.lineMindElementDataDict.containsKey(leftLine.id)) {
                this.delegate.removeViewByMindData(leftLine);
                this.lineMindElementDataDict.remove(leftLine.id);
            }
        }
        if (!rightLine.isEmpty() && this.lineMindElementDataDict.containsKey(rightLine.id)) {
            this.delegate.removeViewByMindData(rightLine);
            this.lineMindElementDataDict.remove(rightLine.id);
        }
        for (var index = 0; index < this.commonGroundMindElementDatas.length; index++) {
            if (this.commonGroundMindElementDatas[index].id == node.value.id) {
                this.commonGroundMindElementDatas.splice(index, 1);
                return;
            }
        }
    }

    removeImage(id) {
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.imageContent != null) {

            node.value.imageContent = null;
            MindElementCalculation.set(node.value).calcule();
        }
    }

    getLineDataById(id) {
        if (this.lineMindElementDataDict.containsKey(id)) {
            return this.lineMindElementDataDict.get(id);
        }
        return new MindElementData();
    }

    removeElementByNode(node) {
        var data = node.value;
        this.delegate.removeViewByMindData(data);
        if (this.textElementLineMindElementDataDict.containsKey(data.id)) {
            var lineData = this.textElementLineMindElementDataDict.get(data.id);
            this.delegate.removeViewByMindData(lineData);
            this.lineMindElementDataDict.remove(lineData.id);
            this.textElementLineMindElementDataDict.remove(data.id);

        }
        if (this.mainMindElementDataDict.containsKey(data.id)) {
            this.mainMindElementDataDict.remove(data.id);
        }

        node.children.forEach(child => {
            this.removeElementNode(child);
        });
    }

    getRect(space = -1) {
        if (this.rootTreeNode == null) {
            return new CGRect();
        }
        var calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, new HashMap(), new HashMap(), this.rootTreeNode.value, new HashMap(), new HashMap());
        if (space == -1) {
            return calculSaveImageRect.get(MindSettingManager.getMindExportImageSpace());
        } else {
            return calculSaveImageRect.get(space);
        }
    }

    onTextSizeChanged(text, width, height, id) {
        var node = this.getNodeById(id);

        if (node.value.id == IdGenerator.INVALID_ID) {
            return new LineMindTypeNode();
        }
        if (node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            var generalizationContent = node.value.generalizationContent;
            var widhtDifferenceValueAbs = Math.abs(generalizationContent.width - width);
            var heigthDifferenceValueAbs = Math.abs(generalizationContent.height - height);
            generalizationContent.width = width;
            generalizationContent.height = height;
            generalizationContent.text = text;
            MindElementCalculation.set(node.value).caluleTextForData().calcule();
            if (widhtDifferenceValueAbs < 3 && heigthDifferenceValueAbs < 3) {
                return new LineMindTypeNode();
            }
        } else {
            var textContent = node.value.textContent;
            var widhtDifferenceValueAbs = Math.abs(textContent.width - width);
            var heigthDifferenceValueAbs = Math.abs(textContent.height - height);
            textContent.width = width;
            textContent.height = height;
            textContent.text = text;
            MindElementCalculation.set(node.value).caluleTextForData().calcule();
            if (widhtDifferenceValueAbs < 3 && heigthDifferenceValueAbs < 3) {
                return new LineMindTypeNode();
            }
        }
        return node;
    }

    setMindBGColor(value) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return
        }
        let arr = this.mainMindElementDataDict.keys()
        let len = arr.length;
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type != MindElementType.MAIN_SUBJECT &&
                    node.value.type != MindElementType.SUBJECT &&
                    node.value.type != MindElementType.SON_SUBJECT &&
                    node.value.type != MindElementType.CONTENT_GENERALIZATION) {
                continue
            }
            if (node.value.backgroundColor == this.mindBGColor) {
                node.value.backgroundColor = value
            }
            node.value.checkSetTextColor(value);
            if (node.value.borderColor == this.mindBGColor) {
                node.value.borderColor = value;
            }
            if (node.value.isCardContent()) {
                node.value.checkShapeCard(value)
            }
        }
        for (var index = 0; index < this.commonGroundMindElementDatas.length; index++) {
            let data = this.commonGroundMindElementDatas[index]
            if (data.backgroundColor == this.mindBGColor) {
                data.backgroundColor = value;
            }
            data.checkSetTextColor(value);
            if (data.borderColor == this.mindBGColor) {
                data.borderColor = value;
            }
            if (data.isCardContent()) {
                data.checkShapeCard(value)
            }
        }
        this.mindBGColor = value
    }

    refreshLayout(isChange, settingData) {
        if (this.isEmpty()) {
            return;
        }
        this.elementLayout.setDatas(this.rootTreeNode,
            this.lineMindElementDataDict,
            this.mainMindElementDataDict,
            this.textElementLineMindElementDataDict,
            new HashMap(),
            new HashMap(),
            new HashMap(),
            this.mindMapStyleColor, EditMindmapVM.mindBGColor, settingData);
        if (this.rootTreeBrotherNode != null) {
            this.elementLayout.setCommonData(this.rootTreeBrotherNode, this.commonGroundMindElementDatas);
        }
        this.elementLayout.encircleMindElementDataDict = new HashMap();
        this.elementLayout.globalLayout = this.globalLineLayout;
        this.elementLayout.layout(isChange);
    }

    moveNodePoint(moveX, moveY) {
        if (this.rootTreeNode != null) {
            let changeMap = new HashMap()
            this.rootTreeNode.value.x += moveX;
            this.rootTreeNode.value.y += moveY;
            changeMap.put(this.rootTreeNode.value.id, this.rootTreeNode.value)
            for (var index = 0; index < this.rootTreeNode.children.length; index++) {
                let child = this.rootTreeNode.children[index]
                if (child.value.isFreeLayoutNode && !changeMap.containsKey(child.value.id)) {
                    child.value.x += moveX;
                    child.value.y += moveY;
                    changeMap.put(child.value.id, child.value)
                }
            }
            for (var index = 0; index < this.rootTreeBrotherNode.children.length; index++) {
                let child = this.rootTreeBrotherNode.children[index]
                if (child.value.isFreeLayoutNode && !changeMap.containsKey(child.value.id)) {
                    child.value.x += moveX;
                    child.value.y += moveY;
                    changeMap.put(child.value.id, child.value)
                }
            }
            for (var index = 0; index < this.commonGroundMindElementDatas.length; index++) {
                let data = this.commonGroundMindElementDatas[index]
                if (data.isFreeLayoutNode && !changeMap.containsKey(data.id)) {
                    data.x += moveX;
                    data.y += moveY;
                    changeMap.put(data.id, data)
                }
            }
        }
    }

    setElementLayout(layout) {
        this.globalLayout = layout;

        this.elementLayout = new DoubleBubbleLayout();
        this.elementLayout.mindLayoutSelect = MindLayoutSelect
        this.setvColorMode(new BubbleColorMode());
        this.mainMindElementDataDict.keys().forEach(key => {
            var node = this.mainMindElementDataDict.get(key);
            node.value.layout = this.globalLayout;
        });
        this.elementLayout.delegate = this;
    }


    changeNodeAllLineWidth(nodeId, width) {
        if (this.isEmpty()) {
            return;
        }
        if (IdGenerator.INVALID_ID == nodeId) {
            this.lineMindElementDataDict.keys().forEach(key => {
                var line = this.lineMindElementDataDict.get(key);
                line.lineContent.lineWidth = width
            });
            return;
        }

        var node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return;
        }

        this.lineMindElementDataDict.keys().forEach(key => {
            var line = this.lineMindElementDataDict.get(key);
            line.lineContent.lineWidth = width;
        });
    }

    changeNodeLineWidth(id, value) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }

        let line = this.getNodeLine(id);
        if (!line.isEmpty() && line.lineContent.targetId == id) {
            let targetNode = this.getNodeById(line.lineContent.targetId);
            targetNode.children.forEach(child => {
                this.changeNodeLineWidth(child.value.id, value);
            });
        }
    }

    isEmpty() {
        return this.rootTreeNode == null;
    }

    onSelectedRainbowr(lineColors, nodeBackgorundColor = false) {
        if (this.isEmpty()) {
            return;
        }

        this.lineColors = new Array();
        let lineColorsLength = lineColors.length;
        for (let index = 1; index < lineColorsLength; index++) {
            this.lineColors.push(lineColors[index]);
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        let rootTreeNodeColor = this.rootTreeNode.value.backgroundColor;
        if (!Colors.isClear(this.rootTreeNode.value.borderColor) && this.rootTreeNode.value.borderWidth > 0) {
            this.rootTreeNode.value.borderColor = lineColors[0];
            if (this.rootTreeBrotherNode != null) {
                this.rootTreeBrotherNode.value.borderColor = lineColors[0];
            }
        }

        if (!Colors.isClear(rootTreeNodeColor) && this.rootTreeNode.value.textContent != null) {
            this.rootTreeNode.value.backgroundColor = lineColors[0];
            if (this.rootTreeBrotherNode != null) {
                this.rootTreeBrotherNode.value.backgroundColor = lineColors[0];
            }
            if (Colors.isDarkColor(rootTreeNodeColor) && !Colors.isDarkColor(lineColors[0])) {
                this.rootTreeNode.value.textContent.textColor = 0x4a4a4a;
            } else if (!Colors.isDarkColor(rootTreeNodeColor) && Colors.isDarkColor(lineColors[0])) {
                this.rootTreeNode.value.textContent.textColor = 0xffffff;
            }
            if (this.rootTreeBrotherNode != null && this.rootTreeBrotherNode.value.textContent != null) {
                this.rootTreeBrotherNode.value.textContent.textColor = this.rootTreeNode.value.textContent.textColor;
            }
        }

        let rootTreeNodeChildrenLength = this.rootTreeNode.children.length;
        for (let index = 0; index < rootTreeNodeChildrenLength; index++) {
            let point = index % lineColors.length;
            let node = this.rootTreeNode.children[index];
            let line = this.textElementLineMindElementDataDict.get(node.value.id);
            line.lineContent.color = lineColors[point];
            node.value.textContent.textColor = Colors.black80;
            if (this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
                node.value.borderColor = lineColors[point];
            }
            this.setNodeLineColor(node, lineColors[point]);
        }
        let rootTreeBrotherNodeChildrenLength = this.rootTreeBrotherNode.children.length;
        for (let index = 0; index < rootTreeBrotherNodeChildrenLength; index++) {
            let point = index % lineColors.length;
            let node = this.rootTreeBrotherNode.children[index];
            let line = this.textElementLineMindElementDataDict.get(node.value.id);
            line.lineContent.color = lineColors[point];
            node.value.textContent.textColor = Colors.black80;
            if (this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
                node.value.borderColor = lineColors[point];
            }
            this.setNodeLineColor(node, lineColors[point]);
        }
    }

    setlineThicken(b) {
        if (this.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        for (var index = 0; index < this.rootTreeNode.children.length; index++) {
            var node = this.rootTreeNode.children[index];
            var line = this.textElementLineMindElementDataDict.get(node.value.id);
            line.lineContent.lineThicken = b;
        }
    }

    setlineDot(b, id) {
        if (this.isEmpty()) {
            return;
        }
        let node = this.getNodeById(id);
        if (this.rootTreeNode.children.isEmpty() || node.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.value.id == id) {
            let keys = this.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let data = this.lineMindElementDataDict.get(key);
                if (data.lineContent != null &&
                    (data.type == MindElementType.LINE ||
                        data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE)) {
                    data.lineContent.dottedLine = b;
                }
            }
        } else {
            let lines = this.getNodeLines(node.value.id);
            if (!lines.isEmpty()) {
                let linesLength = lines.length;
                for (let index = 0; index < linesLength; index++) {
                    let line = lines[index];
                    line.lineContent.dottedLine = b;
                }
            }
        }
    }

    setlineShadow(b, id) {
        if (this.isEmpty()) {
            return;
        }
        let node = this.getNodeById(id);
        if (this.rootTreeNode.children.isEmpty() || node.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.value.id == id) {
            let keys = this.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let data = this.lineMindElementDataDict.get(key);
                if (data.lineContent != null &&
                    (data.type == MindElementType.LINE ||
                        data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE)) {
                    data.isShadow = b;
                }
            }
        } else {
            let lines = this.getNodeLines(node.value.id);
            if (!lines.isEmpty()) {
                let linesLength = lines.length;
                for (let index = 0; index < linesLength; index++) {
                    let line = lines[index];
                    line.isShadow = b;
                }
            }
        }
    }

    setNodeLineColor(node, lineColor) {
        var line = this.textElementLineMindElementDataDict.get(node.value.id);
        line.lineContent.color = lineColor;
        node.children.forEach(child => {
            this.setNodeLineColor(child, lineColor);
        });
    }

    changeNodeAllLineColor(nodeId, color) {
        if (IdGenerator.INVALID_ID == nodeId) {
            this.lineMindElementDataDict.keys().forEach(key => {
                var line = this.lineMindElementDataDict.get(key);
                line.lineContent.color = color;
            });
            return;
        }
        var node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return;
        }

        this.lineMindElementDataDict.keys().forEach(key => {
            var line = this.lineMindElementDataDict.get(key);
            line.lineContent.color = color;
        });
    }

    callbackResetDatas(rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict) {
        this.rootTreeNode = rootTreeNode;
        this.lineMindElementDataDict = lineMindElementDataDict;
        this.mainMindElementDataDict = mainMindElementDataDict;
        this.textElementLineMindElementDataDict = textElementLineMindElementDataDict;
    }

    callbackDoubleBubbleResetDatas(rootTreeBrotherNode, commonElementDatas) {
        this.rootTreeBrotherNode = rootTreeBrotherNode;
        this.commonGroundMindElementDatas = commonElementDatas;
    }
    createRootNode() {
        var mainNode = this.createMainNode();
        this.rootTreeBrotherNode = this.createMainNode();
        this.rootTreeBrotherNode.value.id = IdGenerator.shared.getId();

        this.setRootNode(mainNode);
        var commonNode1 = this.createSubjectNode(mainNode.value.id, 0, 0,
            new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic));
        var commonNode2 = this.createSubjectNode(mainNode.value.id, 0, 1,
            new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Common_Characteristic));

        this.commonGroundMindElementDatas.push(commonNode1.value);
        this.commonGroundMindElementDatas.push(commonNode2.value);
        this.setRootTreeBrotherNode(this.rootTreeBrotherNode);
    }

    isCommonNode(id) {
        let length = this.commonGroundMindElementDatas.length
        for (let index = 0; index < length; index++) {
            let data = this.commonGroundMindElementDatas[index]
            if (data.id == id) {
                return true;
            }
        }
        return false;
    }

    getCommonNode(id) {
        let length = this.commonGroundMindElementDatas.length
        for (let index = 0; index < length; index++) {
            let data = this.commonGroundMindElementDatas[index]
            if (data.id == id) {
                return new LineMindTypeNode(data);
            }
        }
        return new LineMindTypeNode();
    }

    createMainNode() {
        var rootData = new MindElementData();
        rootData.id = IdGenerator.shared.getRootId();
        rootData.type = MindElementType.MAIN_SUBJECT;
        rootData.parentNodeId = IdGenerator.INVALID_ID;
        var textContent = new TextContent((this.globalLayout == NodeLayoutType.LAYOUT_BOTTOM ||
            this.globalLayout == NodeLayoutType.LAYOUT_TOP) ?
            new UiUtil().getString(strings.Mind_Organization_Edit_Main_Idea) :
            new UiUtil().getString(strings.Mind_Edit_Main_Idea));
        textContent.width = 112;
        textContent.height = 56;
        textContent.x = 0;
        textContent.y = 0;
        rootData.textContent = textContent;
        rootData.level = 0;
        if (this.globalLayout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE) {
            rootData.mindElementShape = MindElementShapeType.Circular;
        }
        rootData.backgroundColor = this.mindMapStyleColor.getRootNodeFillColor();
        rootData.borderColor = this.mindMapStyleColor.getRootNodeBorderColor();
        rootData.borderWidth = this.mindMapStyleColor.getRootNodeBorderWidth();
        textContent.textColor = this.mindMapStyleColor.getRootNodeTextColor();
        textContent.textFontSize = this.mindMapStyleColor.getrootNodeTextFontSize();
        textContent.textBold = true
        rootData.styleColor = this.mindMapStyleColor.getLineColor();
        rootData.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();
        rootData.checkShapeCard(EditMindmapVM.mindBGColor)
        MindElementCalculation.set(rootData).caluleTextForData().calcule();
        var rootNode = new LineMindTypeNode();
        rootNode.value = rootData;
        return rootNode;
    }

    addIcon(id, key) {
        var node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        var iconElementContents = node.value.iconElementContents == null ? new Array() : node.value.iconElementContents;
        if (iconElementContents.length > 0) {
            for (var index = 0; index < iconElementContents.length; index++) {
                if (iconElementContents[index].key.equals(key)) {
                    iconElementContents.remove(index);
                    break;
                }
            }
        }
        var cell = new IconElementContent(key, new UiUtil().dip2px(20), new UiUtil().dip2px(20));
        iconElementContents.push(cell);
        node.value.iconElementContents = iconElementContents;
    }

    createSubjectNode(parentNodeId, level, point) {
        return this.createSubjectNode(parentNodeId, level, point, new UiUtil().getString(strings.Mind_Double_Bubble_Edit_Main_Characteristic));
    }

    createSubjectNode(parentNodeId, level, point, text) {
        var parentNode = this.getNodeById(parentNodeId);
        var brotherNode = new LineMindTypeNode();
        if (!parentNode.isEmpty() && parentNode.children.length > 0) {
            brotherNode = parentNode.children[0];
        } else {
            var brotherNodeTextContent = new TextContent(text);
            brotherNode.value.textContent = brotherNodeTextContent;
            brotherNode.value.textContent = brotherNodeTextContent;

            brotherNode.value.styleColor = this.mindMapStyleColor.getLineColor();
            brotherNode.value.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();

            brotherNode.value.backgroundColor = this.mindMapStyleColor.getSubjectViewFillColor();
            brotherNode.value.borderColor = this.mindMapStyleColor.getSubjectBorderColor();
            brotherNode.value.borderWidth = this.mindMapStyleColor.getSubjectBorderWidth();
            brotherNodeTextContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
            brotherNodeTextContent.textFontSize = this.mindMapStyleColor.getSubjectTextFontSize();
        }


        var textData = new MindElementData();
        textData.id = IdGenerator.shared.getId();
        textData.type = MindElementType.SUBJECT;
        textData.parentNodeId = parentNodeId;
        textData.level = level;
        var textContent = new TextContent(text);
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 65;
        textContent.height = 38;
        textData.textContent = textContent;

        textData.styleColor = brotherNode.value.styleColor;
        textData.styleFillColor = brotherNode.value.styleFillColor;
        if (this.globalLayout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE) {
            textData.mindElementShape = MindElementShapeType.Circular;
        }
        textData.backgroundFullType = brotherNode.value.backgroundFullType;
        textData.backgroundColor = brotherNode.value.backgroundColor;
        textData.borderColor = brotherNode.value.borderColor;
        textData.borderWidth = brotherNode.value.borderWidth;
        textData.isShadow = brotherNode.value.isShadow;
        textContent.textColor = brotherNode.value.textContent.textColor;
        textContent.textShadow = brotherNode.value.textContent.textShadow;
        textContent.bgColor = brotherNode.value.textContent.bgColor;
        textContent.textFontSize = brotherNode.value.textContent.textFontSize;

        MindElementCalculation.set(textData).caluleTextForData().calcule();
        textData.checkShapeCard(EditMindmapVM.mindBGColor)
        var node = new LineMindTypeNode(textData);
        this.mainMindElementDataDict.put(textData.id, node);
        return node;
    }

    createFreeNode(parentNodeId, level) {
        var textData = new MindElementData();
        textData.id = IdGenerator.shared.getId();
        textData.type = MindElementType.SUBJECT;
        textData.parentNodeId = parentNodeId;
        textData.level = level;
        var textContent = new TextContent(new UiUtil().getString(strings.Mind_Edit_Free_Topic));
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 110;
        textContent.height = 41;
        textData.textContent = textContent;
        if (this.globalLayout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE) {
            textData.mindElementShape = MindElementShapeType.Circular;
        }
        textData.backgroundColor = this.mindMapStyleColor.getSubjectViewFillColor();
        textData.borderColor = this.mindMapStyleColor.getSubjectBorderColor();
        textData.borderWidth = this.mindMapStyleColor.getSubjectBorderWidth();
        textContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
        textContent.textFontSize = this.mindMapStyleColor.getSubjectTextFontSize();
        textData.styleColor = this.mindMapStyleColor.getLineColor();
        textData.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();

        MindElementCalculation.set(textData).caluleTextForData().calcule();
        textData.checkShapeCard(EditMindmapVM.mindBGColor)
        var node = new LineMindTypeNode(textData);
        this.mainMindElementDataDict.put(textData.id, node);
        return node;
    }

    createSonSubjectNode(parentNodeId, level) {
        var sonTextData = new MindElementData();
        var parentNode = this.getNodeById(parentNodeId);

        sonTextData.parentNodeId = parentNodeId;
        sonTextData.level = level;
        sonTextData.id = IdGenerator.shared.getId();
        sonTextData.type = MindElementType.SON_SUBJECT;
        sonTextData.width = 50;
        sonTextData.height = 23;

        var textContent = new TextContent(new UiUtil().getString(strings.Mind_Edit_Sub_Topic));
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 58;
        textContent.height = 26;
        sonTextData.textContent = textContent;

        sonTextData.backgroundColor = this.lineColorMode.getSonSubjectViewFillColor();
        sonTextData.borderColor = this.lineColorMode.getSonSubjectViewBorderColor();
        sonTextData.borderWidth = this.lineColorMode.getSonSubjectViewBorderWidth();
        textContent.textColor = this.lineColorMode.subjectTextColor;
        textContent.textFontSize = this.lineColorMode.sonSubjectTextFontSize;
        if (this.globalLayout == NodeLayoutType.LAYOUT_DOUBLE_BUBBLE) {
            sonTextData.mindElementShape = MindElementShapeType.Circular;
        }
        if (!Colors.isClear(sonTextData.backgroundColor)) {
            if (!Colors.isClear(sonTextData.backgroundColor) && Colors.isDarkColor(sonTextData.backgroundColor) &&
                sonTextData.backgroundColor != Colors.white &&
                sonTextData.backgroundColor != 0xF4F4F4) {
                textContent.textColor = this.lineColorMode.sonSubjectDarkTextColor;
            } else {
                textContent.textColor = this.lineColorMode.sonSubjectTextColor;
            }
        } else {
            if (!Colors.isClear(EditMindmapVM.mindBGColor) && Colors.isDarkColor(EditMindmapVM.mindBGColor) &&
                EditMindmapVM.mindBGColor != Colors.white &&
                EditMindmapVM.mindBGColor != 0xF4F4F4) {
                textContent.textColor = this.lineColorMode.sonSubjectDarkTextColor;
            } else {
                textContent.textColor = this.lineColorMode.sonSubjectTextColor;
            }
        }

        // sonTextData.styleColor = parentNode.value.styleColor;
        sonTextData.styleFillColor = parentNode.value.styleFillColor;
        sonTextData.checkShapeCard(EditMindmapVM.mindBGColor)

        MindElementCalculation.set(sonTextData).caluleTextForData().calcule();
        var node = new LineMindTypeNode(sonTextData);
        this.mainMindElementDataDict.put(sonTextData.id, node);
        return node;
    }

    copy() {
        var unit = new DoubleBubbleMindNodeUnit(this.mindType, this.mindMapStyleColor.currentSelectedIndex);
        if (this.rootTreeNode == null) {
            return unit;
        }
        unit.elementLayout = this.elementLayout;
        unit.globalLayout = this.globalLayout;
        unit.globalLineLayout = this.globalLineLayout;
        unit.lineColorMode = this.lineColorMode;
        unit.lineColors = this.lineColors;
        unit.delegate = this.delegate;
        unit.setElementLayout(this.globalLayout);
        unit.rootTreeNode = this.rootTreeNode.copy();
        unit.rootTreeBrotherNode = this.rootTreeBrotherNode.copy();
        unit.lineColors = this.lineColors;
        unit.mindBGColor = this.mindBGColor;
        let keys = this.lineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            unit.lineMindElementDataDict.put(keys[index], this.lineMindElementDataDict.get(keys[index]).copy());
        }
        if (unit.rootTreeNode != null) {
            unit.setMainMindElementDataDictByRootTreeNode(unit.rootTreeNode);
        }
        if (unit.rootTreeBrotherNode != null) {
            unit.setMainMindElementDataDictByRootTreeNode(unit.rootTreeBrotherNode);
        }
        unit.setTextElementLineMindElementDataDict();

        this.commonGroundMindElementDatas.forEach(cell => {
            unit.commonGroundMindElementDatas.push(cell.copy());
        });
        return unit;
    }

    setMainMindElementDataDictByRootTreeNode(node) {
        this.mainMindElementDataDict.put(node.value.id, node);
        node.children.forEach(cell => {
            this.setMainMindElementDataDictByRootTreeNode(cell);
        });
    }

    setTextElementLineMindElementDataDict() {
        let keys = this.lineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            var data = this.lineMindElementDataDict.get(keys[index]);
            if (data != null && !data.isEmpty() && data.lineContent != null) {
                this.textElementLineMindElementDataDict.put(data.lineContent.targetId, data);
            }
        }
    }

    changeSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys()
        let len = arr.length;
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }

        let commonArr = this.commonGroundMindElementDatas;
        let commonLen = commonArr.length
        for (let index = 0; index < commonLen; index++) {
            let data = commonArr[index]
            if (data.type == MindElementType.SUBJECT) {
                if (data.textContent != null) {
                    data.textContent.textFontSize = value;
                    MindElementCalculation.set(data).caluleTextForData();
                } else if (data.generalizationContent != null) {
                    data.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(data).caluleTextForData();
                }
            }
        }
    }

    changeSonSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys();
        let len = arr.length;
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SON_SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }
    }

    removeUselessElement() {
        if (this.rootTreeNode == null || this.rootTreeBrotherNode == null) {
            return;
        }
        let removeList = []
        let valuse = this.lineMindElementDataDict.values()
        let length = valuse.length
        for (let index = 0; index < length; index++) {
            let line = valuse[index];
            if (line == null || line.isEmpty() || line.lineContent == null) {
                continue
            }
            let node = this.getNodeById(line.lineContent.targetId);
            let parentNode = this.getNodeById(line.parentNodeId);
            if (node.isEmpty() || parentNode.isEmpty()) {
                removeList.push(line);
            }
        }
        for (let index = 0; index < removeList.length; index++) {
            let cell = removeList[index]
            if (cell.type == MindElementType.LINE ||
                cell.type == MindElementType.SON_LINE ||
                cell.type == MindElementType.LAYOUT_FISH_RIGHT_LINE)
                this.removeLine(cell);
        }
    }

    removeLine(line) {
        if (line == null || line.isEmpty()) {
            return;
        }
        this.delegate.removeViewByMindData(line)
        this.lineMindElementDataDict.remove(line.id)
        let keys = this.textElementLineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let id = keys[index]
            if (this.textElementLineMindElementDataDict.get(id).id == line.id) {
                this.textElementLineMindElementDataDict.remove(id);
                break
            }
        }
    }

    styleToPeer(node) {
        if (node.isEmpty() || node.value.type != MindElementType.SUBJECT && node.value.type != MindElementType.SON_SUBJECT) {
            return;
        }
        if (this.isCommonNode(node.value.id)) {
            for (let index = 0; index < this.commonGroundMindElementDatas.length; index++) {
                const data = this.commonGroundMindElementDatas[index];
                if (data.id == node.value.id) {
                    continue;
                }
                data.stickStyle(node.value);
                MindElementCalculation.set(data).caluleTextForData();
            }
        } else {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.isEmpty()) {
                return;
            }
            for (let index = 0; index < parentNode.children.length; index++) {
                const cell = parentNode.children[index];
                if (cell.value.id == node.value.id) {
                    continue;
                }
                cell.stickStyle(node);
                MindElementCalculation.set(cell.value).caluleTextForData();
            }
        }
    }

    isRootNode(id) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return false;
        }
        return this.rootTreeNode.value.id == id;
    }

    isRootBrotherNode(id) {
        if (this.rootTreeBrotherNode == null || this.rootTreeBrotherNode.isEmpty()) {
            return false;
        }
        return this.rootTreeBrotherNode.value.id == id;
    }
}
export default DoubleBubbleMindNodeUnit
