import UiUtil from "../../../utils/UiUtil"
import CGRect from "../../../viewmodel/core/base/basedata/Rect"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import HashMap from "../../../viewmodel/core/base/HashMap"
import Config from "./Config"
import Size from "../../../viewmodel/core/base/Size"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import MindElementType from "../../../viewmodel/datatype/MindElementType"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/2
 * Copyright(c) 2020 mindyushu.com
 */

class CalculSaveImageRect {
    constructor(mindNodes, generalizationMind, 
        encircleMindElementDataDict, root,
        lineMindElementDataDict = new HashMap(),
        explainMindElementDataDict = new HashMap(),
        minLeft = 0, minTop = 0) {
        this.mainMindElementDataDict = mindNodes; //主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.generalizationMindElementDataDict = generalizationMind; //概要字典， Int为线ID data为概要节点
        this.encircleMindElementDataDict = encircleMindElementDataDict;
        this.root = root != null ? root : (new MindElementData()).emptyMindNode();
        this.lineMindElementDataDict = lineMindElementDataDict;
        this.explainMindElementDataDict = explainMindElementDataDict;
        this.maxHeight = 3000;
        this.maxWidth = 3000;
        this.maxRight = 400000;
        this.maxBottom = 400000;
        this.minLeft = minLeft;
        this.minTop = minTop;
        this.init();
    }

    init() {
        if (this.root == null || this.root.isEmpty()) {
            return;
        }
        this.maxRight = this.root.x + this.root.width + 100000;
        this.maxBottom = this.root.y + this.root.height + 100000;

        // this.minLeft = this.maxRight - 300000;
        // this.minTop = this.maxBottom - 300000;
        // console.log("this.maxRight --",this.maxRight, this.maxBottom, this.minLeft, this.minTop);
        // if (this.minLeft <= 0) {
        //     this.minLeft = 0;
        // }
        // if (this.minTop <= 0) {
        //     this.minTop = 0;
        // }
    }

    get(space = (new UiUtil).dip2px(40)) {
        var rect = new CGRect();
        var left = 400000000;
        var top = 400000000;
        var right = -40000;
        var bottom = -40000;

        let mainMindElementDataDictValues = this.mainMindElementDataDict.values()
        let mainMindElementDataDictLength = mainMindElementDataDictValues.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let node = mainMindElementDataDictValues[index];
            if (node.value.isHidden||
                node.value.x <= this.minLeft ||
                node.value.y <= this.minTop  ||
                node.value.x > this.maxRight||
                node.value.y > this.maxBottom||
                node.value.width > this.maxWidth ||
                node.value.height > this.maxHeight) {
                continue
            }
            if (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                if (left > node.value.x - 50) {
                    left = node.value.x - 50;
                }
            } else {
                if (left > node.value.x) {
                    left = node.value.x;
                }
            }
            
            if (top > node.value.y) {
                top = node.value.y;
            }
            if (node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                if (right < node.value.x + node.value.width + 50) {
                    right = node.value.x + node.value.width + 50;
                }
            } else if (right < node.value.x + node.value.width) {
                right = node.value.x + node.value.width;
            }
            if (bottom < node.value.y + node.value.height) {
                bottom = node.value.y + node.value.height;
            }
        }
        let lineMindElementDataDictValues = this.lineMindElementDataDict.values()
        let lineMindElementDataDictLength = lineMindElementDataDictValues.length
        for (let index = 0; index < lineMindElementDataDictLength; index++) {
            let data = lineMindElementDataDictValues[index];
            
            if (data.lineContent == null) {
                continue
            }
            let targetNode = this.getNodeById(data.lineContent.targetId)
            let parentNode = this.getNodeById(data.parentNodeId)

            if ((data.type == MindElementType.LINE || 
                data.type == MindElementType.SON_LINE) && (targetNode.isEmpty() || targetNode.value.isHidden || parentNode.isEmpty() || parentNode.value.isHidden)) {
                continue
            }
            
            if (data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                if ((!targetNode.isEmpty() && 
                    (targetNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT))) {
                    if (left > data.x) {
                        left = data.x;
                    }
                    if (top > data.y) {
                        top = data.y;
                    }
                    if (right < data.x + data.width + 50) {
                        right = data.x + data.width + 50;
                    }
                    if (bottom < data.y + data.height) {
                        bottom = data.y + data.height;
                    }
                }
                if ((!targetNode.isEmpty() && 
                    (targetNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT))) {
                    if (left > data.x - 50) {
                        left = data.x - 50;
                    }
                    if (top > data.y) {
                        top = data.y;
                    }
                    if (right < data.x + data.width) {
                        right = data.x + data.width;
                    }
                    if (bottom < data.y + data.height) {
                        bottom = data.y + data.height;
                    }
                }
                continue
            }

            if (data.isHidden ||
                    data.x <= this.minLeft ||
                    data.y <= this.minTop  ||
                    data.x > this.maxRight ||
                    data.y > this.maxBottom ||
                    (data.type != MindElementType.FORM_LINE && 
                        this.root.type != MindElementType.RADIATION_MAP_LINE &&
                        this.root.layout != NodeLayoutType.LAYOUT_CIRCLE &&
                        data.layout != NodeLayoutType.LAYOUT_RADIATE) ||
                        (this.root.layout != NodeLayoutType.LAYOUT_CIRCLE && !parentNode.isEmpty() &&
                         (parentNode.value.isHidden || parentNode.children.length == 0 || parentNode.children[0].value.isHidden)))  {
                continue;
            }
            if (data.type == MindElementType.FORM_LINE) {
                let target = this.getNodeById(data.lineContent.targetId);
                if (target.isEmpty() || target.value.isHidden) {
                    continue;
                }
            }
            if (data.layout == NodeLayoutType.LAYOUT_RADIATE && this.root.layout != NodeLayoutType.LAYOUT_RADIATE) {
                continue;
            }
            if (left > data.x) {
                left = data.x;
            }
            if (top > data.y) {
                top = data.y;
            }
            if (right < data.x + data.width) {
                right = data.x + data.width;
            }
            if (bottom < data.y + data.height) {
                bottom = data.y + data.height;
            }
        }
        let explainMindElementDataDictValues = this.explainMindElementDataDict.values()
        let explainMindElementDataDictLength = explainMindElementDataDictValues.length;
        for (let index = 0; index < explainMindElementDataDictLength; index++) {
            let data = explainMindElementDataDictValues[index];
            if (data.isHidden ||
                    data.x <= this.minLeft ||
                    data.y <= this.minTop  ||
                    data.x > this.maxRight ||
                    data.y > this.maxBottom ||
                    data.width > this.maxWidth ||
                    data.height > this.maxHeight) {
                continue;
            }
            if (left > data.x) {
                left = data.x;
            }
            if (top > data.y) {
                top = data.y;
            }
            if (right < data.x + data.width) {
                right = data.x + data.width;
            }
            if (bottom < data.y + data.height) {
                bottom = data.y + data.height;
            }
        }
        let generalizationMindElementDataDictValues = this.generalizationMindElementDataDict.values()
        let generalizationMindElementDataDictLength = generalizationMindElementDataDictValues.length
        for (let index = 0; index < generalizationMindElementDataDictLength; index++) {
            let node = generalizationMindElementDataDictValues[index];
            if (node.value.isHidden||
                node.value.x <= this.minLeft ||
                node.value.y <= this.minTop  ||
                node.value.x > this.maxRight||
                node.value.y > this.maxBottom||
                node.value.width > this.maxWidth ||
                node.value.height > this.maxHeight) {
                continue
            }
            if (left > node.value.x) {
                left = node.value.x;
            }
            if (top > node.value.y) {
                top = node.value.y;
            }
            if (right < node.value.x + node.value.width) {
                right = node.value.x + node.value.width;
            }
            if (bottom < node.value.y + node.value.height) {
                bottom = node.value.y + node.value.height;
            }
        }
        if (this.encircleMindElementDataDict != null) {
            let keys = this.encircleMindElementDataDict.keys();
            let encircleLength = keys.length;
            for (let index = 0; index < encircleLength; index++) {
                let data = this.encircleMindElementDataDict.get(keys[index]);
                if (data.isHidden||
                    data.x <= this.minLeft ||
                    data.y <= this.minTop  ||
                    data.x > this.maxRight||
                    data.y > this.maxBottom||
                    data.width > this.maxWidth ||
                    data.height > this.maxHeight) {
                    continue;
                }
                if (left > data.x) {
                    left = data.x;
                }
                if (top > data.y) {
                    top = data.y;
                }
                if (right < data.x + data.width) {
                    right = data.x + data.width;
                }
                if (bottom < data.y + data.height) {
                    bottom = data.y + data.height;
                }
                if (right - left > 2000) {
                    // console.log(data);
                }
            }
        }
        left = left > space ? (left - space) : left;
        top = top > space ? (top - space) : top;
        right = right > Config.Mind_Width - space ? right : right + space;
        bottom = bottom >Config.Mind_Height - space ? bottom : bottom + space;
        rect.x = left;
        rect.y = top;
        rect.setSize(new Size(right - left, bottom - top));
        return rect;
    }

    getNodeById(id) {
        if (this.mainMindElementDataDict.containsKey(id)) {
            return this.mainMindElementDataDict.get(id);
        } else if (this.generalizationMindElementDataDict.containsKey(id)) {
            return this.generalizationMindElementDataDict.get(id);
        } else if (this.explainMindElementDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.explainMindElementDataDict.get(id));
        } else if (this.encircleMindElementDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.encircleMindElementDataDict.get(id));
        }
        return new LineMindTypeNode();
    }
}

export default CalculSaveImageRect