// package mind.yushu.com.mindmap.main.edit.tools;

import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import LinePath from "../../../viewmodel/core/base/LinePath";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import ResetMindType from "./ResetMindType";

// import android.content.Context;
// import android.util.Size;
// import android.view.Gravity;
// import android.view.View;
// import android.widget.FrameLayout;
// import android.widget.TextView;

// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.utils.UiUtil;



/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */

class resetMindView {
    constructor(contentViewSvg,scrollView) {
        this.space = new UiUtil().dip2px(5),
        this.dragViewHeight = new UiUtil().dip2px(12),
        this.encircleLinePitchOnNode,
        this.encircleLinePitchOnNodeChild,
        this.contentViewSvg = contentViewSvg,
        this.scrollView = scrollView,
        this.encircleLinePitchOnNodeLineChild,
        this.boxBorderColor = 'rgb(253, 148, 130)',
        this.lineColor = '#fff',
        this.boxData,   //存储的宽高与坐标。
        this.nowBoxData, //实际的包裹盒子。
        this.dragBoader = '',
        this.DragFlag = false,  //当前是否在拖动矩形区域
        this.DragImageFlag = false, //是否拖动图片矩形
        this.isBorderType, //判断是包裹线的框，还是摘要的框，还是图片的框
        this.wrappingPath,//包裹线编辑图标foreignObject
        // this.rightPath,
        this.leftPath,
        this.rightPath,
        this.linePath1,
        this.linePath2,
        this.linePath3,
        this.linePath4,
        this.imgDragBottomRightButton,
        this.imgDragBottomRightButtonData = {
            width: 12,
            height: 12,
            x: 0,
            y: 0
        }
        this.imgDragBottomLeftButtonData = {
            width: 12,
            height: 12,
            x: 0,
            y: 0
        }
        this.imgDragTopLeftButtonData = {
            width: 12,
            height: 12,
            x: 0,
            y: 0
        }
        this.imgDragTopRightButtonData = {
            width: 12,
            height: 12,
            x: 0,
            y: 0
        }
        this.mindData, //存储的节点数据
        this.mindThis,  //节点实例，包含了节点的dom还有节点的数据。
        this.buttonType = null //当前点击的拖动图片按钮位置
    }
    createdEncircleLinePitchOnNode() {
        
        this.encircleLinePitchOnNode = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.encircleLinePitchOnNodeLineChild = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        this.encircleLinePitchOnNode.appendChild(this.encircleLinePitchOnNodeLineChild);
        this.encircleLinePitchOnNodeChild = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.encircleLinePitchOnNodeChild);
        this.encircleLinePitchOnNodeChildBorder = document.createElement('div');
        this.encircleLinePitchOnNodeChild.appendChild(this.encircleLinePitchOnNodeChildBorder);
        this.contentViewSvg.appendChild(this.encircleLinePitchOnNode);
        this.leftPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.leftPath);
        this.rightPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.rightPath);

        // this.topPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        // this.encircleLinePitchOnNode.appendChild(this.topPath);
        // this.bottomPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        // this.encircleLinePitchOnNode.appendChild(this.bottomPath);

        this.linePath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.linePath1);
        this.linePath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.linePath2);
        this.linePath3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.linePath3);
        this.linePath4 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        this.encircleLinePitchOnNode.appendChild(this.linePath4);

        //包裹线编辑图标
        this.wrappingPath = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.wrappingPath);
        this.wrappingPathDiv = document.createElement('div');
        this.wrappingPath.appendChild(this.wrappingPathDiv);

        //图片右下角拖动框
        this.imgDragBottomRightButton = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.imgDragBottomRightButton);
        this.imgDragBottomRightButtonChild = document.createElement('div');
        this.imgDragBottomRightButton.appendChild(this.imgDragBottomRightButtonChild);

        //图片左下角拖动框
        this.imgDragBottomLeftButton = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.imgDragBottomLeftButton);
        this.imgDragBottomLeftButtonChild = document.createElement('div');
        this.imgDragBottomLeftButton.appendChild(this.imgDragBottomLeftButtonChild);

        //图片左上角拖动框
        this.imgDragTopLeftButton = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.imgDragTopLeftButton);
        this.imgDragTopLeftButtonChild = document.createElement('div');
        this.imgDragTopLeftButton.appendChild(this.imgDragTopLeftButtonChild);

        //图片左上角拖动框
        this.imgDragTopRightButton = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
        this.encircleLinePitchOnNode.appendChild(this.imgDragTopRightButton);
        this.imgDragTopRightButtonChild = document.createElement('div');
        this.imgDragTopRightButton.appendChild(this.imgDragTopRightButtonChild);

    }

    hit(x, y) {
        if (!this.isShow() || this.boxData == null || this.boxData.x == null ||
            this.boxData.y == null || this.boxData.height == null ||
            this.boxData.width == null) {
            return false
        }
        
        return Util.containsInRectForXY(new CGRect(this.boxData.x, this.boxData.y, this.boxData.width, this.boxData.height), x, y)
    }

    setRect(data, type, rect, mindThis) {
        // , width, height, leftMargin, topMargin
        if (this.encircleLinePitchOnNode == null) {
            this.createdEncircleLinePitchOnNode()
        }
        this.mindData = data
        let obj = {}
        obj.width = rect.width();
        obj.height = rect.height();
        obj.x = rect.x;
        obj.y = rect.y;
        this.boxData = obj;
        this.isBorderType = type;
        this.mindThis = mindThis;
        if (type == ResetMindType.LEFT_RIGHT) {
            this.pathDrage('left');
            this.pathDrage2('right');
            this.leftButtonDrage('left');
            this.rightButtonDrage('right');
            this.clickToEdit();
            this.showEncircleLinePitchOnNodeLeftRight(obj);
            this.setPathStyle()
            this.setLinePathStyle();
        } else if (type == ResetMindType.TOP_BOTTOM) {
            this.pathDrage('top');
            this.pathDrage2('bottom')
            this.leftButtonDrage('top');
            this.rightButtonDrage('bottom');
            this.clickToEdit();
            this.showEncircleLinePitchOnNodeTopBottom(obj);
            this.setPathStyle()
            this.setLinePathStyle();
        } else if ((type == 'image' || type == 'free') && this.DragImageFlag == false) {  //这里限制不能在拖动时设置值，是因为缩小图片的时候有时候会出现这里传入的图片宽高把另外一个入口的宽高覆盖掉了，导致图片没有变化。
            
            this.leftPath.style.display = "none"
            this.rightPath.style.display = "none"
            this.imgDragBottomRightButtonDrage();
            this.imgDragBottomLeftButtonDrage();
            this.imgDragTopLeftButtonDrage();
            this.imgDragTopRightButtonDrage();
            this.showImageDarg(obj);
            // this.setPathStyle()
            // this.setLinePathStyle();
        }
    }

    getData() {
        return this.mindData
    }

    setPathStyle() {
        this.leftPath.style.cursor = 'pointer';
        this.rightPath.style.cursor = 'pointer';
    }

    showEncircleLinePitchOnNodeLeftRight(data) {        //左右拖动
        if (this.encircleLinePitchOnNode == null ||
            this.encircleLinePitchOnNode.style == null ||
            data == null) {
            return
        }
        this.nowBoxData = data;
        this.dragViewHeight = data.width > new UiUtil().dip2px(800) ? new UiUtil().dip2px(25) :
        data.height > new UiUtil().dip2px(500) ? new UiUtil().dip2px(20) : new UiUtil().dip2px(12);
        // textDrawingBoard.removeView(resetMindView);
        let width = data.width + this.space * 2 + this.dragViewHeight * 2;
        let height = data.height + this.space * 2;
        let leftMargin = data.x - this.space - this.dragViewHeight;
        let topMargin = data.y - this.space;

        this.encircleLinePitchOnNode.style.width = width + 'px';
        this.encircleLinePitchOnNode.style.height = height + 'px';
        this.encircleLinePitchOnNodeLineChild.setAttribute('width',width);
        this.encircleLinePitchOnNodeLineChild.setAttribute('height',height);
        this.encircleLinePitchOnNodeLineChild.setAttribute('fill','none');
        this.encircleLinePitchOnNode.style.transform = 'translate(' + leftMargin + 'px,' + topMargin + 'px)';

        this.encircleLinePitchOnNodeChild.setAttribute('x', this.dragViewHeight);
        this.encircleLinePitchOnNodeChild.setAttribute('y', 0);
        // this.encircleLinePitchOnNodeChild.setAttribute('widht',data.width);
        // this.encircleLinePitchOnNodeChild.setAttribute('height',data.height);
        //不知道什么原因foreignObject以前是用setAttribute定义宽高的，现在是用style定义宽高。
        let borderWidth = data.width + this.space * 2
        let borderHeight =  data.height + this.space * 2
        this.encircleLinePitchOnNodeChild.style.width = borderWidth + 'px';
        this.encircleLinePitchOnNodeChild.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChild.setAttribute('width' , borderWidth);
        this.encircleLinePitchOnNodeChild.setAttribute('height', borderHeight);
        this.encircleLinePitchOnNodeChildBorder.style.width = borderWidth  + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.border = '1px solid ' + this.boxBorderColor;
        this.encircleLinePitchOnNodeChildBorder.style.borderRadius = '5px';
        // this.encircleLinePitchOnNodeChildBorder.style.backgroundColor = 'red';

        let triangleHeight = this.dragViewHeight * 2 /3;  //按钮三角形高度
        let buttonRectHeight = this.dragViewHeight - triangleHeight; //按钮矩形高度
        let buttonRectWidth = height * 0.6; //高度
        let buttonHeightSpace = height * 0.2; //距离上面
        let lineWidth = 1;
        let lineHeight = 20;
        if (lineHeight > buttonRectWidth/2) {       //如果綫條寬度超過一半
            lineHeight = buttonRectWidth/2;
        }

        let wrappLineEditIconHeight = this.dragViewHeight * 4 /3;
        let wrappLineEditIconWidth = this.dragViewHeight * 4 /3 ;
        if(this.mindData.type == MindElementType.BAOWEI_VIEW && 
            this.mindData.lineContent != null && 
            (this.mindData.lineContent.textContent == null ||
            this.mindData.lineContent.textContent.text == "")) {
            this.wrappingPath.setAttribute('x', this.dragViewHeight);
            this.wrappingPath.setAttribute('y', -wrappLineEditIconHeight);
            
            this.wrappingPath.style.width = wrappLineEditIconWidth + 'px';
            this.wrappingPath.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPath.setAttribute('width' , wrappLineEditIconWidth);
            this.wrappingPath.setAttribute('height', wrappLineEditIconHeight);
            this.wrappingPathDiv.style.width = wrappLineEditIconWidth  + 'px';
            this.wrappingPathDiv.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPathDiv.style.backgroundColor = this.boxBorderColor;
            this.wrappingPathDiv.style.textAlign = 'center';
            this.wrappingPathDiv.style.lineHeight = wrappLineEditIconHeight + 'px';
            if(this.boxBorderColor == 'rgb(253, 148, 130)'){
                this.wrappingPathDiv.style.color = '#ffffff';
            }else{
                this.wrappingPathDiv.style.color = '#fd9482';
            }
            this.wrappingPathDiv.style.cursor = 'pointer';
            this.wrappingPathDiv.innerHTML = '&#xe63c;';
            this.wrappingPathDiv.classList.add('wrapping-path-div');
        }

        let leftPath = new LinePath();
        leftPath.moveTo(0, height/2);
        leftPath.lineTo(triangleHeight, buttonHeightSpace);
        leftPath.lineTo(this.dragViewHeight, buttonHeightSpace);
        leftPath.lineTo(this.dragViewHeight, buttonHeightSpace + buttonRectWidth);
        leftPath.lineTo(triangleHeight,  buttonHeightSpace + buttonRectWidth);
        leftPath.lineTo(0, height/2);
        this.renderObj({dom: this.leftPath, d: leftPath, fill: this.boxBorderColor});

        let leftPath1 = new LinePath();
        leftPath1.moveTo(width, height/2);
        leftPath1.lineTo(width - triangleHeight ,  buttonHeightSpace + buttonRectWidth);
        leftPath1.lineTo(width - this.dragViewHeight, buttonHeightSpace + buttonRectWidth);
        leftPath1.lineTo(width - this.dragViewHeight, buttonHeightSpace);
        leftPath1.lineTo(width - triangleHeight, buttonHeightSpace);
        leftPath1.lineTo(width, height/2);
        this.renderObj({dom: this.rightPath, d: leftPath1, fill: this.boxBorderColor});

        let line1Path = new LinePath();
        line1Path.moveTo(triangleHeight, height/2 - lineHeight/2 );
        line1Path.lineTo(triangleHeight, height/2 + lineHeight/2);
        this.renderObj({dom: this.linePath1, d: line1Path, stroke: this.lineColor, strokeWidth: lineWidth});

        let line2Path = new LinePath();
        line2Path.moveTo(triangleHeight - lineWidth * 2, height/2 - lineHeight/2);
        line2Path.lineTo(triangleHeight - lineWidth * 2, height/2 + lineHeight/2);
        this.renderObj({dom: this.linePath2,d:line2Path,stroke: this.lineColor, strokeWidth:lineWidth});

        let line3Path = new LinePath();
        line3Path.moveTo(width - triangleHeight, height/2 - lineHeight/2);
        line3Path.lineTo(width - triangleHeight,  height/2 + lineHeight/2);
        this.renderObj({dom: this.linePath3, d: line3Path, stroke: this.lineColor, strokeWidth: lineWidth});

        let line4Path = new LinePath();
        line4Path.moveTo(width - triangleHeight + lineWidth * 2, height/2 - lineHeight/2);
        line4Path.lineTo(width - triangleHeight + lineWidth * 2,  height/2 + lineHeight/2);
        this.renderObj({dom: this.linePath4, d:line4Path, stroke: this.lineColor, strokeWidth: lineWidth});
    };

    showEncircleLinePitchOnNodeTopBottom(data) {  //上下拖动
        if (this.encircleLinePitchOnNode == null ||
            this.encircleLinePitchOnNode.style == null ||
            data == null) {
            return
        }
        
        this.nowBoxData = data;
        this.dragViewHeight = data.width > new UiUtil().dip2px(800) ? new UiUtil().dip2px(25) :
        data.width > new UiUtil().dip2px(500) ? new UiUtil().dip2px(20) : new UiUtil().dip2px(12);

        let width = data.width + this.space * 2;
        let height = data.height + this.space * 2 + this.dragViewHeight * 2;
        let leftMargin = data.x - this.space;
        let topMargin = data.y - this.space - this.dragViewHeight;

        this.encircleLinePitchOnNode.style.width = width + 'px';
        this.encircleLinePitchOnNode.style.height = height + 'px';
        this.encircleLinePitchOnNodeLineChild.setAttribute('width', width);
        this.encircleLinePitchOnNodeLineChild.setAttribute('height', height);
        this.encircleLinePitchOnNodeLineChild.setAttribute('fill', 'none');
        this.encircleLinePitchOnNode.style.transform = 'translate(' + leftMargin + 'px,' + topMargin + 'px)';

        this.encircleLinePitchOnNodeChild.setAttribute('x', 0);
        this.encircleLinePitchOnNodeChild.setAttribute('y', this.dragViewHeight);

        // this.encircleLinePitchOnNodeChild.setAttribute('widht',data.width);
        // this.encircleLinePitchOnNodeChild.setAttribute('height',data.height);
        //不知道什么原因foreignObject以前是用setAttribute定义宽高的，现在是用style定义宽高。
        let borderWidth = data.width + this.space * 2
        let borderHeight =  data.height + this.space * 2
        this.encircleLinePitchOnNodeChild.style.width = borderWidth + 'px';
        this.encircleLinePitchOnNodeChild.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChild.setAttribute('width' , borderWidth);
        this.encircleLinePitchOnNodeChild.setAttribute('height', borderHeight);
        this.encircleLinePitchOnNodeChildBorder.style.width = borderWidth  + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.border = '1px solid ' + this.boxBorderColor;
        this.encircleLinePitchOnNodeChildBorder.style.borderRadius = '5px';

        let triangleHeight = this.dragViewHeight * 2 /3;  //按钮三角形高度
        let buttonRectHeight = this.dragViewHeight - triangleHeight; //按钮矩形高度
        let buttonRectWidth = width * 0.6; //宽度
        let buttonWidthSpace = width * 0.2; //距离左边
        let lineWidth = 1;
        let lineHeight = 20;
        if (lineHeight > buttonRectWidth/2) {       //如果綫條寬度超過一半
            lineHeight = buttonRectWidth/2;
        }

        let wrappLineEditIconHeight = this.dragViewHeight * 4 /3;
        let wrappLineEditIconWidth = this.dragViewHeight * 4 /3 ;
        if(this.mindData.type == MindElementType.BAOWEI_VIEW && 
            this.mindData.lineContent != null &&
            (this.mindData.lineContent.textContent == null ||
                this.mindData.lineContent.textContent.text == "")){
            this.wrappingPath.setAttribute('x', 1);
            this.wrappingPath.setAttribute('y', -wrappLineEditIconHeight/4);
            
            this.wrappingPath.style.width = wrappLineEditIconWidth + 'px';
            this.wrappingPath.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPath.setAttribute('width' , wrappLineEditIconWidth);
            this.wrappingPath.setAttribute('height', wrappLineEditIconHeight);
            this.wrappingPathDiv.style.width = wrappLineEditIconWidth  + 'px';
            this.wrappingPathDiv.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPathDiv.style.backgroundColor = this.boxBorderColor;
            this.wrappingPathDiv.style.textAlign = 'center';
            this.wrappingPathDiv.style.lineHeight = wrappLineEditIconHeight + 'px';
            if(this.boxBorderColor == 'rgb(253, 148, 130)'){
                this.wrappingPathDiv.style.color = '#ffffff';
            }else{
                this.wrappingPathDiv.style.color = '#fd9482';
            }
            this.wrappingPathDiv.style.cursor = 'pointer';
            this.wrappingPathDiv.innerHTML = '&#xe63c;';
            this.wrappingPathDiv.classList.add('wrapping-path-div');
        }
        let leftPath = new LinePath();
        leftPath.moveTo(width/2,0);
        leftPath.lineTo(buttonWidthSpace + buttonRectWidth,triangleHeight);
        leftPath.lineTo(buttonWidthSpace + buttonRectWidth,this.dragViewHeight);
        leftPath.lineTo(buttonWidthSpace,this.dragViewHeight);
        leftPath.lineTo(buttonWidthSpace,triangleHeight);
        leftPath.lineTo(width/2,0);
        this.renderObj({dom: this.leftPath, d: leftPath, fill: this.boxBorderColor});

        let leftPath1 = new LinePath();
        leftPath1.moveTo(buttonWidthSpace , data.height + this.space * 2  + this.dragViewHeight);
        leftPath1.lineTo(buttonWidthSpace , data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight);
        leftPath1.lineTo(width/2,height);
        leftPath1.lineTo(buttonWidthSpace + buttonRectWidth , data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight);
        leftPath1.lineTo(buttonWidthSpace + buttonRectWidth , data.height + this.space * 2 + this.dragViewHeight);
        leftPath1.lineTo(buttonWidthSpace , data.height + this.space * 2  + this.dragViewHeight);
        this.renderObj({dom: this.rightPath, d: leftPath1,fill: this.boxBorderColor});
        
        let line1Path = new LinePath();
        line1Path.moveTo(width/2 - lineHeight/2, triangleHeight);
        line1Path.lineTo(width/2 + lineHeight/2, triangleHeight);
        this.renderObj({dom: this.linePath1, d: line1Path, stroke: this.lineColor, strokeWidth: lineWidth});

        let line2Path = new LinePath();
        line2Path.moveTo(width/2 - lineHeight/2, triangleHeight - lineWidth * 2);
        line2Path.lineTo(width/2 + lineHeight/2, triangleHeight - lineWidth * 2);
        this.renderObj({dom: this.linePath2,d:line2Path,stroke: this.lineColor, strokeWidth:lineWidth});


        let line3Path = new LinePath();
        line3Path.moveTo(width/2 - lineHeight/2, data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight);
        line3Path.lineTo(width/2 + lineHeight/2,  data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight);
        this.renderObj({dom: this.linePath3, d: line3Path, stroke: this.lineColor, strokeWidth: lineWidth});

        let line4Path = new LinePath();
        line4Path.moveTo(width/2 - lineHeight/2, data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight + lineWidth * 2);
        line4Path.lineTo(width/2 + lineHeight/2,  data.height + this.space * 2  + this.dragViewHeight + buttonRectHeight + lineWidth * 2);
        this.renderObj({dom: this.linePath4, d:line4Path, stroke: this.lineColor, strokeWidth: lineWidth});
    };

    showImageDarg(data,type) {   //并渲染图片框和图片按钮。

        this.nowBoxData = data;
        this.dragViewHeight = 0;

        let width = data.width + this.space * 2;
        let height = data.height + this.space * 2 + this.dragViewHeight * 2;
        let leftMargin = data.x - this.space + (this.isBorderType == 'image' ? this.mindThis.mindElementData.imageContent.x : 0) ;
        let topMargin = data.y - this.space - this.dragViewHeight + (this.isBorderType == 'image' ? this.mindThis.mindElementData.imageContent.y : 0);

        this.encircleLinePitchOnNode.style.width = Math.abs(width) + 'px';
        this.encircleLinePitchOnNode.style.height = Math.abs(height) + 'px';
        this.encircleLinePitchOnNodeLineChild.setAttribute('width', Math.abs(width));
        this.encircleLinePitchOnNodeLineChild.setAttribute('height', Math.abs(height));
        this.encircleLinePitchOnNodeLineChild.setAttribute('fill', 'none');
        this.encircleLinePitchOnNode.style.transform = 'translate(' + leftMargin + 'px,' + topMargin + 'px)';

        this.encircleLinePitchOnNodeChild.setAttribute('x', 0);
        this.encircleLinePitchOnNodeChild.setAttribute('y', this.dragViewHeight);
        // this.encircleLinePitchOnNodeChild.setAttribute('widht',data.width);
        // this.encircleLinePitchOnNodeChild.setAttribute('height',data.height);
        //不知道什么原因foreignObject以前是用setAttribute定义宽高的，现在是用style定义宽高。
        let borderWidth = data.width + this.space * 2
        let borderHeight =  data.height + this.space * 2
        this.encircleLinePitchOnNodeChild.style.width = borderWidth + 'px';
        this.encircleLinePitchOnNodeChild.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.width = borderWidth  + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.height = borderHeight + 'px';
        this.encircleLinePitchOnNodeChildBorder.style.borderRadius = '5px';

        this.setImageDragStyle(this.imgDragBottomRightButton, this.imgDragBottomRightButtonChild, this.imgDragBottomRightButtonData);
        this.setBottomRightPoint(this.imgDragBottomRightButton, this.imgDragBottomRightButtonData, borderWidth, borderHeight, this.imgDragBottomRightButtonChild);

        this.setImageDragStyle(this.imgDragBottomLeftButton, this.imgDragBottomLeftButtonChild, this.imgDragBottomLeftButtonData);
        this.setBottomLeftPoint(this.imgDragBottomLeftButton, this.imgDragBottomLeftButtonData, borderWidth, borderHeight, this.imgDragBottomLeftButtonChild);

        this.setImageDragStyle(this.imgDragTopLeftButton, this.imgDragTopLeftButtonChild, this.imgDragTopLeftButtonData);
        this.setTopLeftPoint(this.imgDragTopLeftButton, this.imgDragTopLeftButtonData, borderWidth, borderHeight, this.imgDragTopLeftButtonChild);

        this.setImageDragStyle(this.imgDragTopRightButton, this.imgDragTopRightButtonChild, this.imgDragTopRightButtonData);
        this.setTopRightPoint(this.imgDragTopRightButton, this.imgDragTopRightButtonData, borderWidth, borderHeight, this.imgDragTopRightButtonChild);
        
        if(this.mindData.type == MindElementType.BAOWEI_VIEW && 
            this.mindData.lineContent != null &&
            (this.mindData.lineContent.textContent == null ||
                this.mindData.lineContent.textContent.text == "") && this.isBorderType == "free") {
            this.dragViewHeight = data.width > new UiUtil().dip2px(800) ? new UiUtil().dip2px(25) :
                data.width > new UiUtil().dip2px(500) ? new UiUtil().dip2px(20) : new UiUtil().dip2px(12);

            let wrappLineEditIconHeight = this.dragViewHeight * 4 /3;
            let wrappLineEditIconWidth = this.dragViewHeight * 4 /3 ;

            this.wrappingPath.setAttribute('x', 15);
            this.wrappingPath.setAttribute('y', -wrappLineEditIconHeight);
            
            this.wrappingPath.style.width = wrappLineEditIconWidth + 'px';
            this.wrappingPath.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPath.setAttribute('width' , wrappLineEditIconWidth);
            this.wrappingPath.setAttribute('height', wrappLineEditIconHeight);
            this.wrappingPathDiv.style.width = wrappLineEditIconWidth  + 'px';
            this.wrappingPathDiv.style.height = wrappLineEditIconHeight + 'px';
            this.wrappingPathDiv.style.backgroundColor = this.boxBorderColor;
            this.wrappingPathDiv.style.textAlign = 'center';
            this.wrappingPathDiv.style.lineHeight = wrappLineEditIconHeight + 'px';
            if(this.boxBorderColor == 'rgb(253, 148, 130)'){
                this.wrappingPathDiv.style.color = '#ffffff';
            }else{
                this.wrappingPathDiv.style.color = '#fd9482';
            }
            this.wrappingPathDiv.style.cursor = 'pointer';
            this.wrappingPathDiv.innerHTML = '&#xe63c;';
            this.wrappingPathDiv.classList.add('wrapping-path-div');
            this.clickToEdit();
        }
        // this.setImageBorderBorder()
    }

    setImageDragStyle(dom ,childDom , data) {       //设置单个按钮样式
        childDom.style.width = data.width + 'px';
        childDom.style.height = data.height + 'px';
        childDom.style.userSelect = 'none';
       

        dom.style.width = data.width + 'px';
        dom.style.height = data.height + 'px';
        dom.style.borderRadius = '50%';
        dom.setAttribute('width',data.width);
        dom.setAttribute('height',data.height);
        this.setImageBorderBorder(childDom);
    }

    setBottomRightPoint(dom,  data, borderWidth, borderHeight, childDom) {    //计算右下角按钮位置
        dom.setAttribute('x',borderWidth - data.width/2);
        dom.setAttribute('y',borderHeight - data.height/2);
        childDom.style.cursor = 'nw-resize';
    }

    setBottomLeftPoint(dom,  data, borderWidth, borderHeight, childDom) {    //计算左下角按钮位置
        dom.setAttribute('x',- data.width/2);
        dom.setAttribute('y',borderHeight - data.height/2);
        childDom.style.cursor = 'ne-resize';
    }

    setTopLeftPoint(dom,  data, borderWidth, borderHeight, childDom) {    //计算左上角按钮位置
        dom.setAttribute('x', - data.width/2);
        dom.setAttribute('y', - data.height/2);
        childDom.style.cursor = 'nw-resize';
    }

    setTopRightPoint(dom,  data, borderWidth, borderHeight, childDom) {    //计算右上角按钮位置
        dom.setAttribute('x', borderWidth - data.width/2);
        dom.setAttribute('y', - data.height/2);
        childDom.style.cursor = 'ne-resize';
    }


    setImageBorderBorder(childDom) {  //设置按钮还有边框颜色
        this.encircleLinePitchOnNodeChildBorder.style.border = '1px solid ' + this.boxBorderColor;
        childDom.style.backgroundColor = Colors.getUiColor(EditMindmapVM.mindBGColor);
        childDom.style.border = '2px solid ' + this.boxBorderColor;
        childDom.style.borderRadius = '50%'
        childDom.style.color = this.lineColor;
    }

    setLinePathStyle() {
        this.linePath1.style.cursor = 'pointer';
        this.linePath2.style.cursor = 'pointer';
        this.linePath3.style.cursor = 'pointer';
        this.linePath4.style.cursor = 'pointer';
    }

    checkAllColor(newColor) {
        // this.mindThis.setImageSize(data.width, data.height);
        let isLightColor = Colors.isLightColor(newColor);
        let color = "";
        let fontColor = "";
        if (isLightColor) {
            fontColor = "#fff";
            this.boxBorderColor = 'rgb(253, 148, 130)';
            this.lineColor = '#fff'
        } else {
            fontColor = "#666";
            this.boxBorderColor = "#fff";
            this.lineColor = '#666'
        }
        if (this.isBorderType == 'image' || this.isBorderType == 'free') {
            this.setImageBorderBorder(this.imgDragBottomRightButtonChild);
        } else {
            if (this.leftPath != null) {
                this.encircleLinePitchOnNodeChildBorder.style.border = '1px solid ' + this.boxBorderColor;
                this.leftPath.setAttribute("fill",this.boxBorderColor);
                this.rightPath.setAttribute("fill", this.boxBorderColor);
                this.linePath1.setAttribute("stroke", this.lineColor);
                this.linePath2.setAttribute("stroke", this.lineColor);
                this.linePath3.setAttribute("stroke", this.lineColor);
                this.linePath4.setAttribute("stroke", this.lineColor);
            }
        }
    };

    saveData() {
        this.boxData = this.nowBoxData 
    }

    getNowData() {
        return this.nowBoxData 
    }

    leftButtonDrage(val) {
        // let flag = false;   //用于控制鼠标拖动
        this.leftPath.addEventListener('mousedown',(ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        })
    }

    rightButtonDrage(val) {
        this.rightPath.addEventListener('mousedown',(ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        })
    }

    imgDragBottomRightButtonDrage() {
        this.imgDragBottomRightButton.addEventListener('mousedown',(ev)=>{
            if (this.isBorderType == 'image') {
                this.DragImageFlag = true;
            } else {
                this.DragFlag = true; 
            }
            this.buttonType = "right-buttom"
        })
    }

    imgDragBottomLeftButtonDrage() {
        this.imgDragBottomLeftButton.addEventListener('mousedown',(ev)=>{
            if (this.isBorderType == 'image') {
                this.DragImageFlag = true;
            } else {
                this.DragFlag = true; 
            }
            this.buttonType = "left-buttom"
        })
    }

    imgDragTopLeftButtonDrage() {
        this.imgDragTopLeftButton.addEventListener('mousedown',(ev)=>{
            if (this.isBorderType == 'image') {
                this.DragImageFlag = true;
            } else {
                this.DragFlag = true; 
            }
            this.buttonType = "left-top"
        })
    }
    imgDragTopRightButtonDrage() {
        this.imgDragTopRightButton.addEventListener('mousedown',(ev)=>{
            if (this.isBorderType == 'image') {
                this.DragImageFlag = true;
            } else {
                this.DragFlag = true; 
            }
            this.buttonType = "right-top"
        })
    }

    pathDrage(val) {
        // let flag = false;   //用于控制鼠标拖动
        this.linePath1.onmousedown = (ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        }
        this.linePath2.onmousedown = (ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        }
    }

    pathDrage2(val) {
        this.linePath3.onmousedown = (ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        }
        this.linePath4.onmousedown = (ev)=>{
            this.DragFlag = true;
            this.dragBoader = val;
        }
    }

    // topButtonDrage() {
    //     // let flag = false;   //用于控制鼠标拖动
    //     this.topPath.addEventListener('mousedown',(ev)=>{
    //         this.DragFlag = true;
    //         this.dragBoader = 'top';
    //     })
    // }

    // bottomButtonDrage() {
    //     this.bottomPath.addEventListener('mousedown',(ev)=>{
    //         this.DragFlag = true;
    //         this.dragBoader = 'bottom';
    //     })
    // }

    computedNowPoint(dargeEvent, startX, startY) {

        if (this.isBorderType == 'image' || this.isBorderType == 'free') {
            this.imgBorder(dargeEvent, startX, startY)
        } else {
            this.encircleBorder(dargeEvent, startX, startY);
        }
    }

    imgBorder(dargeEvent, startX, startY) {     //用于计算新的框的大小和位置。
        let moveX = (dargeEvent.pageX - startX) * (1 / this.scrollView.scale);
        let moveY = (dargeEvent.pageY - startY) * (1 / this.scrollView.scale);
        let obj = {};
        obj.x = this.boxData.x;
        obj.width = this.boxData.width ;
        obj.y = this.boxData.y;
        obj.height = this.boxData.height;

        //计算宽高
        if (this.buttonType == "left-buttom") {
            obj.width = this.boxData.width - moveX;
            obj.height = this.boxData.height + moveY;
        } else if (this.buttonType == "left-top") {
            obj.width = this.boxData.width - moveX;
            obj.height = this.boxData.height - moveY;
        } else if (this.buttonType == "right-buttom") {
            obj.width = this.boxData.width + moveX;
            obj.height = this.boxData.height + moveY;
        } else if (this.buttonType  == "right-top") {
            obj.width = this.boxData.width + moveX;
            obj.height = this.boxData.height - moveY;
        }


        let maxNumber = Math.max(obj.width, obj.height);
        let proportion = 1;
        if (this.isBorderType == 'image') {
            proportion = this.mindThis.mindElementData.imageContent.width / this.mindThis.mindElementData.imageContent.height
            if (maxNumber == obj.width) {
                obj.height = obj.width / proportion;
            } else if (maxNumber == obj.height) {
                obj.width = obj.height * proportion;
            }
        }
        

        //计算x 与y,由于宽高要按照比例计算，不是按照鼠标移动的距离来计算，如果x、y坐标按照鼠标的拖动距离来算，会有误差，所以用之前当前宽高与之前宽高相减来重新设置x与y。
        if (this.buttonType == "left-buttom") {
            obj.x = this.boxData.x + (this.boxData.width - obj.width);
            obj.y = this.boxData.y;
            //如果x轴坐标拖到了最右边
            if (obj.x > this.boxData.x + this.boxData.width) {
                obj.x = this.boxData.x + this.boxData.width
            }
        } else if (this.buttonType == "left-top") {
            obj.x = this.boxData.x + (this.boxData.width - obj.width);
            obj.y = this.boxData.y + (this.boxData.height - obj.height);
            //如果x轴坐标拖到了最右边
            if (obj.x > this.boxData.x + this.boxData.width) {
                obj.x = this.boxData.x + this.boxData.width
            }
            if (obj.y > this.boxData.y + this.boxData.height) {
                obj.y = this.boxData.y + this.boxData.height
            }
        } else if (this.buttonType == "right-buttom") {

        } else if (this.buttonType  == "right-top") {
            obj.x = this.boxData.x ;
            obj.y = this.boxData.y + (this.boxData.height - obj.height);
            if (obj.y > this.boxData.y + this.boxData.height) {
                obj.y = this.boxData.y + this.boxData.height
            }
        }

        if (this.isBorderType == 'image') {
            if(obj.width < 5) {
                obj.width = 5;
                obj.height = obj.width / proportion;
            }
    
            if(obj.height < 5) {
                obj.height = 5;
                obj.width = obj.height * proportion;
            }
        }        
        this.showImageDarg(obj);
    }

    encircleBorder(dargeEvent, startX, startY) {
        let moveX = (dargeEvent.pageX - startX) * (1 / this.scrollView.scale);
        let moveY = (dargeEvent.pageY - startY) * (1 / this.scrollView.scale);
        let obj = {};
        obj.x = this.boxData.x;
        obj.width = this.boxData.width ;
        obj.y = this.boxData.y;
        obj.height = this.boxData.height;
        if (this.dragBoader == 'left') {
            obj.x = moveX + this.boxData.x;
            obj.width = this.boxData.width - moveX;
            if(obj.x > this.boxData.x + this.boxData.width) {   //不能拖过右边
                obj.x = this.boxData.x + this.boxData.width
            }
        } else if (this.dragBoader == 'right') {
            obj.x = this.boxData.x;
            obj.width = this.boxData.width + moveX;
            if(obj.x < this.boxData.x) {   //不能拖过左边
                obj.x = this.boxData.x
            }
        } else if (this.dragBoader == 'top') {
            obj.y = moveY + this.boxData.y;
            obj.height = this.boxData.height - moveY;
            if (obj.y > this.boxData.y + this.boxData.height) {
                obj.y = this.boxData.y + this.boxData.height
            }
        } else if (this.dragBoader == 'bottom') {
            obj.y = this.boxData.y;
            obj.height = this.boxData.height + moveY;
            if (obj.y < this.boxData.y) {
                obj.y = this.boxData.y
            }
        }

        if(obj.width < 0) {
            obj.width = 0;
        }

        if(obj.height < 0) {
            obj.height = 0;
        }
        
        if (this.dragBoader == 'left' || this.dragBoader == 'right') {
            this.showEncircleLinePitchOnNodeLeftRight(obj);
        } else if (this.dragBoader == 'top' || this.dragBoader == 'bottom') {
            this.showEncircleLinePitchOnNodeTopBottom(obj);
        }
    }


    renderObj(obj = {dom,d,stroke,strokeWidth}) {  //设置path
        if (obj.dom == null) {
            return;
        }
        obj.dom.style.display = ''
        if(obj.d != undefined) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != undefined) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != undefined) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != undefined) {
            obj.dom.setAttribute("fill", obj.fill)
        }
        // this.AssistNodeLineChild.setAttribute('stroke-width','2');
    }


    hidden() {
        if (this.encircleLinePitchOnNode == null || this.encircleLinePitchOnNode.parentNode == null) {
            return
        }
        this.encircleLinePitchOnNode.parentNode.removeChild(this.encircleLinePitchOnNode);  //之所以要remove是因为要一直保持节点不被其它节点遮挡。
        this.encircleLinePitchOnNode = null
    }
    
    isShow() {
        return this.encircleLinePitchOnNode != null && this.encircleLinePitchOnNode.parentNode != null;
    }

    isDragFlag() {
        return this.DragImageFlag;
    }

    clickToEdit(){
        if(this.mindData.type == MindElementType.BAOWEI_VIEW){
            // let _this = this
            this.wrappingPath.addEventListener('mousedown',(e)=>{
                window.event ? window.event.cancelBubble = true : e.stopPropagation();
                let obj = {type:'encircle',name:this.scrollView.delegate.strings.Global_Edit,clickIcon:"clickIcon"}
                this.scrollView.delegate.checkMenuLineElement(obj);
            });
        }
    }
}

export default resetMindView