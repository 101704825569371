var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content-box"},[_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"lright-cont"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmitSignIn}},[_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'newPassword',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.getString(_vm.strings.Input_Please_Enter_A_New_Password),
                        },
                        {
                          validator: _vm.validateToNextPassword,
                        } ],
                    } ]),expression:"[\n                    'newPassword',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: getString(strings.Input_Please_Enter_A_New_Password),\n                        },\n                        {\n                          validator: validateToNextPassword,\n                        },\n                      ],\n                    },\n                  ]"}],ref:"newPassword",attrs:{"allow-clear":"","oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.Input_Please_Enter_The_Password),"type":"password"},on:{"blur":_vm.newPasswordBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'confirm',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.getString(_vm.strings.Input_Confirm_The_New_Password),
                        },
                        {
                          validator: _vm.compareToFirstPassword,
                        } ],
                    } ]),expression:"[\n                    'confirm',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: getString(strings.Input_Confirm_The_New_Password),\n                        },\n                        {\n                          validator: compareToFirstPassword,\n                        },\n                      ],\n                    },\n                  ]"}],ref:"confirm",attrs:{"allow-clear":"","oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.User_Lolgin_Confirm_Password),"type":"password"},on:{"blur":_vm.confirmBlurForValidate}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"Login-Button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mind_Group_Accomplish))+" ")])],1),_c('div',{staticClass:"lc-zi"},[_c('a',{staticClass:"sign-a",attrs:{"href":"javascript:;"},on:{"click":_vm.skip}},[_vm._v(" 跳过 ")])])],1)],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }