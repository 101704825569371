<template>
  <main class="com-menu-share-links">
    <div class="com-lf-menu-content">
      <div class="com-lf-right-hide" @click="hideModels">
        <a-icon type="close" :style="{ fontSize: '20px' }" />
      </div>
      <div
        class="com-lf-left-hide"
        v-if="activity != '' && activity != null"
        @click="activtiyToMind"
      >
        <span>{{ this.activity[0].title }}</span>
      </div>
      <!-- 分享链接 -->
      <div class="com-mind-title">
        <div class="com-mind-text">
          <span>{{ getString(strings.Mind_Edit_Left_Menu_Share_Links) }}</span>
        </div>
      </div>
      <!-- 分享开关 -->
      <div class="com-mind-share">
        <div class="com-share-open open-click">
          <div class="com-share-open-title">
            <span>{{
              getString(strings.Mind_Edit_Left_Menu_Share_Switch)
            }}</span>
          </div>
          <div class="com-share-open-click">
            <a-switch
              v-model="linkShareSwitch"
              size="small"
              @change="onChangeShareOpen"
            />
          </div>
        </div>

        <!-- 复制链接 -->
        <div class="com-share-open copy-link">
          <div class="com-share-copy-link">
            <a-form
              :form="form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-item>
                <input
                  type="text"
                  :readonly="linkUrl"
                  v-model="linkUrl"
                  ref="copyLink"
                />
              </a-form-item>
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  :disabled="clickCopyLink"
                  @click="copyLinkSubmit"
                >
                  {{ getString(strings.Mind_Edit_Left_Menu_Copy_Link) }}
                </a-button>
              </a-form-item>
            </a-form>
            <a-button
              class="com-share-regenerate-button"
              ghost
              :disabled="clickRegenerate"
              @click="regenerateLink"
            >
              <span>
                {{ getString(strings.Mind_Edit_Left_Menu_Regenerate) }}
              </span>
            </a-button>
          </div>
        </div>

        <!-- <div class="com-share-open regenerate-link">
          <div class="com-share-open-title">
            <span>{{getString(strings.Mind_Edit_Left_Menu_Follow_The_Link)}}</span>
            <a-button ghost :disabled="clickRegenerate" @click="regenerateLink"
              ><span class="com-share-regenerate-link">{{getString(strings.Mind_Edit_Left_Menu_Regenerate)}}</span>
            </a-button>
          </div>
        </div> -->
        <!-- 链接密码 -->
        <div class="com-share-open">
          <div class="com-share-open-title">
            <span>{{
              getString(strings.Mind_Edit_Left_Menu_Link_Password)
            }}</span>
            <span class="com-share-regenerate-link com-share-link-pwd">
              <a-input
                :disabled="openLinkPwd"
                :maxLength="4"
                v-model="linkPassworld"
              />
              <!-- <input type="text" :readonly="linkUrl" v-model="linkUrl"> -->
            </span>
          </div>
          <div class="com-share-open-click">
            <a-switch
              :disabled="!linkShareSwitch"
              v-model="linkPwdSwitch"
              size="small"
              @change="onChangeLinkPwd"
            />
          </div>
        </div>
        <!-- 允许保存 -->
        <div class="com-share-open allow-save">
          <div class="com-share-open-title">
            <span>{{ getString(strings.Mind_Edit_Left_Menu_Allow_Save) }}</span>
          </div>
          <div class="com-share-open-click">
            <a-switch
              :disabled="!linkShareSwitch"
              v-model="linkSaveSwitch"
              size="small"
              @change="onChangeShareSave"
            />
          </div>
        </div>
        <!--附件开关 -->
        <div class="com-share-open allow-save">
          <div class="com-share-open-title">
            <span>是否允许查看者查看附件</span>
          </div>
          <div class="com-share-open-click">
            <a-switch
              :disabled="!linkShareSwitch"
              v-model="enclosureSwitch"
              size="small"
              @change="onChangeLookEnclosure"
            />
          </div>
        </div>
        <!--查看关联导图开关 -->
        <div class="com-share-open allow-save">
          <div class="com-share-open-title">
            <span>查看关联导图层级(0表示不允许往下查看)</span>
          </div>
          <div class="com-share-open-click">
            <a-input-number
              type="text"
              v-model="associatedMapSwitch"
              :min="0"
              :max="100000"
              @change="postMindmapModifyShare('setLinkContent')"
            />
          </div>
        </div>
        <!-- 最多可查看次数 -->
        <div class="com-share-open look-count">
          <div class="com-share-open-title">
            <span>{{
              getString(strings.Mind_Edit_Left_Menu_Maximum_Number_Of_Views)
            }}</span>
          </div>
          <div class="com-share-open-click">
            <a-radio-group
              name="radioGroup"
              v-model="linkCountValue"
              :defualt-value="0"
              :disabled="lookShareCount"
            >
              <a-radio :value="0" @click="getClickLookCount(0)">
                {{ getString(strings.Mind_Edit_Left_Menu_Infinite) }}
              </a-radio>
              <a-radio :value="1" @click="getClickLookCount(1)">
                {{ getString(strings.Mind_Edit_Left_Menu_Limit) }}
              </a-radio>
            </a-radio-group>
            <span class="com-share-regenerate-link com-share-look-count">
              <a-input-number
                type="text"
                :disabled="linkLookCount"
                v-model="linkLookCountNumber"
                :min="1"
                :max="100000"
                @change="postMindmapModifyShare('setLinkContent')"
              />
            </span>
            <span>{{ getString(strings.Mind_Edit_Left_Menu_Second) }}</span>
          </div>
        </div>
        <!-- 有效期 -->
        <div class="com-share-open link-time">
          <div class="com-share-open-title">
            <span>{{
              getString(strings.Mind_Edit_Left_Menu_Link_Validity)
            }}</span>
            <label>
              <a-select
                v-model="linkTime"
                :disabled="clickLinkTimeLimit"
                style="width: 120px; margin-left: 10px"
                @change="linkTimeHandleChange"
              >
                <a-select-option
                  v-for="item of linkTimeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </label>
          </div>
          <!-- <div class="com-share-open-click">
            <a-select v-model="linkTime" style="width: 102px" @change="linkTimeHandleChange">
              <a-select-option v-for="item of linkTimeList" :key="item.value" :value="item.value">
                {{item.label}}
              </a-select-option>
            </a-select>
            <a-radio-group
              name="radioGroup"
              v-model="linkTime"
              :defualt-value="1"
              :disabled="clickLinkTimeLimit"
            >
              <a-radio :value="1"> {{getString(strings.Mind_Edit_Left_Menu_1_Day)}} </a-radio>
              <a-radio :value="3"> {{getString(strings.Mind_Edit_Left_Menu_3_Days)}} </a-radio>
              <a-radio :value="7"> {{getString(strings.Mind_Edit_Left_Menu_7_Days)}} </a-radio>
              <a-radio :value="0"> {{getString(strings.Mind_Edit_Left_Menu_Permanent)}} </a-radio>
            </a-radio-group>
            
          </div> -->
        </div>
        <!-- 分享二维码 -->
        <section v-if="linkShareSwitch" class="com-mind-sharing-code">
          <div class="com-mind-qr">
            <vue-qr
              :logoSrc="logoSrc"
              :logoScale="0.25"
              :text="url"
              :size="135"
            ></vue-qr>
          </div>
          <div class="com-mind-code-title">
            <span>{{ getString(strings.Scan_The_Code_To_Get_The_file) }}</span>
          </div>
        </section>
        <!-- 复制内容&链接 -->
        <div class="com-share-open copy-content-link">
          <div class="com-share-open-title">
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="copyContentAndLink"
                @click="getContentAndLink"
              >
                {{
                  getString(strings.Mind_Edit_Left_Menu_Copy_Content_And_Link)
                }}
              </a-button>
            </a-form-item>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import vueQr from "vue-qr";
import { mapMutations, mapState } from "vuex";
import HttpServerConfig from "../../../common/HttpServerConfig";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import {
  postMindmapShare,
  postMindmapModifyShare,
  postMindmapReshare,
} from "../../../common/netWork/mind_map_api";
import randomNumber from "../../../utils/randomNumber";
import EditMindmapVMs from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
export default {
  data() {
    return {
      strings,
      logoSrc: require("../../../assets/img/code/logo.png"),
      url: "",
      form: this.$form.createForm(this),
      linkUrl: "",
      linkCountValue: 0,
      linkPassworld: null, //new randomNumber().randomStr()
      linkLookCountNumber: 0,
      temLinkLookCountNumber: 1, //临时查看次数
      //状态
      clickCopyLink: false,
      clickRegenerate: false,
      openLinkPwd: true,
      linkPwdSwitch: false,
      lookShareCount: false,
      linkLookCount: true,
      clickLinkTimeLimit: false,
      copyContentAndLink: false,
      linkShareSwitch: true,
      activity: "",
      linkSaveSwitch: false,
      enclosureSwitch: false,
      associatedMapSwitch: 0,
      linkTime: 0,
      linkTimeList: [
        {
          label: getString(strings.Mind_Edit_Left_Menu_1_Day),
          value: 1,
        },
        {
          label: getString(strings.Mind_Edit_Left_Menu_3_Days),
          value: 3,
        },
        {
          label: getString(strings.Mind_Edit_Left_Menu_7_Days),
          value: 7,
        },
        {
          label: getString(strings.Mind_Edit_Left_Menu_30_Days),
          value: 30,
        },
        {
          label: getString(strings.Mind_Edit_Left_Menu_Permanent),
          value: 0,
        },
      ],
      isSafari: false,
    };
  },
  components: {
    vueQr,
  },
  created() {
    this.postMindmapShare();
    let userData = localStorage.getItem("meMessage");
    if (userData == null || userData == "") {
      return;
    }
  },
  computed: {
    ...mapState({
      mindMapId: (state) => state.hideModel.mindMapId,
    }),
  },
  mounted() {
    this.isSafari = this.$tools.isMacSafari();
  },
  methods: {
    ...mapMutations(["hideModelLink"]),
    getString(i) {
      return getString(i);
    },
    hideModels() {
      // this.postMindmapModifyShare("setLinkContent");
      this.hideModelLink(false);
    },
    shareOpen() {},
    //点击查看
    getClickLookCount(val) {
      this.linkCountValue = val;
      if (val == 0) {
        (this.linkLookCount = true),
          ((this.linkLookCountNumber = 0),
          this.postMindmapModifyShare("setLinkContent"));
      } else {
        (this.linkLookCount = false),
          (this.linkLookCountNumber = this.temLinkLookCountNumber);
        this.postMindmapModifyShare("setLinkContent");
      }
      // console.log(this.linkLookCountNumber);
    },
    //查看次数
    getLinkLookCount(lookCount) {
      if (lookCount == 0) {
        this.linkCountValue = 0;
        this.linkLookCountNumber = this.temLinkLookCountNumber;
        this.linkLookCount = true;
      } else {
        this.linkCountValue = 1;
        this.temLinkLookCountNumber = lookCount;
        if (this.linkShareSwitch) {
          this.linkLookCount = false;
        }
      }
    },
    //导图链接
    postMindmapShare() {
      postMindmapShare(
        { mindMapId: this.mindMapId },
        (res) => {
          this.url = res.link;
          this.linkUrl = res.link;
          this.linkShareSwitch = res.enabled;
          this.linkPassworld = res.password;
          let passwordEnabled = res.passwordEnabled;
          if (passwordEnabled) {
            //是否需要链接密码
            this.linkPwdSwitch = passwordEnabled;
            if (this.linkShareSwitch) {
              this.openLinkPwd = !passwordEnabled;
            }
          }
          this.linkLookCountNumber = res.viewLimit;
          this.linkTime = res.expireDays;
          this.activity = res.activities;
          this.linkSaveSwitch = res.canBeSaved;
          this.enclosureSwitch = res.viewAttachment;
          this.associatedMapSwitch = res.viewConnectMap;
          this.getLinkLookCount(this.linkLookCountNumber);
          if (!this.linkShareSwitch) {
            this.onChangeShareOpen(false);
          }
        },
        (error) => {
          this.$message.error(getString(strings.Message_Tips_Login_Success));
        }
      );
    },
    //重新生成
    regenerateLink() {
      const that = this;
      this.$confirm({
        title: getString(strings.Mind_Edit_Left_Menu_Regenerate),
        content: getString(strings.Mind_Edit_Left_Menu_Regenerate_Tips),
        zIndex: 9999,
        okText: getString(strings.Global_Ok),
        cancelText: getString(strings.Global_Cancel),
        onOk() {
          let getPostMindmapReshare = postMindmapReshare(
            { mindMapId: that.mindMapId },
            (res) => {
              that.url = res.link;
              that.linkUrl = res.link;
              that.linkShareSwitch = res.enabled;
              that.linkPassworld = res.password;
              let passwordEnabled = res.passwordEnabled;
              if (passwordEnabled) {
                //是否需要链接密码
                that.linkPwdSwitch = passwordEnabled;
                if (that.linkShareSwitch) {
                  that.openLinkPwd = !passwordEnabled;
                }
              }
              that.linkLookCountNumber = res.viewLimit;
              that.linkTime = res.expireDays;
              that.getLinkLookCount(that.linkLookCountNumber);
            }
          );
          return new Promise((resolve, reject) => {
            setTimeout(
              Math.random() > 0.5 ? resolve(getPostMindmapReshare) : reject,
              1000
            );
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    },
    //分享开关
    onChangeShareOpen(checked) {
      if (checked) {
        (this.clickCopyLink = false),
          (this.clickRegenerate = false),
          // (this.linkLookCount = false),
          // (this.linkPwdSwitch = false),
          (this.lookShareCount = false),
          (this.clickLinkTimeLimit = false),
          (this.copyContentAndLink = false);
        this.postMindmapModifyShare("setLinkContent");
      } else {
        (this.clickCopyLink = true),
          (this.clickRegenerate = true),
          (this.openLinkPwd = true),
          (this.linkLookCount = true),
          // (this.linkPwdSwitch = false),
          (this.lookShareCount = true),
          (this.clickLinkTimeLimit = true),
          (this.copyContentAndLink = true);
        this.postMindmapModifyShare("setLinkContent");
      }
    },
    //是否允许保存开关
    onChangeShareSave(checked) {
      this.postMindmapModifyShare("setLinkContent");
    },
    //是否允许查看附件开关
    onChangeLookEnclosure(checked) {
      this.postMindmapModifyShare("setLinkContent");
      // console.log('是否允许查看附件开关');
    },
    //是否允许查看关联导图开关
    onChangeAssociatedMap(checked) {
      // this.postMindmapModifyShare("setLinkContent");
      // console.log('是否允许查看关联导图开关');
    },
    //密码
    onChangeLinkPwd(checked) {
      checked ? (this.openLinkPwd = false) : (this.openLinkPwd = true);
      this.postMindmapModifyShare("setLinkContent");
    },
    //复制链接
    copyLinkSubmit() {
      this.postMindmapModifyShare(this.linkUrl);
    },
    //修改分享
    postMindmapModifyShare(values) {
      const mindMapId = this.mindMapId;
      const enabled = this.linkShareSwitch;
      const viewLimit =
        this.linkCountValue == 0 ? 0 : parseInt(this.linkLookCountNumber);
      const passwordEnabled = this.linkPwdSwitch;
      const expireDays = this.linkTime;
      const password = this.linkPassworld;
      const canBeSaved = this.linkSaveSwitch;
      const viewAttachment = this.enclosureSwitch;
      const viewConnectMap = this.associatedMapSwitch;
      postMindmapModifyShare(
        {
          mindMapId,
          enabled,
          canBeSaved,
          viewLimit,
          expireDays,
          password,
          passwordEnabled,
          viewAttachment,
          viewConnectMap,
        },
        (res) => {
          this.url = res.link;
          this.linkUrl = res.link;
          this.linkShareSwitch = res.enabled;
          this.linkPassworld = res.password;
          let passwordEnabled = res.passwordEnabled;
          if (passwordEnabled) {
            //是否需要链接密码
            this.linkPwdSwitch = passwordEnabled;
            if (this.linkShareSwitch) {
              this.openLinkPwd = !passwordEnabled;
            }
          }
          this.linkLookCountNumber = res.viewLimit;
          this.linkTime = res.expireDays;
          this.linkSaveSwitch = res.canBeSaved;
          this.enclosureSwitch = res.viewAttachment;
          this.associatedMapSwitch = res.viewConnectMap;
          this.getLinkLookCount(this.linkLookCountNumber);
          if (values == "setLinkContent") {
            return;
          }
          /*var input = document.createElement("textarea"); // 直接构建input
          input.value = values; // 设置内容 需要复制的内容
          document.body.appendChild(input); // 添加临时实例
          input.select(); // 选择实例内容
          document.execCommand("Copy"); // 执行复制
          document.body.removeChild(input); // 删除临时实例*/

          const copyText = async () => {
            try {
              await navigator.clipboard.writeText(values);
              this.$message.success(
                getString(strings.Message_Tips_Copy_Success)
              );
            } catch (err) {
              this.$copyText(values).then(
                (res) => {
                  this.$message.success(
                    getString(strings.Message_Tips_Copy_Success)
                  );
                },
                (err) => {
                  this.$message.error("Failed to copy ", err);
                }
              );
            }
          };
          copyText();
        }
      );
    },
    //复制内容&链接
    getContentAndLink() {
      const titel =
        EditMindmapVMs.getTitle() +
        "\n" +
        EditMindmapVMs.getSubtitle() +
        "\n# " +
        this.linkUrl +
        "\n" +
        (this.openLinkPwd
          ? ""
          : this.getString(this.strings.Mind_Edit_Left_Menu_Link_Password) +
            this.linkPassworld);
      this.postMindmapModifyShare(titel);
    },
    //活动跳转到导图
    activtiyToMind() {
      this.postMindmapModifyShare("setLinkContent");
      this.hideModelLink(false);
      let newWindow = window.open();
      newWindow.location = this.activity[0].link;
    },
    linkTimeHandleChange(value) {
      // console.log(value);
    },
  },
  watch: {
    linkTime(newLinkTime, olLinkTime) {
      this.postMindmapModifyShare("setLinkContent");
    },
  },
};
</script>

<style lang="less" scoped>
.ant-modal {
  z-index: 9999;
}
.com-menu-share-links {
  width: 568px;
  // min-height: 622px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1002;
  transform: translate(-50%, -50%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background-color: #ffffff;
  .com-lf-menu-content {
    margin: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    .com-lf-right-hide {
      position: absolute;
      right: 0px;
      top: 10px;
      cursor: pointer;
      transition: 0.3s;
    }
    .com-lf-right-hide:hover {
      transition: 0.3s;
      color: #fd492b;
      transform: rotate(180deg);
    }
    .com-lf-left-hide {
      position: absolute;
      left: 0px;
      top: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 26px;
      span {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #e5654e;
        opacity: 1;
        margin-left: 10px;
      }
    }
    .com-mind-title {
      margin-top: 10px;

      .com-mind-text {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        font-family: PingFang SC;
      }
    }
    .com-mind-sharing-code {
      .com-mind-qr {
        width: 135px;
        height: 135px;
        margin: 0 auto;
      }
      .com-mind-code-title {
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        color: #666666;
        font-weight: 500;
        text-align: center;
      }
    }
    .com-mind-share {
      width: 100%;
      .com-share-open {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .com-share-open-title {
          span {
            width: 56px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-family: PingFang SC;
            color: #666666;
            font-weight: 500;
          }
          .com-share-regenerate-link {
            color: #fd492b;
            margin-left: 10px;
            cursor: pointer;
          }
          .com-share-link-pwd {
            input {
              width: 124px;
              height: 32px;
            }
          }
        }
        .com-share-open-click {
          line-height: 30px;
          .com-share-look-count {
            margin-right: 10px;
            input {
              width: 90px;
              height: 32px;
            }
          }
        }
        .com-share-copy-link {
          position: relative;
          .ant-form {
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-row {
              margin-bottom: 20px;
            }
            input {
              width: 326px;
              height: 32px;
              padding: 0 10px;
              border-radius: 4px;
              border: 1px solid #dddddd;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
            }
            input:focus {
              outline: 0px;
            }

            button {
              width: 90px;
              height: 32px;
              border-radius: 4px;
              margin-left: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .com-share-regenerate-button {
            height: 20px;
            line-height: 20px;
            color: #fd492b;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            position: absolute;
            top: 10px;
            right: 120px;
            // cursor: pointer;
            outline: none;
            border: 0;
          }
        }
      }
      .copy-title {
        margin-top: 30px;
      }
      .copy-link {
        margin-top: 20px;
      }
      .regenerate-link {
        .ant-btn-background-ghost {
          border: none;
        }
      }
      .open-click {
        margin-top: 20px;
      }
      .allow-save {
        margin-top: 20px;
      }
      .look-count {
        margin-top: 20px;
      }
      .link-time {
        margin-top: 20px;
      }
      .copy-content-link {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .ant-row {
          margin-bottom: 0;
        }
        button {
          width: 436px;
          height: 36px;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>