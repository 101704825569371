// package mind.yushu.com.mindmap.main.edit.tools;

import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";

// import android.content.Context;
// import android.util.Size;
// import android.view.Gravity;
// import android.view.View;
// import android.widget.FrameLayout;
// import android.widget.TextView;

// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.utils.UiUtil;



/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindNodeUpView {
    constructor(fatherDom,size,delegate) {
        this.lineHeight = new UiUtil().dip2px(2);
        this.lineView;
        this.line;
        this.size = size;
        this.fatherDom = fatherDom;
        this.delegate = delegate;
        this.mousdownFunction;
        this.selectId;
        this.isMoveDom = false;
        this.timeInterval;
        this.initView();
    }

    // public MindNodeUpView(Context context, Size size) {
    //     super(context);
    //     this.size = size;
    //     this.initView();
    // }

    initView() {
        this.lineView = document.createElement("div");
        this.lineView.style.position = 'absolute';
        this.lineView.style.zIndex = 31;
        this.fatherDom.appendChild(this.lineView);
        this.lineView.style.width = this.size.width + 'px';
        this.lineView.style.height = this.size.height + 'px';
        this.lineView.style.border = '2px solid ' + Colors.getUiColor(Colors.black50); 
        this.lineView.style.backgroundColor = Colors.getUiColor(Colors.white);
        this.lineView.style.borderRadius = '50%';
        this.lineView.style.cursor = 'pointer';
        this.lineView.style.userSelect = "none"

        this.line = document.createElement("div");
        this.lineView.appendChild(this.line);
        this.line.style.position = 'absolute';
        this.line.style.left = "50%";
        this.line.style.top = "50%";
        this.line.style.transform = "translate(-50%,-50%)";
        // this.line.style.width = this.size.width - new UiUtil().dip2px(8) + 'px';
        // this.line.style.height = this.lineHeight + 'px';
        this.line.style.width = '100%';
        this.line.style.height = '100%';
        this.line.style.lineHeight = this.size.height + 'px';
        this.line.style.textAlign = 'center';
        // this.line.style.backgroundColor = Colors.getUiColor(Colors.black50); 
        this.line.style.color = Colors.getUiColor(Colors.black50); 
        this.line.innerHTML = "&#xe651;";
        this.line.classList.add("hidden-child-node");
        this.lineView.style.display = 'none';
        this.bundling();

        // let that = this;
        // let mouseoverFunction = function(e) {
        //     console.log('移入');
            
        //     // that.lineView.removeEventListener('mouseout',mouseoutFunction);
        //     that.isMoveDom = true;
        // }
        
        // that.lineView.addEventListener('mouseover',mouseoverFunction)

        // let mouseoutFunction = function(e) {
        //     console.log('移出了dom');
        //     that.isMoveDom = false;
        //     that.hideDom()
        // }
        // that.lineView.addEventListener('mouseout',mouseoutFunction)
    }

    bundling() {
        this.mousdownFunction = (ev) => {
            window.event ? window.event.cancelBubble = true : ev.stopPropagation();
            this.delegate.onMousedownHiddenChildren(this.selectId);
            this.hideDom()
        }
        this.lineView.addEventListener('mousedown', this.mousdownFunction)
    }

    setColor(bg, line) {
        if (Colors.getUiColor(bg) == Colors.getUiColor(line)) {
            if (Colors.isDarkColor(bg)) {
                line = Colors.white;
            } else {
                line = Colors.black80;
            }
        }
        this.lineView.style.border = '2px solid ' + Colors.getUiColor(line); 
        this.lineView.style.backgroundColor = Colors.getUiColor(bg);
        // this.line.style.backgroundColor = Colors.getUiColor(line);
        this.line.style.color = Colors.getUiColor(line);
    }

    setPosition(obj) {
        if(obj==undefined) {
            return;
        }
        clearInterval(this.timeInterval);
        this.lineView.style.display = 'block';
        this.lineView.style.left = obj.left + 'px';
        this.lineView.style.top = obj.top + 'px';
    }

    checkId(id) {
        this.selectId = id;
    }

    hideDom() {
        this.lineView.style.display = 'none';
        // var that = this
        // let elem = this.addChildIconImg;
        // new UiUtil().scaleDownAnimation(this.lineView, function() {
        //     that.lineView.style.display = 'none';
        // })
    }

    movePoint(data, isDelay = true) {
        let that = this;
        clearInterval(this.timeInterval);
        if (isDelay) {
            this.timeInterval = setInterval(() => {
                that.hideDom()
                clearInterval(this.timeInterval);
            },3000)
        } else {
            that.hideDom()
        }
    }

    // moveFunction(e) {
    //     console.log('移出了dom');
    //     this.hideDom()
    //     this.lineView.removeEventListener('mouseout',this.moveFunction);
    //     // console.log(e);

    // }
}

export default MindNodeUpView