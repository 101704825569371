var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBox),expression:"showBox"}],staticClass:"big-img-box"},[_c('div',{staticClass:"big-img-list",attrs:{"alt":""},on:{"click":_vm.hideImg}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMaterial),expression:"!showMaterial"}],staticClass:"center-img-box",style:({
        marginLeft: -_vm.showIndex * _vm.fullWidth + 'px',
        width: _vm.fullWidth * _vm.imgUrlList.length + 'px',
      }),attrs:{"id":"moveFrame"}},_vm._l((_vm.imgUrlList),function(item,index){return _c('div',{key:index,staticClass:"center-big-img",style:({
          backgroundSize: _vm.backgroundSize,
          width: _vm.fullWidth + 'px',
        }),attrs:{"alt":""}},[_c('img',{key:index,staticClass:"center-big-img-tag",style:({
          }),attrs:{"src":_vm.getImgUrl(item),"alt":""}})])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMaterial),expression:"showMaterial"}],staticClass:"center-big-bg-img",style:({
        backgroundImage: 'url(' + _vm.imgUrl + ')',
        backgroundSize: _vm.backgroundSize,
      }),attrs:{"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMaterial),expression:"!showMaterial"}],staticClass:"move-btn"},[_c('div',{staticStyle:{"float":"left","cursor":"pointer","margin-left":"50px","user-select":"none"},on:{"click":function($event){return _vm.pre()}}},[_c('img',{staticStyle:{"width":"54px","user-select":"none"},attrs:{"src":require("../../../../src/assets/img/page/pre_img.svg"),"alt":"上一张"}})]),_c('div',{staticStyle:{"float":"right","cursor":"pointer","margin-right":"50px","user-select":"none"},on:{"click":function($event){return _vm.nex()}}},[_c('img',{staticStyle:{"width":"54px","user-select":"none"},attrs:{"src":require("../../../../src/assets/img/page/nex_img.svg"),"alt":"下一张"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }