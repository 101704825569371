import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import UiUtil from '../../../utils/UiUtil'
import LineColorMode from '../../../viewmodel/core/base/LineColorMode'
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'
import ConnectLineType from '../../../viewmodel/datatype/ConnectLineType'
import LineElementContent from "../../../viewmodel/mindelementdata/mindcontent/LineElementContent"

import BaseElementView from "./BaseElementView";
import Config from "../../../core/core/calcule/Config"
import Strings from "../../../utils/Strings"
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM"
import strings from "../../../common/lang/strings"
import LinePointShape from "../../../viewmodel/datatype/LinePointShape"
import Util from "../../../utils/Util"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */

class NodeConnectLineView extends BaseElementView {
    constructor(viewCanvs, data,vuethis) {
        super(viewCanvs, data,vuethis);
        this.path;  //用来画线条的元素
        this.circular1; //用来画圆
        this.circular2; //用来画圆
        this.circular3; //用来画圆
        this.arrow; //用来画箭头的元素
        this.selectPath; //选中的线条
        this.lineContorller1;//控制杆1
        this.lineContorller2;//控制杆2
        this.smallCircular1; //控制杆与线之间连接的小圆
        this.smallCircular2; //控制杆与线之间连接的小圆
        this.lineWidth = 3;
        this.startPoint;
        this.endPoint;
        this.color;
        this.startControlPoint = new CGPoint();
        this.endControlPoint = new CGPoint();
        this.lineMindElementContent;
        this.textBackground;  //文字背景。
        this.textForeignObject; //装文字的父盒子。
        this.textForeignObjectChildren; //文字。
        this.isAnimation = false;
        this.duration = 1;

        this.NodeConnectLineView();
        this.onDraw();
    }

    NodeConnectLineView() {
        this.isRefresh = false;
    }

    setSelectedHoverLayer(value) {
        if (this.isSelected) {
            return
        }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isHover == value) {
            return;
        }
        this.isHover = value;
        this.isRefresh = true;
        this.onDraw();
    }

    setSelected(bool) {
        if (this.isSelected == bool) {
            return;
        }
        this.isRefresh = true;
        this.isSelected = bool;
        this.onDraw();
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.lineMindElementContent = this.mindElementData.lineContent;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    refresh() {
        super.refresh();
        if (this.isRefresh) {
            this.onDraw();
        }
    }

    onDraw() {
        this.drawLine();
    }

    drawLine() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }

        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        let lineMindElementContent = this.mindElementData.lineContent;
        this.startControlPoint = new CGPoint((lineMindElementContent.startControlPointX - this.mindElementData.x),
            (lineMindElementContent.startControlPointY - this.mindElementData.y));
        this.endControlPoint = new CGPoint((lineMindElementContent.endControlPointX - this.mindElementData.x),
            (lineMindElementContent.endControlPointY - this.mindElementData.y));

        this.color = lineMindElementContent.color;
        this.startPoint = lineMindElementContent.startPoint();
        this.endPoint = lineMindElementContent.endPoint();
        this.lineWidth = lineMindElementContent.lineWidth;
        if (this.mindElementData.isShadow) {
            this.context.setAttribute('filter', 'url(#connectLinemapLineShadow)');
        } else {
            this.context.setAttribute('filter', '');
        }
        switch (lineMindElementContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:

                this.setStraightArrow();
                break;
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                this.setStraightCircular();

                break;
            case ConnectLineType.CURVE_LINE:
                this.setCurveLine();

                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                this.setRightAngleLine();

                break;
            default:

                this.setStraightArrow();
        }
        this.drawStartShape();
        this.drawEndShape();
        this.setSelectedView();
        this.drawTitle();
    }

    drawStartShape() {
        if(this.contextStart != null && this.contextStart != ""){
            this.contextStart.innerHTML = "";
            this.contextStart.parentNode.removeChild(this.contextStart);
            this.contextStart = null;
        }
        this.contextStart = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.context.appendChild(this.contextStart);
        this.lineMindElementContent = this.mindElementData.lineContent;
        let radius = this.UiUtil.dip2px(this.lineMindElementContent.lineWidth) * 2;
        let targetPoint = this.startPoint;
        let referencePoint = this.endPoint;
        switch (this.lineMindElementContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                targetPoint = this.startPoint;
                referencePoint = this.endPoint;
                break;
            case ConnectLineType.CURVE_LINE:
                targetPoint = this.startPoint;
                referencePoint = this.startControlPoint;
                if (Math.abs(this.startControlPoint.x - this.startPoint.x) < 3 &&
                        Math.abs(this.startControlPoint.y - this.startPoint.y) < 3 &&
                        (this.lineMindElementContent.startShape == LinePointShape.ARROW ||
                            this.lineMindElementContent.startShape == LinePointShape.BAMBOO_ARROW ||
                            this.lineMindElementContent.startShape == LinePointShape.SIMPLE_ARROW)) {
                    referencePoint = this.endControlPoint;
                }
                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                if (this.lineMindElementContent.rightAnglePoints.isEmpty()) {
                    targetPoint = this.startPoint;
                    referencePoint = this.endPoint;
                    if (Math.abs(this.endPoint.x - this.startPoint.x) < 3 &&
                        Math.abs(this.endPoint.y - this.startPoint.y) < 3 &&
                        (this.lineMindElementContent.startShape == LinePointShape.ARROW ||
                            this.lineMindElementContent.startShape == LinePointShape.BAMBOO_ARROW ||
                            this.lineMindElementContent.startShape == LinePointShape.SIMPLE_ARROW)) {
                        referencePoint = this.endControlPoint;
                    }
                } else {
                    targetPoint = this.startPoint;
                    referencePoint = new CGPoint(this.lineMindElementContent.rightAnglePoints[0].x,
                            this.lineMindElementContent.rightAnglePoints[0].y);
                }
                break;
            default:
        }
        switch (this.lineMindElementContent.startShape) {
            case LinePointShape.NORMAL:
                //不画形状
                break;
            case LinePointShape.ARROW:
                let arrowSel = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextStart.appendChild(arrowSel)
                this.drawArrow1( referencePoint, targetPoint, this.lineWidth, true,arrowSel);
                break;
            case LinePointShape.CIRCULAR:
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextStart.appendChild(circular1)
                this.drawCircular( targetPoint, radius, this.color, 0, 0,circular1);
                break;
            case LinePointShape.CIRCULAR_RADIATION:
                let circularR1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextStart.appendChild(circularR1)
                let circularR2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextStart.appendChild(circularR2)
                this.drawCircular( targetPoint, radius, this.color, 0, 0,circularR1);
                this.drawCircular( targetPoint, radius * 2, Colors.getUiColorByAlpha(this.color, 0.3), 0, 0,circularR2);
                break;
            case LinePointShape.CIRCULAR_HOLLOW:
                let circularH1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextStart.appendChild(circularH1)
                this.drawCircular( targetPoint, radius, Colors.clear, this.lineWidth, this.color,circularH1);
                break;
            case LinePointShape.T_SHAPED:
                let tShape = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextStart.appendChild(tShape)
                this.drawTShape( referencePoint, targetPoint, this.lineWidth, this.color,tShape);
                break;
            case LinePointShape.DIAMOND:
                let diamondPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextStart.appendChild(diamondPath)
                this.drawDiamond( referencePoint, targetPoint, this.lineWidth, this.color,diamondPath);
                break;
            case LinePointShape.SIMPLE_ARROW:
                let simpleArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextStart.appendChild(simpleArrow)
                this.drawSimpleArrow( referencePoint, targetPoint, this.lineWidth, this.color,simpleArrow);
                break;
            case LinePointShape.BAMBOO_ARROW:
                let bambooArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextStart.appendChild(bambooArrow)
                this.drawBambooArrow( referencePoint, targetPoint, this.lineWidth, this.color,bambooArrow);
                break;
        }
    }

    drawEndShape() {
        if(this.contextEnd != null && this.contextEnd != ""){
            this.contextEnd.innerHTML = "";
            this.contextEnd.parentNode.removeChild(this.contextEnd);
            this.contextEnd = null;
        }
        this.contextEnd = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        this.context.appendChild(this.contextEnd);
        this.lineMindElementContent = this.mindElementData.lineContent;
        let radius = this.UiUtil.dip2px(this.lineMindElementContent.lineWidth) * 2;
        let targetPoint = this.endPoint;
        let referencePoint = this.startPoint;
        switch (this.lineMindElementContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                targetPoint = this.endPoint;
                referencePoint = this.startPoint;
                break;
            case ConnectLineType.CURVE_LINE:
                targetPoint = this.endPoint;
                referencePoint = this.endControlPoint;
                if (Math.abs(this.endControlPoint.x - this.endPoint.x) < 3 &&
                        Math.abs(this.endControlPoint.y - this.endPoint.y) < 3 &&
                        (this.lineMindElementContent.endShape == LinePointShape.ARROW ||
                            this.lineMindElementContent.endShape == LinePointShape.BAMBOO_ARROW ||
                            this.lineMindElementContent.endShape == LinePointShape.SIMPLE_ARROW)) {
                    referencePoint = this.startControlPoint;
                }
                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                if (this.lineMindElementContent.rightAnglePoints.isEmpty()) {
                    targetPoint = this.endPoint;
                    referencePoint = this.startPoint;
                    if (Math.abs(this.startPoint.x - this.endPoint.x) < 3 &&
                        Math.abs(this.startPoint.y - this.endPoint.y) < 3 &&
                        (this.lineMindElementContent.endShape == LinePointShape.ARROW ||
                            this.lineMindElementContent.endShape == LinePointShape.BAMBOO_ARROW ||
                            this.lineMindElementContent.endShape == LinePointShape.SIMPLE_ARROW)) {
                        referencePoint = this.startControlPoint;
                    }
                } else {
                    targetPoint = this.endPoint;
                    referencePoint = new CGPoint(this.lineMindElementContent.rightAnglePoints[this.lineMindElementContent.rightAnglePoints.length - 1].x,
                            this.lineMindElementContent.rightAnglePoints[this.lineMindElementContent.rightAnglePoints.length - 1].y);
                }
                break;
        }

        switch (this.lineMindElementContent.endShape) {
            case LinePointShape.NORMAL:
                //不画形状
                break;
            case LinePointShape.ARROW:
                
                let arrowselect = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextEnd.appendChild(arrowselect)
                this.drawArrow1( referencePoint, targetPoint, this.lineWidth,true, arrowselect );
                break;
            case LinePointShape.CIRCULAR:
                let circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextEnd.appendChild(circular1)
                this.drawCircular( targetPoint, radius, this.color, 0, 0,circular1);
                break;
            case LinePointShape.CIRCULAR_RADIATION:
                let circularR1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextEnd.appendChild(circularR1)
                let circularR2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextEnd.appendChild(circularR2)
                this.drawCircular( targetPoint, radius,Colors.getUiColor(this.color), 0, 0,circularR1);
                this.drawCircular( targetPoint, radius * 2, Colors.getUiColorByAlpha(this.color, 0.3), 0, 0,circularR2);
                break;
            case LinePointShape.CIRCULAR_HOLLOW:
                let circularH1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
                this.contextEnd.appendChild(circularH1)
                this.drawCircular( targetPoint, radius, Colors.clear, this.lineWidth, this.color,circularH1);
                break;
            
            case LinePointShape.T_SHAPED:
                let tShape = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextEnd.appendChild(tShape)
                this.drawTShape( referencePoint, targetPoint, this.lineWidth, this.color,tShape);
                break;
            case LinePointShape.DIAMOND:
                let diamondPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextEnd.appendChild(diamondPath)
                this.drawDiamond( referencePoint, targetPoint, this.lineWidth, this.color,diamondPath);
                break;
            case LinePointShape.SIMPLE_ARROW:
                let simpleArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextEnd.appendChild(simpleArrow)
                this.drawSimpleArrow( referencePoint, targetPoint, this.lineWidth, this.color,simpleArrow);
                break;
            case LinePointShape.BAMBOO_ARROW:
                let bambooArrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.contextEnd.appendChild(bambooArrow)
                this.drawBambooArrow( referencePoint, targetPoint, this.lineWidth, this.color,bambooArrow);
                break;
        }
    }


    drawTitle() {
        if (this.textBackground != undefined && this.textBackground != null && this.textBackground.parentNode != null) {
            this.textBackground.parentNode.removeChild(this.textBackground);
            this.textForeignObject.parentNode.removeChild(this.textForeignObject);
            this.textBackground = null;
        }
        if (!this.mindElementData.lineContent.isContainText()) { //如果内容为空，这里的lineContent就是LineElementContent
            return;
        }
        if (!this.isSelected && new Strings().equals(this.mindElementData.lineContent.textContent.text, new UiUtil().getString(strings.Mind_Usinghelp_Btn_3))) {
            return;
        }

        this.textBackground = document.createElementNS('http://www.w3.org/2000/svg', "path");
        this.context.appendChild(this.textBackground);
        this.textForeignObject = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject");
        this.textForeignObjectChildren = document.createElement("div");
        this.textForeignObject.appendChild(this.textForeignObjectChildren);
        this.context.appendChild(this.textForeignObject);

        let textContent = this.mindElementData.lineContent.textContent;
        let textFontSize = textContent.textFontSize;
        let textItalics = textContent.textItalics;
        let textBold = textContent.textBold;
        let strikethroughStyle = textContent.textStrikethrough;
        let title = textContent.text;
        let width = textContent.width;
        let height = textContent.height;
        let titleBackgroundColor;
        let textColor = textContent.textColor;


        this.textForeignObject.setAttribute('x', textContent.x);
        this.textForeignObject.setAttribute('y', textContent.y);
        this.textForeignObject.setAttribute('width', width);
        this.textForeignObject.setAttribute('height', height);
        this.textForeignObjectChildren.style.userSelect = 'none';
        this.textForeignObjectChildren.style.fontSize = textFontSize + 'px';
        this.textForeignObjectChildren.style.color = Colors.getUiColor(textColor);

        //标题只有一行
        this.textForeignObjectChildren.style.lineHeight = height + 'px';
        this.textForeignObjectChildren.style.textAlign = 'center';
        if (textItalics) {
            this.textForeignObjectChildren.style.fontStyle = 'italic'
        } else {
            this.textForeignObjectChildren.style.fontStyle = 'normal'
        }
        if (textBold) {
            this.textForeignObjectChildren.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.textForeignObjectChildren.style.fontWeight = Config.textNormalFontWeight
        }
        if (strikethroughStyle) {
            this.textForeignObjectChildren.style.textDecoration = 'line-through'
        } else {
            this.textForeignObjectChildren.style.textDecoration = 'none'
        }
        this.textForeignObjectChildren.innerHTML = title;
        titleBackgroundColor = Colors.getUiColor(EditMindmapVM.mindBGColor);

        let titleBgRadius = 2;
        let linePath = new LinePath();
        linePath.moveTo(textContent.x, textContent.y + height);
        linePath.lineTo(textContent.x, textContent.y + titleBgRadius);//|
        linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, textContent.x + titleBgRadius, textContent.y); //(
        linePath.lineTo(textContent.x + width - titleBgRadius, textContent.y);//-
        linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, textContent.x + width, textContent.y + titleBgRadius); //)
        linePath.lineTo(textContent.x + width, textContent.y + height);//|
        linePath.lineTo(textContent.x, textContent.y + height);//-
        this.renderObj({ dom: this.textBackground, d: linePath, fill: titleBackgroundColor })
    }

    setRightAngleLine() {
        let linePath = new LinePath();
        let lineMindElementContent = this.mindElementData.lineContent;
        linePath.setEffectRadius(this.UiUtil.dip2px(4))
        linePath.moveTo(this.startPoint.x, this.startPoint.y);

        let count = lineMindElementContent.rightAnglePoints.length;
        let lastPoint = this.startPoint;
        if (count > 0) {
            let points = []
            for (let index = 0; index < count; index++) {
                let cell = new CGPoint(lineMindElementContent.rightAnglePoints[index].x,
                    lineMindElementContent.rightAnglePoints[index].y);
                if (index == 0) {
                    points.push(cell)
                    continue
                }
                let perCell = points[points.length - 1]
                let space = Util.getPointSpacing(perCell, cell);
                if (space > 3) {
                    points.push(cell)
                }
            }

            if (points.length > 0 && points[0].x != this.startPoint.x && points[0].y != this.startPoint.y) {
                if (Math.abs(points[0].x - this.startPoint.x) < Math.abs(points[0].y - this.startPoint.y) && Math.abs(points[0].x - this.startPoint.x) < 3) {
                    points[0].x = this.startPoint.x
                } else if (Math.abs(points[0].y - this.startPoint.y) < 3) {
                    points[0].y = this.startPoint.y
                }
            }
            let lastIndex = points.length - 1
            if (points.length > 0 && points[lastIndex].x != this.endPoint.x && points[lastIndex].y != this.endPoint.y) {
                if (Math.abs(points[lastIndex].x - this.endPoint.x) < Math.abs(points[lastIndex].y - this.endPoint.y) && Math.abs(points[lastIndex].x - this.endPoint.x) < 3) {
                    points[lastIndex].x = this.endPoint.x
                } else if (Math.abs(points[lastIndex].y - this.endPoint.y) < 3) {
                    points[lastIndex].y = this.endPoint.y
                }
            }
            for (let index = 0; index < points.length; index++) {
                let point = points[index];
                let x = point.x
                let y = point.y
                if (index == 0) {
                    if (Math.abs(point.x - lastPoint.x) < 2) {
                        x = lastPoint.x
                    }
                    if (Math.abs(point.y - lastPoint.y) < 2) {
                        y = lastPoint.y
                    }
                }
                linePath.lineTo(x, y);
                lastPoint = point;
            }

            
        }
        linePath.lineTo(this.endPoint.x, this.endPoint.y);        
        this.renderCanvas(linePath);
        this.drawArrow();
    }

    setCurveLine() {
        let linePath = new LinePath();
        linePath.moveTo(this.startPoint.x, this.startPoint.y);
        linePath.cubicTo(
            this.startControlPoint.x,
            this.startControlPoint.y,
            this.endControlPoint.x,
            this.endControlPoint.y,
            this.endPoint.x,
            this.endPoint.y);
        this.renderCanvas(linePath);
        //画箭头
        this.drawArrow();
    }

    setStraightCircular() {
        this.drawStraightCircular();
    }

    drawStraightCircular() {
        let lineMindElementContent = this.mindElementData.lineContent;
        let radius = lineMindElementContent.lineWidth * 2;
        let linePath = new LinePath();
        this.straightLine();
        //画圆
        if (this.circular1 == null && this.circular2 == null && this.circular3 == null) {
            this.circular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.contextStart.appendChild(this.circular1)
            this.circular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.contextStart.appendChild(this.circular2)
            this.circular3 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.contextEnd.appendChild(this.circular3)
        }

        this.drawCircular(this.startPoint, radius, Colors.getUiColor(this.color), 0,0,this.circular1);
        this.drawCircular(this.startPoint, radius * 2, Colors.getUiColorByAlpha(this.color, 0.3),0,0, this.circular2);
        this.drawCircular(this.endPoint, radius, Colors.getUiColor(this.color),0,0, this.circular3);
    }

    drawCircular(point, radius, fillColor,lineWidth, lineColor, element) {
        let fcolor = 'rgba(0,0,0,0)';
        let lcolor = 'rgba(0,0,0,0)';
        let lWidth = 0;
        if (Colors.isClear(fillColor)) {
            fcolor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
            lWidth = 0.1;
        } else {
            fcolor = Colors.getUiColor(fillColor);
            lWidth = 0.1;
        }
        if (!Colors.isClear(lineColor) && lineWidth > 0) {
            lcolor = Colors.getUiColor(lineColor);
            lWidth = lineWidth;

        }
        let lineMindElementContent = this.mindElementData.lineContent
        element.setAttribute("cx", point.x)
        element.setAttribute("cy", point.y)
        element.setAttribute("r", radius)
        element.setAttribute("fill", fcolor)
        element.setAttribute("stroke-width", lWidth)
        element.setAttribute("stroke", lcolor)

    }

    setStraightArrow() {
        this.drawStraightArrow();
    }

    drawStraightArrow() {
        this.straightLine();
        this.drawArrow();
    }

    drawTShape(  startPoint,  endPoint,  lineWidth,  color , element) {
        if (lineWidth <= 0) {
            return;
        }

        let width = Math.max(lineWidth, new UiUtil().dip2px(1)) * 4;
        let lineLenght = Util.getPointSpacing(startPoint, endPoint);
        let largeRadius = Math.sqrt(Math.pow(width/2,2) + Math.pow(lineLenght, 2)) + lineWidth/2;

        let angle = Util.getCircleDegreesInPoint(startPoint, endPoint);
        let includedAngle = Util.getTanDegrees(width/2/lineLenght);

        let point1 = Util.getCirclePoint(startPoint, angle+includedAngle, largeRadius);
        let point2 = Util.getCirclePoint(startPoint, angle-includedAngle, largeRadius);

        let linePath = new LinePath();

        linePath.moveTo(point1.x,point1.y);
        linePath.lineTo(point2.x,point2.y);

        element.setAttribute("d", linePath.getLine())
        element.setAttribute("stroke", Colors.getUiColor(color))
        element.setAttribute("stroke-width", lineWidth)
        element.setAttribute("fill", Colors.getUiColor(color))

    }

    drawDiamond( startPoint, endPoint, lineWidth, color,element) {
        if (lineWidth <= 0) {
            return;
        }

        let width = Math.max(lineWidth, new UiUtil().dip2px(1)) * 4;

        let lineLenght = Util.getPointSpacing(startPoint, endPoint);
        let largeRadius = Math.sqrt(Math.pow(width/2,2) + Math.pow(lineLenght, 2));

        let angle = Util.getCircleDegreesInPoint(startPoint, endPoint);
        let includedAngle = Util.getTanDegrees(width/2/lineLenght);

        let point1 = Util.getCirclePoint(startPoint, angle+includedAngle, largeRadius);
        let point2 = Util.getCirclePoint(startPoint, angle, lineLenght + width/2);
        let point3 = Util.getCirclePoint(startPoint, angle-includedAngle, largeRadius);
        let point4 = Util.getCirclePoint(startPoint, angle, lineLenght - width/2);
        let linePath = new LinePath();

        linePath.moveTo(point1.x,point1.y);
        linePath.lineTo(point2.x,point2.y);
        linePath.lineTo(point3.x,point3.y);
        linePath.lineTo(point4.x,point4.y);
        linePath.lineTo(point1.x,point1.y);
        // linePath.lineTo(point2.x,point2.y);

        element.setAttribute("d", linePath.getLine())
        element.setAttribute("stroke", Colors.getUiColor(color))
        element.setAttribute("stroke-width", 0)
        element.setAttribute("fill", Colors.getUiColor(color))
        // element.setAttribute("stroke-width", 2)
        // element.setAttribute("stroke", Colors.getUiColor(Colors.red))

        // Paint paint = new Paint();
        // paint.setStyle(Paint.Style.FILL);
        // paint.setStrokeCap(Paint.Cap.ROUND);
        // paint.setStrokeWidth(1);
        // paint.setAntiAlias(true);
        // paint.setColor(Colors.getUiColor(color));
        // canvas.drawPath(linePath, paint);
    }

    drawSimpleArrow( startPoint, endPoint, lineWidth, color,element) {
        if (lineWidth <= 0) {
            return;
        }

        let width = Math.max(lineWidth, new UiUtil().dip2px(1.5)) * 5;

        let lineLenght = Util.getPointSpacing(startPoint, endPoint);
        let lineSimpleLenght = Util.getPointSpacing(startPoint, endPoint) - width;
        let largeRadius = Math.sqrt(Math.pow(width/2,2) + Math.pow(lineSimpleLenght, 2));

        let angle = Util.getCircleDegreesInPoint(startPoint, endPoint);
        let includedAngle = Util.getTanDegrees(width/2/lineSimpleLenght);

        let point1 = Util.getCirclePoint(startPoint, angle+includedAngle, largeRadius);
        let point2 = endPoint;
        let point3 = Util.getCirclePoint(startPoint, angle-includedAngle, largeRadius);

        let linePath = new LinePath();

        linePath.moveTo(point1.x,point1.y);
        linePath.lineTo(point2.x,point2.y);
        linePath.lineTo(point3.x,point3.y);

        element.setAttribute("d", linePath.getLine())
        element.setAttribute("stroke", Colors.getUiColor(color))
        element.setAttribute("stroke-width", lineWidth)
        element.setAttribute("fill", "none")

        // Paint paint = new Paint();
        // paint.setStyle(Paint.Style.STROKE);
        // paint.setStrokeCap(Paint.Cap.ROUND);
        // paint.setStrokeWidth(lineWidth);
        // paint.setAntiAlias(true);
        // paint.setColor(Colors.getUiColor(color));
        // canvas.drawPath(linePath, paint);
    }

    drawBambooArrow( startPoint, endPoint, lineWidth, color,element) {
        if (lineWidth <= 0) {
            return;
        }

        let width = Math.max(lineWidth, new UiUtil().dip2px(3)) * 5;
        let lineLenght = Util.getPointSpacing(startPoint, endPoint);
        let lineBambooLenght = Util.getPointSpacing(startPoint, endPoint) - width * 0.75;
        let largeRadius = Math.sqrt(Math.pow(width/2,2) + Math.pow(lineBambooLenght, 2));

        let angle = Util.getCircleDegreesInPoint(startPoint, endPoint);
        let includedAngle = Util.getTanDegrees(width/2/lineBambooLenght);

        let point1 = Util.getCirclePoint(startPoint, angle+includedAngle, largeRadius);
        let point2 = Util.getCirclePoint(startPoint, angle, lineLenght + lineWidth * 2);
        let point3 = Util.getCirclePoint(startPoint, angle-includedAngle, largeRadius);
        let point4 = endPoint;

        let linePath = new LinePath();

        linePath.moveTo(point1.x,point1.y);
        linePath.lineTo(point2.x,point2.y);
        linePath.lineTo(point3.x,point3.y);
        linePath.lineTo(point4.x,point4.y);
        linePath.lineTo(point1.x,point1.y);

        element.setAttribute("d", linePath.getLine())
        element.setAttribute("stroke", Colors.getUiColor(color))
        element.setAttribute("stroke-width", 0)
        element.setAttribute("fill", Colors.getUiColor(color))

        // Paint paint = new Paint();
        // paint.setStyle(Paint.Style.FILL);
        // paint.setStrokeCap(Paint.Cap.ROUND);
        // paint.setStrokeWidth(0);
        // paint.setAntiAlias(true);
        // paint.setColor(Colors.getUiColor(color));
        // canvas.drawPath(linePath, paint);
    }


    drawArrow() {
        let lineMindElementContent = this.mindElementData.lineContent;
        
        let arrowJ = document.createElementNS('http://www.w3.org/2000/svg', "path")
        this.contextEnd.appendChild(arrowJ)
        switch (lineMindElementContent.connectLineType) {
            case ConnectLineType.STRAIGHT_ARROW_LINE:
            case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                this.drawArrow1( this.startPoint, this.endPoint, this.lineWidth, true,arrowJ);
                break;
            case ConnectLineType.CURVE_LINE:
                this.drawArrow1( this.endControlPoint, this.endPoint, this.lineWidth, true,arrowJ);
                break;
            case ConnectLineType.RIGHT_ANGLE_LINE:
                //这一块代码不大懂，箭头为这个角度会有问题吗？以后有逻辑控制
                if (lineMindElementContent.rightAnglePoints.length == 0) {
                    this.drawArrow1( this.startPoint, this.endPoint, this.lineWidth, true,arrowJ);
                } else {
                    this.drawArrow1(new CGPoint(lineMindElementContent.rightAnglePoints[lineMindElementContent.rightAnglePoints.length - 1].x,
                        lineMindElementContent.rightAnglePoints[lineMindElementContent.rightAnglePoints.length - 1].y), this.endPoint, this.lineWidth,true,arrowJ);
                }
                break;
            default:
                // p = (new UiUtil).getArrowPoint(this.startPoint, this.endPoint, this.lineWidth);
        }
        

    }
    drawArrow1( startPoint,  endPoint,  lineWidth, isFull, element){
        let linePath = new LinePath();
        let p = new UiUtil().getArrowPoint(startPoint, endPoint, lineWidth);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);

        element.setAttribute("d", linePath.getLine())
        element.setAttribute("stroke", Colors.getUiColor(this.color))
        element.setAttribute("stroke-width", lineWidth)
        element.setAttribute("fill", Colors.getUiColor(this.color))
    }
    //自己添加的一个函数
    renderCanvas(path) {
        let fillColor = Colors.getUiColorByAlpha((new LineColorMode).selectedColor, 0.5);

        let lineMindElementContent = this.mindElementData.lineContent
        this.path.setAttribute("d", path.getLine())
        this.path.setAttribute("stroke", Colors.getUiColor(this.color))
        this.path.setAttribute("stroke-width", this.lineWidth)
        //判断是否是虚线
        if (lineMindElementContent.nodeConnectLineDottedLine) {
            this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str)
            if (this.isAnimation) {
                this.path.style.strokeDashoffset = this.path.getTotalLength()
                this.path.style.strokeDasharray = this.path.getTotalLength()
                this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
                setTimeout(() => {
                    this.path.style.strokeDasharray = Config.strokeDasharray1
                    this.path.style.strokeDashoffset = 2
                    this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str);
                }, this.duration + 10);
            } else {
                this.path.style.strokeDasharray = Config.strokeDasharray1
                this.path.style.strokeDashoffset = 2
                this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str);
            }
        } else {
            this.path.setAttribute("stroke-dasharray", "none")
            if (this.isAnimation) {
                this.path.style.strokeDashoffset = this.path.getTotalLength()
                this.path.style.strokeDasharray = this.path.getTotalLength()
                this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
            } else {
                this.path.style.strokeDashoffset = 0
                this.path.style.strokeDasharray = 0
                this.path.style.animation = ''
            }
        }

        this.path.setAttribute("fill", "none")


    }
    //线条被选中的样式
    setSelectedView() {
        if (!this.isSelected && !this.isHover ) {
            if (this.selectPath != null && this.selectPath.parentNode != null) {   //删除dom节点
                this.selectPath.parentNode.removeChild(this.selectPath);
                if (this.lineContorller1 != null) {
                    this.lineContorller1.parentNode.removeChild(this.lineContorller1);
                }
                if (this.lineContorller2 != null) {
                    this.lineContorller2.parentNode.removeChild(this.lineContorller2);
                }
                this.smallCircular1.parentNode.removeChild(this.smallCircular1);
                this.smallCircular2.parentNode.removeChild(this.smallCircular2);
                this.selectPath = null;
                this.lineContorller1 = null;
                this.lineContorller2 = null;
                this.smallCircular1 = null;
                this.smallCircular2 = null;
            }
            return;
        }

        //重新画的一个线
        let linePath = new LinePath();
        let lineMindElementContent = this.mindElementData.lineContent;
        if (lineMindElementContent.connectLineType == ConnectLineType.CURVE_LINE) {
            linePath.moveTo(this.startPoint.x, this.startPoint.y);
            linePath.cubicTo(
                this.startControlPoint.x,
                this.startControlPoint.y,
                this.endControlPoint.x,
                this.endControlPoint.y,
                this.endPoint.x,
                this.endPoint.y);
        } else if (lineMindElementContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
            linePath.moveTo(this.startPoint.x, this.startPoint.y);
            let count = lineMindElementContent.rightAnglePoints.length;
            if (count > 0) {
                for (let index = 0; index < count; index++) {
                    let pointTwo = new CGPoint((lineMindElementContent.rightAnglePoints[index].x),
                        (lineMindElementContent.rightAnglePoints[index].y))
                    linePath.lineTo(pointTwo.x, pointTwo.y);
                }
            }
            linePath.lineTo(this.endPoint.x, this.endPoint.y);
        } else {
            linePath.moveTo(this.startPoint.x, this.startPoint.y);
            linePath.lineTo(this.endPoint.x, this.endPoint.y);
        }

        this.selectedCanvas(linePath)
        if (this.isSelected && lineMindElementContent.connectLineType == ConnectLineType.CURVE_LINE) {
            let lineContorllerLinePath1 = new LinePath();
            lineContorllerLinePath1.moveTo(this.startPoint.x, this.startPoint.y);
            lineContorllerLinePath1.lineTo(this.startControlPoint.x, this.startControlPoint.y);
            if (this.lineContorller1 == null) {
                this.lineContorller1 = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.lineContorller1)
            }
            this.selectedCanvasController(lineContorllerLinePath1, this.lineContorller1);
    
            let lineContorllerLinePath2 = new LinePath();
            lineContorllerLinePath2.moveTo(this.endPoint.x, this.endPoint.y);
            lineContorllerLinePath2.lineTo(this.endControlPoint.x, this.endControlPoint.y);
            if (this.lineContorller2 == null) {
                this.lineContorller2 = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.lineContorller2)
            }
            this.selectedCanvasController(lineContorllerLinePath2, this.lineContorller2);
        }

        let radius = 2;
        if (this.smallCircular1 == null) {
            this.smallCircular1 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.context.appendChild(this.smallCircular1)
        }
        if (this.smallCircular2 == null) {
            this.smallCircular2 = document.createElementNS('http://www.w3.org/2000/svg', "circle")
            this.context.appendChild(this.smallCircular2)
        }
        this.selectedCanvasCircle(this.startPoint, radius, Colors.getUiColor(this.color), this.smallCircular1);

        this.selectedCanvasCircle(this.endPoint, radius, Colors.getUiColor(this.color), this.smallCircular2);
    }

    selectedCanvas(path) {
        if (this.selectPath == null) {
            this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.selectPath)
        }

        let lineMindElementContent = this.mindElementData.lineContent
        this.selectPath.setAttribute("d", path.getLine())
        this.selectPath.setAttribute("stroke", Colors.getUiColorByAlpha((new LineColorMode()).selectedColor, 0.4))
        this.selectPath.setAttribute("stroke-width", Math.max(this.lineWidth * 2, 5))
        this.selectPath.setAttribute("fill", "none")
    }
    selectedCanvasController(path, element) {
        let lineMindElementContent = this.mindElementData.lineContent
        element.setAttribute("d", path.getLine())
        element.setAttribute("stroke", Colors.getUiColorByAlpha((new LineColorMode()).selectedColor, 0.4))
        element.setAttribute("stroke-width", this.lineWidth)
        this.selectPath.setAttribute("fill", "none")
    }
    selectedCanvasCircle(point, radius, fillColor, element) {
        let lineMindElementContent = this.mindElementData.lineContent
        element.setAttribute("cx", point.x)
        element.setAttribute("cy", point.y)
        element.setAttribute("r", radius)
        element.setAttribute("stroke", Colors.getUiColor(this.color))
        element.setAttribute("stroke-width", 1)
        element.setAttribute("fill", Colors.getUiColor(Colors.white90))
    }

    straightLine() {
        let linePath = new LinePath();
        linePath.moveTo(this.startPoint.x, this.startPoint.y);
        linePath.lineTo(this.endPoint.x, this.endPoint.y);
        this.renderCanvas(linePath)
    }

    renderObj(obj = { dom, d, stroke, strokeWidth }) {  //设置path
        if (obj.d != undefined) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != undefined) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != undefined) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != undefined) {
            obj.dom.setAttribute("fill", obj.fill)
        }
    }

    createBackgroundView() {
    }
}


export default NodeConnectLineView