var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content-box",attrs:{"id":"mailbox-login-box"}},[_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"lright-cont"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmitLogin}},[_c('div',{staticClass:"drop-down d-quhao"},[_c('img',{attrs:{"src":require("../../../../assets/img/code/mailbox_login.png"),"alt":""}})]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'email',
                    {
                      rules: [
                        {
                          type: 'email',
                          required: true,
                          message: _vm.getString(_vm.strings.Input_Email_isNull),
                        } ],
                    } ]),expression:"[\n                    'email',\n                    {\n                      rules: [\n                        {\n                          type: 'email',\n                          required: true,\n                          message: getString(strings.Input_Email_isNull),\n                        },\n                      ],\n                    },\n                  ]"}],ref:"userEmailInput",attrs:{"allow-clear":"","type":"email","placeholder":_vm.getString(_vm.strings.Contact_Us_Email)}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../../assets/img/code/pwd_input_logo.png"),"alt":""}})]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: _vm.getString(
                            _vm.strings.Input_Please_Enter_The_Password
                          ),
                        },
                        {
                          validator: _vm.validateToNextPassword,
                        } ],
                    } ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: getString(\n                            strings.Input_Please_Enter_The_Password\n                          ),\n                        },\n                        {\n                          validator: validateToNextPassword,\n                        },\n                      ],\n                    },\n                  ]"}],ref:"password",attrs:{"oninput":"if(value.length>24)value=value.slice(0,24)","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Password),"type":"password"},on:{"blur":_vm.passwordBlurForValidate}})],1),_c('a-form-item',_vm._b({},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"Login-Button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_Login))+" ")])],1),_c('div',_vm._b({staticClass:"Login-Button lbtwo"},'div',_vm.tailFormItemLayout,false),[_c('a',{staticClass:"mailbox-login ho-a",attrs:{"href":"javascript:;"},on:{"click":_vm.forgetPassWorld}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Forget_Email_Password))+" ")]),_c('span',{staticClass:"lc-zi"},[_c('label',[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mind_No_Account))+" ")]),_c('a',{staticClass:"sign-a",attrs:{"href":"javascript:;"},on:{"click":_vm.clickLoginShow}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mind_To_Register))+" ")])])])],1)],1)]),_c('div',{staticClass:"Login-code",on:{"click":_vm.ClickShowCode}},[_c('img',{attrs:{"src":require("../../../../assets/img/code/qrcode9.png"),"alt":""}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }