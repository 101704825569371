import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import HashMap from "../../core/base/HashMap";
import IdGenerator from "../../core/base/IdGenerator";
import MindElementType from "../../datatype/MindElementType";
import PlayTitlePosition from "../../datatype/PlayTitlePosition";
import MindType from "../../datatype/MindType";
import NodeLayoutType from "../../datatype/NodeLayoutType";
import LineMindTypeNode from "../LineMindTypeNode"
import LineMindTypeNodeUnit from "../LineMindTypeNodeUnit"
import MindElementData from "../MindElementData";
import DoubleBubbleMindNodeUnit from "../DoubleBubbleMindNodeUnit";
import TimeMindTypeNode from "../TimeMindTypeNode";
import TimeMindTypeNodeUnit from "../TimeMindTypeNodeUnit";
import MindMapPlayStatus from "../../datatype/MindMapPlayStatus";
import Util from "../../../utils/Util";
import UiUtil from "../../../utils/UiUtil";
import PlaySettingData from "../../minddata/PlaySettingData";
import PlayType from "../../datatype/PlayType";
import CGPoint from "../../core/base/basedata/CGPoint";
import Size from "../../core/base/Size";
import TextCalculation from "../../../core/core/calcule/elementCalculation/TextCalculation";
import MindElementAlignmentType from "../../datatype/MindElementAlignmentType";
import Strings from "../../../utils/Strings";
import LineLayout from "../../datatype/LineLayout";
import LineOrientation from "../../datatype/LineOrientation";
import Colors from "../../../utils/Colors";
import MindElementShapeType from "../../datatype/MindElementShapeType";
import Config from "../../../core/core/calcule/Config";
import LineElementContent from "../mindcontent/LineElementContent";
import { setTimeout } from "core-js";
import { Point } from "canvg";
import MindElementFullType from "../../datatype/MindElementFullType";
import CGRect from "../../core/base/basedata/Rect";
/**
 * ProjectName: MindMap
 * Created by tony on 3/29/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindMapPlayVM {
    constructor(mindType, delegate, mindMapPlayStatusDelegate) {
        this.speechLargeScreenSize = {
            mainSize: 90,
            subjectSize: 30,
            sonSubjectSize: 20,
            topMainSize: 50,
            topSize: 40,
            endTitleSize: 60,
            endSize: 30,
            palyTitleMainSize: 35,
            palyTitlSubjectSize: 24,
        }
        this.speechPhoneScreenSize = {
            mainSize: 45,
            subjectSize: 24,
            sonSubjectSize: 16,
            topMainSize: 40,
            topSize: 30,
            endTitleSize: 35,
            endSize: 24,
            palyTitleMainSize: 24,
            palyTitlSubjectSize: 18,
        }
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.mindBGColor = 0xffffff;

        this.mapScale = 1.0; //画布比例
        this.speed = 500; //播放速度
        this.PlayOrderPeer = false
        this.textSpeed = 120; //播放速度
        this.nodeScale = 1.2; //节点出现时放大比例
        this.playOrderList = new Array();
        this.status = MindMapPlayStatus.NORMAL;
        this.showData = new Array();
        this.currentData = new MindElementData().emptyMindNode();
        this.preData = new MindElementData().emptyMindNode();
        this.encircleData = new MindElementData().emptyMindNode();
        this.generalizationData = new LineMindTypeNode();
        this.nodeConnectLinesData = new Array();
        this.containEncircleData = new LineMindTypeNode();
        this.containGeneralizationData = new LineMindTypeNode();
        this.currentCopyData = new MindElementData().emptyMindNode();
        this.mindType = mindType;
        this.delegate = delegate;
        this.mindMapPlayStatusDelegate = mindMapPlayStatusDelegate;
        this.playNextDataTimeout = -1;
        this.lastPlayId = -1;
        this.addViewWithoutAnimationDelay = 20;
        this.quickRelease = false

        this.settingData = new PlaySettingData()
        this.speechModeShowData = [];
        this.removeDatas = [];
        this.topMarginInSpeechMode = (new UiUtil()).getScreenHeight() * 0.1
        this.leftMarginInSpeechMode = 50
        this.peerSpacingInSpeechMode = 40
        this.horizontalSpacingInSpeechMode = 40
        this.speechSize = (new UiUtil()).isPhoneAndPad() ? this.speechPhoneScreenSize : this.speechLargeScreenSize
    }

    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit, mindBGColor) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
        this.mindBGColor = mindBGColor;
    }

    setConfig(data) {
        this.mapScale = data.mapScale / 100;
        this.speed = data.speed * 1000;
        this.textSpeed = data.textSpeed * 1000;
        this.playOrderList = data.playOrderList;
        this.PlayOrderPeer = data.playOrderPeer
        this.settingData = data
        this.speechSize = (new UiUtil()).isPhoneAndPad() ? this.speechPhoneScreenSize : this.speechLargeScreenSize
    }

    play() {
        if (this.status == MindMapPlayStatus.NORMAL) {
            setTimeout(() => {                
                this.play();
            }, 1000);      
            this.clearMap();      
            this.status = MindMapPlayStatus.PLAY;
            this.delegate.setMindMapScale(this.mapScale);

            return;
        }
        
        if (this.settingData.playType == PlayType.SpeechMode &&
            this.status != MindMapPlayStatus.PAUSE) {
            this.speechPlay();
        }
        
        if (this.status != MindMapPlayStatus.PAUSE) {
            this.findNextData();
        }
        this.quickRelease = false
        this.status = MindMapPlayStatus.PLAY;
        this.playNextData(1000);
    }

    resumePlay() {
        if (this.lastPlayId == -1) {
            this.play();
            return;
        }
        let lastData = this.getNodeById(this.lastPlayId);
        if (lastData.isEmpty()) {
            this.play()
            return;
        }
        if (this.status == MindMapPlayStatus.NORMAL) {
            setTimeout(() => {
                this.clearMap();
                this.resumePlay();
            }, 1000);
            this.status = MindMapPlayStatus.PLAY;
            this.delegate.setMindMapScale(this.mapScale);
            return;
        }
        if (this.settingData.playType == PlayType.SpeechMode &&
            this.status != MindMapPlayStatus.PAUSE) {
            this.speechPlay();
        }
        this.quickRelease = true
        if (this.status != MindMapPlayStatus.PAUSE) {
            this.findNextData();
        }
        this.status = MindMapPlayStatus.PLAY;        
        this.addViewWithoutAnimationRunnable(1000);
    }

    playNextData(speed) {
        clearTimeout(this.playNextDataTimeout)
        this.playNextDataTimeout = setTimeout(() => {
            this.playData()
        }, Math.max(speed, 50));
    }

    addViewWithoutAnimationRunnable(speed) {
        clearTimeout(this.playNextDataTimeout)
        this.playNextDataTimeout = setTimeout(() => {
            this.addDataToViewWithoutAnimation()
        }, speed);
    }

    playData() {
        if (this.status != MindMapPlayStatus.PLAY) {
            return;
        }
        if (!this.generalizationData.isEmpty() && !this.isAddView(this.generalizationData.value)) {
            let generalizationLineData = this.getGeneralizationNodeLine(this.generalizationData.value.id);
            this.addMindContentView(generalizationLineData, true, this.speed);
            this.showData.push(generalizationLineData);
            setTimeout(() => {
                this.changeMindContentView(this.generalizationData.value);
                this.showData.push(this.generalizationData.value);
                this.generalizationData = new LineMindTypeNode();
                this.playNextData(this.speed);
            }, this.speed);
            return;
        }
        if (!this.encircleData.isEmpty() && !this.isAddView(this.encircleData)) {
            this.addMindContentView(this.encircleData, true, this.speed * 3);
            this.showData.push(this.encircleData);
            this.encircleData = new MindElementData().emptyMindNode();
            this.playNextData(this.speed * 3);
            this.setPlayNodeConntentLine()
            return;
        }
        if (!this.nodeConnectLinesData.isEmpty()) {
            for (let i = 0; i < this.nodeConnectLinesData.length; i++) {
                const line = this.nodeConnectLinesData[i];
                if (!this.isAddView(line)) {
                    this.addMindContentView(line, true, this.speed);
                    this.showData.push(line);
                    this.nodeConnectLinesData.remove(line);
                    this.playNextData(this.speed);
                    this.setPlayNodeConntentLine()
                    return;
                }
            }
        }

        if (this.currentCopyData.isEmpty()) {
            this.lastPlayId = -1;
            this.status = MindMapPlayStatus.NORMAL;
            this.clearPlayData();
            if (this.mindMapPlayStatusDelegate != null) {
                this.mindMapPlayStatusDelegate.mindMapPlayComplete();
            }
            if (this.settingData.playType == PlayType.SpeechMode) {
                setTimeout(() => {
                    this.speechEnd();
                }, 500);
            }
            return;
        }
        if (this.isAddView(this.currentCopyData)) {
            if (!this.isFullShow()) {
                this.currentCopyData.textContent.text = this.currentData.textContent.text.substring(0, this.currentCopyData.textContent.text.length + 1);
                MindElementCalculation.layoutRefreshCalculeDataList = []
                if (this.currentData.layout != NodeLayoutType.LAYOUT_FORM &&
                    this.currentData.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        MindElementCalculation.set(this.currentCopyData).caluleTextForData().calcule(false);
                    this.currentCopyData.width = this.currentData.width
                    this.currentCopyData.height = this.currentData.height
                }
                
                if (this.currentCopyData.orderContent != null && 
                    this.currentCopyData.orderContent.width > 0 &&
                    this.currentCopyData.orderContent.order != null &&
                    this.currentCopyData.orderContent.order.length > 0) {
                    let orderContentX = this.currentCopyData.getPadingLeft() 
                    MindElementCalculation.set(this.currentCopyData).setDataContentMoveLeft(this.currentCopyData.orderContent.width);
                    this.currentCopyData.orderContent.x = orderContentX
                }
                this.changeMindContentView(this.currentCopyData);
                this.playNextData(this.textSpeed);
                return;
            } else {
                this.changeMindContentView(this.currentData);
                this.findNextData();
                this.playNextData(this.speed);
                return;
            }
        }
        if (this.currentData.parentNodeId > -1) {
            let parentNode = this.getNodeById(this.currentData.parentNodeId);
            if (parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                parentNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                let line = this.getFishLineData(parentNode);
                if (!line.isEmpty() && !this.isAddView(line)) {
                    this.addMindContentView(line, true, this.speed);
                    this.showData.push(line);
                    setTimeout(() => {
                        this.playData();
                    }, this.speed);
                    return;
                }
            }
        }

        this.lastPlayId = this.currentData.id;
        let lines = this.getNodeDisplayLine(this.currentData.id);
        if (lines.length > 0) {
            lines.forEach(line => {
                if (!this.isAddView(line)) {
                    this.addMindContentView(line, true, this.speed);
                    this.showData.push(line);
                }
            });
            setTimeout(() => {
                this.addMindContentView(this.currentCopyData, true, this.speed);
                this.showData.push(this.currentCopyData);
                if (this.isFullShow()) {
                    this.addMindContentView(this.currentData);
                    this.findNextData();
                    this.playNextData(this.textSpeed);
                } else {
                    this.playNextData(this.textSpeed);
                }
            }, this.speed);
        } else {
            let isParentNodeMoveTop = this.isParentNodeMoveTop(this.currentData);
            this.addMindContentView(this.currentCopyData, true, this.speed);
            this.showData.push(this.currentCopyData);
            if (this.settingData.playType == PlayType.AnimationReview && 
                this.currentData.type == MindElementType.MAIN_SUBJECT) {
                let mainNode = this.getNodeById(this.currentData.id)
                if (!mainNode.isEmpty() && mainNode.value.x > 0) {
                    this.delegate.mindContentDisplay(new CGRect(mainNode.value.x, mainNode.value.y, mainNode.value.width, mainNode.value.height), false);
                }
            }
            if (this.isFullShow()) {
                if (this.settingData.playType == PlayType.SpeechMode) {
                    if (this.PlayOrderPeer) {
                        let parentNode = this.getNodeById(this.currentData.parentNodeId)
                        if (!parentNode.isEmpty() && parentNode.children.length > 0 && 
                            parentNode.children[0].value.id == this.currentData.id) {
                            this.playNextData(this.speed + 2200);
                        } else {
                            this.playNextData(this.speed + 100);
                        }
                    } else if (isParentNodeMoveTop) {
                        this.playNextData(this.speed + 2200);
                    } else {
                        this.playNextData(this.speed + 100);
                    }
                    
                } else {
                    this.playNextData(this.speed + 100);
                }
                this.findNextData();
            } else {
                if (this.settingData.playType == PlayType.SpeechMode) {
                    if (this.PlayOrderPeer) {
                        let parentNode = this.getNodeById(this.currentData.parentNodeId)
                        if (!parentNode.isEmpty() && parentNode.children.length > 0 && 
                            parentNode.children[0].value.id == this.currentData.id) {
                            this.playNextData(this.textSpeed + 2200);
                        } else {
                            this.playNextData(this.textSpeed + 100);
                        }
                    } else if (isParentNodeMoveTop) {
                        this.playNextData(this.textSpeed + 2200);
                    } else {
                        this.playNextData(this.textSpeed + 100);
                    }
                } else {
                    this.playNextData(this.textSpeed + 100);
                }
            }
        }
    }

    addDataToViewWithoutAnimation() {
        if (this.status != MindMapPlayStatus.PLAY) {
            return;
        }
        if (!this.generalizationData.isEmpty() && !this.isAddView(this.generalizationData.value)) {
            let generalizationLineData = this.getGeneralizationNodeLine(this.generalizationData.value.id);
            this.addMindContentView(generalizationLineData);
            this.showData.push(generalizationLineData);
            setTimeout(() => {
                this.changeMindContentView(this.generalizationData.value);
                this.showData.push(this.generalizationData.value);
                this.generalizationData = new LineMindTypeNode();
                this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
            }, this.addViewWithoutAnimationDelay);
            return;
        }
        if (!this.encircleData.isEmpty() && !this.isAddView(this.encircleData)) {
            this.addMindContentView(this.encircleData);
            this.showData.push(this.encircleData);
            this.encircleData = new MindElementData().emptyMindNode();
            this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
            return;
        }
        if (!this.nodeConnectLinesData.isEmpty()) {
            for (let i = 0; i < this.nodeConnectLinesData.length; i++) {
                const line = this.nodeConnectLinesData[i];
                if (!this.isAddView(line)) {
                    this.addMindContentView(line);
                    this.showData.push(line);
                    this.nodeConnectLinesData.remove(line);
                    this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
                    return;
                }
            }
        }

        if (this.currentCopyData.isEmpty()) {
            this.lastPlayId = -1;
            this.status = MindMapPlayStatus.NORMAL;
            this.clearPlayData();
            if (this.mindMapPlayStatusDelegate != null) {
                this.mindMapPlayStatusDelegate.mindMapPlayComplete();
            }
            return;
        }
        if (this.isAddView(this.currentCopyData)) {
            if (!this.isFullShow()) {
                this.changeMindContentView(this.currentData);
                this.findNextData();
                this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
                return;
            }
        }
        if (this.lastPlayId == this.currentData.id) {
            this.quickRelease = false
            clearTimeout(this.playNextDataTimeout)
            this.playData();
            return;
        }
        let lines = this.getNodeDisplayLine(this.currentData.id);
        if (lines.length > 0) {
            lines.forEach(line => {
                if (!this.isAddView(line)) {
                    this.addMindContentView(line);
                    this.showData.push(line);
                }
            });
            setTimeout(() => {
                this.addMindContentView(this.currentCopyData);
                this.showData.push(this.currentCopyData);
                if (this.isFullShow()) {
                    this.addMindContentView(this.currentData);
                    this.findNextData();
                    this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
                } else {
                    this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
                }
            }, this.addViewWithoutAnimationDelay);
        } else {
            this.addMindContentView(this.currentCopyData);
            this.showData.push(this.currentCopyData);
            if (this.isFullShow()) {
                this.changeMindContentView(this.currentData);
                this.findNextData();
            }
            this.addViewWithoutAnimationRunnable(this.addViewWithoutAnimationDelay);
        }
    }

    isExistResumeData() {
        if (this.lastPlayId == -1) {
            return false;
        }
        return !this.getNodeById(this.lastPlayId).isEmpty()
    }

    isFullShow() {
        if (this.currentCopyData.textContent != null && this.currentData.textContent != null &&
            this.currentCopyData.id == this.currentData.id &&
            this.currentCopyData.textContent.text.length < this.currentData.textContent.text.length) {
            return false;
        }
        return true;
    }

    findNextData() {//this.PlayOrderPeer
        let data = new MindElementData().emptyMindNode();
        if (this.currentData.isEmpty()) {
            if (this.playOrderList.length == 0) {
                if (this.status == MindMapPlayStatus.NORMAL || this.currentData.isEmpty()) {
                    if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
                        data = this.mainMindNodeUnit.rootTreeNode.value;
                    } else if (this.mindType == MindType.TIME_MAP) {
                        data = this.timeMindTypeNodeUnit.title;
                    } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                        data = this.doubleBubbleMindNodeUnit.rootTreeNode.value;
                    }
                }
            } else {
                data = this.playOrderList[0].value;
            }
        } else {
            if (this.PlayOrderPeer) {
                data = this.getPeerNextNode()
                if (!data.isEmpty() && this.isAddView(data)) {
                    this.currentData = data;
                    for (let index = 0; index < 20; index++) {
                        data = this.getPeerNextNode()
                        // if (data.isEmpty()) {
                        //     data = this.getSelectedNodeDownData(this.currentData.id);
                        // }
                        if (data.isEmpty()) {
                            break
                        }
                        if (!this.isAddView(data)) {
                            this.currentData = data;
                        } else {
                            break
                        }
                    }
                }
                
            } else {
                data = this.getSelectedNodeSonData();
                if (data.isEmpty()) {
                    data = this.getSelectedNodeDownData(this.currentData.id);
                }
            }
            
            if (data.isEmpty()) {
                for (let i = 0; i < this.playOrderList.length; i++) {
                    const node = this.playOrderList[i];
                    if (!this.isAddView(node.value)) {
                        data = node.value;
                        break;
                    }
                }
            }
            this.preData = this.currentData;
        }


        if (!this.containEncircleData.isEmpty()) {
            if (!this.containEncircleData.containsData(data.id)) {
                let list = this.getEncircleData(this.containEncircleData.value);
                if (list.length > 0) {
                    this.encircleData = list[0]
                } else {
                    this.encircleData = new MindElementData().emptyMindNode()
                }
                this.containEncircleData = new LineMindTypeNode()
            }
        }
        if (!this.containGeneralizationData.isEmpty()) {
            if (!this.containGeneralizationData.containsData(data.id)) {
                this.generalizationData = this.getGeneralizationData(this.containGeneralizationData.value);
            }
        }
        this.setPlayNodeConntentLine()

        this.currentData = data;
        this.currentCopyData = this.currentData.copy();
        this.currentCopyData.id = this.currentData.id;
        if (!this.quickRelease && this.currentCopyData.textContent != null && this.currentCopyData.textContent.text.length > 1 && this.textSpeed > 0) {
            let isHasOrder = this.currentCopyData.orderContent != null && 
                                this.currentCopyData.orderContent.width > 0 &&
                                this.currentCopyData.orderContent.order != null &&
                                this.currentCopyData.orderContent.order.length > 0;
            if (isHasOrder) {
                this.currentCopyData.textContent.text = " ";
                MindElementCalculation.layoutRefreshCalculeDataList = []
                MindElementCalculation.set(this.currentCopyData).caluleTextForData().calcule(false);
                this.currentCopyData.width = this.currentData.width
                this.currentCopyData.height = this.currentData.height
                this.currentCopyData.textContent.text = "";
                let orderContentX = this.currentCopyData.getPadingLeft()
                MindElementCalculation.set(this.currentCopyData).setDataContentMoveLeft(this.currentCopyData.orderContent.width);
                this.currentCopyData.orderContent.x = orderContentX
            } else {
                this.currentCopyData.textContent.text = this.currentData.textContent.text.substring(0, 1);
                MindElementCalculation.layoutRefreshCalculeDataList = []                
                if (this.currentData.layout != NodeLayoutType.LAYOUT_FORM &&
                    this.currentData.layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        MindElementCalculation.set(this.currentCopyData).caluleTextForData().calcule(false);
                    this.currentCopyData.width = this.currentData.width
                    this.currentCopyData.height = this.currentData.height
                }
            }
        }
        let list = this.getEncircleData(this.currentData);
        let encircleLine = list.length > 0 ? list[0] : new MindElementData().emptyMindNode();
        if (!encircleLine.isEmpty()) {
            this.containEncircleData = this.getNodeById(this.currentData.id);
        }
        let generalizationData = this.getGeneralizationData(this.currentData);
        if (!generalizationData.isEmpty()) {
            this.containGeneralizationData = this.getNodeById(this.currentData.id);
        }
    }

    isNodeAndChildAddedView(node) {
        if (node == null || node.isEmpty()) {
            return true
        }
        if (!this.isAddView(node.value)) {
            return false
        }   
        for (let index = 0; index < node.children.length; index++) {
            return this.isNodeAndChildAddedView(node.children[index])
        }   
        return true
    }

    setPlayNodeConntentLine() {
        for (let i = 0; i < this.nodeConnectLineDataDict.keys().length; i++) {
            const key = this.nodeConnectLineDataDict.keys()[i];
            let line = this.nodeConnectLineDataDict.get(key);
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (!target.isEmpty() && !to.isEmpty() &&
                this.isAddView(target) && this.isAddView(to)) {
                this.nodeConnectLinesData.push(line);
            }
        }
    }

    pause() {
        if (this.status == MindMapPlayStatus.NORMAL) {
            return;
        }
        this.status = MindMapPlayStatus.PAUSE;
        clearTimeout(this.playNextDataTimeout)
    }

    stop() {
        this.clearPlayData();
    }

    getFishLineData(node)  {
        var line = this.mainMindNodeUnit.getFishLineData(node);
        if (!line.isEmpty() && !line.isHidden) {
            return line;
        }
        for (let i = 0; i < this.freeNodes.length; i++) {
            line = this.freeNodes[i].getFishLineData(node)
            if (!line.isEmpty() && !line.isHidden) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    clearPlayData() {
        this.status = MindMapPlayStatus.NORMAL;
        this.currentData = new MindElementData().emptyMindNode();
        this.preData = new MindElementData().emptyMindNode();
        this.encircleData = new MindElementData().emptyMindNode();
        this.containEncircleData = new LineMindTypeNode();
        this.generalizationData = new LineMindTypeNode();
        this.containGeneralizationData = new LineMindTypeNode();
        this.nodeConnectLinesData = [];
        clearTimeout(this.playNextDataTimeout)
    }

    clearMap() {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) {
            for (let i = 0; i < this.mainMindNodeUnit.lineMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.lineMindElementDataDict.keys()[i];
                let line = this.mainMindNodeUnit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < this.mainMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.mainMindElementDataDict.keys()[i];
                let node = this.mainMindNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < this.mainMindNodeUnit.generalizationLineMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.generalizationLineMindElementDataDict.keys()[i];
                let line = this.mainMindNodeUnit.generalizationLineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < this.mainMindNodeUnit.generalizationMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()[i];
                let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < this.mainMindNodeUnit.encircleMindElementDataDict.keys().length; i++) {
                const key = this.mainMindNodeUnit.encircleMindElementDataDict.keys()[i];
                let mind = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
                this.removeViewByMindData(mind);
            }

            for (let i = 0; i < this.mainMindNodeUnit.explainMindElementDataDict.values().length; i++) {
                let mind = this.mainMindNodeUnit.explainMindElementDataDict.values()[i];
                this.removeViewByMindData(mind);
            }

        } else if (this.mindType == MindType.TIME_MAP) {
            this.removeViewByMindData(this.timeMindTypeNodeUnit.title);
            this.removeViewByMindData(this.timeMindTypeNodeUnit.baseLine);
            let keys = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys()
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                let data = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(data.head);
                this.removeViewByMindData(data.title);
                this.removeViewByMindData(data.desc);
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.lineMindElementDataDict.keys().length; i++) {
                const key = this.doubleBubbleMindNodeUnit.lineMindElementDataDict.keys()[i];
                let line = this.doubleBubbleMindNodeUnit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
                const key = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()[i];
                let node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }

            for (let i = 0; i < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; i++) {
                const data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[i];
                this.removeViewByMindData(data);
            }

        }

        for (let i = 0; i < this.nodeConnectLineDataDict.keys().length; i++) {
            const key = this.nodeConnectLineDataDict.keys()[i];
            let line = this.nodeConnectLineDataDict.get(key);
            this.removeViewByMindData(line);
        }

        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            for (let i = 0; i < unit.lineMindElementDataDict.keys().length; i++) {
                const key = unit.lineMindElementDataDict.keys()[i];
                let line = unit.lineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }
            for (let i = 0; i < unit.mainMindElementDataDict.keys().length; i++) {
                const key = unit.mainMindElementDataDict.keys()[i];
                let node = unit.mainMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }
            for (let i = 0; i < unit.generalizationLineMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationLineMindElementDataDict.keys()[i];
                let line = unit.generalizationLineMindElementDataDict.get(key);
                this.removeViewByMindData(line);
            }

            for (let i = 0; i < unit.generalizationMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationMindElementDataDict.keys()[i];
                let node = unit.generalizationMindElementDataDict.get(key);
                this.removeViewByMindData(node.value);
            }

            for (let i = 0; i < unit.encircleMindElementDataDict.keys().length; i++) {
                const key = unit.encircleMindElementDataDict.keys()[i];
                let mind = unit.encircleMindElementDataDict.get(key);
                this.removeViewByMindData(mind);
            }

            for (let i = 0; i < unit.explainMindElementDataDict.values().length; i++) {
                let mind = unit.explainMindElementDataDict.values()[i];
                this.removeViewByMindData(mind);
            }
        }
        this.showData = [];
    }

    removeViewByMindData(data) {
        this.delegate.removeElementView(data);
        let list = new Array();
        for (let i = 0; i < this.removeDatas.length; i++) {
            let cell = this.removeDatas[i]
            if (cell.id != data.id) {
                list.push(cell);
            }
        }
        this.removeDatas = list;
        this.removeDatas.push(data);
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    getNodeDisplayLine(nodeId) {
        let list = new Array();
        if (this.settingData.playType == PlayType.SpeechMode) {
            return list;
        }
        let line = this.mainMindNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            if (!line.isHidden &&
                line.lineContent != null &&
                line.lineContent.lineWidth > 0) {
                if (this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    let targetData = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                    if (targetData.isEmpty() || targetData.value.type != MindElementType.SUBJECT) {
                        list.push(line);
                    }
                } else if (this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM || this.mainMindNodeUnit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    if (line.type != MindElementType.SON_LINE &&
                        line.type != MindElementType.LINE &&
                        line.type != MindElementType.LEFTBRACELINE &&
                        line.type != MindElementType.BAOWEI_VIEW) {
                        list.push(line);
                    }
                } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                    let node = this.getNodeById(nodeId);
                    if (!node.isEmpty() && (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT)) {
                        list.push(line);
                    }
                } else if (line.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                    let node = this.getNodeById(nodeId);
                    if (!node.isEmpty() && node.value.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                        list.push(line);
                    }
                } else if (line.type != MindElementType.FORM_LINE &&
                    line.type != MindElementType.RADIATION_MAP_LINE) {
                    if (line.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        line.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                        let node = this.mainMindNodeUnit.getNodeById(nodeId);
                        if (!node.isEmpty()) {
                            let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId);

                            for (let i = 0; i < parentNode.children.length; i++) {
                                let cell = parentNode.children[i];
                                let cellLine = this.mainMindNodeUnit.getNodeLine(cell.value.id);
                                if (line.id != cellLine.id && !cellLine.isHidden) {
                                    list.push(cellLine);
                                }
                            }
                        }
                    }
                    list.push(line);
                }
                let node = this.getNodeById(nodeId);
                if (node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                    node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    let formLine = this.mainMindNodeUnit.getFormLineData(node)
                    if (!formLine.isHidden && line.id != formLine.id) {
                        list.push(formLine);
                    }
                }
            }
            return list;
        }
        let lines = this.doubleBubbleMindNodeUnit.getNodeLines(nodeId);
        if (lines.length > 0 && (lines.length > 1 || !lines[0].isEmpty())) {
            return lines;
        }
        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            line = unit.getNodeLine(nodeId);
            if (!line.isEmpty()) {
                if (!line.isHidden &&
                    line.lineContent != null &&
                    line.lineContent.lineWidth > 0) {
                    if (unit.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
                        let targetData = unit.getNodeById(line.parentNodeId);
                        if (targetData.isEmpty() || unit.rootTreeNode.value.id != targetData.value.id) {
                            list.push(line);
                        }
                    } else if (unit.globalLayout == NodeLayoutType.LAYOUT_FORM || unit.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        if (line.type != MindElementType.SON_LINE &&
                            line.type != MindElementType.LINE &&
                            line.type != MindElementType.LEFTBRACELINE &&
                            line.type != MindElementType.BAOWEI_VIEW) {
                            list.push(line);
                        }
                    } else if (line.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                        let node = this.getNodeById(nodeId);
                        if (!node.isEmpty() && (node.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            node.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT)) {
                            list.push(line);
                        }
                    } else if (line.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                        let node = this.getNodeById(nodeId);
                        if (!node.isEmpty() && node.value.layout == NodeLayoutType.LAYOUT_CIRCLE) {
                            list.push(line);
                        }
                    } else if (line.type != MindElementType.FORM_LINE &&
                        line.type != MindElementType.RADIATION_MAP_LINE) {
                        list.push(line);
                    }
                    let node = this.getNodeById(nodeId);
                    if (node.value.layout == NodeLayoutType.LAYOUT_FORM ||
                        node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        let formLine = unit.getFormLineData(node)
                        if (!formLine.isHidden && line.id != formLine.id) {
                            list.push(formLine);
                        }
                    }
                }
                return list;
            }
        }
        line = this.timeMindTypeNodeUnit.getNodeLine(nodeId);
        if (!line.isEmpty()) {
            list.push(line);
            return list;
        }
        if (this.mindType == MindType.TIME_MAP) {
            list.push(this.timeMindTypeNodeUnit.baseLine);
            return list;
        }
        return list;
    }

    getPeerNextNode() {
        let data = new MindElementData()
        let parentNode = this.getNodeById(this.currentData.parentNodeId);
        if (!parentNode.isEmpty() && parentNode.children.length > 0) {
            // let isFindPeerNode = false
            // let count = parentNode.children.length
            // for (let index = 0; index < count; index++) {
            //     let child = parentNode.children[index]
            //     if (!child.value.isHidden && !this.isAddView(child.value)) {
            //         data = child.value;
            //         isFindPeerNode = true;
            //         break;
            //     }
            // }
            // if (!isFindPeerNode) {
            //     for (let index = 0; index < count; index++) {
            //         let child = parentNode.children[index]
            //         if (child.children.length > 0 && 
            //             !child.children[0].value.isHidden && 
            //             !this.isAddView(child.children[0].value)) {
            //             data = child.children[0].value;
            //             isFindPeerNode = true;
            //             break;
            //         }
            //     }
            // }
            data = this.getParentNotAddedData(this.currentData)
            if (data.isEmpty()) {
                data = this.getSelectedNodeSonData();
                if (data.isEmpty()) {
                    data = this.getSelectedNodeDownData(this.currentData.id);
                }
            }
        } else {
            data = this.getSelectedNodeSonData();
            if (data.isEmpty()) {
                data = this.getSelectedNodeDownData(this.currentData.id);
            }
        }
        return data;
    }

    getParentNotAddedData(data) {
        if (data.isEmpty()) {
            return new MindElementData()
        }
        let node = this.getNodeById(data.id)
        if (node.isEmpty()) {
            return new MindElementData()
        }
        
        let parentNode = this.getNodeById(data.parentNodeId)
        if (parentNode.isEmpty()) {
            return new MindElementData()
        }
        let count = parentNode.children.length
        for (let index = 0; index < count; index++) {
            let child = parentNode.children[index]
            if (!child.value.isHidden && !this.isAddView(child.value)) {
                return child.value;
            }
        }
        for (let index = 0; index < count; index++) {
            let child = parentNode.children[index]
            let show = this.getChildrenNotAddedData(child.value)
            if (!show.isEmpty()) {
                return show
            }
        }
        return this.getParentNotAddedData(parentNode.value)
    }

    getChildrenNotAddedData(data) {
        if (data.isEmpty() || data.isHidden) {
            return new MindElementData()
        }
        if (!this.isAddView(data)) {
            return data
        }
        let node = this.getNodeById(data.id)
        if (node.isEmpty() || node.children.length == 0 || node.children[0].value.isHidden) {
            return new MindElementData()
        }
        let count = node.children.length
        for (let index = 0; index < count; index++) {
            let child = node.children[index]
            if (!child.value.isHidden && !this.isAddView(child.value)) {
                return child.value;
            }
            let show = this.getChildrenNotAddedData(child.value)
            if (!show.isEmpty()) {
                return show;
            }
        }
        return new MindElementData()
    }

    getSelectedNodeSonData() {
        let node = this.getNodeById(this.currentData.id);
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode();
        }
        if (this.timeMindTypeNodeUnit.isRootNode(node.value.id)) {
            if (this.timeMindTypeNodeUnit.getTopTimeNode().isEmpty()) {
                for (let i = 0; i < this.playOrderList.length; i++) {
                    const cell = this.playOrderList[i];
                    if (!this.isAddView(cell.value)) {
                        return cell.value;
                    }
                }
            }
        } else if (this.isRootNode(node.value) && (node.children.isEmpty() || node.children[0].value.isHidden)) {
            for (let i = 0; i < this.playOrderList.length; i++) {
                const cell = this.playOrderList[i];
                if (!this.isAddView(cell.value)) {
                    return cell.value;
                }
            }
            return new MindElementData();
        }


        let freeNodesCount = this.freeNodes.length
        for (let i = 0; i <  freeNodesCount; i++) {
            let unit = this.freeNodes[i];
            if (unit.getNodeById(node.value.id).isEmpty()) {
                continue;
            }
            if (node.value.parentNodeId == IdGenerator.INVALID_ID &&
                    (node.children.isEmpty() || node.children[0].value.isHidden)) {
                if (i < freeNodesCount - 1) {
                    let nextRootNode = this.freeNodes[i + 1].rootTreeNode
                    if (!nextRootNode.isEmpty()) {
                        return nextRootNode.value;
                    }
                }
            } else {
                if (node.children.length == 0) {
                    return new MindElementData().emptyMindNode();
                }
                if (node.children[0].value.isHidden) {
                    return this.getSelectedNodeDownData(node.value.id);
                } else {
                    return node.children[0].value;
                }
            }
        }

        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.children.length == 0) {
                return new MindElementData().emptyMindNode();
            }
            if (node.children[0].value.isHidden) {
                return this.getSelectedNodeDownData(node.value.id);
            } else {
                return node.children[0].value;
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            if (node.value.id == this.timeMindTypeNodeUnit.title.id) {
                let timeNode = this.timeMindTypeNodeUnit.getTopTimeNode();
                if (timeNode.isEmpty()) {
                    return new MindElementData().emptyMindNode();
                } else {
                    if (!timeNode.head.isHidden) {
                        return timeNode.head;
                    } else if (!timeNode.title.isHidden) {
                        return timeNode.title;
                    } else if (!timeNode.desc.isHidden) {
                        return timeNode.desc;
                    }
                    return timeNode.head;
                }
            } else {
                let timeNode = this.timeMindTypeNodeUnit.getTimeNodeByDataId(node.value.id);
                if (!timeNode.isEmpty()) {
                    if (node.value.id == timeNode.head.id) {
                        if (!timeNode.title.isHidden) {
                            return timeNode.title;
                        } else if (!timeNode.desc.isHidden) {
                            return timeNode.desc;
                        } else {
                            timeNode = this.timeMindTypeNodeUnit.getNextNodeByLevel(timeNode.level);
                            if (!timeNode.isEmpty()) {
                                if (!timeNode.head.isHidden) {
                                    return timeNode.head;
                                } else if (!timeNode.title.isHidden) {
                                    return timeNode.title;
                                } else if (!timeNode.desc.isHidden) {
                                    return timeNode.desc;
                                }
                            } else {
                                for (let i = 0; i < this.playOrderList.length; i++) {
                                    const cell = this.playOrderList[i];
                                    if (!this.isAddView(cell.value)) {
                                        return cell.value;
                                    }
                                }
                                return new MindElementData().emptyMindNode();
                            }
                        }
                        return timeNode.title;
                    } else if (node.value.id == timeNode.title.id) {
                        if (!timeNode.desc.isHidden) {
                            return timeNode.desc;
                        } else {
                            timeNode = this.timeMindTypeNodeUnit.getNextNodeByLevel(timeNode.level);
                            if (!timeNode.isEmpty()) {
                                if (!timeNode.head.isHidden) {
                                    return timeNode.head;
                                } else if (!timeNode.title.isHidden) {
                                    return timeNode.title;
                                } else if (!timeNode.desc.isHidden) {
                                    return timeNode.desc;
                                }
                            } else {
                                for (let i = 0; i < this.playOrderList.length; i++) {
                                    const cell = this.playOrderList[i];
                                    if (!this.isAddView(cell.value)) {
                                        return cell.value;
                                    }
                                }
                                return new MindElementData().emptyMindNode();
                            }
                        }
                        return timeNode.desc;
                    } else {
                        timeNode = this.timeMindTypeNodeUnit.getNextNodeByLevel(timeNode.level);
                        if (!timeNode.isEmpty()) {
                            if (!timeNode.head.isHidden) {
                                return timeNode.head;
                            } else if (!timeNode.title.isHidden) {
                                return timeNode.title;
                            } else if (!timeNode.desc.isHidden) {
                                return timeNode.desc;
                            }
                            return timeNode.head;
                        } else {
                            for (let i = 0; i < this.playOrderList.length; i++) {
                                const cell = this.playOrderList[i];
                                if (!this.isAddView(cell.value)) {
                                    return cell.value;
                                }
                            }
                            return new MindElementData().emptyMindNode();
                        }
                    }
                }
            }
            return this.timeMindTypeNodeUnit.title;
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.children.length == 0) {
                return new MindElementData().emptyMindNode();
            }
            return node.children[0].value;
        }

        return new MindElementData().emptyMindNode();
    }

    getSelectedNodeDownData(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || node.value.isHidden) {
            return new MindElementData().emptyMindNode();
        }
        if (this.isRootNode(node.value) && (node.children.isEmpty() || node.children[0].value.isHidden)) {
            for (let i = 0; i < this.playOrderList.length; i++) {
                const cell = this.playOrderList[i];
                if (!this.isAddView(cell.value)) {
                    return cell.value;
                }
            }
            return new MindElementData();
        }
        if (this.isFreeRootNode(node.value)) {
            for (let i = 0; i < this.playOrderList.length; i++) {
                const cell = this.playOrderList[i];
                if (!this.isAddView(cell.value)) {
                    return cell.value;
                }
            }
        }

        let freeNodesCount = this.freeNodes.length;
        for (let i = 0; i < freeNodesCount; i++) {
            const unit = this.freeNodes[i];
            if (unit.getNodeById(node.value.id).isEmpty()) {
                continue
            }
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                if (i < freeNodesCount - 1) {
                    let nextRootNode = this.freeNodes[i + 1].rootTreeNode
                    if (!nextRootNode.isEmpty()) {
                        return nextRootNode.value
                    } else {
                        return new MindElementData().emptyMindNode();
                    }
                } else {
                    return new MindElementData().emptyMindNode();
                }
            }
            let parentNode = unit.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty() && !node.value.isHidden) {
                if (parentNode.children.length > 0) {
                    let count = parentNode.children.length;
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index];
                        if (!child.value.isHidden && child.value.id == node.value.id) {
                            if (index < count - 1) {
                                return parentNode.children[index + 1].value;
                            } else {
                                return this.getSelectedNodeDownData(parentNode.value.id);
                            }
                        }
                    }
                }
            }
        }

        if ((this.mindType == MindType.LINE_MAP || this.mindType == MindType.BUBBLE_MAP) && this.mainMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                if (this.isFreeRootNode(node.value)) {
                    return new MindElementData().emptyMindNode();
                } else {
                    return this.getSelectedNodeSonData();
                }
            }

            let parentNode = this.mainMindNodeUnit.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty()) {
                if (parentNode.children.length > 0) {
                    let count = parentNode.children.length;
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index];
                        if (child.value.id == node.value.id) {
                            if (index < count - 1) {
                                return parentNode.children[index + 1].value;
                            } else {
                                if (this.isRootNode(parentNode.value)) {
                                    for (let i = 0; i < this.playOrderList.length; i++) {
                                        const cell = this.playOrderList[i];
                                        if (!this.isAddView(cell.value)) {
                                            return cell.value;
                                        }
                                    }
                                } else {
                                    return this.getSelectedNodeDownData(parentNode.value.id);
                                }
                            }
                        }
                    }
                }
            } else {
                return this.getSelectedNodeSonData();
            }
        } else if (this.mindType == MindType.TIME_MAP && this.timeMindTypeNodeUnit.title != null) {
            return this.getSelectedNodeSonData();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP && this.doubleBubbleMindNodeUnit.rootTreeNode != null) {
            if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
                return this.getSelectedNodeSonData();
            }
            let parentNode = this.doubleBubbleMindNodeUnit.getNodeById(node.value.parentNodeId);
            if (!parentNode.isEmpty()) {
                if (parentNode.children.length > 0) {
                    let count = parentNode.children.length;
                    for (let index = 0; index < count; index++) {
                        let child = parentNode.children[index];
                        if (child.value.id == node.value.id) {
                            if (index < count - 1) {
                                return parentNode.children[index + 1].value;
                            } else {
                                return this.getSelectedNodeSonData();
                            }
                        }
                    }
                }
            } else {
                return this.getSelectedNodeSonData();
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getMindMapPlayStatus() {
        return this.status;
    }

    isAddView(data) {
        for (let i = 0; i < this.showData.length; i++) {
            const cell = this.showData[i];
            if (cell.id == data.id) {
                return true;
            }
        }
        return false;
    }

    getGeneralizationNodeLine(nodeId) {
        let line = this.mainMindNodeUnit.getGeneralizationNodeLine(nodeId);
        if (!line.isEmpty()) {
            return line;
        }
        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            line = unit.getGeneralizationNodeLine(nodeId);
            if (!line.isEmpty()) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getEncircleData(data) {
        let list = []
        let lines = this.mainMindNodeUnit.getEncircleData(data);
        list = list.concat(lines)
        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            lines = unit.getEncircleData(data);
            if (lines.length > 0) {
                list = list.concat(lines)
            }
        }
        return list;
        // let line = this.mainMindNodeUnit.getEncircleData(data);
        // if (line.isEmpty()) {
        //     for (let i = 0; i < this.freeNodes.length; i++) {
        //         const unit = this.freeNodes[i];
        //         line = unit.getEncircleData(data);
        //         if (!line.isEmpty()) {
        //             break;
        //         }
        //     }
        //     if (line.isEmpty()) {
        //         return line;
        //     }
        // }
        // if (line.lineContent.targetIds.length < 2) {
        //     return line;
        // } else {
        //     let id = line.lineContent.targetIds[0];
        //     let node = this.getNodeById(id);
        //     if (node.isEmpty()) {
        //         return new MindElementData().emptyMindNode();
        //     }
        //     if (node.value.parentNodeId == IdGenerator.INVALID_ID) {
        //         return line;
        //     }
        //     let parentNode = this.getNodeById(node.value.parentNodeId);
        //     if (parentNode.isEmpty()) {
        //         return new MindElementData().emptyMindNode();
        //     }
        //     let bottomId = -1;
        //     for (let index = 0; index < parentNode.children.length; index++) {
        //         if (line.lineContent.targetIds.indexOf(parentNode.children[index].value.id) != -1) {
        //             bottomId = parentNode.children[index].value.id;
        //         }
        //     }
        //     if (bottomId > -1 && bottomId == data.id) {
        //         return line;
        //     }
        // }
        // return new MindElementData().emptyMindNode();
    }

    getGeneralizationData(data) {
        let generalizationNode = this.mainMindNodeUnit.getNodeGeneralizationByNode(data);
        if (generalizationNode.isEmpty()) {
            for (let i = 0; i < this.freeNodes.length; i++) {
                const unit = this.freeNodes[i];
                generalizationNode = unit.getNodeGeneralizationByNode(data);
                if (!generalizationNode.isEmpty()) {
                    break;
                }
            }
        }
        if (generalizationNode.isEmpty() ||
            generalizationNode.value.generalizationContent == null ||
            generalizationNode.value.generalizationContent.targetIds.isEmpty()) {
            return new LineMindTypeNode();
        }
        let targetIds = generalizationNode.value.generalizationContent.targetIds;

        if (targetIds.length < 2) {
            return generalizationNode;
        } else {
            let id = targetIds[0];
            let node = this.getNodeById(id);
            if (node.isEmpty()) {
                return new LineMindTypeNode();
            }
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.isEmpty()) {
                return new LineMindTypeNode();
            }
            let bottomId = -1;
            for (let index = 0; index < parentNode.children.length; index++) {
                if (targetIds.indexOf(parentNode.children[index].value.id) != -1) {
                    bottomId = parentNode.children[index].value.id;
                }
            }
            if (bottomId > -1 && bottomId == data.id) {
                return generalizationNode;
            }
        }
        return new LineMindTypeNode();
    }

    isTextDataCenter() {
        return this.currentData.mindElementShape == MindElementShapeType.Circular ||
            this.currentData.mindElementShape == MindElementShapeType.Circular_Right_Top ||
            this.currentData.mindElementShape == MindElementShapeType.Ring ||
            this.currentData.mindElementShape == MindElementShapeType.Ring2 ||
            this.currentData.mindElementShape == MindElementShapeType.Ring2_UP ||
            this.currentData.mindElementShape == MindElementShapeType.Ring3 ||
            this.currentData.mindElementShape == MindElementShapeType.Star||
            this.currentData.mindElementShape == MindElementShapeType.Octagonal||
            this.currentData.mindElementShape == MindElementShapeType.Regular_hexagon||
            this.currentData.mindElementShape == MindElementShapeType.Book||
            this.currentData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
            this.currentData.layout == NodeLayoutType.LAYOUT_TOP ||
            this.isRootNode(this.currentData);
    }

    isRootNode(data) {
        if (data.isEmpty() || (data.type != MindElementType.MAIN_SUBJECT && data.type != MindElementType.SUBJECT)) {
            return false;
        }
        let result = false;
        result = result || this.mainMindNodeUnit.isRootNode(data.id);
        result = result || this.isFreeRootNode(data);
        result = result || this.timeMindTypeNodeUnit.isRootNode(data.id);
        result = result || this.doubleBubbleMindNodeUnit.isRootNode(data.id);
        return result;
    }

    isFreeRootNode(data) {
        if (data.isEmpty() || (data.type != MindElementType.MAIN_SUBJECT && data.type != MindElementType.SUBJECT)) {
            return false;
        }
        let result = false;
        this.freeNodes.forEach(unit => {
            result = result || unit.isRootNode(data.id);
        })
        return result;
    }

    addMindContentView(data, isAnimation = false, duration = 100) {
        if (this.settingData.playType == PlayType.SpeechMode) {
            this.addMindContentViewBySpeechMode(data, isAnimation, duration);
        } else {
            this.delegate.addMindContentViewIncludeAnimation(data, isAnimation, duration)
        }
    }

    changeMindContentView(data, isAnimation = false, duratio = 100) {
        if (this.settingData.playType == PlayType.SpeechMode) {
            this.changeMindContentViewBySpeechMode(data, isAnimation, duratio);
        } else {
            this.delegate.changeMindContentView(data, isAnimation, duratio);
        }
    }

    speechPlay() {
        this.speechModeShowData = []
        if ((new UiUtil()).isPhoneAndPad()) {
            this.leftMarginInSpeechMode = 20
            this.peerSpacingInSpeechMode = 20
            this.horizontalSpacingInSpeechMode = 36
            this.topMarginInSpeechMode = (new UiUtil()).getScreenHeight() * 0.1
        } else {
            this.leftMarginInSpeechMode = 50
            this.peerSpacingInSpeechMode = 40
            this.horizontalSpacingInSpeechMode = 66
            this.topMarginInSpeechMode = (new UiUtil()).getScreenHeight() * 0.1
        }

        if (this.settingData.playBackground.key.length > 0) {
            this.delegate.setMindMapBackgroundImage(this.settingData.playBackground)
        }
        this.delegate.drawingBoardScrollEnabled(false)
    }
    
    backToUpDataPlay() {
        if (this.settingData.playType != PlayType.SpeechMode) {
            return
        }
        if (this.currentData.isEmpty() ||
            this.currentData.type == MindElementType.MAIN_SUBJECT ||
            this.showData.length <= 1) {
            return
        }
        if (this.settingData.playType == PlayType.SpeechMode && this.speechModeShowData.length <= 1) {
            return
        }
        let curenntStatus = this.status
        this.pause()
        this.clearAllMindDataView([])
        this.setUpData()
        if (!this.currentData.isEmpty()) {
            this.addMindContentView(this.currentData)
        }
        if (curenntStatus == MindMapPlayStatus.PAUSE) {
            this.pause()
        } else {
            this.play()
        }
    }
        
    setUpData() {
        if (this.currentData.isEmpty()) {
            return
        }
        if (this.showData.length < 2) {
            return
        }
        if (this.settingData.playType == PlayType.SpeechMode && this.speechModeShowData.length < 2) {
            return
        }
        var newShowData = []
        var newSpeechModeShowData = []
        var newCurrentData = new MindElementData()
        var removeShowDatas = []
        
        if (this.isAddView(this.currentData)) {
            if (this.showData[this.showData.length - 1].id == this.currentData.id) {
                removeShowDatas.push(this.currentData)
                newCurrentData = this.showData[this.showData.length - 2]
                removeShowDatas.push(newCurrentData)
            } else {
                for (let index = 0; index < this.showData.length; index++) {
                    let reversedIndex = this.showData.length - 1 - index //反向遍历
                    let cell = this.showData[reversedIndex]
                    removeShowDatas.push(cell)
                    if (cell.id == this.currentData.id) {
                        if (reversedIndex == 0) {
                            return
                        }
                        newCurrentData = this.showData[reversedIndex - 1]
                        removeShowDatas.push(newCurrentData)
                        break
                    }
                }
            }
        } else {
            newCurrentData = this.showData[this.showData.length - 2]
            removeShowDatas.push(this.showData[this.showData.length - 1])
            removeShowDatas.push(this.showData[this.showData.length - 2])
        }
        
        if (!newCurrentData.isEmpty()) {
            for (let index = 0; index < this.showData.length; index++) {
                var remove = false
                for (let j = 0; j < removeShowDatas.length; j++) {
                    let removeCell = removeShowDatas[j]
                    if (this.showData[index].id == removeCell.id) {
                        remove = true
                        break
                    }
                }
                if (!remove) {
                    newShowData.push(this.showData[index])
                }
            }
            for (let index = 0; index < this.speechModeShowData.length - 1; index++) {
                var remove = false
                for (let j = 0; j < removeShowDatas.length; j++) {
                    let removeCell = removeShowDatas[j]
                    if (this.speechModeShowData[index].id == removeCell.id) {
                        remove = true
                        break
                    }
                }
                if (!remove) {
                    newSpeechModeShowData.push(this.speechModeShowData[index])
                }
            }
            this.showData = newShowData
            this.speechModeShowData = newSpeechModeShowData
            this.currentData = this.getNodeById(newCurrentData.id).value
            this.currentCopyData = this.currentData.copy();
            this.currentCopyData.id = this.currentData.id;
        }
        
    }
    
    speechEnd() {
        if (this.showData.length == 0 || this.showData[0].textContent == null) {
            return
        }
        this.clearAllMindDataView([])
        let data = new MindElementData()
        let speechData = this.convertSpeechModeData(this.showData[0], false)
        
        data.textContent = speechData.textContent.copy()
        data.id = speechData.id
        data.type = speechData.type
        data.copyStyle(speechData)

        if (!Util.isMember()) {
            let secondData = this.showData.length > 1 ? this.showData[1] : new MindElementData()
            if (secondData.isEmpty() || secondData.textContent == null) {
                data.textContent.text = "Play End  © Presented with " + "寻简思维导图"
                data.textContent.textFontSize = this.speechSize.endSize;
                data.alignmentType = MindElementAlignmentType.MIDDLE
                this.convertSize(data, false)
                this.setDataToScreenCenter(data, true, false)
            } else {
                data.textContent.text = "Play End"
                data.textContent.textFontSize = this.speechSize.endTitleSize;
                data.alignmentType = MindElementAlignmentType.MIDDLE
                this.convertSize(data, false)
                this.setDataToScreenCenter(data, true, false)
                
                let copyrightData = MindElementData()
                let speechCopyrightData = this.convertSpeechModeData(secondData, false)
                
                copyrightData.textContent = speechCopyrightData.textContent.copy()
                copyrightData.id = speechCopyrightData.id
                copyrightData.type = speechCopyrightData.type
                copyrightData.copyStyle(speechCopyrightData)
                
                copyrightData.textContent.text = "Presented with " + "App.Name".localized
                copyrightData.textContent.textFontSize = this.speechSize.endSize;
                copyrightData.alignmentType = MindElementAlignmentType.MIDDLE
                this.convertSize(copyrightData, false)
                
                copyrightData.x = data.x + (data.width - copyrightData.width) / 2
                copyrightData.y = data.y + data.height - (data.getTextEdgeInsets().bottom * 0.8)
                
                this.delegate.addMindContentViewIncludeAnimation(copyrightData, false)
                this.addSpeechModeShowData(copyrightData)
            }
        } else {
            data.textContent.textFontSize = this.speechSize.endTitleSize;
            data.textContent.text = "Play End"
            data.alignmentType = MindElementAlignmentType.MIDDLE
            this.convertSize(data, false)
            this.setDataToScreenCenter(data, true, false)
        }
        
    }
    
    getLastShowData(data) {
        var lastData = new MindElementData()
        for (let index = 0; index < this.speechModeShowData.length; index++) {
            let cell = this.speechModeShowData[index]
            if ((cell.type == MindElementType.MAIN_SUBJECT ||
                cell.type == MindElementType.SUBJECT ||
                cell.type == MindElementType.SON_SUBJECT ||
                 cell.type == MindElementType.CONTENT_GENERALIZATION) && data.id != cell.id) {
                lastData = cell
            }
        }
        return lastData
    }
    
    isAddShowDataBySpeech(data) {
        var showata = this.getSpeechModeShowData(data)
        return !showata.isEmpty()
    }

    getSpeechModeShowData(data) {
        return this.getSpeechModeShowDataById(data.id)
    }
    
    getSpeechModeShowDataById(id) {
        for (let index = 0; index < this.speechModeShowData.length; index++) {
            let cell = this.speechModeShowData[index]
            if (id == cell.id) {
                return cell
            }
        }
        return new MindElementData()
    }
    
    addSpeechModeShowData(data) {
        if (data.isEmpty()) {
            return
        }
        for (let index = 0; index < this.speechModeShowData.length; index++) {
            let cell = this.speechModeShowData[index]
            if (cell.id == data.id) {
                this.speechModeShowData.splice(index, 1)
                break
            }
        }
        this.speechModeShowData.push(data)
        
        var list = []
        for (let index = 0; index < this.removeDatas.length; index++) {
            let cell = this.removeDatas[index]
            if (cell.id != data.id) {
                list.push(cell)
            }
        }
        this.removeDatas = list
    }
        
    clearAllMindDataView(exceptList) {
        for (let index = 0; index < this.speechModeShowData.length; index++) {
            let cell = this.speechModeShowData[index]
            var isRemove = true
            for (let j = 0; j < exceptList.length; j++) {
                let exceptCell = exceptList[j]
                if (exceptCell.id == cell.id) {
                    isRemove = false
                    break
                }
            }
            if (isRemove) {
                this.removeViewByMindData(cell)
            }
        }
    }
    
    isRemoveFromUi(data) {
        for (let index = 0; index < this.removeDatas.length; index++) {
            let cell = this.removeDatas[index]
            if (cell.id == data.id) {
                return true
            }
        }
        return false
    }
    
    isDataTop(data) {
        if (data.isEmpty()) {
            return false
        }
        let point = this.getMindMapPoint()
        return Math.abs(data.y - ((point.y + this.topMarginInSpeechMode))) < 10
    }
    
    getMindMapPoint() {
        let point = this.delegate.getMindMapPoint();
        return point;
    }

    isDataInCenter(data) {
        if (data.isEmpty()) {
            return false
        }
        let point = this.getMindMapPoint()
        let x = (point.x) + ((this.getScreenWidth()) - data.width) / 2
        let y = (point.y) + ((this.getScreenHeight()) - data.height) / 2

        if (data.textContent != null) {
            let allTextData = data.copy();
            let originalNode = this.getNodeById(data.id);
            if (originalNode.value.id == allTextData.id &&
                    allTextData.textContent.text != originalNode.value.textContent.text) {
                allTextData.textContent.text = originalNode.value.textContent.text;
                this.convertSize(allTextData, false);
                x = point.x + (this.getScreenWidth() - allTextData.width) / 2
                y = point.y + (this.getScreenHeight() - allTextData.height) / 2
            }
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentData = this.getSpeechModeShowData(parentNode.value);
        if (!parentNode.isEmpty() && !parentData.isEmpty() &&
            this.isNodeShowParentNode(data) && y < point.y + this.topMarginInSpeechMode + this.getUnitSize(parentData).getHeight()) {
            y = point.y + this.topMarginInSpeechMode + this.getUnitSize(parentData).getHeight() + this.topMarginInSpeechMode;
        }

        return Math.abs(data.y - y) < 5 && Math.abs(data.x - x) < 5
    }
    
    isAddDataFollowUp(data, checkText = true) {
        let showData = this.getSpeechModeShowData(data)
        if (showData.isEmpty()) {
            return false
        }
        let isShow = this.isInUiForData(data)
        if (checkText && isShow) {
            if (data.textContent != null && data.textContent.text != showData.textContent.text) {
                return true
            } else if (data.generalizationContent != null && data.generalizationContent.text != showData.generalizationContent.text) {
                return true
            } else {
                return false
            }
        } else {
            return isShow
        }
    }

    isInUiForData(data) {
        let showData = this.getSpeechModeShowData(data)
        if (showData.isEmpty()) {
            return false
        }
        return !this.showData.isEmpty() && !this.isRemoveFromUi(showData)
    }

    getCurrentShowDatas() {
        let list = new Array()
        for (let index = 0; index < this.speechModeShowData.length; index++) {
            let cell = this.speechModeShowData[index]
            if (!this.isRemoveFromUi(cell)) {
                list.push(cell)
            }
        }
        return list
    }

    setPlayTitle(data, position = PlayTitlePosition.LEFT_TOP) {
        var list = new Array()
        
        let parentNode = this.getNodeById(data.parentNodeId)
        if (parentNode.isEmpty()) {
            return list
        }
        let point = this.getMindMapPoint()
        let grandpaNode = this.getNodeById(parentNode.value.parentNodeId)
        let grandpaParentNode = new LineMindTypeNode()
        
        if (!grandpaNode.isEmpty()) {
            grandpaParentNode = this.getNodeById(grandpaNode.value.parentNodeId)
            if (!grandpaParentNode.isEmpty()) {
                let grandpaParentShowData = this.convertSpeechModeData(grandpaParentNode.value, true)
                let grandpaParentTextContent = null
                if (grandpaParentShowData.textContent != null && grandpaParentShowData.textContent.text.length > 0) {
                    grandpaParentTextContent = grandpaParentShowData.textContent
                } else if (grandpaParentShowData.generalizationContent != null && grandpaParentShowData.generalizationContent.text.length > 0) {
                    grandpaParentTextContent = grandpaParentShowData.generalizationContent
                }
                if (grandpaParentTextContent != null) {
                    
                    if (grandpaParentTextContent.text.length > 15) {
                        grandpaParentTextContent.text = grandpaParentTextContent.text.substring(0, 13) + "..."
                    }

                    grandpaParentTextContent.textFontSize = this.speechSize.palyTitleMainSize
                    grandpaParentShowData.imageContent = null
                    grandpaParentShowData.borderColor = this.settingData.playBackground.borderColor
                    grandpaParentShowData.borderWidth = 4
                    grandpaParentShowData.mindElementShape = MindElementShapeType.Underline
                    grandpaParentShowData.type = MindElementType.SON_SUBJECT
                    this.convertSize(grandpaParentShowData, false)
                    let left = 0 - grandpaParentShowData.getPadingLeft(true) - grandpaParentShowData.getTextEdgeInsets().left
                    MindElementCalculation.set(grandpaParentShowData).setDataContentMoveLeft(left, false, false, true);
                    let maxSpace = (new UiUtil()).isPhoneAndPad() ? 25 : 80
                    if (position == PlayTitlePosition.LEFT_TOP) {
                        grandpaParentShowData.x = point.x + this.leftMarginInSpeechMode
                        grandpaParentShowData.y = point.y + Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else if (position == PlayTitlePosition.RIGHT_TOP) {
                        grandpaParentShowData.x = point.x + this.getScreenWidth() - grandpaParentShowData.width - this.leftMarginInSpeechMode
                        grandpaParentShowData.y = point.y + Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else if (position == PlayTitlePosition.LEFT_BOTTOM) {
                        grandpaParentShowData.x = point.x + this.leftMarginInSpeechMode
                        grandpaParentShowData.y = point.y + this.getScreenHeight() - grandpaParentShowData.height - Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else {
                        grandpaParentShowData.x = point.x + this.getScreenWidth() - grandpaParentShowData.width - this.leftMarginInSpeechMode
                        grandpaParentShowData.y = point.y + this.getScreenHeight() - grandpaParentShowData.height - Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    }

                    this.delegate.addMindContentView(grandpaParentShowData)
                    list.push(grandpaParentShowData)
                    this.addSpeechModeShowData(grandpaParentShowData)
                }
                let grandpaShowData = this.convertSpeechModeData(grandpaNode.value, true)
                let textContent = null
                if (grandpaShowData.textContent != null && grandpaShowData.textContent.text.length > 0) {
                    textContent = grandpaShowData.textContent
                } else if (grandpaShowData.generalizationContent != null && grandpaShowData.generalizationContent.text.length > 0) {
                    textContent = grandpaShowData.generalizationContent
                }
                if (textContent != null) {
                    if (textContent.text.length > 20) {
                        textContent.text = textContent.text.substring(0, 18) + "..."
                    }
                    grandpaShowData.imageContent = null
                    textContent.textFontSize = this.speechSize.palyTitlSubjectSize
                    grandpaShowData.type = MindElementType.SON_SUBJECT
                    this.convertSize(grandpaShowData, false)
                    let left = 0 - grandpaShowData.getPadingLeft(true) - grandpaShowData.getTextEdgeInsets().left
                    MindElementCalculation.set(grandpaShowData).setDataContentMoveLeft(left, false, false, true);

                    grandpaShowData.x = grandpaParentShowData.x
                    grandpaShowData.y = grandpaParentShowData.y + grandpaParentShowData.height
                    this.delegate.addMindContentView(grandpaShowData)
                    list.push(grandpaShowData)
                    this.addSpeechModeShowData(grandpaShowData)
                }
            } else {
                let textContent = null
                let grandpaShowData = this.convertSpeechModeData(grandpaNode.value, true)
                if (grandpaShowData.textContent != null && grandpaShowData.textContent.text.length > 0) {
                    textContent = grandpaShowData.textContent
                } else if (grandpaShowData.generalizationContent != null && grandpaShowData.generalizationContent.text.length > 0) {
                    textContent = grandpaShowData.generalizationContent
                }
                if (textContent != null) {
                    if (textContent.text.length > 15) {
                        textContent.text = textContent.text.substring(0, 13) + "..."
                    }
                    grandpaShowData.type = MindElementType.SON_SUBJECT
                    grandpaShowData.imageContent = null
                    textContent.textFontSize = this.speechSize.palyTitleMainSize
                    grandpaShowData.borderColor = this.settingData.playBackground.borderColor
                    grandpaShowData.borderWidth = 4
                    grandpaShowData.mindElementShape = MindElementShapeType.Underline
                    this.convertSize(grandpaShowData, false)
                    let left = 0 - grandpaShowData.getPadingLeft(true) - grandpaShowData.getTextEdgeInsets().left
                    MindElementCalculation.set(grandpaShowData).setDataContentMoveLeft(left, false, false, true);
                    let maxSpace = (new UiUtil()).isPhoneAndPad() ? 25 : 80
                    if (position == PlayTitlePosition.LEFT_TOP) {
                        grandpaShowData.x = point.x + this.leftMarginInSpeechMode
                        grandpaShowData.y = point.y + Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else if (position == PlayTitlePosition.RIGHT_TOP) {
                        grandpaShowData.x = point.x + this.getScreenWidth() - grandpaShowData.width - this.leftMarginInSpeechMode
                        grandpaShowData.y = point.y + Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else if (position == PlayTitlePosition.LEFT_BOTTOM) {
                        grandpaShowData.x = point.x + this.leftMarginInSpeechMode
                        grandpaShowData.y = point.y + this.getScreenHeight() - grandpaShowData.height - Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    } else {
                        grandpaShowData.x = point.x + this.getScreenWidth() - grandpaShowData.width - this.leftMarginInSpeechMode
                        grandpaShowData.y = point.y + this.getScreenHeight() - grandpaShowData.height - Math.max(maxSpace, this.getScreenHeight() * 0.06)
                    }

                    this.delegate.addMindContentView(grandpaShowData)
                    list.push(grandpaShowData)
                    this.addSpeechModeShowData(grandpaShowData)
                }
            }
        }
        return list
    }

    setDataSizeAnimation(data, fontSize, toFontSize, point, toPoint, duration) {
        if (data.isEmpty()) {
            return
        }
        if (duration <= 50) {
            
            return;
        }
        let rate = 50
        let times = Math.ceil(duration / rate)

        let spaceX = (toPoint.x - point.x) / times
        let spaceY = (toPoint.y - point.y) / times
        let moveToX = point.x
        let moveToY = point.y
        let runTime = 0
        let that = this
        let setPointByAnimationInterval = setInterval(function() {
            runTime += 1;
            if (runTime >= times) {
                clearInterval(setPointByAnimationInterval);
                let textFontSize = toFontSize       
                if (data.textContent != null) {
                    data.textContent.textFontSize = textFontSize
                } else if (data.generalizationContent != null) {
                    data.generalizationContent.textFontSize = textFontSize
                }   
                data.x = toPoint.x;  
                data.y = toPoint.y;   
            } else {
                moveToX = moveToX + spaceX
                moveToY = moveToY + spaceY  
                let textFontSize = fontSize + Math.ceil((toFontSize - fontSize) / times * runTime)
                if (data.textContent != null) {
                    data.textContent.textFontSize = textFontSize
                } else if (data.generalizationContent != null) {
                    data.generalizationContent.textFontSize = textFontSize
                }
                data.x = moveToX;  
                data.y = moveToY; 
            }
            that.convertSize(data, false)
            that.delegate.changeMindContentView(data)
        }, rate);
    }

    addMindContentViewBySpeechModePlayOrderPeer(data, isAnimation = true, duration = 100) {
        var showData = this.convertSpeechModeData(data, true)
        let parentNode = this.getNodeById(data.parentNodeId)
        if (parentNode.isEmpty() || data.type == MindElementType.CONTENT_GENERALIZATION) {            
            if (data.type == MindElementType.CONTENT_GENERALIZATION) {
                let addedParentData = this.getSpeechModeShowDataById(data.generalizationContent.targetIds[0])
                if (addedParentData.isEmpty()) {
                    return
                }
                showData.x = addedParentData.x + addedParentData.width + 10
                showData.y = addedParentData.y + (addedParentData.height - showData.height) / 2
                this.delegate.addMindContentViewIncludeAnimation(showData, false)
                this.addSpeechModeShowData(showData)
                return
            }
            this.clearAllMindDataView([])
            this.setDataToScreenCenter(showData, true)
        } else {
            let addedParentData = this.getSpeechModeShowData(parentNode.value)            
            if (addedParentData.isEmpty()) {  
                this.clearAllMindDataView([])                      
                this.setDataToScreenCenter(showData, true)
            } else {
                if (parentNode.children.length == 1) {
                    if (!this.isRemoveFromUi(parentNode.value) && !this.isInUiForData(data)) {
                        this.clearAllMindDataView([])
                    }
                    showData = this.addNodeAndMoveParentNodeToMove(data, showData, addedParentData, true, isAnimation)
                } else {
                    let currentShowDatas = this.getCurrentShowDatas()
                    if (currentShowDatas.length > 0) {
                        let futureShowList = [parentNode.value] 
                        for (let index = 0; index < parentNode.children.length; index++) {
                            let cell = parentNode.children[index]
                            futureShowList.push(cell.value)
                        }
                        let currentAddData = new MindElementData()
                        let isAddingDataText = true
                        for (let index = 0; index < futureShowList.length; index++) {
                            let cell = futureShowList[index]
                            let hit = false
                            for (let j = 0; j < currentShowDatas.length; j++) {
                                if (currentShowDatas[j].id == cell.id) {
                                    hit = true
                                    if (cell.id == showData.id) {
                                        currentAddData = currentShowDatas[j]
                                    }
                                    break
                                }
                            }
                            if (!hit) {
                                isAddingDataText = false
                                break
                            }
                        }
                        if (isAddingDataText && !currentAddData.isEmpty()) {
                            showData.x = currentAddData.x
                            showData.y = currentAddData.y
                            this.delegate.addMindContentViewIncludeAnimation(showData, false)
                            this.addSpeechModeShowData(showData)
                            return
                        }
                    }                    
                    let addedParentDataOriginalFontSize = 0
                    let addedParentDataFontSize = 0
                    let titleDatas = this.setPlayTitle(data, PlayTitlePosition.LEFT_TOP)
                    if (addedParentData.type == MindElementType.MAIN_SUBJECT) {
                        if (addedParentData.textContent != null) {
                            addedParentDataOriginalFontSize = addedParentData.textContent.textFontSize
                            addedParentData.textContent.textFontSize = this.speechSize.topMainSize
                            addedParentDataFontSize = addedParentData.textContent.textFontSize
                        } else if (addedParentData.generalizationContent != null) {
                            addedParentDataOriginalFontSize = addedParentData.generalizationContent.textFontSize
                            addedParentData.generalizationContent.textFontSize = this.speechSize.topMainSize
                            addedParentDataFontSize = addedParentData.generalizationContent.textFontSize
                        }
                    } else {
                        if (addedParentData.textContent != null) {
                            addedParentDataOriginalFontSize = addedParentData.textContent.textFontSize
                            addedParentData.textContent.textFontSize = this.speechSize.topSize
                            addedParentDataFontSize = addedParentData.textContent.textFontSize
                        } else if (addedParentData.generalizationContent != null) {
                            addedParentDataOriginalFontSize = addedParentData.generalizationContent.textFontSize
                            addedParentData.generalizationContent.textFontSize = this.speechSize.topSize
                            addedParentDataFontSize = addedParentData.generalizationContent.textFontSize
                        }
                    }                            
                    this.convertSize(addedParentData, false)
                    let point = this.getMindMapPoint()
                    let allWidth = 0
                    let allHeight = 0
                    let childrenMap = new HashMap()
                    let dataPoint = 0
                    let topData = new MindElementData()
                    let bottomData = new MindElementData()
                    for (let index = 0; index < parentNode.children.length; index++) {
                        let cell = parentNode.children[index]
                        let added = new MindElementData()
                        if (cell.value.id == showData.id) {
                            added = this.convertSpeechModeData(cell.value, false)
                            dataPoint = index
                        } else {
                            added = this.getSpeechModeShowData(cell.value)
                        }
                        if (added.isEmpty()) {
                            added = this.convertSpeechModeData(cell.value, false)
                        }
                        allWidth = Math.max(allWidth, added.width)
                        allHeight = allHeight + added.height
                        if (index == 0) {
                            topData = added
                        }
                        childrenMap.put(cell.value.id, added)
                        bottomData = added
                    }
                    let verticalSpace = this.peerSpacingInSpeechMode
                    let maxHeight = this.getScreenHeight() * 0.85
                    if ((allHeight + verticalSpace * (parentNode.children.length - 1)) > maxHeight) {
                        verticalSpace = 20
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    } else if (allHeight < maxHeight * 0.5) {
                        verticalSpace = (maxHeight * 0.4) / (parentNode.children.length - 1)
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    } else if (allHeight < maxHeight * 0.6) {
                        verticalSpace = (maxHeight * 0.3) / (parentNode.children.length - 1)
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    } else if (allHeight < maxHeight * 0.7) {
                        verticalSpace = (maxHeight * 0.2) / (parentNode.children.length - 1)
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    } else if (allHeight < maxHeight * 0.8) {
                        verticalSpace = (maxHeight * 0.15) / (parentNode.children.length - 1)                        
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    } else {
                        verticalSpace = 20
                        allHeight = allHeight + verticalSpace * (parentNode.children.length - 1)
                    }
                    let parentDataX = point.x + (this.getScreenWidth() - addedParentData.width - allWidth - addedParentData.getTextEdgeInsets().left
                                    - Config.BracketsRightSpacee * 2 - Config.BracketsNodeHorizontalSpacee * 2) / 2;
                    if (parentDataX - point.x > this.getScreenWidth() * 0.4) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.2
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.35) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.15
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.3) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.1
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.25) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.08
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.20) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.06
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.15) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.05
                    } else if (parentDataX - point.x > this.getScreenWidth() * 0.10) {
                        parentDataX = parentDataX - this.getScreenWidth() * 0.04
                    }
                    var runningAnimationTimes = 0
                    let parentDataY = point.y + (this.getScreenHeight() - addedParentData.height) / 2
                    if (Math.abs(addedParentData.x - parentDataX) > 5 || 
                        Math.abs(addedParentData.y - parentDataY) > 5) {
                        let point = new Point(addedParentData.x, addedParentData.y)
                        let toPoint = new Point(parentDataX, parentDataY)
                        addedParentData.x = parentDataX
                        addedParentData.y = parentDataY
                        if (!this.quickRelease && this.isInUiForData(addedParentData)) {
                            addedParentDataOriginalFontSize
                            this.setDataSizeAnimation(addedParentData, addedParentDataOriginalFontSize, addedParentDataFontSize, point, toPoint, 400)
                            // setTimeout(() => {
                            //     this.delegate.changeMindContentViewPointAnimation(addedParentData, point, toPoint, 200)
                            // }, 300);
                            // this.delegate.changeMindContentViewPointAnimation(addedParentData, point, toPoint, 200)
                            runningAnimationTimes = 300
                        } else {
                            this.delegate.addMindContentViewIncludeAnimation(addedParentData, false)
                        }
                    } else {
                        this.delegate.addMindContentViewIncludeAnimation(addedParentData, false)
                    }
                    
                    this.addSpeechModeShowData(addedParentData)
                    let top = (addedParentData.y + addedParentData.height/2) - (allHeight - topData.height / 2 - bottomData.height/2) / 2 - topData.height / 2
                    for (let index = 0; index < parentNode.children.length; index++) {
                        let cell = parentNode.children[index]
                        let added = childrenMap.get(cell.value.id)
                        if (index > dataPoint) {
                            if (added.textContent != null) {
                                added.textContent.textColor = this.settingData.playBackground.darknessTextColor
                            } else if (added.generalizationContent != null) {
                                added.generalizationContent.textColor = this.settingData.playBackground.darknessTextColor
                            }
                        }
                        if (new UiUtil().isPhoneAndPad()) {
                            added.x = parentDataX + addedParentData.width + Config.BracketsRightSpacee + Config.BracketsNodeHorizontalSpacee
                        } else {
                            added.x = parentDataX + addedParentData.width + Config.BracketsRightSpacee * 2 + Config.BracketsNodeHorizontalSpacee * 2
                        }
                        
                        added.y = top
                        top += (added.height + verticalSpace);
                        if (added.id == showData.id) {
                            showData.x = added.x
                            showData.y = added.y
                        }
                    }
                    if (dataPoint == 0) {
                        let topLine = this.getLineForSpeechBracketsRight(topData)
                        let bottomLine = this.getLineForSpeechBracketsRight(bottomData)
                        let isAddLine = !topLine.isEmpty() && !this.isInUiForData(topLine)
                        if (!this.quickRelease && runningAnimationTimes > 0) {
                            setTimeout(() => {
                                if (!bottomLine.isEmpty() && !this.isInUiForData(bottomLine)) {
                                    this.delegate.addMindContentViewIncludeAnimation(bottomLine, true, 600)
                                    this.addSpeechModeShowData(bottomLine)
                                }
                                if (!topLine.isEmpty() && !this.isInUiForData(topLine)) {
                                    this.delegate.addMindContentViewIncludeAnimation(topLine, true, 600)
                                    this.addSpeechModeShowData(topLine)
                                }
                            }, runningAnimationTimes + 100);
                        } else {
                            if (!bottomLine.isEmpty() && !this.isInUiForData(bottomLine)) {
                                this.delegate.addMindContentViewIncludeAnimation(bottomLine, !this.quickRelease, 600)
                                this.addSpeechModeShowData(bottomLine)
                            }
                            if (!topLine.isEmpty() && !this.isInUiForData(topLine)) {
                                this.delegate.addMindContentViewIncludeAnimation(topLine, !this.quickRelease, 600)                                
                                this.addSpeechModeShowData(topLine)
                            }
                        }
                        
                        if (isAddLine && !this.quickRelease) {
                            setTimeout(() => {
                                for (let index = 0; index < parentNode.children.length; index++) {
                                    let cell = parentNode.children[index]
                                    let added = childrenMap.get(cell.value.id)                            
                                    if (added.id == showData.id) {
                                        this.delegate.addMindContentViewIncludeAnimation(showData, false)
                                        this.addSpeechModeShowData(showData)
                                    } else {
                                        this.delegate.addMindContentViewIncludeAnimation(added, false)
                                        this.addSpeechModeShowData(added)
                                    }
                                }
                            }, runningAnimationTimes + 1200);
                        } else {
                            for (let index = 0; index < parentNode.children.length; index++) {
                                let cell = parentNode.children[index]
                                let added = childrenMap.get(cell.value.id)                            
                                if (added.id == showData.id) {
                                    this.delegate.addMindContentViewIncludeAnimation(showData, false)
                                    this.addSpeechModeShowData(showData)
                                } else {
                                    this.delegate.addMindContentViewIncludeAnimation(added, false)
                                    this.addSpeechModeShowData(added)
                                }
                            }
                        }
                        var list = [parentNode.value, topLine, bottomLine]
                        for (let index = 0; index < parentNode.children.length; index++) {
                            let cell = parentNode.children[index]
                            list.push(cell.value)
                        }
                        for (let index = 0; index < titleDatas.length; index++) {
                            let cell = titleDatas[index]
                            list.push(cell)
                        }
                        
                        this.clearAllMindDataView(list)
                    } else {
                        for (let index = 0; index < parentNode.children.length; index++) {
                            let cell = parentNode.children[index]
                            let added = childrenMap.get(cell.value.id)                            
                            if (added.id == showData.id) {
                                this.delegate.addMindContentViewIncludeAnimation(showData, false)
                                this.addSpeechModeShowData(showData)
                            } else {
                                this.delegate.addMindContentViewIncludeAnimation(added, false)
                                this.addSpeechModeShowData(added)
                            }
                        }
                    }
                }
            }
        }
    }

    addMindContentViewBySpeechMode(data, isAnimation = true, duration = 100) {
        var showData = this.convertSpeechModeData(data, true)
        if (!showData.isEmpty()) {
            if (this.PlayOrderPeer) {
                this.addMindContentViewBySpeechModePlayOrderPeer(data, isAnimation, duration)
            } else {
                let lastData = this.getLastShowData(data)
                if (!lastData.isEmpty()) {
                    if (this.isLastDataParentNode(showData)) {
                        showData = this.addNodeAndMoveParentNodeToMove(data, showData, lastData, true, isAnimation)
                    } else if (lastData.parentNodeId == showData.parentNodeId) {
                        this.setBrotherToUi(showData, lastData, true)
                    } else {
                        this.setDataToScreenCenter(showData, true)
                    }
                } else {
                    this.setDataToScreenCenter(showData, true)
                }
                this.addSpeechModeShowData(showData)
            }
        }
    }
    
    changeMindContentViewBySpeechMode(data, isAnimation = true) {
        var showData = this.convertSpeechModeData(data, true)
        if (!showData.isEmpty()) {
            if (this.PlayOrderPeer) {
                this.addMindContentViewBySpeechModePlayOrderPeer(data, isAnimation, 100)
            } else {
                let lastData = this.getLastShowData(data)
                if (!lastData.isEmpty()) {
                    if (this.isLastDataParentNode(showData)) {
                        showData = this.addNodeAndMoveParentNodeToMove(data, showData, lastData, false, isAnimation)
                    } else if (lastData.parentNodeId == showData.parentNodeId) {
                        this.setBrotherToUi(showData, lastData, false)
                    } else {
                        this.setDataToScreenCenter(showData, false)
                    }
                } else {
                    this.setDataToScreenCenter(showData, false)
                }
                this.addSpeechModeShowData(showData)
            }
            
            
        }
    }
    
    addNodeAndMoveParentNodeToMove(data, showData, lastData, isAdd, isAnimation = true) {
        var showData = this.convertSize(showData, true)
        if (this.isAddView(showData)) {
            let cacheShowData =  this.getSpeechModeShowDataById(showData.id)
            if (this.isRemoveFromUi(showData) || (cacheShowData.isEmpty() || !this.isDataInCenter(cacheShowData))) {
                showData = this.convertSpeechModeData(data, true)
                let x = lastData.x + (this.horizontalSpacingInSpeechMode)
                if (lastData.type == MindElementType.MAIN_SUBJECT) {
                    x += lastData.getTextEdgeInsets().left / 2
                }
                let y = lastData.y + lastData.height + (this.peerSpacingInSpeechMode)
                if (!this.isAddShowDataBySpeech(showData) || this.isRemoveFromUi(showData) || 
                    Math.abs(showData.x - x) > 10 || Math.abs(showData.y - y) > 10) {
                    showData.x = x
                    showData.y = y
                }
                if (isAdd) {
                    if (this.quickRelease) {
                        this.delegate.addMindContentView(showData, false)
                    } else {
                        this.delegate.addMindContentViewIncludeAnimation(showData, false)
                    }
                } else {
                    this.delegate.changeMindContentView(showData)
                }
                
                if (this.isNodeShowParentNode(showData)) {
                    let line = this.getLineForSpeech(showData)
                    if (!line.isEmpty() && !this.isInUiForData(line)) {
                        this.delegate.changeMindContentView(line)
                        this.addSpeechModeShowData(line)
                    }
                }
            } else {
                this.setDataToScreenCenter(showData, false)
            }
            let titleDatas = this.setPlayTitle(data, PlayTitlePosition.RIGHT_BOTTOM)
        } else {
            this.setTopData(lastData)
            
            showData = this.convertSize(showData, true)
            // showData.x = lastData.x + (this.horizontalSpacingInSpeechMode)
            // showData.y = lastData.y + lastData.height + (this.peerSpacingInSpeechMode)
            let x = lastData.x + (this.horizontalSpacingInSpeechMode)
            if (lastData.type == MindElementType.MAIN_SUBJECT) {
                x += lastData.getTextEdgeInsets().left / 2
            }
            let y = lastData.y + lastData.height + (this.peerSpacingInSpeechMode)
            if (!this.isAddShowDataBySpeech(showData) || this.isRemoveFromUi(showData) || 
                Math.abs(showData.x - x) > 10 || Math.abs(showData.y - y) > 10) {
                showData.x = x
                showData.y = y
            }  
            
            let line = this.getLineForSpeech(showData)
            if (line.isEmpty()) {
                this.clearAllMindDataView([showData, lastData])
            } else {
                this.clearAllMindDataView([showData, lastData, line])
            }
            let titleDatas = this.setPlayTitle(data, PlayTitlePosition.RIGHT_BOTTOM)
            if (isAnimation) {
                this.delegate.changeMindContentView(lastData, true, 200)
                if (!line.isEmpty()) {
                    if (this.isRemoveFromUi(line) ||  this.getSpeechModeShowDataById(line.id).isEmpty()) {
                        if (this.quickRelease) {
                            this.delegate.addMindContentView(line)
                            this.addSpeechModeShowData(line)
                        } else {
                            setTimeout(() => {
                                this.delegate.addMindContentViewIncludeAnimation(line, true, 200)
                                this.addSpeechModeShowData(line)
                            }, 1000);
                        }
                    } else {
                        this.delegate.changeMindContentView(line)
                        this.addSpeechModeShowData(line)
                    }
                }
                setTimeout(() => {
                    if (isAdd || this.isRemoveFromUi(showData) || this.getSpeechModeShowData(showData.id).isEmpty()) {
                        if (this.quickRelease) {
                            this.delegate.addMindContentView(showData)
                        } else {
                            this.delegate.addMindContentViewIncludeAnimation(showData, false)
                        }                        
                    } else {
                        this.delegate.changeMindContentView(showData)
                    }
                    this.addSpeechModeShowData(showData)
                }, 2000);
            } else {
                this.delegate.changeMindContentView(lastData)
                if (isAdd || this.isRemoveFromUi(showData) || this.getSpeechModeShowData(showData.id).isEmpty()) {
                    if (this.quickRelease) {
                        this.delegate.addMindContentView(showData)
                    } else {
                        this.delegate.addMindContentViewIncludeAnimation(showData, false)
                    }
                } else {
                    this.delegate.changeMindContentView(showData)
                }
                if (!line.isEmpty() && !this.isInUiForData(line)) {
                    if (this.isRemoveFromUi(line) || this.getSpeechModeShowDataById(line.id).isEmpty()) {
                        if (this.quickRelease) {
                            this.delegate.addMindContentView(line, false)
                        } else {
                            this.delegate.addMindContentViewIncludeAnimation(line, false)
                        }
                    } else {
                        this.delegate.changeMindContentView(line)
                    }
                    this.addSpeechModeShowData(line)
                }
            }
        }
        return showData
    }
    
    setBrotherToUi(showData, lastData, isAdd) {
        if (lastData.parentNodeId == showData.parentNodeId) {
            if (this.isRemoveFromUi(lastData)) {
                this.delegate.addMindContentViewIncludeAnimation(lastData, false)
                this.addSpeechModeShowData(lastData)
            }
            if (this.isNodeShowParentNode(showData)) {
                let parentData = this.getSpeechModeShowDataById(showData.parentNodeId)
                if (this.isRemoveFromUi(parentData)) {
                    this.setTopData(parentData)
                    this.delegate.addMindContentViewIncludeAnimation(parentData, false)
                }
                let line = this.getLineForSpeech(showData)
                if (!line.isEmpty() && !this.isInUiForData(line) && this.isAdd) {
                    this.delegate.changeMindContentView(line)
                    this.addSpeechModeShowData(line)
                }
            }
            
            let parentNode = this.getNodeById(lastData.parentNodeId)
            if (!parentNode.isEmpty() && parentNode.children.length != 0) {
                let parentData = this.getSpeechModeShowDataById(showData.parentNodeId)
                let point = this.getMindMapPoint()
                if (!parentData.isEmpty() &&
                    (Math.abs(lastData.x - ((point.x) + ((this.getScreenWidth()) - lastData.width) / 2)) < 5 &&
                    Math.abs(lastData.y - ((point.y) + ((this.getScreenHeight()) - lastData.height) / 2)) < 5)) { //last in center
                    lastData.alignmentType = MindElementAlignmentType.LEFT
                    this.convertSize(lastData, true)
                    lastData.x = parentData.x + (this.horizontalSpacingInSpeechMode)
                    lastData.y = parentData.y + parentData.height + (this.peerSpacingInSpeechMode)
                    
                    let line = this.getLineForSpeech(lastData)
                    if (!line.isEmpty() && !this.isInUiForData(line)) {
                        this.delegate.changeMindContentView(line)
                        this.addSpeechModeShowData(line)
                    }
                    this.delegate.changeMindContentView(lastData)
                }
            }
            let x = lastData.x
            let y = lastData.y + lastData.height + (this.peerSpacingInSpeechMode)
            if (!this.isAddShowDataBySpeech(showData) || this.isRemoveFromUi(showData) || 
                Math.abs(showData.x - x) > 10 || Math.abs(showData.y - y) > 10) {
                showData.x = x
                showData.y = y
            }            
            
            if (this.isNodeShowParentNode(showData)) {
                let line = this.getLineForSpeech(showData)
                if (!line.isEmpty() && !this.isInUiForData(line)) {
                    this.delegate.changeMindContentView(line)
                    this.addSpeechModeShowData(line)
                }
            }
            
            if (isAdd) {
                this.delegate.addMindContentViewIncludeAnimation(showData, false);
            } else {
                this.delegate.changeMindContentView(showData);
            }
            let titleDatas = this.setPlayTitle(showData, PlayTitlePosition.RIGHT_BOTTOM)
        }
    }
    
    setDataToScreenCenter(data, isAdd, checkText = true) {
        let point = this.getMindMapPoint()
        if (checkText) {
            data.alignmentType = MindElementAlignmentType.LEFT;
            this.convertSize(data, false)
        }
        
        let x = (point.x) + (this.getScreenWidth() - data.width) / 2
        let y = (point.y) + (this.getScreenHeight() - data.height) / 2

        if (checkText && data.textContent != null) {
            let allTextData = data.copy();
            let originalNode = this.getNodeById(data.id);
            if (originalNode.value.id == allTextData.id &&
                allTextData.textContent.text != originalNode.value.textContent.text) {
                allTextData.textContent.text = originalNode.value.textContent.text;
                this.convertSize(allTextData, false);
                x = (point.x) + (this.getScreenWidth() - allTextData.width) / 2;
                y = (point.y) + (this.getScreenHeight() - allTextData.height) / 2;
            } else {
                x = (point.x) + (this.getScreenWidth() - data.width) / 2;
                y = (point.y) + (this.getScreenHeight() - data.height) / 2;
            }
        } else {
            x = (point.x) + (this.getScreenWidth() - data.width) / 2;
            y = (point.y) + (this.getScreenHeight() - data.height) / 2;
        }
        let parentNode = this.getNodeById(data.parentNodeId);
        let parentData = this.getSpeechModeShowData(parentNode.value);
        if (!parentNode.isEmpty() && !parentData.isEmpty() &&
                this.isNodeShowParentNode(data) && y < point.y + this.topMarginInSpeechMode + this.getUnitSize(parentData).getHeight()) {
            y = point.y + this.topMarginInSpeechMode + this.getUnitSize(parentData).getHeight() + this.topMarginInSpeechMode;
        }
        data.x = x;
        data.y = y;
        if (isAdd) {
            if (this.quickRelease) {
                this.delegate.addMindContentView(data, false);
            } else {
                this.delegate.addMindContentViewIncludeAnimation(data, false);
            }
        } else {
            this.delegate.changeMindContentView(data);
        }
        if (this.isNodeShowParentNode(data)) {
            let parentData = this.getSpeechModeShowData(parentNode.value)
            if (!parentData.isEmpty() && (this.isRemoveFromUi(parentNode.value) || !this.isDataTop(parentData))) {
                this.setTopData(parentData)
                this.delegate.changeMindContentView(parentData);
                this.addSpeechModeShowData(parentData)
            }
            this.clearAllMindDataView([data, parentNode.value])
        } else {
            this.clearAllMindDataView([data])
        }
        this.addSpeechModeShowData(data)
        if (this.isNodeShowParentNode(data)) {
            let line = this.getLineForSpeech(data)
            if (!line.isEmpty() && !this.isAddShowDataBySpeech(line)) {
//                this.delegate.changeMindContentView(line)
                this.removeViewByMindData(line)
            }
        }
        let titleDatas = this.setPlayTitle(data, PlayTitlePosition.RIGHT_BOTTOM)
    }
    
    convertSize(data, isParent = false) {
        let maxWidth = this.getMaxWidth(data, isParent)
        let text = ""
        if (data.textContent != null) {
            text = data.textContent.text
        } else if (data.generalizationContent != null) {
            text = data.generalizationContent.text
        }
        if (text == "" || text == " ") {
            let originalData = this.getNodeById(data.id).value
            if (originalData.textContent != null) {
                if (originalData.textContent.text != text) {
                    data.textContent.text = "寻"
                    MindElementCalculation.set(data).caluleTextMaxWidth(maxWidth).calcule(false);
                } else {
                    MindElementCalculation.set(data).caluleTextMaxWidth(maxWidth).calcule(false);
                }
            } else if (originalData.generalizationContent != null) {
                if (originalData.generalizationContent.text != text) {
                    data.generalizationContent.text = "寻"
                    MindElementCalculation.set(data).caluleTextMaxWidth(maxWidth).calcule(false);
                } else {
                    MindElementCalculation.set(data).caluleTextMaxWidth(maxWidth).calcule(false);
                }
            }
            if (data.textContent != null) {
                data.textContent.text = text
            } else if (data.generalizationContent != null) {
                data.generalizationContent.text = text
            }
        } else {
            MindElementCalculation.set(data).caluleTextMaxWidth(maxWidth).calcule(false);
        }
        let limitHeight = (this.getScreenHeight() - Math.min(120.0, this.getScreenHeight() * 0.2))
        if (data.height > limitHeight) {
            var fontSize = 0
            if (data.textContent != null) {
                fontSize = data.textContent.textFontSize
            }
            if (data.generalizationContent != null) {
                fontSize = data.generalizationContent.textFontSize
            }
            if (fontSize > 14) {
                if (data.textContent != null) {
                    data.textContent.textFontSize = fontSize - 1
                }
                if (data.generalizationContent != null) {
                    data.generalizationContent.textFontSize = fontSize - 1
                }
                this.convertSize(data, isParent)
                return data
            }
        }
        if (data.isOrderContent()) {
            let orderContentX = data.getPadingLeft()
            let top = data.getPadingTop(true)
            let bottom = data.getPadingBottom()
            let textContent = data.textContent
            if (textContent == null) {
                textContent = data.generalizationContent
            }
            if (textContent != null) {
                let size = MindElementCalculation.publicCaluleText(data.orderContent.order, 
                    textContent.textFontSize, 
                    textContent.textBold,
                    textContent.textItalics,
                    textContent.fontFamily);

                data.orderContent.width = Math.ceil(size.width) + 4  
                data.orderContent.height = size.getHeight()
                
                MindElementCalculation.set(data).setDataContentMoveLeft(data.orderContent.width, false, false);
                data.orderContent.y = top + data.getTextEdgeInsets().top + (bottom - top - size.getHeight()) / 2
                data.orderContent.x = orderContentX
            }
        }
        return data
    }
    
    convertSpeechModeData(data, isParent = false) {
        if (data.type == MindElementType.MAIN_SUBJECT) {
            let showData = data.copy()
            showData.backgroundUrl = ""
            showData.backgroundColor = Colors.clear
            showData.borderColor = Colors.clear
            showData.textContent.textColor = this.settingData.playBackground.textColor
            showData.textContent.styleCells = new Array();
            showData.mindElementShape = MindElementShapeType.Corner
            showData.customWidth = 0;
            showData.textContent.textFontSize = this.speechSize.mainSize
            showData.textContent.textBold = true
            showData.textContent.textShadow = -10000;
            showData.textContent.bgColor = -10000;
            showData.backgroundFullType = MindElementFullType.FULL;
            showData.alignmentType = MindElementAlignmentType.LEFT
            showData.hiddenNumber = 0;
            this.convertSize(showData, false)
            return showData
        } else if (data.type == MindElementType.SUBJECT) {
            let showData = data.copy()
            showData.backgroundUrl = ""
            showData.backgroundColor = Colors.clear
            showData.borderColor = Colors.clear
            showData.textContent.textColor = this.settingData.playBackground.textColor
            showData.textContent.styleCells = new Array();
            showData.mindElementShape = MindElementShapeType.Corner
            showData.customWidth = 0;
            showData.textContent.textFontSize = this.speechSize.subjectSize
            showData.textContent.textBold = true
            showData.textContent.textShadow = -10000;
            showData.textContent.bgColor = -10000;
            showData.backgroundFullType = MindElementFullType.FULL;
            showData.alignmentType = MindElementAlignmentType.LEFT
            showData.hiddenNumber = 0;
            this.convertSize(showData, isParent)
            return showData
        } else if (data.type == MindElementType.SON_SUBJECT) {
            let showData = data.copy()
            showData.backgroundUrl = ""
            showData.backgroundColor = Colors.clear
            showData.borderColor = Colors.clear
            showData.textContent.textColor = this.settingData.playBackground.textColor
            showData.textContent.styleCells = new Array();
            showData.mindElementShape = MindElementShapeType.Corner
            showData.customWidth = 0;
            showData.textContent.textFontSize = this.speechSize.sonSubjectSize
            showData.textContent.textBold = false
            showData.textContent.textShadow = -10000;
            showData.textContent.bgColor = -10000;
            showData.backgroundFullType = MindElementFullType.FULL;
            showData.alignmentType = MindElementAlignmentType.LEFT
            showData.hiddenNumber = 0;
            this.convertSize(showData, isParent)
            return showData
        } else if (data.type == MindElementType.CONTENT_GENERALIZATION) {
            let showData = data.copy()
            showData.backgroundUrl = ""
            showData.backgroundColor = Colors.clear
            showData.borderColor = Colors.clear
            showData.generalizationContent.textColor = this.settingData.playBackground.textColor
            showData.generalizationContent.styleCells = new Array();
            showData.mindElementShape = MindElementShapeType.Corner
            showData.customWidth = 0;
            showData.generalizationContent.textFontSize = this.speechSize.subjectSize
            showData.generalizationContent.textBold = true
            showData.generalizationContent.textShadow = -10000;
            showData.generalizationContent.bgColor = -10000;
            showData.backgroundFullType = MindElementFullType.FULL;
            showData.alignmentType = MindElementAlignmentType.LEFT
            showData.hiddenNumber = 0;
            this.convertSize(showData, isParent)
            return showData
        }
        return new MindElementData()
    }
    
    setTopData(data) {
        if (data.isEmpty()) {
            return
        }
        
        data.textContent.textBold = true
        data.alignmentType = MindElementAlignmentType.LEFT
        if (data.type != MindElementType.MAIN_SUBJECT) {
            if (data.textContent != null) {
                data.textContent.textFontSize = this.speechSize.topSize
            }
            if (data.generalizationContent != null) {
                data.generalizationContent.textFontSize = this.speechSize.topSize
            }
        } else {
            if (data.textContent != null) {
                data.textContent.textFontSize = this.speechSize.topMainSize
            }
            if (data.generalizationContent != null) {
                data.generalizationContent.textFontSize = this.speechSize.topMainSize
            }
        }
        if (data.textContent != null) {
            let unitSize = this.getUnitSize(data)
            var limitCount = 0
            if (unitSize.width > 0) {
                limitCount = Math.floor(this.getMaxWidth(data) / unitSize.width)
                if (limitCount >= data.textContent.text.length) {
                    limitCount = 0
                } else if (limitCount > 6) {
                    limitCount = limitCount - 1
                }
            } else {
                if (data.textContent.text.length > 20) {
                    limitCount = 19
                }
            }
            if (limitCount > 0) {
                data.textContent.text = new Strings().lineFeed((data.textContent.text.substring(0, limitCount) + "...").trim())
            }
        }
        this.convertSize(data, false)
        let point = this.getMindMapPoint()
        // data.x = (point.x + this.leftMarginInSpeechMode)
        data.x = point.x + (this.getScreenWidth() - data.width) / 2
        data.y = (point.y + this.topMarginInSpeechMode)
    }
    
    getUnitSize(data) {
        var value = ""
        var fontSize = 15
        var textBold = false
        if (data.textContent != null) {
            value = data.textContent.text
            fontSize = data.textContent.textFontSize
            textBold = data.textContent.textBold
        } else if (data.generalizationContent != null) {
            value = data.generalizationContent.text
            fontSize = data.generalizationContent.textFontSize
            textBold = data.generalizationContent.textBold
        }

        if (value == null || value.length == 0) {
            let original = this.getNodeById(data.id)
            if (!original.isEmpty()) {
                if (original.value.textContent != null) {
                    value = original.value.textContent.text
                } else if (original.value.generalizationContent != null) {
                    value = original.value.generalizationContent.text
                }
            }
        }
        if (value == null || value.length == 0 || value == " ") {
            value = "寻简";
        }
        var calculatValue = ""
        for (let index = 0; index < value.length - 1; index++) {
            if (calculatValue.length >= 3) {
                break
            }
            let item = value.substring(index, index + 1)
            if (item != " ") {
                calculatValue = calculatValue + item
            }
        }
        if (calculatValue == null || calculatValue.length == 0 || calculatValue == " ") {
            calculatValue = "寻简";
        }
        if (calculatValue.length == 0) {
            return new Size(0, 0)
        }
        
        let size = MindElementCalculation.caluleText(calculatValue, fontSize, textBold)
        return new Size(size.width / (calculatValue.length), size.height)
    }
    
    getMaxWidth(data, isParent = false) {
        var maxWidth = this.getScreenWidth() - 60
        var withoutWidth = 0
        if (data.isContainImageContent()) {
            if (data.imageContent.layoutType == NodeLayoutType.LAYOUT_LEFT || data.imageContent.layoutType == NodeLayoutType.LAYOUT_RIGHT) {
                if (data.imageContent.width > (this.getScreenWidth()/2)) {
                    data.imageContent.layoutType = NodeLayoutType.LAYOUT_TOP
                    if (data.imageContent.width > (this.getScreenWidth() - data.getTextEdgeInsets().left - data.getTextEdgeInsets().right - this.leftMarginInSpeechMode * 2)) {
                        let imageWidth = (this.getScreenWidth() - data.getTextEdgeInsets().left - data.getTextEdgeInsets().right - this.leftMarginInSpeechMode * 2)
                        data.imageContent.height = imageWidth / (data.imageContent.width / data.imageContent.height)
                        data.imageContent.width = imageWidth
                    }
                } else {
                    withoutWidth += (data.imageContent.width)
                }
            } else {
                if (data.imageContent.width > (this.getScreenWidth() - data.getTextEdgeInsets().left - data.getTextEdgeInsets().right - this.leftMarginInSpeechMode * 2)) {
                    let imageWidth = (this.getScreenWidth() - data.getTextEdgeInsets().left - data.getTextEdgeInsets().right - this.leftMarginInSpeechMode * 2)
                    data.imageContent.height = imageWidth / (data.imageContent.width / data.imageContent.height)
                    data.imageContent.width = imageWidth
                }
            }
        }
        if (data.iconElementContents != null && data.iconElementContents.length != 0 &&
            (data.iconElementContents[0].layoutType == NodeLayoutType.LAYOUT_LEFT || data.iconElementContents[0].layoutType == NodeLayoutType.LAYOUT_RIGHT)) {
            var fontSize = 15
            var textBold = false
            if (data.textContent != null) {
                fontSize = data.textContent.textFontSize
                textBold = data.textContent.textBold
            } else if (data.generalizationContent != null) {
                fontSize = data.generalizationContent.textFontSize
                textBold = data.generalizationContent.textBold
            }
            
            let size = MindElementCalculation.caluleText("A", fontSize, textBold)
            let iconSize = size.height
            for (let index = 0; index < data.iconElementContents.length; index++) {
                let cell = data.iconElementContents[index]
                cell.width = iconSize
                cell.height = iconSize
                withoutWidth += iconSize
            }
        }
        if (data.isOrderContent() && data.textContent != null) {
            // let contentLeft = data.getPadingLeft()
            let size = MindElementCalculation.caluleText(data.orderContent.order, data.textContent.textFontSize,
                                                               data.textContent.textBold)

            let width = Math.ceil((size.width)) + 2
            // MindElementCalculation.set(data).setDataContentMoveLeft(width, false, false);
            
            let orderContentWidth = width;
            // data.orderContent.width = width;
            // data.orderContent.height = (size.height)
            // data.orderContent.x = contentLeft
            // let top = data.getPadingTop(true)
            // let bottom = data.getPadingBottom()
            // data.orderContent.y = top + (data.getTextEdgeInsets().top) + (bottom - top - data.orderContent.height) / 2;
            
            withoutWidth += orderContentWidth
        }
        if (data.type == MindElementType.MAIN_SUBJECT) {
            let edgeInsets =  this.getTextUIEdgeInsets(data)
            maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - this.horizontalSpacingInSpeechMode * 2 - withoutWidth
        } else if (data.type == MindElementType.SUBJECT) {
            let edgeInsets = this.getTextUIEdgeInsets(data)
            if (isParent) {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 15 - this.horizontalSpacingInSpeechMode - this.leftMarginInSpeechMode - withoutWidth
            } else {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 24 - this.leftMarginInSpeechMode * 2 - withoutWidth
            }
        } else if (data.type == MindElementType.SON_SUBJECT) {
            let edgeInsets = this.getTextUIEdgeInsets(data)
            if (isParent) {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 15 - this.horizontalSpacingInSpeechMode - this.leftMarginInSpeechMode - withoutWidth
            } else {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 24 - this.leftMarginInSpeechMode * 2 - withoutWidth
            }
            
        } else if (data.type == MindElementType.CONTENT_GENERALIZATION) {
            let edgeInsets = this.getTextUIEdgeInsets(data)
            if (isParent) {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 15 - this.horizontalSpacingInSpeechMode - this.leftMarginInSpeechMode - withoutWidth
            } else {
                maxWidth = this.getScreenWidth() - edgeInsets.left - edgeInsets.right - 24 - this.leftMarginInSpeechMode * 2 - withoutWidth
            }
        }
        return maxWidth
    }
    
    isParentNodeMoveTop(data) {
        if (!data.isEmpty()) {
            let lastData = this.getLastShowData(data)
            if (!lastData.isEmpty()) {
                if (lastData.id == data.parentNodeId && !this.isRemoveFromUi(lastData) && !this.isDataTop(lastData)) {
                    return true
                }
            }
        }
        return false
    }
    
    isLastDataParentNode(data) {
        if (!data.isEmpty()) {
            let lastData = this.getLastShowData(data)
            if (!lastData.isEmpty()) {
                if (lastData.id == data.parentNodeId && !this.isRemoveFromUi(lastData)) {
                    return true
                }
            }
        }
        return false
    }
    
    isNodeShowParentNode(data) {
        let parentNode = this.getNodeById(data.parentNodeId)
        if (parentNode.isEmpty() || parentNode.children.length == 0) {
            return false
        }
        let showParentNode = this.getSpeechModeShowDataById(parentNode.value.id)
        if (!showParentNode.isEmpty()) {
            return true
        }
        return false
    }
    
    getTextUIEdgeInsets(data) {
        return (new TextCalculation(data, data.layout)).getTextEdgeInsets();
    }
    
    getLineForSpeech(data) {
        let nodeId = data.id
        var line = this.mainMindNodeUnit.getNodeLine(nodeId, false)
        if (line.isEmpty()) {
            let lines = this.doubleBubbleMindNodeUnit.getNodeLines(nodeId)
            if (lines.length > 0 && (lines.length > 1 || !lines[0].isEmpty())) {
                line = lines[0];
            }
        }
        
        if (line.isEmpty()) {
            this.freeNodes.forEach(unit => {
                let unitLine = unit.getNodeLine(nodeId, false)
                if (!unitLine.isEmpty()) {
                    line = unitLine
                }
            })
        }
        if (line.isEmpty()) {
            return new MindElementData()
        }
        
        let parentNodeData = this.getSpeechModeShowDataById(data.parentNodeId)
        if (parentNodeData.isEmpty()) {
            return new MindElementData()
        }
        
        let SonNodeHorizontalSpacee = Config.SonNodeHorizontalSpacee
        let NodeHorizontalSpacee = Config.NodeHorizontalSpacee
        
        let lineData = line.copy()
        let lineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE
        lineData.isHidden = false
        lineData.layout = NodeLayoutType.LAYOUT_TREE_RIGHT
        let unitSize = this.getUnitSize(data)
        let unitParentSize = this.getUnitSize(parentNodeData)

        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE
            lineData.y = parentNodeData.y + parentNodeData.height;
            lineData.x = parentNodeData.x + parentNodeData.getTextEdgeInsets().left + unitParentSize.width / 2
            
            if (parentNodeData.textContent != null) {
                lineData.x += Math.min(parentNodeData.textContent.x, 8)
            } else if (parentNodeData.generalizationContent != null) {
                lineData.x +=  Math.min(parentNodeData.generalizationContent.x, 8)
            }
            
            lineData.width = Math.abs(Math.min((data.x - lineData.x) + (data.getTextEdgeInsets().left * 0.60), NodeHorizontalSpacee))
            if (unitSize.height > 0) {
                lineData.height = data.y - lineData.y + (unitSize.height) / 2 + (data.getTextEdgeInsets().top)
            } else {
                lineData.height = data.y - lineData.y + data.height / 2
            }
            if (data.textContent != null) {
                lineData.height += data.textContent.y
            } else if (data.generalizationContent != null) {
                lineData.height += data.generalizationContent.y
            }
            let startPoint = new CGPoint(0, 0)
            let endPoint = new CGPoint((lineData.width), (lineData.height))
            
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            
            lineData.lineContent.orientation = LineOrientation.RIGHT
            lineData.lineContent.lineLayout = lineLayout
            
        } else {
            lineData.type = MindElementType.SON_LINE
            lineData.y = parentNodeData.y + parentNodeData.height;
            lineData.x = parentNodeData.x + (parentNodeData.getTextEdgeInsets().left) + (unitParentSize.width / 2)            
            if (parentNodeData.textContent != null) {
                lineData.x += Math.min(parentNodeData.textContent.x, 8)
            } else if (parentNodeData.generalizationContent != null) {
                lineData.x +=  Math.min(parentNodeData.generalizationContent.x, 8)
            }
            lineData.width = Math.abs(Math.min(data.x - lineData.x + (data.getTextEdgeInsets().left * 0.3), SonNodeHorizontalSpacee))
            if (unitSize.height > 0) {
                lineData.height = data.y - lineData.y + (unitSize.height) / 2 + (data.getTextEdgeInsets().top)
            } else {
                lineData.height = data.y - lineData.y + data.height / 2
            }
            if (data.textContent != null) {
                lineData.height += data.textContent.y
            } else if (data.generalizationContent != null) {
                lineData.height += data.generalizationContent.y
            }
            var curveStartPoint = new CGPoint(0, 0);
            var curveEndPoint = new CGPoint(0,  (lineData.height));
            var straightEndPoint = new CGPoint((lineData.width), (lineData.height))
                       
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
        
            lineData.lineContent.orientation = LineOrientation.RIGHT
            lineData.lineContent.lineLayout = lineLayout
        }
        lineData.lineContent.color = this.settingData.playBackground.lineColor
        lineData.lineContent.lineWidth = 4.0        
        lineData.lineContent.lineThicken = false
        return lineData
    }

    getLineForSpeechBracketsRight(data) {
        let nodeId = data.id
        var line = this.mainMindNodeUnit.getNodeLine(nodeId, false)
        if (line.isEmpty()) {
            let lines = this.doubleBubbleMindNodeUnit.getNodeLines(nodeId)
            if (lines.length > 0 && (lines.length > 1 || !lines[0].isEmpty())) {
                line = lines[0];
            }
        }
        
        if (line.isEmpty()) {
            this.freeNodes.forEach(unit => {
                let unitLine = unit.getNodeLine(nodeId, false)
                if (!unitLine.isEmpty()) {
                    line = unitLine
                }
            })
        }
        if (line.isEmpty()) {
            return new MindElementData()
        }
        
        let parentNodeData = this.getSpeechModeShowDataById(data.parentNodeId)
        if (parentNodeData.isEmpty()) {
            return new MindElementData()
        }
        let lineData = line.copy()
        let lineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE
        lineData.isHidden = false
        let BracketsRightSpacee = (new UiUtil()).isPhoneAndPad() ? Config.BracketsRightSpacee * 0.5 : Config.BracketsRightSpacee
        lineData.layout = NodeLayoutType.LAYOUT_BRACKETS_RIGHT;
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.CONTENT_GENERALIZATION) {
            lineData.type = MindElementType.LINE;
            lineData.y = data.y + data.height / 2;
            lineData.x = parentNodeData.x + parentNodeData.width + BracketsRightSpacee;

            lineData.width = data.x - lineData.x - BracketsRightSpacee;
            lineData.height = parentNodeData.y - lineData.y + parentNodeData.height / 2;
            let startPoint = new Point(0, lineData.height);
            let endPoint = new Point(lineData.width, 0);
            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent(startPoint, endPoint, 0x333333, data.id);
            } else {
                lineData.lineContent.setStartPoint(startPoint);
                lineData.lineContent.setEndPoint(endPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineWidth = 6.0        
        } else {

            lineData.type = MindElementType.SON_LINE;
            lineData.y = parentNodeData.y + parentNodeData.height / 2;
            lineData.x = parentNodeData.x + parentNodeData.width + BracketsRightSpacee;

            let curveStartPoint = new Point(0, 0);
            let curveEndPoint = new Point(0, 0);
            let straightEndPoint = new Point(0, 0);

            lineData.height = data.y - lineData.y + data.height / 2;
            lineData.width = data.x - lineData.x - BracketsRightSpacee;

            curveStartPoint = new Point(0, 0);
            curveEndPoint = new Point(lineData.width / 2, lineData.height);
            straightEndPoint = new Point(lineData.width, lineData.height);

            if (lineData.lineContent == null) {
                lineData.lineContent = new LineElementContent();
                lineData.lineContent.setLineElementContent(curveStartPoint, curveEndPoint, 0x333333, data.id, straightEndPoint);
            } else {
                lineData.lineContent.setCurveStartPoint(curveStartPoint);
                lineData.lineContent.setCurveEndPoint(curveEndPoint);
                lineData.lineContent.setStraightEndPoint(straightEndPoint);
            }
            lineData.lineContent.orientation = LineOrientation.RIGHT;
            lineData.lineContent.lineWidth = 6.0        
        }
        lineData.lineContent.lineLayout = lineLayout
        lineData.lineContent.color = this.settingData.playBackground.lineColor
        
        lineData.lineContent.lineThicken = false
        return lineData
    }

    getScreenWidth() {
        let width = Util.getScreenWidth()

        return width + 12; //12滚动条宽度
    }

    getScreenHeight() {
        return Util.getScreenHeight()
    }
}
export default MindMapPlayVM
