var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content-box",attrs:{"id":"verification-code-login"}},[_c('div',{staticClass:"login-right"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"lright-cont"},[_c('div',{staticClass:"login-content"},[_c('div',{staticClass:"login"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmitLogin}},[_c('div',{staticClass:"drop-down d-quhao"},[_c('img',{staticStyle:{"float":"left","margin-top":"9px"},attrs:{"src":require("../../../assets/img/code/phone_number_logo.png"),"alt":""}}),_c('country-code-selector',{staticClass:"qudeng",staticStyle:{"margin-left":"17px"},attrs:{"countryCode":_vm.countryCode},on:{"update:countryCode":function($event){_vm.countryCode=$event},"update:country-code":function($event){_vm.countryCode=$event}}})],1),_c('div',{staticClass:"modal-backdrop fade in",staticStyle:{"z-index":"1"}}),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'phoneNumber',
                    {
                      rules: [{ validator: _vm.phoneNumber }],
                    } ]),expression:"[\n                    'phoneNumber',\n                    {\n                      rules: [{ validator: phoneNumber }],\n                    },\n                  ]"}],ref:"phoneNumber",staticClass:"login-phone-number",attrs:{"oninput":"if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\\d]/g,'')","placeholder":_vm.getString(_vm.strings.User_Lolgin_Please_Enter_Phone_Number),"id":"kodeid","allow-clear":""},on:{"blur":_vm.phoneNumberBlurForValidate}})],1),_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/verification_code_logo.png"),"alt":""}})]),_c('a-form-item',[_c('div',{staticClass:"dr-agroup"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'captcha',
                      {
                        rules: [
                          {
                            required: true,
                            message: _vm.getString(
                              _vm.strings.Input_Please_Enter_Verification_Code
                            ),
                          },
                          {
                            validator: _vm.validataToCode,
                          } ],
                      } ]),expression:"[\n                      'captcha',\n                      {\n                        rules: [\n                          {\n                            required: true,\n                            message: getString(\n                              strings.Input_Please_Enter_Verification_Code\n                            ),\n                          },\n                          {\n                            validator: validataToCode,\n                          },\n                        ],\n                      },\n                    ]"}],ref:"CodeValue",attrs:{"allow-clear":"","oninput":"if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\\d]/g,'')","placeholder":_vm.getString(
                        _vm.strings.User_Lolgin_Please_Enter_Verification_Code
                      )}}),_c('div',{on:{"click":_vm.handleSubmitCode}},[(_vm.showTimeData)?_c('a-button',{staticClass:"dra-btn scode",attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.timeData)+" "),_c('span',[_vm._v("s")])]):_c('a-button',{staticClass:"dra-btn"},[_vm._v(_vm._s(_vm.getString(_vm.strings.User_Lolgin_Obtain)))])],1)],1)]),(!_vm.enterInvitationCode)?_c('section',[_c('div',{staticClass:"drop-down"},[_c('img',{attrs:{"src":require("../../../assets/img/code/Invitation_code_logo.png"),"alt":""}})]),(_vm.optional)?_c('div',{staticClass:"drop-down-before",on:{"click":function($event){_vm.enterInvitationCode = !_vm.enterInvitationCode}}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_Optional))+" ")]):_vm._e(),_c('a-form-item',{staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"dr-agroup"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'invitation',
                        {
                          rules: [
                            {
                              required: true,
                              message: _vm.getString(
                                _vm.strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                              ),
                            } ],
                        },
                        (_vm.maxLength = 12) ]),expression:"[\n                        'invitation',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: getString(\n                                strings.User_Lolgin_Please_Correct_Enter_Invitation_Code\n                              ),\n                            },\n                          ],\n                        },\n                        (maxLength = 12),\n                      ]"}],ref:"invitation",attrs:{"allow-clear":"","placeholder":_vm.getString(
                          _vm.strings.User_Lolgin_Please_Enter_Invitation_Code
                        )},on:{"focus":function($event){_vm.optional = false}}})],1)])],1):_vm._e(),_c('a-form-item',_vm._b({staticStyle:{"margin-bottom":"0"}},'a-form-item',_vm.tailFormItemLayout,false),[_c('a-button',{staticClass:"Login-Button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.User_Lolgin_Login))+" ")])],1),(_vm.enterInvitationCode)?_c('section',{staticClass:"enter-invitation-code"},[_c('span',{on:{"click":function($event){return _vm.emailVerificationCodeLogin()}}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mailbox_Registration))+" ")]),_c('span',{on:{"click":function($event){_vm.enterInvitationCode = !_vm.enterInvitationCode}}},[_vm._v(" "+_vm._s(_vm.getString(_vm.strings.Mind_Group_Enter_Invitation_Code))+" ")])]):_vm._e()],1)],1)]),_c('div',{staticClass:"Login-code",on:{"click":_vm.ClickShowCode}},[_c('img',{attrs:{"src":require("../../../assets/img/code/qrcode9.png"),"alt":""}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }