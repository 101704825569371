
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"
import MindBaseFormat from "./MindBaseFormat"
import MindType from "../../../viewmodel/datatype/MindType"
import Colors from "../../../utils/Colors"
import MindBGStripesType from "../../../viewmodel/datatype/MindBGStripesType"
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType"
import DeviceType from "../../../viewmodel/datatype/DeviceType"
import HashMap from "../../../viewmodel/core/base/HashMap"
import MindFormatCheck from "./MindFormatCheck"
import Config from "../calcule/Config"

import { length } from "file-loader"
import { getMachineId } from "../../../common/netWork/base"
import SettingData from "../../../viewmodel/core/minddata/SettingData"
import Util from "../../../utils/Util"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/22
 * Copyright(c) 2020 mindyushu.com
 */

class MindDateFormat extends MindBaseFormat {

    constructor() {
        super();
        this.rootNode = ""; //主体树状数据结构
        this.rootLine = new Array(); //主体线
        this.generalizationLine = new Array(); //概要线
        this.generalizationNode = new Array(); //概要节点
        this.explainData = new Array(); //浮动解释节点
        this.globalLineLayout = LineLayout.UNKNOWN;
        this.globalLayout = NodeLayoutType.LAYOUT_UNKNOWN;
        this.nodeConnectLinet = new Array(); //节点连接线
        this.encircle = new Array(); //包裹节点
        this.freeNodes = new Array();//自由节点
        this.mindType = MindType.LINE_MAP;
        this.mindBGColor = Colors.white;
        this.settingData = new SettingData();
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.mindDisplayType = MindDisplayType.MindMap;
    }

    setDatas(rootNode,
        rootLine,
        generalizationLine,
        generalizationNode,
        freeNodes,
        encircle,
        explainData) {
        this.rootNode = rootNode;
        this.rootLine = rootLine;
        this.generalizationLine = generalizationLine;
        this.generalizationNode = generalizationNode;
        this.freeNodes = freeNodes;
        this.encircle = encircle;
        this.explainData = explainData;
        this.uuid = getMachineId();
        return this;
    }

    setLayout(globalLayout, globalLineLayout) {
        this.globalLayout = globalLayout;
        this.globalLineLayout = globalLineLayout;
        return this;
    }

    toJSONString() {
        this.deviceType = DeviceType.WEB;
        this.nodeConnectLineControlPointMap = {};
        this.mindOriginDataRelativeRootInfo = {};
        this.freeNodes.forEach(unit => {
            unit.nodeConnectLineControlPointMap = {};
            unit.mindOriginDataRelativeRootInfo = {};
        });
        this.modifyTime = Util.getCurrentTime()
        let that = this
        let nodeStr = JSON.stringify(this, function (key, val) {
            if (typeof val === "function") {
                return val + '';
            }
            if (that.isRemovMindElementKeyForToJson(key, val)) {
                return undefined
            }
            return that.formatNumber(val)
        });
        return nodeStr;
    }

    format(vaule) {
        let mind = JSON.parse(vaule)
        let mindDateFormat = this.deserializationData(mind)
        if (mindDateFormat != null && mindDateFormat.rootNode != null) {
            mindDateFormat.checkDate();
            return mindDateFormat;
        }
        return new MindDateFormat();
    }

    setNodeToDictStructure(checkData, node) {
        this.resetDataInCheckData(checkData, node.value);
        node.children.forEach(child => {
            this.setNodeToDictStructure(checkData, child);
        });
    }

    resetDataInCheckData(checkData, data) {
        checkData.checkData(data);
    }

    resetData(checkData) {
        this.setNodeToDictStructure(checkData, this.rootNode);
        this.rootLine.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });
        this.generalizationLine.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });
        this.generalizationNode.forEach(node => {
            this.setNodeToDictStructure(checkData, node);
        });
        this.encircle.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });
        this.explainData.forEach(data => {
            this.resetDataInCheckData(checkData, data);
        });
        this.freeNodes.forEach(unit => {
            this.setNodeToDictStructure(checkData, unit.rootNode);
            unit.rootLine.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });
            unit.generalizationLine.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });
            unit.generalizationNode.forEach(node => {
                this.setNodeToDictStructure(checkData, node);
            });
            unit.encircle.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });
            unit.explainData.forEach(data => {
                this.resetDataInCheckData(checkData, data);
            });
        });
        this.nodeConnectLinet.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });
    }

    checkDate() {
        this.checkPoint(this.rootNode);
        this.freeNodes.forEach(item => {
            if (item.rootNode != null) {
                item.checkPoint(item.rootNode);
            }
        });
        if (this.deviceType == DeviceType.UNKNOWN) {
            this.deviceDensity = 2;
        }
        let checkData = new MindFormatCheck(this.deviceType, this.deviceDensity, this.rootNode, 
            this.nodeConnectLinet, this.version, this.freeNodes, this.uuid, this.settingData);
        checkData.setRootLine(this.rootLine);
        checkData.setIsOriginCutValue(true);
        this.resetData(checkData);
        this.nodeConnectLineControlPointMap = checkData.nodeConnectLineControlPointMap;
        this.mindOriginDataRelativeRootInfo = checkData.mindOriginDataRelativeRootInfo;

        checkData.setIsOriginCutValue(false);
        this.resetData(checkData);
    }

    checkPoint(node) {
    }

    getMindDateFormat() {
        return new MindDateFormat();
    }

    deserializationData(mind) {
        let data = this.deserializationMindDateFormat(mind);
        let freeNodes = this.deserializationFreeNodes(mind)
        data.freeNodes = freeNodes;
        return data
    }

}
export default MindDateFormat
