
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import TextContent from "../../../viewmodel/mindelementdata/mindcontent/TextContent"
import LineElementContent from "../../../viewmodel/mindelementdata/mindcontent/LineElementContent"
import GeneralizationContent from "../../../viewmodel/mindelementdata/mindcontent/GeneralizationContent"
import IconElementContent from "../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import LinkElementContent from "../../../viewmodel/mindelementdata/mindcontent/LinkElementContent"
import RemarksElementContent from "../../../viewmodel/mindelementdata/mindcontent/RemarksElementContent"
import ConnectMapElementContent from "../../../viewmodel/mindelementdata/mindcontent/ConnectMapElementContent"
import TimeLineContent from "../../../viewmodel/mindelementdata/mindcontent/TimeLineContent"
import TimeDotElementContent from "../../../viewmodel/mindelementdata/mindcontent/TimeDotElementContent"
import ImageContent from "../../../viewmodel/mindelementdata/mindcontent/ImageContent";
import StatisticsContent from "../../../viewmodel/mindelementdata/mindcontent/StatisticsContent";
import StatisticsCellData from "../../../viewmodel/mindelementdata/mindcontent/StatisticsCellData";
import TextStyleCell from "../../../viewmodel/mindelementdata/mindcontent/TextStyleCell"
import BoldData from "../../../viewmodel/mindelementdata/mindcontent/BoldData"
import TaskContent from "../../../viewmodel/mindelementdata/mindcontent/TaskContent"
import People from "../../../viewmodel/mindelementdata/mindcontent/People"
import LatexContent from "../../../viewmodel/mindelementdata/mindcontent/LatexContent"
import ResourceContent from "../../../viewmodel/mindelementdata/mindcontent/ResourceContent"
import MoreContent from "../../../viewmodel/mindelementdata/mindcontent/MoreContent"
import OrderContent from "../../../viewmodel/mindelementdata/mindcontent/OrderContent"

class MindElementDataDeserialize {

    deserialize(dataValue) {
        if (dataValue == null) {
            return new MindElementData().emptyMindNode();
        }
        let mindElementData = new MindElementData()
        for (var mindElementDataKey in dataValue) {
            let mindElementDataValue = dataValue[mindElementDataKey]
            mindElementData[mindElementDataKey] = mindElementDataValue
            if (mindElementDataKey == "lineContent" && mindElementDataValue != null) {
                let lineContent = new LineElementContent()
                for (var lineContentKey in mindElementDataValue) {
                    let lineContentValue = mindElementDataValue[lineContentKey]
                    lineContent[lineContentKey] = lineContentValue
                    if ((lineContentKey == "lineLayout" ||
                        lineContentKey == "orientation" ||
                        lineContentKey == "startShape" ||
                        lineContentKey == "endShape" ||
                        lineContentKey == "encircleShapeType" ||
                        lineContentKey == "encircleTitleType" ||
                        lineContentKey == "connectLineType") &&
                        lineContentValue != null &&
                        typeof lineContentValue == "string") {
                        lineContent[lineContentKey] = parseInt(lineContentValue)
                    } else if (lineContentKey == "textContent" && lineContentValue != null) {
                        let textContent = new TextContent()
                        for (var textContentKey in lineContentValue) {
                            let textContentValue = lineContentValue[textContentKey]
                            textContent[textContentKey] = textContentValue
                        }
                        lineContent[lineContentKey] = textContent
                    }
                }
                mindElementData[mindElementDataKey] = lineContent
            } else if ((mindElementDataKey == "textContent" ||
                        mindElementDataKey == "titleContent") && mindElementDataValue != null) {
                let textContent = new TextContent()
                for (var textContentKey in mindElementDataValue) {
                    let textContentValue = mindElementDataValue[textContentKey]
                    textContent[textContentKey] = textContentValue

                    if (textContentKey == "styleCells" && textContentValue != null) {
                        let styleCells = new Array();
                        let styleCellsSize = textContentValue.length;
                        for (let index = 0; index < styleCellsSize; index++) {
                            let cell = new TextStyleCell();
                            let styleCellData = textContentValue[index];
                            for (var styleCellDataKey in styleCellData) {
                                let tyleCellValue = styleCellData[styleCellDataKey]
                                cell[styleCellDataKey] = tyleCellValue
                                if (styleCellDataKey == "textBold" ||
                                    styleCellDataKey == "textItalics" ||
                                    styleCellDataKey == "textStrikethrough") {
                                    var bold = new BoldData()
                                    for (var boldKey in tyleCellValue) {
                                        bold[boldKey] = tyleCellValue[boldKey]
                                    }
                                    cell[styleCellDataKey] = bold
                                }
                            }
                            styleCells.push(cell);
                        }
                        textContent[textContentKey] = styleCells
                    }
                }
                mindElementData[mindElementDataKey] = textContent
            } else if (mindElementDataKey == "generalizationContent" && mindElementDataValue != null) {
                let generalizationContent = new GeneralizationContent();
                for (var generalizationContentKey in mindElementDataValue) {
                    let generalizationContentValue = mindElementDataValue[generalizationContentKey]
                    generalizationContent[generalizationContentKey] = generalizationContentValue

                    if (generalizationContentKey == "styleCells" && generalizationContentValue != null) {
                        let styleCells = new Array();
                        let styleCellsSize = generalizationContentValue.length;
                        for (let index = 0; index < styleCellsSize; index++) {
                            let cell = new TextStyleCell();
                            let styleCellData = generalizationContentValue[index];
                            for (var styleCellDataKey in styleCellData) {
                                let tyleCellValue = styleCellData[styleCellDataKey]
                                cell[styleCellDataKey] = tyleCellValue
                                if (styleCellDataKey == "textBold" ||
                                    styleCellDataKey == "textItalics" ||
                                    styleCellDataKey == "textStrikethrough") {
                                    var bold = new BoldData()
                                    for (var boldKey in tyleCellValue) {
                                        bold[boldKey] = tyleCellValue[boldKey]
                                    }
                                    cell[styleCellDataKey] = bold
                                }
                            }
                            styleCells.push(cell);
                        }
                        generalizationContent[generalizationContentKey] = styleCells
                    }
                }
                mindElementData[mindElementDataKey] = generalizationContent
            } else if (mindElementDataKey == "iconElementContents" && mindElementDataValue != null) {
                let iconElementContents = new Array();
                let iconElementContentSize = mindElementDataValue.length;
                for (let index = 0; index < iconElementContentSize; index++) {
                    let iconElementContent = new IconElementContent();
                    let iconElementData = mindElementDataValue[index];
                    for (var iconElementContentKey in iconElementData) {
                        let iconElementContentValue = iconElementData[iconElementContentKey]
                        iconElementContent[iconElementContentKey] = iconElementContentValue
                        if ((iconElementContentKey == "layoutType") &&
                            iconElementContentValue != null && typeof iconElementContentValue == "string") {
                            iconElementContent[iconElementContentKey] = parseInt(iconElementContentValue)
                        }
                    }
                    iconElementContents.push(iconElementContent);
                }
                mindElementData[mindElementDataKey] = iconElementContents
            } else if (mindElementDataKey == "linkElementContent" && mindElementDataValue != null) {
                let linkElementContent = new LinkElementContent();
                for (var linkElementContentKey in mindElementDataValue) {
                    let linkElementContentValue = mindElementDataValue[linkElementContentKey]
                    linkElementContent[linkElementContentKey] = linkElementContentValue
                    if ((linkElementContentKey == "layoutType") &&
                        linkElementContentValue != null && typeof linkElementContentValue == "string") {
                        linkElementContent[linkElementContentKey] = parseInt(linkElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = linkElementContent
            } else if (mindElementDataKey == "remarksElementContent" && mindElementDataValue != null) {
                let remarksElementContent = new RemarksElementContent();
                
                for (var remarksElementContentKey in mindElementDataValue) {
                    let remarksElementContentValue = mindElementDataValue[remarksElementContentKey]
                    remarksElementContent[remarksElementContentKey] = remarksElementContentValue
                    if ((remarksElementContentKey == "layoutType") &&
                        remarksElementContentValue != null && typeof remarksElementContentValue == "string") {
                        remarksElementContent[remarksElementContentKey] = parseInt(remarksElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = remarksElementContent
            } else if (mindElementDataKey == "resourceContent" && mindElementDataValue != null) {
                let resourceContent = new ResourceContent();
                
                for (var resourceContentKey in mindElementDataValue) {
                    let resourceContentValue = mindElementDataValue[resourceContentKey]
                    resourceContent[resourceContentKey] = resourceContentValue
                    if ((resourceContentKey == "type") &&
                        resourceContentValue != null && typeof resourceContentValue == "string") {
                        resourceContent[resourceContentKey] = parseInt(resourceContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = resourceContent
            } else if (mindElementDataKey == "moreContent" && mindElementDataValue != null) {
                let moreContent = new MoreContent();
                
                for (var moreContentKey in mindElementDataValue) {
                    let moreContentValue = mindElementDataValue[moreContentKey]
                    moreContent[moreContentKey] = moreContentValue
                    // if ((moreContentKey == "type") &&
                    // moreContentValue != null && typeof moreContentValue == "string") {
                    //     moreContent[moreContentKey] = parseInt(moreContentValue)
                    // }
                }
                mindElementData[mindElementDataKey] = moreContent
            } else if (mindElementDataKey == "connectMapElementContent" && mindElementDataValue != null) {
                let connectMapElementContent = new ConnectMapElementContent();

                for (var connectMapElementContentKey in mindElementDataValue) {
                    let connectMapElementContentValue = mindElementDataValue[connectMapElementContentKey]
                    connectMapElementContent[connectMapElementContentKey] = connectMapElementContentValue

                    if ((connectMapElementContentKey == "layoutType") &&
                        connectMapElementContentValue != null && typeof connectMapElementContentValue == "string") {
                            connectMapElementContent[connectMapElementContentKey] = parseInt(connectMapElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = connectMapElementContent
            } else if (mindElementDataKey == "timeLineContent" && mindElementDataValue != null) {
                let timeLineContent = new TimeLineContent();
                for (var timeLineContentKey in mindElementDataValue) {
                    let timeLineContentValue = mindElementDataValue[timeLineContentKey]
                    timeLineContent[timeLineContentKey] = timeLineContentValue
                    if ((timeLineContentKey == "nodeType" ||
                        timeLineContentKey == "orientationType" ||
                        timeLineContentKey == "timeNodeLayoutType") &&
                        timeLineContentValue != null &&
                        typeof timeLineContentValue == "string") {
                        timeLineContent[timeLineContentKey] = parseInt(timeLineContentValue)
                    }
                    if (timeLineContentKey == "dots") {
                        let dots = new Array();
                        let dotsSize = timeLineContentValue.length;
                        for (var index = 0; index < dotsSize; index++) {
                            let timeDotElementContent = new TimeDotElementContent();
                            let timeDotElementData = mindElementDataValue[index];
                            for (var timeDotElementContentKey in timeDotElementData) {
                                let timeDotElementDataValue = timeDotElementData[timeDotElementContentKey]
                                timeDotElementContent[timeDotElementContentKey] = timeDotElementDataValue
                                if ((timeDotElementContentKey == "color") && timeDotElementDataValue != null && typeof timeDotElementDataValue == "string") {
                                    timeDotElementContent[timeDotElementContentKey] = parseInt(timeDotElementDataValue)
                                }
                            }
                            dots.push(timeDotElementContent);
                        }
                        timeLineContent[timeLineContentKey] = dots
                    }
                }
                mindElementData[mindElementDataKey] = timeLineContent
            } else if (mindElementDataKey == "imageContent" && mindElementDataValue != null) {
                let imageContent = new ImageContent();
                for (var imageContentKet in mindElementDataValue) {
                    let imageContentContentValue = mindElementDataValue[imageContentKet]
                    imageContent[imageContentKet] = imageContentContentValue

                    if ((imageContentKet == "layoutType" || imageContentKet == "shapeType") &&
                        imageContentContentValue != null && typeof imageContentContentValue == "string") {
                        imageContent[imageContentKet] = parseInt(imageContentContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = imageContent
            } else if (mindElementDataKey == "statisticsContent" && mindElementDataValue != null) {
                let statisticsContent = new StatisticsContent();
                for (var statisticsContentKey in mindElementDataValue) {
                    let statisticsContentValue = mindElementDataValue[statisticsContentKey]
                    statisticsContent[statisticsContentKey] = statisticsContentValue

                    if ((statisticsContentKey == "type") && statisticsContentValue != null && typeof statisticsContentValue == "string") {
                        statisticsContent[statisticsContentKey] = parseInt(statisticsContentValue)
                    }
                    if (statisticsContentKey == "cellsData") {
                        let cellsData = new Array();
                        let cellSize = statisticsContentValue.length;
                        for (var index = 0; index < cellSize; index++) {
                            let statisticsCellDataContent = new StatisticsCellData();
                            let statisticsCellData = statisticsContentValue[index];
                            for (var statisticsCellDataKey in statisticsCellData) {
                                let statisticsCellDataValue = statisticsCellData[statisticsCellDataKey]
                                statisticsCellDataContent[statisticsCellDataKey] = statisticsCellDataValue
                                if ((statisticsCellDataKey == "color" || 
                                    statisticsCellDataKey == "lineColor" || 
                                    statisticsCellDataKey == "nameColor" || 
                                    statisticsCellDataKey == "explainColor" || 
                                    statisticsCellDataKey == "explainTitleColor" || 
                                    statisticsCellDataKey == "explainLineColor")
                                 && statisticsCellDataValue != null && typeof statisticsCellDataValue == "string") {
                                    statisticsCellDataContent[statisticsCellDataKey] = parseInt(statisticsCellDataValue)
                                }
                            }
                            cellsData.push(statisticsCellDataContent);
                        }
                        statisticsContent[statisticsContentKey] = cellsData
                    }
                }
                mindElementData[mindElementDataKey] = statisticsContent
            } else if (mindElementDataKey == "taskContent" && mindElementDataValue != null) {
                let taskContent = new TaskContent();
                for (var taskContentKey in mindElementDataValue) {
                    let taskContentValue = mindElementDataValue[taskContentKey]
                    taskContent[taskContentKey] = taskContentValue

                    if ((taskContentKey == "peoples")) {
                        let peoples = new Array();
                        if (taskContentValue == null) {
                            taskContent[taskContentKey] = peoples
                            continue
                        }
                        let conut = taskContentValue.length;
                        for (var index = 0; index < conut; index++) {
                            let people = new People();
                            let peopleData = taskContentValue[index];
                            for (var peopleDataKey in peopleData) {
                                let peopleDataValue = peopleData[peopleDataKey]
                                people[peopleDataKey] = peopleDataValue
                            }
                            peoples.push(people);
                        }
                        taskContent[taskContentKey] = peoples
                    }
                }
                mindElementData[mindElementDataKey] = taskContent
            } else if (mindElementDataKey == "latexContent" && mindElementDataValue != null) {
                let latexContent = new LatexContent();
                for (var latexContentKey in mindElementDataValue) {
                    let latexContentValue = mindElementDataValue[latexContentKey];
                    latexContent[latexContentKey] = latexContentValue;

                }
                mindElementData[mindElementDataKey] = latexContent
            } else if (mindElementDataKey == "orderContent" && mindElementDataValue != null) {
                let orderContent = new OrderContent();
                for (var orderContentKey in mindElementDataValue) {
                    let orderContentValue = mindElementDataValue[orderContentKey];
                    if (orderContentKey == "orderType") {
                        orderContent[orderContentKey] = parseInt(orderContentValue);
                    } else {
                        orderContent[orderContentKey] = orderContentValue;
                    }
                }
                mindElementData[mindElementDataKey] = orderContent
            } else if ((mindElementDataKey == "type" ||
                mindElementDataKey == "layout" ||
                mindElementDataKey == "mindElementShape") &&
                mindElementDataValue != null && typeof mindElementDataValue == "string") {
                mindElementData[mindElementDataKey] = parseInt(mindElementDataValue)
            }
        }
        return mindElementData
    }
    
}

export default new MindElementDataDeserialize()